import React from 'react';
import {Theme} from './theme';

interface SkillLevelBarProps{
    skillText:string,
    skillValue:number,
    theme:Theme,
    width:number
}
 
export class SkillLevelBar extends React.Component<SkillLevelBarProps,{}>{

    render(){
        let colorBG = this.props.theme.getBG(null, 0.1, 0).GetRGB();
        let colorDetail = this.props.theme.getDetail(null, 1, 0).GetRGB();
        let colorShape = this.props.theme.getDetail(null, 0.5, 0).GetRGB();

        let skillOffset = 2 + this.props.skillValue * ((this.props.width/2)-4);
        let skillVoidWidth = (1 - this.props.skillValue) * ((this.props.width/2)-4);

        return (
            <div style={{
                color : colorDetail,
                width: this.props.width + "px",
                display: "flex",
                flexDirection: "row"
            }}>
                <div style={{
                    color : colorDetail,
                    width: "50%",
                    height: "22px",
                    fontSize: "18px",
                    textAlign: "left"
                }}>
                    {this.props.skillText}
                </div>

                <div style={{
                    position: "relative",
                    height: "22px",
                    width: "50%",
                    backgroundColor: colorShape
                }}>
                    <div style={{
                        position: "absolute",
                        top: "2px",
                        left: skillOffset + "px",
                        height: "18px",
                        width: skillVoidWidth + "px",
                        backgroundColor: colorBG
                    }}>

                    </div>
                </div>
            </div>
        )
    }
}

interface SkillLevelViewProps{
    skills: {text:string, value:number}[],
    theme: Theme,
    width: number
}

export class SkillLevelsView extends React.Component<SkillLevelViewProps,{}>{

    constructor(props:SkillLevelViewProps){
        super(props);
    }

    render(){
        let colorBG = this.props.theme.getBG(null, 0.1, 0).GetRGB();
        let colorDetail = this.props.theme.getDetail(null, 1, 0).GetRGB();

        let contents: Array<JSX.Element> = new Array<JSX.Element>();
        let keyInc = 0;

        contents.push(<div key={"key:" + keyInc++} style={{height:"10px"}}></div>);

        for(let i=0; i<this.props.skills.length; i++){
            contents.push(<SkillLevelBar key={"key:" + keyInc++} width={this.props.width} theme={this.props.theme} skillText={this.props.skills[i].text} skillValue={this.props.skills[i].value}/>)
            contents.push(<div key={"key:" + keyInc++} style={{height:"17px"}}></div>);
        }

        return (
            <div style={{
                color : colorDetail
            }}>
                {contents}
            </div>
        )
    }
}
