import * as React from "react";
import { JGLogo } from './JGLogo';
import { ThemeComponent, Theme } from './theme';
import { JGSelect } from './JGSelect';
import { ReduxStateLink , ReduxStateBundle, Redux } from '../tools/Redux';
import { jsxAttribute } from "@babel/types";

///  -----------------  JGHeaderOption  -------------------

/*

    #####   ####   #   #       ####   ####  #####   ###   #   #
        #  #    #  #   #      #    #  #   #   #    #   #  ##  #
        #  #       #####      #    #  ####    #    #   #  # # #
    #   #  #   ##  #   #  ##  #    #  #       #    #   #  #  ##
     ###    ### #  #   #  ##   ####   #      ###    ###   #   #

*/

interface JGHeaderOptionProps{
    key : string;
    optionID : any;
    optionText : string;
    theme : Theme;
    panelScale : number;
    onChoise : (optionID:string) => void;
    owner : JGHeader;
    choiseIndex : number;
    displayMode : string; // "regular", "swiftUpdate"
}

// JGHeaderOption is a help class to JGHeader that displays a menu button
class JGHeaderOption extends React.Component<JGHeaderOptionProps,{}>{

    private mFigureWidth : number;
    private mHoverLevel : number;
    private mHasPassedImageLoadCheck : boolean;

    constructor(props:JGHeaderOptionProps){
        super(props);
        this.mFigureWidth = 400; // temporary default value
        props.owner.connectOption(this,props.optionID);
        this.mHoverLevel = 0;
        this.mHasPassedImageLoadCheck = false;
    }

    public static getBaseWidthOfCharacter(character:string){
        switch(character){
            case "A" : return 1.4;
            case "B" : return 1.2;
            case "C" : return 1.3;
            case "D" : return 1.5;
            case "E" : return 1.1;
            case "F" : return 1.05;
            case "G" : return 1.45;
            case "H" : return 1.5;
            case "I" : return 0.55;
            case "J" : return 0.7;
            case "K" : return 1.2;
            case "L" : return 1.0;
            case "M" : return 1.9;
            case "N" : return 1.6;
            case "O" : return 1.6;
            case "P" : return 1.2;
            case "Q" : return 1.6;
            case "R" : return 1.3;
            case "S" : return 1.15;
            case "T" : return 1.1;
            case "U" : return 1.4;
            case "V" : return 1.3;
            case "W" : return 2.0;
            case "X" : return 1.25;
            case "Y" : return 1.2;
            case "Z" : return 1.2;
            case "Å" : return 1.35;
            case "Ä" : return 1.35;
            case "Ö" : return 1.6;
            case "Æ" : return 1.8;
            case "Ø" : return 1.6;
            case "a" : return 1.1;
            case "b" : return 1.3;
            case "c" : return 1.0;
            case "d" : return 1.3;
            case "e" : return 1.1;
            case "f" : return 0.7;
            case "g" : return 1.25;
            case "h" : return 1.2;
            case "i" : return 0.5;
            case "j" : return 0.5;
            case "k" : return 1.05;
            case "l" : return 0.5;
            case "m" : return 1.8;
            case "n" : return 1.2;
            case "o" : return 1.2;
            case "p" : return 1.2;
            case "q" : return 1.2;
            case "r" : return 0.75;
            case "s" : return 0.9;
            case "t" : return 0.75;
            case "u" : return 1.2;
            case "v" : return 1.0;
            case "w" : return 1.5;
            case "x" : return 1.0;
            case "y" : return 1.0;
            case "z" : return 1.0;
            case "å" : return 1.1;
            case "ä" : return 1.1;
            case "ö" : return 1.3;
            case "æ" : return 1.8;
            case "ø" : return 1.3;
            case "\"" : return 0.85;
            case ":" : return 0.5;
            case "?" : return 0.95;
            case "!" : return 0.6;
            case "(" : return 0.65;
            case ")" : return 0.65;
            case "@" : return 2.0;
            case "#" : return 1.25;
            case "&" : return 1.7;
            case "/" : return 0.85;
            case "\\" : return 0.85;
            case ";" : return 0.45;
            case "," : return 0.45;
            case "." : return 0.45;
            case "-" : return 0.85;
            case "_" : return 0.9;
            case "'" : return 0.5;
            case "+" : return 1.45;
            case "*" : return 0.9;
            case ">" : return 1.45;
            case "<" : return 1.45;
            case "0" : return 1.1;
            case "1" : return 1.1;
            case "2" : return 1.1;
            case "3" : return 1.1;
            case "4" : return 1.1;
            case "5" : return 1.1;
            case "6" : return 1.1;
            case "7" : return 1.1;
            case "8" : return 1.1;
            case "9" : return 1.1;
            case " " : return 0.5;
            default:;
        }

        return 1.0;
    }

    public static getBaseWidthOfTextLine(textLine:string){
        let result = 0.0;

        for(let i=0; i<textLine.length; i++){
            result += this.getBaseWidthOfCharacter("" + textLine.charAt(i));
        }

        result = (Math.round( result * 10000 )) / 10000;

        return result;
    }

    componentDidMount(){
        this.generateOptionGraphics();
        this.imageLoadedCheck();
        this.runInitialRefreshes();
    }

    runInitialRefreshes(){
        setTimeout(this.forceUpdate.bind(this), 250);
        setTimeout(this.forceUpdate.bind(this), 500);
        setTimeout(this.forceUpdate.bind(this), 1000);
    }

    imageLoadedCheck(){
        if(this.mHasPassedImageLoadCheck === false){
            setTimeout(this.imageLoadedCheck.bind(this), 100);
            this.forceUpdate()
        }
        else{
            this.generateOptionGraphics();
            this.forceUpdate()
        }
    }

    onThemeUpdate(){
        this.generateOptionGraphics();
    }

    generateOptionGraphics(){
        let elem : any = document.getElementById("optionCanvasWithID:" + this.props.optionID);

        if(elem){
            let canEl : HTMLCanvasElement = elem;
            let cont = canEl.getContext('2d');

            let source = this.props.owner.getTabSource();

            let leftSectionWidth = 160;
            let rightSectionWidth = 230;
            let rightSectionLeftAdjust = 69;
            let rightSectionPosX = this.mFigureWidth+rightSectionLeftAdjust-rightSectionWidth;
            let midSectionWidth = 10;

            if(cont && source){

                for(let posX=leftSectionWidth-2; posX<rightSectionPosX; posX += midSectionWidth ){
                    cont.drawImage(source,leftSectionWidth,0,midSectionWidth,200,posX,0,midSectionWidth,200);
                }

                cont.drawImage(source,0,0,leftSectionWidth,200,0,0,leftSectionWidth,200);
                cont.drawImage(source,400-rightSectionWidth,0,rightSectionWidth,200,this.mFigureWidth+rightSectionLeftAdjust-rightSectionWidth,0,rightSectionWidth,200);
            }
        }

        let elemHover : any = document.getElementById("optionCanvasWithID:" + this.props.optionID + "-hover");

        if(elemHover){
            let canElHover : HTMLCanvasElement = elemHover;
            let contHover = canElHover.getContext('2d');

            let sourceHover = this.props.owner.getTabSourceHover();

            let leftSectionWidth = 160;
            let rightSectionWidth = 230;
            let rightSectionLeftAdjust = 69;
            let rightSectionPosX = this.mFigureWidth+rightSectionLeftAdjust-rightSectionWidth;
            let midSectionWidth = 10;

            if(contHover && sourceHover){

                for(let posX=leftSectionWidth-2; posX<rightSectionPosX; posX += midSectionWidth ){
                    contHover.drawImage(sourceHover,leftSectionWidth,0,midSectionWidth,200,posX,0,midSectionWidth,200);
                }

                contHover.drawImage(sourceHover,0,0,leftSectionWidth,200,0,0,leftSectionWidth,200);
                contHover.drawImage(sourceHover,400-rightSectionWidth,0,rightSectionWidth,200,this.mFigureWidth+rightSectionLeftAdjust-rightSectionWidth,0,rightSectionWidth,200);
            }
        }

        this.forceUpdate()
    }

    advanceHoverEffect(millisecondsPassed:number){
        const previous = this.mHoverLevel;

        let decreaseFactor = 0.8;
        let increaseFactor = 3.2;
        let baseIncrement = 0.01;

        if(((this.props.optionID === this.props.owner.getHoveredTab()) || this.props.optionID === this.props.owner.getSelectedTab())){
            this.mHoverLevel += millisecondsPassed * baseIncrement * increaseFactor;

            if(this.mHoverLevel > 1.0){
                this.mHoverLevel = 1.0;
            }

            if(this.props.optionID !== this.props.owner.getSelectedTab()){
                if(this.mHoverLevel > 0.55){
                    this.mHoverLevel = 0.55;
                }
            }
        }
        else{
            this.mHoverLevel -= millisecondsPassed * baseIncrement * decreaseFactor;
            if(this.mHoverLevel < 0.0){
                this.mHoverLevel = 0.0;
            }
        }

        if(previous !== this.mHoverLevel){
            this.forceUpdate();
        }
    }

    mouseEnterHandler(){
        this.props.owner.setHoveredTab(this.props.optionID);
    }

    mouseLeaveHandler(){
        if(this.props.optionID === this.props.owner.getHoveredTab()){
            this.props.owner.setHoveredTab("NoNe");
        }
    }

    render(){
   
        let textWidth = JGHeaderOption.getBaseWidthOfTextLine(this.props.optionText);
        let scale = 0.35 * this.props.panelScale;

        let midSectionIndividualWidth = 20;
        let midSectionWidth = 0;

        let leftSectionWidth = 125;
        let rightSectionWidth = 195;

        let optionID = "optionCanvasWithID:" + this.props.optionID;

        if(this.props.owner.hasImageLoaded() === true){
            this.mHasPassedImageLoadCheck = true;
        }

        let shadowColor = this.props.theme.getShadow(null).GetRGB();

        let shadowIntensity1 = 0.20;
        let shadowIntensity2 = 0.18;
        let shadowIntensity3 = 0.16;

        for(let i=0; i<((textWidth*2)-8); i++){
            midSectionWidth += midSectionIndividualWidth;
        }

        this.mFigureWidth = leftSectionWidth + midSectionWidth + rightSectionWidth;

        let TabGraphics = null;
        let TabGraphicsHover = null;

        if(this.props.displayMode === "swiftUpdate"){
            TabGraphics = (  // Option using overwritten canvas
                <div style={{
                    width:((this.mFigureWidth)*scale)+"px",
                    height:(200*scale)+"px",
                    maxWidth:((this.mFigureWidth)*scale)+"px",
                    maxHeight:(200*scale)+"px",
                    position:"absolute", 
                    top: "0px", 
                    left: "0px"
                }}>
                    <svg viewBox={"35 0 " + this.mFigureWidth + " 200"}>
                        <defs>
                            <clipPath style={{position:"absolute", top:"0px", left:"0px"}} id={"myClip:" + optionID}>
                                <path d={"M95.3,28.18h" + (236.18 + midSectionWidth) + "c14.17,0,17.85,7.65,14.22,21.8L" + (316.6 + midSectionWidth) + ",152.6c-4.81,14.24-13.87,19-35.36,19H56.88c-8.89,0-17.47-8.51-14.45-18.94L71.72,44.26C76.05,31.89,83,28.18,95.3,28.18Z"} />
                            </clipPath>
                        </defs>
                        <g width={(this.mFigureWidth+50) +""} height={(200) +""}>
                            <foreignObject width={(this.mFigureWidth+50) +""} height={(200) +""} clipPath={"url(#myClip:" + optionID + ")"}>
                                <canvas id={optionID}  width={(this.mFigureWidth+50) +""} height={ 200 + ""}>
                                    canvas not supported
                                </canvas>
                            </foreignObject>
                        </g>
                    </svg>
                </div>
            )

            TabGraphicsHover = (  // Option using overwritten canvas - hovered
                <svg style={{
                        width:((this.mFigureWidth)*scale)+"px",
                        height:(200*scale)+"px",
                        maxWidth:((this.mFigureWidth)*scale)+"px",
                        maxHeight:(200*scale)+"px",
                        position:"absolute", 
                        top: (0*scale) + "px", 
                        left: (0*scale) + "px",
                        opacity: this.mHoverLevel
                    }}
                    viewBox={"35 0 " + this.mFigureWidth + " 200"}>

                    <defs>
                        <clipPath style={{position:"absolute", top:"0px", left:"0px"}} id={"myClip:" + optionID + "-hover"}>
                            <path d={"M95.3,28.18h" + (236.18 + midSectionWidth) + "c14.17,0,17.85,7.65,14.22,21.8L" + (316.6 + midSectionWidth) + ",152.6c-4.81,14.24-13.87,19-35.36,19H56.88c-8.89,0-17.47-8.51-14.45-18.94L71.72,44.26C76.05,31.89,83,28.18,95.3,28.18Z"} />
                        </clipPath>
                    </defs>
                
                    <foreignObject style={{position:"absolute", top:"0px", left:"0px", maxWidth:((this.mFigureWidth+50)*scale)+"px", maxHeight:((200*scale) + "px")}} width={(this.mFigureWidth+50) +"px"} height={(200) +"px"} clipPath={"url(#myClip:" + optionID + "-hover" + ")"}>
                        <canvas style={{position:"absolute", top:"0px", left:"0px"}} id={optionID + "-hover"} width={(this.mFigureWidth+50) +""} height={(200) +""}>
                            canvas not supported
                        </canvas>
                    </foreignObject>
                </svg>
            )
        }
        else{
            TabGraphics = (  //  Tab graphics - string based url style
                <div style={{
                    width:((this.mFigureWidth+69)*scale)+"px",
                    height:(200*scale)+"px",
                    position:"absolute",
                    top: (0*scale) + "px",
                    left: (-32*scale) + "px",
                    opacity: 1.0,
                    display:"flex",
                    msFlexDirection:"row"
                }}>
                    <div style={{ 
                            width:(160*scale)+"px",
                            height:(200*scale)+"px",
                            backgroundImage: this.props.owner .getTabImageUrl("left"),
                            backgroundSize: (160 * scale) + "px " + (200 * scale) + "px",
                            opacity: 1.0
                        }} 
                    />

                    <div style={{ 
                            width:(((this.mFigureWidth+69)-390)*scale)+"px",
                            height:(200*scale)+"px",
                            backgroundImage: this.props.owner .getTabImageUrl("center"),
                            backgroundSize: (10*scale) + "px " + (200 * scale) + "px",
                            opacity: 1.0
                        }} 
                    />

                    <div style={{ 
                            width:(230*scale)+"px",
                            height:(200*scale)+"px",
                            backgroundImage: this.props.owner .getTabImageUrl("right"),
                            backgroundSize: (230 * scale) + "px " + (200 * scale) + "px",
                            opacity: 1.0
                        }} 
                    />
                </div>
            )

            TabGraphicsHover = ( //   Tab graphics hover - string based url style
                <div style={{
                    width:((this.mFigureWidth+69)*scale)+"px",
                    height:(200*scale)+"px",
                    position:"absolute", 
                    top: (0*scale) + "px", 
                    left: (-32*scale) + "px",
                    opacity: this.mHoverLevel,
                    display:"flex",
                    msFlexDirection:"row"               
                }}>
                    <div style={{ 
                            width:(160*scale)+"px",
                            height:(200*scale)+"px",
                            backgroundImage: this.props.owner.getTabImageUrlHover("left"),
                            backgroundSize: (160 * scale) + "px " + (200 * scale) + "px",
                            opacity: 1.0
                        }} 
                    />

                    <div style={{ 
                            width:(((this.mFigureWidth+69)-390)*scale)+"px",
                            height:(200*scale)+"px",
                            backgroundImage: this.props.owner.getTabImageUrlHover("center"),
                            backgroundSize: (10*scale) + "px " + (200 * scale) + "px",
                            opacity: 1.0
                        }} 
                    />

                    <div style={{ 
                            width:(230*scale)+"px",
                            height:(200*scale)+"px",
                            backgroundImage: this.props.owner.getTabImageUrlHover("right"),
                            backgroundSize: (230 * scale) + "px " + (200 * scale) + "px",
                            opacity: 1.0
                        }} 
                    />
                </div>
            )
        }

        return (
            <div style={{
                width:(this.mFigureWidth*scale)+"px",
                height:(200*scale)+"px",
                fontSize:(80*scale)+"px",
                position:"relative",
                top:(-25 * scale)+"px", 
                left:"-" + ((100 + (this.props.choiseIndex * 10))*scale) + "px"
            }}>
                {/*  Shadow layer - 1  */}
                <svg style={{ 
                        width:((this.mFigureWidth)*scale)+"px", 
                        height:(200*scale)+"px",
                        position:"absolute", 
                        top: (3*scale) + "px", 
                        left: (3*scale) + "px",
                        opacity: shadowIntensity1
                    }} 
                    viewBox={"35 0 " + this.mFigureWidth + " 200"}
                >
                    {/*  Figure  */}
                    <defs>
                        <style>{".cls_menubutton_Sh{fill:" + shadowColor + "}"}
                        </style>
                    </defs>
                    <path 
                        className="cls_menubutton_Sh" 
                        d={"M95.3,28.18h" + (236.18 + midSectionWidth) + "c14.17,0,17.85,7.65,14.22,21.8L"+ (316.6 + midSectionWidth) +",152.6c-4.81,14.24-13.87,19-35.36,19H56.88c-8.89,0-17.47-8.51-14.45-18.94L71.72,44.26C76.05,31.89,83,28.18,95.3,28.18Z"}
                    />
                </svg>

                {/*  Shadow layer - 2  */}
                <svg style={{ 
                        width:((this.mFigureWidth)*scale)+"px",
                        height:(200*scale)+"px",
                        position:"absolute",
                        top: (6*scale) + "px",
                        left: (6*scale) + "px",
                        opacity: shadowIntensity2
                    }} 
                    viewBox={"35 0 " + this.mFigureWidth + " 200"}
                >
                    {/*  Figure  */}
                    <path 
                        className="cls_menubutton_Sh" 
                        d={"M95.3,28.18h" + (236.18 + midSectionWidth) + "c14.17,0,17.85,7.65,14.22,21.8L"+ (316.6 + midSectionWidth) +",152.6c-4.81,14.24-13.87,19-35.36,19H56.88c-8.89,0-17.47-8.51-14.45-18.94L71.72,44.26C76.05,31.89,83,28.18,95.3,28.18Z"}
                    />
                </svg>

                {/*  Shadow layer - 3  */}
                <svg style={{ 
                        width:((this.mFigureWidth)*scale)+"px", 
                        height:(200*scale)+"px",
                        position:"absolute", 
                        top: (9*scale) + "px", 
                        left: (9*scale) + "px",
                        opacity: shadowIntensity3
                    }} 
                    viewBox={"35 0 " + this.mFigureWidth + " 200"}
                >
                    {/*  Figure  */}
                    <path 
                        className="cls_menubutton_Sh" 
                        d={"M95.3,28.18h" + (236.18 + midSectionWidth) + "c14.17,0,17.85,7.65,14.22,21.8L"+ (316.6 + midSectionWidth) +",152.6c-4.81,14.24-13.87,19-35.36,19H56.88c-8.89,0-17.47-8.51-14.45-18.94L71.72,44.26C76.05,31.89,83,28.18,95.3,28.18Z"}
                    />
                </svg>

                { TabGraphics }
                { TabGraphicsHover }

                {/* Option text */}
                <div style={{
                    position:"absolute", 
                    top: (45 * scale) + "px", 
                    left: (75 * scale) + "px",
                    width: ((this.mFigureWidth - 150 )*scale)+"px",
                    height:(100*scale)+"px",
                    color: this.props.theme.getDetail(null).GetRGB(),
                    textAlign:"center"
                }}>
                    {this.props.optionText}
                </div>

                {/* Option text - hovered */}
                <div style={{
                    position:"absolute", 
                    top: (45 * scale) + "px", 
                    left: (75 * scale) + "px",
                    width: ((this.mFigureWidth - 150 )*scale)+"px",
                    height:(100*scale)+"px",
                    color: this.props.theme.getDetail(null, 0.5, 1).GetRGB(),
                    textAlign:"center",
                    opacity: this.mHoverLevel
                }}>
                    {this.props.optionText}
                </div>

                {/*  Collective mouse section  */}
                <svg style={{ 
                        width:(this.mFigureWidth*scale)+"px", 
                        height:(200*scale)+"px",
                        position:"absolute", 
                        top: 0 + "px", 
                        left: 0 + "px"
                    }} 
                    viewBox={"35 0 " + this.mFigureWidth + " 200"}>

                    {/*  Figure  */}
                    <defs>
                        <style>{".cls_menubutton_D2{opacity:0.0;fill:gray}"}
                        </style>
                    </defs>
                    <path 
                        style={{
                            cursor: "pointer"
                        }}
                        onMouseEnter={this.mouseEnterHandler.bind(this)}
                        onMouseLeave={this.mouseLeaveHandler.bind(this)}
                        onClick={(e)=>{this.props.onChoise(this.props.optionID)}}
                        className="cls_menubutton_D2" 
                        d={"M95.3,28.18h" + (236.18 + midSectionWidth) + "c14.17,0,17.85,7.65,14.22,21.8L"+ (316.6 + midSectionWidth) +",152.6c-4.81,14.24-13.87,19-35.36,19H56.88c-8.89,0-17.47-8.51-14.45-18.94L71.72,44.26C76.05,31.89,83,28.18,95.3,28.18Z"}
                    />
                </svg>

            </div>
        )
    }  
}





// JGHeaderCollapsed is a button with a popup list of items replacing the 
// bar options whenever there is not room enough for them all as buttons on the bar

/*
    #####   ####   #   #       ###   ###   #     #      ###   ####    ###   #####  ####
        #  #    #  #   #      #     #   #  #     #     #   #  #   #  #      #      #   #
        #  #       #####      #     #   #  #     #     #####  ####    ###   ###    #   #
    #   #  #   ##  #   #  ##  #     #   #  #     #     #   #  #          #  #      #   #
     ###    ### #  #   #  ##   ###   ###   ####  ####  #   #  #      ####   #####  ####

*/

interface JGHeaderCollapsedProps{
    theme : Theme;
    panelScale : number;
    onExpand : () => void;
    owner : JGHeader;
}

class JGHeaderCollapsed extends React.Component<JGHeaderCollapsedProps,{}>{

    private mHoverLevel : number;
    private mHasPassedImageLoadCheck : boolean;

    constructor(props:JGHeaderCollapsedProps){
        super(props);
        props.owner.connectCollapsed(this);
        this.mHoverLevel = -0.0001;
        this.mHasPassedImageLoadCheck = false;
    }

    componentDidMount(){
        this.generateCollapsedGraphics();
        this.imageLoadedCheck();
    }

    onThemeUpdate(){
        this.generateCollapsedGraphics();
    }

    generateCollapsedGraphics(){
        let elem : any = document.getElementById("collapsedCanvas");

        if(elem){
            let canEl : HTMLCanvasElement = elem;
            let cont = canEl.getContext('2d');

            let source = this.props.owner.getCollapsedSource();

            let collapsedWidth = 300;
            let collapsedHeight = 200;

            if(cont && source){
                cont.drawImage(source,0,0,collapsedWidth,collapsedHeight,0,0,collapsedWidth,collapsedHeight);
            }
        }

        let elemHover : any = document.getElementById("collapsedCanvas-hover");

        if(elemHover){
            let canElHover : HTMLCanvasElement = elemHover;
            let contHover = canElHover.getContext('2d');

            let sourceHover = this.props.owner.getCollapsedSourceHover();

            let collapsedWidth = 300;
            let collapsedHeight = 200;

            if(contHover && sourceHover){
                contHover.drawImage(sourceHover,0,0,collapsedWidth,collapsedHeight,0,0,collapsedWidth,collapsedHeight);
            }
        }
    }

    advanceHoverEffect(millisecondsPassed:number){
        const previous = this.mHoverLevel;

        let decreaseFactor = 0.8;
        let increaseFactor = 3.2;
        let baseIncrement = 0.01;

        if("collapsedButton" === this.props.owner.getHoveredTab()){
            this.mHoverLevel += millisecondsPassed * baseIncrement * increaseFactor;
            if(this.mHoverLevel > 1.0){
                this.mHoverLevel = 1.0;
            }
        }
        else{
            this.mHoverLevel -= millisecondsPassed * baseIncrement * decreaseFactor;
            if(this.mHoverLevel < 0.0){
                this.mHoverLevel = 0.0;
            }
        }

        if(previous !== this.mHoverLevel){
            this.forceUpdate();
        }
    }

    mouseEnterHandler(){
        this.props.owner.setHoveredTab("collapsedButton");
    }

    mouseLeaveHandler(){
        if("collapsedButton" === this.props.owner.getHoveredTab()){
            this.props.owner.setHoveredTab("NoNe");
        }
    }

    imageLoadedCheck(){
        if(this.mHasPassedImageLoadCheck === false){
            setTimeout(this.imageLoadedCheck.bind(this), 100);
            this.forceUpdate()
        }
        else{
            this.generateCollapsedGraphics();
            this.forceUpdate()
        }
    }

    render(){
        let scale = 0.35 * this.props.panelScale;

        let collapsedWidth = 300;

        if(this.props.owner.hasImageLoaded() !== true){
            console.log("Rendering without any image loaded for header collapsed button");
        }
        else{
            this.mHasPassedImageLoadCheck = true;
        }

        let shadowColor = this.props.theme.getShadow(null).GetRGB();
        let shadowIntensity1 = 0.20;
        let shadowIntensity2 = 0.18;
        let shadowIntensity3 = 0.16;
        let shadowTiltX = -35;

        return (
            <div style={{
                width:(collapsedWidth*scale)+"px",
                height:(200*scale)+"px",
                fontSize:(80*scale)+"px",
                position:"relative",
                top:(-25 * scale)+"px", 
                left: ((40 * scale)-15) + "px"
            }}>
                {/*  Shadow layer - 1  */}
                <svg style={{ 
                        width:(collapsedWidth*scale)+"px", 
                        height:(200*scale)+"px",
                        position:"absolute", 
                        top: (3*scale) + "px", 
                        left: ((shadowTiltX + 3)*scale) + "px",
                        opacity: shadowIntensity1
                    }} 
                    viewBox={"0 0 297.75 200"}
                >
                    {/*  Figure  */}
                    <defs>
                        <style>{".cls_menubutton_Sh{fill:" + shadowColor + "}"}
                        </style>
                    </defs>
                    <rect className="cls_menubutton_Sh" x="49.46" y="28.18" width="204.75" height="143.46" rx="22.72"/>
                </svg>

                {/*  Shadow layer - 2  */}
                <svg style={{ 
                        width:(collapsedWidth*scale)+"px",
                        height:(200*scale)+"px",
                        position:"absolute",
                        top: (6*scale) + "px",
                        left: ((shadowTiltX + 6)*scale) + "px",
                        opacity: shadowIntensity2
                    }} 
                    viewBox={"0 0 297.75 200"}
                >
                    {/*  Figure  */}
                    <defs>
                        <style>{".cls_menubutton_Sh{fill:" + shadowColor + "}"}
                        </style>
                    </defs>
                    <rect className="cls_menubutton_Sh" x="49.46" y="28.18" width="204.75" height="143.46" rx="22.72"/>
                </svg>

                {/*  Shadow layer - 3  */}
                <svg style={{ 
                        width:(collapsedWidth*scale)+"px", 
                        height:(200*scale)+"px",
                        position:"absolute", 
                        top: (9*scale) + "px", 
                        left: ((shadowTiltX + 9)*scale) + "px",
                        opacity: shadowIntensity3
                    }} 
                    viewBox={"0 0 297.75 200"}
                >
                    {/*  Figure  */}
                    <defs>
                        <style>{".cls_menubutton_Sh{fill:" + shadowColor + "}"}
                        </style>
                    </defs>
                    <rect className="cls_menubutton_Sh" x="49.46" y="28.18" width="204.75" height="143.46" rx="22.72"/>
                </svg>

                {/* Collapsed graphics */}
                <div style={{ 
                        width:(collapsedWidth*scale)+"px",
                        height:(200*scale)+"px",
                        position:"absolute", 
                        top: (0*scale) + "px", 
                        left: (-32*scale) + "px",
                        backgroundImage: this.props.owner.getCollapsedImageUrl(),
                        backgroundSize: (300 * scale) + "px " + (200 * scale) + "px",
                        opacity: 1.0
                    }} 
                />

                {/* Collapsed hovered graphics */}
                <div style={{ 
                        width:(collapsedWidth*scale)+"px",
                        height:(200*scale)+"px",
                        position:"absolute", 
                        top: (0*scale) + "px", 
                        left: (-32*scale) + "px",
                        backgroundImage: this.props.owner.getCollapsedImageUrlHover(),
                        backgroundSize: (300 * scale) + "px " + (200 * scale) + "px",
                        opacity: this.mHoverLevel
                    }} 
                />

                {/* Collapsed symbol */}
                <div style={{
                        position:"absolute", 
                        top: (67 * scale) + "px", 
                        left: (80 * scale) + "px",
                        width: (85*scale)+"px",
                        height:(80*scale)+"px"
                    }}>
                    {/* top line */}
                    <div style={{
                        position:"absolute", 
                        top: "0%", 
                        left: "0%",
                        width: "100%",
                        height:"15%",
                        backgroundColor: this.props.theme.getDetail(null).GetRGB()
                    }}></div>
                    {/* mid line */}
                    <div style={{
                        position:"absolute", 
                        top: "40%", 
                        left: "0%",
                        width: "100%",
                        height:"15%",
                        backgroundColor: this.props.theme.getDetail(null).GetRGB()
                    }}></div>
                    {/* low line */}
                    <div style={{
                        position:"absolute", 
                        top: "80%", 
                        left: "0%",
                        width: "100%",
                        height:"15%",
                        backgroundColor: this.props.theme.getDetail(null).GetRGB()
                    }}></div>
                </div>

                {/* Collapsed symbol - hovered*/}
                <div style={{
                        position:"absolute", 
                        top: (67 * scale) + "px", 
                        left: (80 * scale) + "px",
                        width: (85*scale)+"px",
                        height:(80*scale)+"px",
                        opacity: this.mHoverLevel
                    }}>
                    {/* top line */}
                    <div style={{
                        position:"absolute", 
                        top: "0%", 
                        left: "0%",
                        width: "100%",
                        height:"15%",
                        backgroundColor: this.props.theme.getDetail(null, 0.5, 1).GetRGB(),
                    }}></div>
                    {/* mid line */}
                    <div style={{
                        position:"absolute", 
                        top: "40%", 
                        left: "0%",
                        width: "100%",
                        height:"15%",
                        backgroundColor: this.props.theme.getDetail(null, 0.5, 1).GetRGB(),
                    }}></div>
                    {/* low line */}
                    <div style={{
                        position:"absolute", 
                        top: "80%", 
                        left: "0%",
                        width: "100%",
                        height:"15%",
                        backgroundColor: this.props.theme.getDetail(null, 0.5, 1).GetRGB(),
                    }}></div>
                </div>

                {/*  Shadow layer - 3  */}
                <svg style={{ 
                        width:(collapsedWidth*scale)+"px", 
                        height:(200*scale)+"px",
                        position:"absolute", 
                        top: (9*scale) + "px", 
                        left: ((shadowTiltX + 9)*scale) + "px",
                        opacity: shadowIntensity3
                    }} 
                    viewBox={"0 0 297.75 200"}
                >
                    {/*  Figure  */}
                    <defs>
                        <style>{".cls_menubutton_Sh{fill:" + shadowColor + "}"}
                        </style>
                    </defs>
                    <rect className="cls_menubutton_Sh" x="49.46" y="28.18" width="204.75" height="143.46" rx="22.72"/>
                </svg>

                {/*  Collective mouse section  */}
                <svg style={{ 
                        width:(collapsedWidth*scale)+"px", 
                        height:(200*scale)+"px",
                        position:"absolute", 
                        top: (0*scale) + "px", 
                        left: ((shadowTiltX + 0)*scale) + "px",
                    }} 
                    viewBox={"0 0 297.75 200"}>

                    {/*  Figure  */}
                    <defs>
                        <style>{".cls_menubutton_D2{opacity:0.0;fill:gray}"}
                        </style>
                    </defs>
                    <rect 
                        className="cls_menubutton_D2" 
                        x="49.46" 
                        y="28.18" 
                        width="204.75" 
                        height="143.46" 
                        rx="22.72"
                        style={{
                            cursor: "pointer"
                        }}
                        onMouseEnter={this.mouseEnterHandler.bind(this)}
                        onMouseLeave={this.mouseLeaveHandler.bind(this)}
                        onClick={this.props.onExpand}
                    />
                </svg>

            </div>
        )
    }  

}




// JGHeaderLister is the popup list of scrollable options you will see if you click on the
// collapsed button (when the window size is too narrow to show all options as buttons).
// Clicking an option should be equivalent in all aspects to clicking an option button.

/*
    #####   ####   #   #       #      ###    ###   #####  ####   ####
        #  #    #  #   #       #       #    #        #    #      #   # 
        #  #       #####       #       #     ###     #    ###    #### 
    #   #  #   ##  #   #  ##   #       #        #    #    #      #   #
     ###    ### #  #   #  ##   #####  ###   ####     #    ####   #    #

*/

interface JGHeaderListerProps{
    theme : Theme;
    panelScale : number;
    owner : JGHeader;
    options : Array< {optionID:any, optionText:string} >;
}

class JGHeaderLister extends React.Component<JGHeaderListerProps,{}>{

    private mHoveredItem : number;

    constructor(props:JGHeaderListerProps){
        super(props);
        // props.owner.connectLister(this);
        this.mHoveredItem = -1;
    }

    render(){
        return <div>(Header Lister)</div>;
    }
}




/*
    #####   ####   #   #  ####    ###   ####   ####  ####
        #  #    #  #   #  #      #   #  #   #  #     #   #
        #  #       #####  ###    #####  #   #  ###   ####
    #   #  #   ##  #   #  #      #   #  #   #  #     #  #
     ###    ### #  #   #  ####   #   #  ####   ####  #   #

*/

export interface JGHeaderProps{
    themeID?:string;
    options : Array< {optionID:any, optionText:string} >;
    selectedTabID : string;
    onChoise : (choiseID:string)=>void;
    displayMode : string; // "regular", "swiftUpdate"
    scale?:number;
    style?:any;
    className?:string;
}

export interface JGHeaderStates{

}

export class JGHeader extends ThemeComponent< JGHeaderProps, JGHeaderStates >{

    private static HEIGHT_BASE : number = 64.0;
    private static DEFAULT_SCALE : number = 1.0;

    static mResizeWindowHandler : ()=>void = ()=>{};
    static mHasAddedWindowResizeListener : boolean = false;

    private mOptions : Array<{option:JGHeaderOption,id:string}>;
    private mCollapsed : JGHeaderCollapsed | null;
    private mSelect : JGSelect | null;
    private mSelectVisible : boolean;
    private mImageSource : HTMLImageElement | null;
    private mTabSource : HTMLCanvasElement | null;
    private mTabSourceHover : HTMLCanvasElement | null;
    private mTabUrlLeft : string | null;
    private mTabUrlCenter : string | null;
    private mTabUrlRight : string | null;
    private mTabUrlLeftHover : string | null;
    private mTabUrlCenterHover : string | null;
    private mTabUrlRightHover : string | null;
    private mCollapsedSource : HTMLCanvasElement | null;
    private mCollapsedSourceHover : HTMLCanvasElement | null;
    private mCollapsedUrl : string | null;
    private mCollapsedUrlHover : string | null;
    private mImageHasLoaded : boolean = false;
    private mHoveredTabID : string;
    private mSelectedTabID : string;
    private mTabAnimationIsPending : boolean;
    private mTabAnimationIsSetToStop : boolean;
    private mLastAnimationTime : number;
    private mScalingGlassRegionEnterState : boolean;
    private mScalingGlassMouseDownYAxis : number;
    private mScalingGlassOriginalScale : number;
    private mCurrentScale : number;
    private mMouseMode : string;
    private mTabAnimationsCount : number;

    constructor(props:JGHeaderProps){
        super(props);

        if(JGHeader.mHasAddedWindowResizeListener === false){
            JGHeader.mHasAddedWindowResizeListener = true;
            window.addEventListener("resize", JGHeader.catchWindowResize);
        }

        JGHeader.mResizeWindowHandler = this.onWindowResize.bind(this);

        this.mImageHasLoaded = false;
        this.mImageSource = null;
        this.mTabSource = null;
        this.mTabSourceHover = null;
        this.mTabUrlLeft = null;
        this.mTabUrlCenter = null;
        this.mTabUrlRight = null;
        this.mTabUrlLeftHover = null;
        this.mTabUrlCenterHover = null;
        this.mTabUrlRightHover = null;
        this.mCollapsedSource = null;
        this.mCollapsedSourceHover = null;
        this.mCollapsedUrl = null;
        this.mCollapsedUrlHover = null;
        this.mOptions = new Array<{option:JGHeaderOption,id:string}>();
        this.mCollapsed = null;
        this.mSelect = null;
        this.mSelectVisible = false;
        this.mHoveredTabID = "nOnE";
        this.mSelectedTabID = this.props.selectedTabID ? this.props.selectedTabID : "nOnE";
        this.mTabAnimationIsPending = false;
        this.mTabAnimationIsSetToStop = false;
        this.mLastAnimationTime = 0;
        this.mScalingGlassRegionEnterState = false;
        this.mScalingGlassMouseDownYAxis = -1;
        this.mMouseMode = "initial";
        this.mCurrentScale = this.props.scale ? this.props.scale : JGHeader.DEFAULT_SCALE;
        this.mScalingGlassOriginalScale = (this.mCurrentScale + 0);
        this.mTabAnimationsCount = 0;

        this.setStyleDefault({height: (this.mCurrentScale* JGHeader.HEIGHT_BASE) + "px"});

        // this.setStyleDefault({height:"500px"}); // height : "" + (scaledBarHeight*scale) + "px"

        // this.setStyleDefault({zIndex:1});

        this.initiateGraphics();
    }

    public getTabImageUrl(area : string){

        if( area === "right"){
            if( this.mTabUrlRight ){
                return this.mTabUrlRight;
            }
        }

        if( area === "left"){
            if( this.mTabUrlLeft ){
                return this.mTabUrlLeft;
            }
        }

        if( this.mTabUrlCenter ){
            return this.mTabUrlCenter;
        }
        
        return "noImageLoaded:TabUrl";
    }

    public getTabImageUrlHover(area : string){

        if( area === "right"){
            if( this.mTabUrlRightHover ){
                return this.mTabUrlRightHover;
            }
        }

        if( area === "left"){
            if( this.mTabUrlLeftHover ){
                return this.mTabUrlLeftHover;
            }
        }

        if( this.mTabUrlCenterHover ){
            return this.mTabUrlCenterHover;
        }
        
        return "noImageLoaded:TabUrlHover";
    }

    public getCollapsedImageUrl(){
        if( this.mCollapsedUrl ){
            return this.mCollapsedUrl;
        }
        
        return "noImageLoaded:CollapsedUrl";
    }

    public getCollapsedImageUrlHover(){
        if( this.mCollapsedUrlHover ){
            return this.mCollapsedUrlHover;
        }
        
        return "noImageLoaded:CollapsedUrlHover";
    }

    public getSelectedTab(){
        return this.mSelectedTabID
    }

    public setSelectedTab(tabID : string){
        this.mSelectedTabID = tabID;
    }

    public static catchWindowResize(){
        JGHeader.mResizeWindowHandler();
    }

    public onWindowResize(){
        this.forceUpdate();

        if(this.mSelectVisible){
            this.displaySelect(false);
        }
    }

    public setSelectRef(ref: JGSelect | null){
        this.mSelect = ref;
    }

    public displaySelect(visible : boolean){
        this.mSelectVisible = visible;
        let sel = this.mSelect;

        this.forceUpdate();

        if(sel){
            sel.setVisible(visible);
            sel.forceUpdate();
        }
    }

    public getLastAnimationTime(){
        return this.mLastAnimationTime;
    }

    public getHoveredTab(){
        return this.mHoveredTabID;
    }

    public setHoveredTab(tabID:string){
        this.mHoveredTabID = tabID;
    }

    public initiateGraphics(){
        if(this.mImageSource === null){
            this.mImageSource = document.createElement('img');
            this.mImageSource.onload = this.onImageLoaded.bind(this);
            this.mImageSource.src = "images/menu_btn_collection.png";
        }
    }

    private onImageLoaded(){
        this.mImageHasLoaded = true;
        this.prepareTabBasis();
        this.startTabAnimation();
        this.forceUpdate();
    }

    public hasImageLoaded(){
        return this.mImageHasLoaded;
    }

    public getImageSource(){
        return this.mImageSource;
    }

    public getTabSource(){
        return this.mTabSource;
    }

    public getTabSourceHover(){
        return this.mTabSourceHover;
    }

    public getCollapsedSource(){
        return this.mCollapsedSource;
    }

    public getCollapsedSourceHover(){
        return this.mCollapsedSourceHover;
    }

    public connectOption(option:JGHeaderOption, id:string){
        for(let i=0; i<this.mOptions.length; i++){
            if(this.mOptions[i].id === id){
                this.mOptions[i] = {option:option, id:id};
                return;
            }
        }

        this.mOptions.push({option:option, id:id});
    }

    public connectCollapsed(collapsed:JGHeaderCollapsed){
        this.mCollapsed = collapsed;
    }

    public connectSelect(select:JGSelect){
        this.mSelect = select;
    }

    private prepareTabBasis(){
        if(this.mImageSource){

            let diffuseCanvas = document.createElement('canvas');
            diffuseCanvas.width = 400;
            diffuseCanvas.height = 200;
            let diffuseContext = diffuseCanvas.getContext('2d');

            if(diffuseContext){
                diffuseContext.drawImage(this.mImageSource, 0, 0, 400, 200, 0, 0, 400, 200);
            }

            let glowCanvas = document.createElement('canvas');
            glowCanvas.width = 400;
            glowCanvas.height = 200;
            let glowContext = glowCanvas.getContext('2d');

            if(glowContext){
                glowContext.drawImage(this.mImageSource, 0, 200, 400, 200, 0, 0, 400, 200);
            }

            let specularCanvas = document.createElement('canvas');
            specularCanvas.width = 400;
            specularCanvas.height = 200;
            let specularContext = specularCanvas.getContext('2d');

            if(specularContext){
                specularContext.drawImage(this.mImageSource, 0, 400, 400, 200, 0, 0, 400, 200);
            }

            let maskCanvas = document.createElement('canvas');
            maskCanvas.width = 400;
            maskCanvas.height = 200;
            let maskContext = maskCanvas.getContext('2d');

            if(maskContext){
                maskContext.drawImage(this.mImageSource, 0, 600, 400, 200, 0, 0, 400, 200);
            }

            this.mTabSource = document.createElement('canvas');
            this.mTabSource.width = 400;
            this.mTabSource.height = 200;
            let tabSourceContext = this.mTabSource.getContext('2d');

            this.mTabSourceHover = document.createElement('canvas');
            this.mTabSourceHover.width = 400;
            this.mTabSourceHover.height = 200;
            let tabSourceHoverContext = this.mTabSourceHover.getContext('2d');

            // Preparing the collapsed button's contexts

            let diffuseCanvasCollapsed = document.createElement('canvas');
            diffuseCanvasCollapsed.width = 300;
            diffuseCanvasCollapsed.height = 200;
            let diffuseContextCollapsed = diffuseCanvasCollapsed.getContext('2d');

            if(diffuseContextCollapsed){
                diffuseContextCollapsed.drawImage(this.mImageSource, 400, 0, 300, 200, 0, 0, 300, 200);
            }

            let glowCanvasCollapsed = document.createElement('canvas');
            glowCanvasCollapsed.width = 300;
            glowCanvasCollapsed.height = 200;
            let glowContextCollapsed = glowCanvasCollapsed.getContext('2d');

            if(glowContextCollapsed){
                glowContextCollapsed.drawImage(this.mImageSource, 400, 200, 300, 200, 0, 0, 300, 200);
            }

            let specularCanvasCollapsed = document.createElement('canvas');
            specularCanvasCollapsed.width = 300;
            specularCanvasCollapsed.height = 200;
            let specularContextCollapsed = specularCanvasCollapsed.getContext('2d');

            if(specularContextCollapsed){
                specularContextCollapsed.drawImage(this.mImageSource, 400, 400, 300, 200, 0, 0, 300, 200);
            }

            let maskCanvasCollapsed = document.createElement('canvas');
            maskCanvasCollapsed.width = 300;
            maskCanvasCollapsed.height = 200;
            let maskContextCollapsed = maskCanvasCollapsed.getContext('2d');

            if(maskContextCollapsed){
                maskContextCollapsed.drawImage(this.mImageSource, 400, 600, 300, 200, 0, 0, 300, 200);
            }

            this.mCollapsedSource = document.createElement('canvas');
            this.mCollapsedSource.width = 300;
            this.mCollapsedSource.height = 200;
            let collapsedSourceContext = this.mCollapsedSource.getContext('2d');

            this.mCollapsedSourceHover = document.createElement('canvas');
            this.mCollapsedSourceHover.width = 300;
            this.mCollapsedSourceHover.height = 200;
            let collapsedSourceHoverContext = this.mCollapsedSourceHover.getContext('2d');

            if( diffuseContext && glowContext  && specularContext && tabSourceContext 
                && diffuseContextCollapsed && glowContextCollapsed && maskContextCollapsed 
                && specularContextCollapsed && tabSourceHoverContext && maskContext 
                && collapsedSourceContext && collapsedSourceHoverContext
                ){

                let diffuseData = diffuseContext.getImageData(0,0,400,200);
                let glowData = glowContext.getImageData(0,0,400,200);
                let specularData = specularContext.getImageData(0,0,400,200);
                let maskData = maskContext.getImageData(0,0,400,200);
                let tabSourceData = tabSourceContext.getImageData(0,0,400,200);
                let tabSourceDataHover = tabSourceHoverContext.getImageData(0,0,400,200);

                let diffuseDataCollapsed = diffuseContextCollapsed.getImageData(0,0,300,200);
                let glowDataCollapsed = glowContextCollapsed.getImageData(0,0,300,200);
                let specularDataCollapsed = specularContextCollapsed.getImageData(0,0,300,200);
                let maskDataCollapsed = maskContextCollapsed.getImageData(0,0,300,200);
                let collapsedSourceData = collapsedSourceContext.getImageData(0,0,300,200);
                let collapsedSourceDataHover = collapsedSourceHoverContext.getImageData(0,0,300,200);

                let baseHoverLightup = 5;
                let glowLevel = 0.24;

                // preparing set of 256 precalculated, theme based and gradient positioned colors
                let diffuseGrades = new Array<{red:number,green:number,blue:number,alpha:number}>();
                let glowGrades = new Array<{red:number,green:number,blue:number,alpha:number}>();
                let specularGrades = new Array<{red:number,green:number,blue:number,alpha:number}>();
                let theme = this.getTheme();

                for(let d=0; d<256; d++){
                    let diffuseColor = theme.getFG(null,d/255);
                    let glowColor = theme.getFG(null,d/255,1);
                    let specularColor = theme.getSpecular(null,d/255);
                    diffuseGrades.push({red:diffuseColor.GetRed(),green:diffuseColor.GetGreen(),blue:diffuseColor.GetBlue(),alpha:255});

                    glowGrades.push({red:glowColor.GetRed(),green:glowColor.GetGreen(),blue:glowColor.GetBlue(),alpha:255});

                    specularGrades.push({red:specularColor.GetRed(),green:specularColor.GetGreen(),blue:specularColor.GetBlue(),alpha:255});
                }

                // Calculating pixel values based on the image material and theme gradients
                for(let i=0; (i+3)<tabSourceData.data.length; i+=4){

                    // Calculating  RED:
                    let red = diffuseGrades[diffuseData.data[i]].red;
                    let specularRed = specularGrades[specularData.data[i]].red;
                    let redGlow = glowGrades[glowData.data[i]].red;

                    let red_G = redGlow * glowLevel + red;

                    let potentialRed = specularRed - red;
                    if(potentialRed > 0){
                        let redAdd = potentialRed * (specularData.data[i] / 206);
                        red += (redAdd <= potentialRed) ? redAdd : potentialRed;
                    }

                    let potentialRed_G = specularRed - red_G;
                    if(potentialRed_G > 0){
                        let redAdd = potentialRed_G * (specularData.data[i] / 206);
                        red_G += (redAdd <= potentialRed_G) ? redAdd : potentialRed_G;
                    }

                    tabSourceData.data[i] = red;
                    tabSourceDataHover.data[i] = (red_G + baseHoverLightup) <= 255 ? (red_G + baseHoverLightup) : 255 ;

                    // Calculating  GREEN:
                    let green = diffuseGrades[diffuseData.data[i+1]].green;
                    let specularGreen = specularGrades[specularData.data[i+1]].green;
                    let greenGlow = glowGrades[glowData.data[i+1]].green;

                    let green_G = greenGlow * glowLevel + green;

                    let potentialGreen = specularGreen - green;
                    if(potentialGreen > 0){
                        let greenAdd = potentialGreen * (specularData.data[i+1] / 206);
                        green += (greenAdd <= potentialGreen) ? greenAdd : potentialGreen;
                    }

                    let potentialGreen_G = specularGreen - green_G;
                    if(potentialGreen_G > 0){
                        let greenAdd = potentialGreen_G * (specularData.data[i+1] / 206);
                        green_G += (greenAdd <= potentialGreen_G) ? greenAdd : potentialGreen_G;
                    }

                    tabSourceData.data[i+1] = green;
                    tabSourceDataHover.data[i+1] = (green_G + baseHoverLightup) <= 255 ? (green_G + baseHoverLightup) : 255 ;

                    // Calculating  BLUE:
                    let blue = diffuseGrades[diffuseData.data[i+2]].blue;
                    let specularBlue = specularGrades[specularData.data[i+2]].blue;
                    let blueGlow = glowGrades[glowData.data[i+2]].blue;

                    let blue_G = blueGlow * glowLevel + blue;

                    let potentialBlue = specularBlue - blue;
                    if(potentialBlue > 0){
                        let blueAdd = potentialBlue * (specularData.data[i+2] / 206);
                        blue += (blueAdd <= potentialBlue) ? blueAdd : potentialBlue;
                    }

                    let potentialBlue_G = specularBlue - blue_G;
                    if(potentialBlue_G > 0){
                        let blueAdd = potentialBlue_G * (specularData.data[i+2] / 206);
                        blue_G += (blueAdd <= potentialBlue_G) ? blueAdd : potentialBlue_G;
                    }

                    tabSourceData.data[i+2] = blue;
                    tabSourceDataHover.data[i+2] = (blue_G + baseHoverLightup) <= 255 ? (blue_G + baseHoverLightup) : 255 ;

                    // Seting Alpha
                    tabSourceData.data[i+3] = maskData.data[i];
                    tabSourceDataHover.data[i+3] = maskData.data[i];
                }

                tabSourceContext.putImageData(tabSourceData, 0, 0);
                tabSourceHoverContext.putImageData(tabSourceDataHover, 0, 0);

                // Now same process as above but for the quite similar collapsed button graphics
                for(let i=0; (i+3)<collapsedSourceData.data.length; i+=4){

                    // Calculating  RED:
                    let red = diffuseGrades[diffuseDataCollapsed.data[i]].red;
                    let specularRed = specularGrades[specularDataCollapsed.data[i]].red;
                    let redGlow = glowGrades[glowDataCollapsed.data[i]].red;

                    let red_G = redGlow * glowLevel + red;

                    let potentialRed = specularRed - red;
                    if(potentialRed > 0){
                        let redAdd = potentialRed * (specularDataCollapsed.data[i] / 206);
                        red += (redAdd <= potentialRed) ? redAdd : potentialRed;
                    }

                    let potentialRed_G = specularRed - red_G;
                    if(potentialRed_G > 0){
                        let redAdd = potentialRed_G * (specularDataCollapsed.data[i] / 206);
                        red_G += (redAdd <= potentialRed_G) ? redAdd : potentialRed_G;
                    }

                    collapsedSourceData.data[i] = red;
                    collapsedSourceDataHover.data[i] = (red_G + baseHoverLightup) <= 255 ? (red_G + baseHoverLightup) : 255 ;

                    // Calculating  GREEN:
                    let green = diffuseGrades[diffuseDataCollapsed.data[i+1]].green;
                    let specularGreen = specularGrades[specularDataCollapsed.data[i+1]].green;
                    let greenGlow = glowGrades[glowDataCollapsed.data[i+1]].green;

                    let green_G = greenGlow * glowLevel + green;

                    let potentialGreen = specularGreen - green;
                    if(potentialGreen > 0){
                        let greenAdd = potentialGreen * (specularDataCollapsed.data[i+1] / 206);
                        green += (greenAdd <= potentialGreen) ? greenAdd : potentialGreen;
                    }

                    let potentialGreen_G = specularGreen - green_G;
                    if(potentialGreen_G > 0){
                        let greenAdd = potentialGreen_G * (specularDataCollapsed.data[i+1] / 206);
                        green_G += (greenAdd <= potentialGreen_G) ? greenAdd : potentialGreen_G;
                    }

                    collapsedSourceData.data[i+1] = green;
                    collapsedSourceDataHover.data[i+1] = (green_G + baseHoverLightup) <= 255 ? (green_G + baseHoverLightup) : 255 ;

                    // Calculating  BLUE:
                    let blue = diffuseGrades[diffuseDataCollapsed.data[i+2]].blue;
                    let specularBlue = specularGrades[specularDataCollapsed.data[i+2]].blue;
                    let blueGlow = glowGrades[glowDataCollapsed.data[i+2]].blue;

                    let blue_G = blueGlow * glowLevel + blue;

                    let potentialBlue = specularBlue - blue;
                    if(potentialBlue > 0){
                        let blueAdd = potentialBlue * (specularDataCollapsed.data[i+2] / 206);
                        blue += (blueAdd <= potentialBlue) ? blueAdd : potentialBlue;
                    }

                    let potentialBlue_G = specularBlue - blue_G;
                    if(potentialBlue_G > 0){
                        let blueAdd = potentialBlue_G * (specularDataCollapsed.data[i+2] / 206);
                        blue_G += (blueAdd <= potentialBlue_G) ? blueAdd : potentialBlue_G;
                    }

                    collapsedSourceData.data[i+2] = blue;
                    collapsedSourceDataHover.data[i+2] = (blue_G + baseHoverLightup) <= 255 ? (blue_G + baseHoverLightup) : 255 ;

                    // Seting Alpha
                    collapsedSourceData.data[i+3] = maskDataCollapsed.data[i];
                    collapsedSourceDataHover.data[i+3] = maskDataCollapsed.data[i];
                }

                collapsedSourceContext.putImageData(collapsedSourceData, 0, 0);
                collapsedSourceHoverContext.putImageData(collapsedSourceDataHover, 0, 0);
                this.mCollapsedUrl = "url(" + this.mCollapsedSource.toDataURL() + ")";
                this.mCollapsedUrlHover = "url(" + this.mCollapsedSourceHover.toDataURL() + ")";

                // Splitting the tabs graphics into a right, left and center image

                // LEFT
                let leftSection = document.createElement('canvas');
                leftSection.width = 160;
                leftSection.height = 200;
                let leftSectionContext = leftSection.getContext('2d');

                if(leftSectionContext){
                    leftSectionContext.drawImage(this.mTabSource, 0, 0, 160, 200, 0, 0, 160, 200);
                }

                this.mTabUrlLeft = "url(" + leftSection.toDataURL() + ")";

                // CENTER
                let centerSection = document.createElement('canvas');
                centerSection.width = 10;
                centerSection.height = 200;
                let centerSectionContext = centerSection.getContext('2d');

                if(centerSectionContext){
                    centerSectionContext.drawImage(this.mTabSource, 160, 0, 10, 200, 0, 0, 10, 200);
                }

                this.mTabUrlCenter = "url(" + centerSection.toDataURL() + ")";

                // RIGHT
                let rightSection = document.createElement('canvas');
                rightSection.width = 230;
                rightSection.height = 200;
                let rightSectionContext = rightSection.getContext('2d');

                if(rightSectionContext){
                    rightSectionContext.drawImage(this.mTabSource, 170, 0, 230, 200, 0, 0, 230, 200);
                }

                this.mTabUrlRight = "url(" + rightSection.toDataURL() + ")";
    
                // LEFT HOVER
                let leftSectionHover = document.createElement('canvas');
                leftSectionHover.width = 160;
                leftSectionHover.height = 200;
                let leftSectionHoverContext = leftSectionHover.getContext('2d');

                if(leftSectionHoverContext){
                    leftSectionHoverContext.drawImage(this.mTabSourceHover, 0, 0, 160, 200, 0, 0, 160, 200);
                }

                this.mTabUrlLeftHover = "url(" + leftSectionHover.toDataURL() + ")";

                // CENTER HOVER
                let centerSectionHover = document.createElement('canvas');
                centerSectionHover.width = 10;
                centerSectionHover.height = 200;
                let centerSectionHoverContext = centerSectionHover.getContext('2d');

                if(centerSectionHoverContext){
                    centerSectionHoverContext.drawImage(this.mTabSourceHover, 160, 0, 10, 200, 0, 0, 10, 200);
                }

                this.mTabUrlCenterHover = "url(" + centerSectionHover.toDataURL() + ")";

                // RIGHT HOVER
                let rightSectionHover = document.createElement('canvas');
                rightSectionHover.width = 230;
                rightSectionHover.height = 200;
                let rightSectionHoverContext = rightSectionHover.getContext('2d');

                if(rightSectionHoverContext){
                    rightSectionHoverContext.drawImage(this.mTabSourceHover, 170, 0, 230, 200, 0, 0, 230, 200);
                }

                this.mTabUrlRightHover = "url(" + rightSectionHover.toDataURL() + ")";
            }
            else{
                console.error("Error in JGHeader: Contexts for tabs graphics could not be generated.");
            }
        }
    }

    public onThemeUpdate(){
        this.prepareTabBasis();

        for(let i=0; i<this.mOptions.length; i++){
            this.mOptions[i].option.onThemeUpdate();
        }

        if(this.mCollapsed){
            this.mCollapsed.onThemeUpdate();
        }
        
        this.forceUpdate();
    }

    startTabAnimation(){
        if(this.mTabAnimationIsPending === false){
            this.mTabAnimationIsPending = true;
            this.mTabAnimationIsSetToStop = false;
            this.mLastAnimationTime = window.requestAnimationFrame(this.animateTabs.bind(this));
        }
    }

    stopTabAnimation(){
        this.mTabAnimationIsSetToStop = true;
    }

    animateTabs(){
        if(this.mTabAnimationIsSetToStop === true){
            this.mTabAnimationIsSetToStop = false;
            this.mTabAnimationIsPending = false;
            return;
        }

        this.mTabAnimationIsPending = true;

        let newAnimationTime = window.requestAnimationFrame(this.animateTabs.bind(this));
        let timeElapsed = newAnimationTime - this.mLastAnimationTime;
        this.mLastAnimationTime = newAnimationTime;

        for(let i=0; i<this.mOptions.length; i++){
            this.mOptions[i].option.advanceHoverEffect(timeElapsed);
        }

        if(this.mCollapsed){
            this.mCollapsed.advanceHoverEffect(timeElapsed);
        }

        this.mTabAnimationsCount++;

        if(this.mTabAnimationsCount === 1){
            this.forceUpdate();
        }
    }

    onClickLogo(){
        this.mSelectedTabID = "logo";
        this.props.onChoise("logo");
    }

    onExpand(){
        this.displaySelect(true);
    } 

    draw(){

        let scaledHeight = 400;
        let scaledWidth = 600;
        let scaledTop = -226;
        let scaledLeft = -304;

        let scaledLogoHeight = 100;
        let scaledLogoWidth = 100;
        let scaledLogoTop = 5;
        let scaledLogoLeft = 5;

        let scaledMenuStartX = 200;

        let scaledBarTop = 0;
        let scaledBarLeft = 0;

        //let scaledBarHeight = 63.7;
        let scaledBarHeight = JGHeader.HEIGHT_BASE;

        let scale = this.mCurrentScale;

        // Calculating the expected width of the entire option list
        let totalSpacesWidth = 0;
        let totalTextWidth = 0;
        let minimumTextWidth = 4.0;

        for(let i=0; i<this.props.options.length; i++){
            let textWidth = JGHeaderOption.getBaseWidthOfTextLine(this.props.options[i].optionText);
            textWidth = (textWidth >= minimumTextWidth)?textWidth:minimumTextWidth;
            totalTextWidth += textWidth * 13.7 * scale;
            totalSpacesWidth += 56 * scale;
        }

        // Calculating the available width due to window width and the scale in use

        let firstSectionWidth = (185 * scale);
        let calculatedTotalWidth = totalSpacesWidth + totalTextWidth + firstSectionWidth;
        

        // Preparing option buttons
        let options = new Array<JSX.Element>();
        let flexDirectionForHeaderTabs : "row" | "row-reverse" = "row";

        if(calculatedTotalWidth <= window.innerWidth){
            for(let i=0; i<this.props.options.length; i++){
                options.push(
                    <JGHeaderOption 
                        key={"key:"+i} 
                        optionID={this.props.options[i].optionID} 
                        optionText={this.props.options[i].optionText} 
                        theme={this.getTheme()}
                        panelScale={scale}
                        onChoise={this.onChoise.bind(this)}
                        owner={this}
                        choiseIndex={(i+0)}
                        displayMode={this.props.displayMode}
                    >
                    </JGHeaderOption>
                )
            }

            this.mSelectVisible = false;
        }
        else{
            // A single expandable menu button replaces the menu buttons as the window is not wide enough for them all.

            // (First let's press in a margin to adjust the button's X position..)
            let excess = window.innerWidth - 450;
            if(excess > 0){
                excess *= 1.40 - (1.0 * scale);
            }
            else{
                excess = 0;
            }

            options.push(
                <div style={{
                        width: excess + "px",
                        height: "1px"
                    }}
                >
                </div>
            )

            options.push(
                <JGHeaderCollapsed
                        theme={this.getTheme()}
                        panelScale={scale}
                        onExpand={this.onExpand.bind(this)}
                        owner={this}
                >  
                </JGHeaderCollapsed>
            )
            
            let selectMargin = 7 * scale;
            let totWidth = window.innerWidth - (4.6 * selectMargin);
            let totHeight = window.innerHeight - (2 * selectMargin);
            let itemHeight = (50*scale);
            let fontSize = 40 * scale;
            let yOverflow = "auto";

            let amount = this.props.options.length;
            if((amount * itemHeight) < totHeight){
                totHeight = (amount * itemHeight) + (((amount * 1) + 1) * scale);
                yOverflow = "hidden"
            }

            if(this.mSelectVisible){
                options.push(
                    /*
                    <PaletteSelect 
                        options={themeSelectOptions} 
                        setReference={this.onSetReference.bind(this)}
                        onSelectItem={this.onSelectThemeItem.bind(this)}
                        width={Math.round((this.mSubWindowWidth*0.00215)*360) + "px"}
                        height={Math.round((this.mSubWindowWidth*0.00215)*320) + "px"}
                        top={Math.round((this.mSubWindowWidth*0.00215)*50) + "px"}
                        left={Math.round((this.mSubWindowWidth*0.00215)*60) + "px"}
                        itemWidth={(Math.round((this.mSubWindowWidth*0.00215)*360)-2) + "px"}
                        itemHeight={Math.round((this.mSubWindowWidth*0.00215)*40) + "px"}
                        itemFontSize={Math.round((this.mSubWindowWidth*0.00215)*25) + "px"}
                    />
                    
                        options : Array< {optionID:any, optionText:string} >;
                        setReference : (ref:JGSelect|null)=>void;
                        onSelectItem : (itemID : any)=>void;
                        style ? : any;
                        itemStyle ? : any;
                        itemHoverStyle ? : any;
                        className? : string;
                        itemClassName? : string;
                        itemHoverClassName? : string;
                        themeID? : string;
                        visible? : boolean;
                        scalingRatio? : number;
                        relativeMeasures? : {top:number, left:number, width:number, height:number};
                        relativeItemMeasures? : {width:number,height:number, fontSize:number};

                        
                        itemStyle={{height: itemHeight + "px", width: "calc(100% - 2px)", fontSize: fontSize + "px"}}
                        itemHoverStyle={{height: itemHeight + "px", width: "calc(100% - 2px)", fontSize: fontSize + "px"}}
                    
                    */
    
                    <JGSelect 
                        onMouseLeave={this.onLeaveSelect.bind(this)}
                        options={this.props.options} 
                        onSelectItem={this.onChoise.bind(this)} 
                        setReference={this.setSelectRef.bind(this)}
                        visible={this.mSelectVisible}
                        style={{position:"fixed", top: selectMargin +"px", left: selectMargin +"px", width: totWidth + "px", height: totHeight + "px", overflowY: yOverflow}}
                        itemStyle={{height:itemHeight, fontSize:fontSize}}
                        itemHoverStyle={{height:itemHeight, fontSize:fontSize}}
                    ></JGSelect>
                )
            }

            // flexDirectionForHeaderTabs = "row-reverse";
            flexDirectionForHeaderTabs = "row";
        }

        let scalingGlassPart = null;

        if(Theme.showPalette() === true){
            let mainScale = 0.5;
            let hoverOpacity = "0";

            let mouseDraggingArea = null;

            if((this.mMouseMode === "scaling") && (this.mScalingGlassMouseDownYAxis != -1)){
                mouseDraggingArea = (
                    <div style={{
                            backgroundColor:"rgba(255,0,0,0.0)",
                            width:"100%",
                            height:"100%",
                            position:"fixed",
                            top:"0px",
                            left:"0px",
                            cursor:"ns-resize"
                        }}
                        onMouseMove={this.onScalingGlassMouseMove.bind(this)}
                        onMouseLeave={this.onLeaveScalingGlassRegion.bind(this)}
                        onMouseUp={this.onMouseUpScalingGlassRegion.bind(this)}
                    >
                    </div>
                )
            }

            if(this.mScalingGlassRegionEnterState === true){
                hoverOpacity = "1";
            }

            scalingGlassPart = (
                <div style={{
                        height: "" + (120 * scale) + "px", 
                        width: "" + (scaledLogoWidth*2*mainScale) + "px", 
                        position:"absolute", 
                        top: "" + ((scaledLogoTop+100)*scale) + "px", 
                        left: "-4px"
                    }}
                    //onMouseOut={this.onLeaveScalingGlassRegion.bind(this)}
                    onMouseMove={this.onScalingGlassMouseMove.bind(this)}
                    onMouseUp={this.onMouseUpScalingGlassRegion.bind(this)}
                >
                    <svg style={{
                            height: "" + (scaledLogoHeight*mainScale) + "px", 
                            width: "" + (scaledLogoWidth*mainScale) + "px", 
                            position: "absolute", 
                            top: (35*scale) + "px",
                            left: "0px"
                        }}
                        viewBox="0 0 50.0 50.0"
                    >
                        {/*  Hover shine gradient  */}
                        <defs>
                            <style>{".cls_logoAreaScalingGlass03{fill:url(#cls_logoAreaScalingGlass_radial-gradient-16);}"}
                            </style>
                            <radialGradient id="cls_logoAreaScalingGlass_radial-gradient-16" cx="25" cy="25" r="20" gradientUnits="userSpaceOnUse">
                                <stop offset="0" stopColor="#EEEEEE" stop-opacity={hoverOpacity}/>
                                <stop offset="1" stopColor="#EEEEEE" stop-opacity="0"/>
                            </radialGradient>
                        </defs>
                        <circle className="cls_logoAreaScalingGlass03" cx="25" cy="25" r="20"/>
        
                        {/*  Scaling glass sideways arrows  */}
                        <defs>
                            <style>{".cls_logoAreaScalingGlass01{fill:url(#cls_logoAreaScalingGlass_linear-gradient-15);}"}
                            </style>
                            <linearGradient id="cls_logoAreaScalingGlass_linear-gradient-15" x1="8.58" y1="2.91" x2="8.58" y2="44.81" gradientUnits="userSpaceOnUse">
                                <stop offset="0" stopColor="#5787a6"/>
                                <stop offset="0.49" stopColor="#b3b3b3"/>
                                <stop offset="1" stopColor="#5787a6"/>
                            </linearGradient>
                        </defs>
                        <polygon style={{backgroundColor:"red"}} className="cls_logoAreaScalingGlass01" points="26.24 2.91   20.89 6.75   23.56 6.75   23.56 41.53   20.89 41.53   25.83 44.81   30.27 41.81   28.02 41.72   28.02 6.75   30.27 6.75   26.24 2.91"/>
        
                        {/*  Scaling glass gradiented circle area  */}
                        <defs>
                            <style>{".cls_logoAreaScalingGlass02{stroke-miterlimit:10;stroke:#575756;fill:url(#cls_logoAreaScalingGlass_linear-gradient-16);}"}
                            </style>
                            <linearGradient id="cls_logoAreaScalingGlass_linear-gradient-16" x1="15.31" y1="15.89" x2="25.12" y2="25.7" gradientUnits="userSpaceOnUse">
                                <stop offset="0" stopColor="#2581c4"/>
                                <stop offset="1" stopColor="#2581c4" stop-opacity="0"/>
                            </linearGradient>
                        </defs>
                        <circle className="cls_logoAreaScalingGlass02" cx="20.22" cy="20.8" r="6.94"/>
        
                        {/*  Scaling glass handle  */}
                        <defs>
                            <style>{".cls_logoAreaScalingGlass_25{fill:#575756;}"}
                            </style>
                        </defs>
                        <rect className="cls_logoAreaScalingGlass_25" x="16" y="7" width="13.67" height="2.41" transform="translate(20 8.51) rotate(45)"/>
        
                        {/*  Scaling glass specular  */}
                        <defs>
                            <style>{".cls_logoAreaScalingGlass_70{fill:url(#logoAreaScalingGlass_radial-gradient-15);}"}
                            </style>
                            <radialGradient id="logoAreaScalingGlass_radial-gradient-15" cx="16.92" cy="17.53" r="1.35" gradientUnits="userSpaceOnUse">
                                <stop offset="0.48" stopColor="#fff"/>
                                <stop offset="1" stopColor="#2581c4" stop-opacity="0"/>
                            </radialGradient>
                        </defs>
                        <circle className="cls_logoAreaScalingGlass_70" cx="16.92" cy="17.53" r="1.35"/>
        
                        {/*  Mouse interaction region  */}
                        <style>{".cls_logoAreaScalingGlass04{fill:rgba(0,0,0,0);}"}
                        </style>
                        <circle 
                            className="cls_logoAreaScalingGlass04" 
                            style={{cursor:"ns-resize"}} 
                            onMouseEnter={this.onEnterScalingGlassRegion.bind(this)} 
                            onMouseLeave={this.onLeaveScalingGlassRegionMinor.bind(this)} 
                            onMouseDown={this.onMouseDownScalingGlassRegion.bind(this)}
                            cx="25" cy="25" r="20"/>
                    </svg>
                    {mouseDraggingArea}
                </div>
            );
        }

        return(
            <div style={{height : "" + (scaledBarHeight*scale) + "px", width:"100%", position:"fixed", top:"opx", left:"0px", zIndex:1}}>
                <div style={{height: "" + (scaledBarHeight*scale) + "px", width: "100%", position:"absolute", top: "" + (scaledBarTop*scale) + "px", left: "" + (scaledBarLeft*scale) + "px", backgroundColor:this.getBG(1.0).GetRGB()}}>
                    
                </div>

                <svg 
                    style={{
                        height: "" + (scaledHeight*scale) + "px",
                        width: "" + (scaledWidth*scale) + "px",
                        position: "absolute",
                        top: "" + (scaledTop*scale) + "px",
                        left: "" + (scaledLeft*scale) + "px"
                    }}
                    xmlns="http://www.w3.org/2000/svg" 
                    viewBox="0 0 464.2 430.27"
                >
                    <defs>
                        <style>{(
                             ".clsa-1{fill:" + this.getBG(1.00).GetRGB() + ";}"
                            +".clsa-2{fill:" + this.getBG(0.80).GetRGB() + ";}"
                            +".clsa-3{fill:" + this.getBG(0.60).GetRGB() + ";}"
                            +".clsa-4{fill:" + this.getBG(0.40).GetRGB() + ";}"
                            +".clsa-5{fill:" + this.getBG(0.20).GetRGB() + ";}"
                            +".clsa-6{fill:" + this.getBG(0.10).GetRGB() + ";}"
                        )}
                        </style>
                    </defs>
                    <title>headercore</title>

                    <path className="clsa-1" d="M443,311.9c-58.43,1.65-62.26,17.59-86.32,41.78A208.95,208.95,0,0,1,0,205.93C2.72,116.79,62.25,0,208.56,0,385.19,1.65,406.5,5,464.2,5L464,311.9Z"/>
                    <path className="clsa-2" d="M433.56,137c-7.05,60.93-35.45,131.21-48.31,163.45-8.26,20.71-21.26,39-36.8,54.58A171.76,171.76,0,1,1,227,61.92c23.71,0,206.53,5.51,206.53,5.51Z"/>
                    <circle className="clsa-3" cx="246.35" cy="255.64" r="138.84"/>
                    <circle className="clsa-4" cx="260.51" cy="271.69" r="113.27"/>
                    <circle className="clsa-5" cx="272.45" cy="283.63" r="92.83"/>
                    <ellipse className="clsa-6" cx="282.72" cy="292.14" rx="74.16" ry="75.86"/>
                </svg>

                <div style={{height: "" + (scaledLogoHeight*scale) + "px", width: "" + (scaledLogoWidth*scale) + "px", position:"absolute", top: "" + (scaledLogoTop*scale) + "px", left: "" + (scaledLogoLeft*scale) + "px"}}>
                    <JGLogo clickHandler={this.onClickLogo.bind(this)}></JGLogo>
                </div>

                <div style={{
                    height: "" + (scaledBarHeight*scale) + "px", 
                    width: (window.innerWidth) + "px", 
                    position:"absolute", 
                    top: (scaledLogoTop*scale) + "px", 
                    left: (scaledMenuStartX*scale) + "px", 
                    display:"flex", flexDirection: flexDirectionForHeaderTabs
                }}>
                    {options}
                </div>

                {scalingGlassPart}

            </div>
        )
    }

    onEnterScalingGlassRegion(){
        this.mScalingGlassRegionEnterState = true;
        this.forceUpdate();
    }

    onLeaveScalingGlassRegion(){
        this.mScalingGlassRegionEnterState = false;
        this.mMouseMode = "initial";
        this.mScalingGlassMouseDownYAxis = -1;
        this.forceUpdate();
    }

    onLeaveScalingGlassRegionMinor(){
        this.mScalingGlassRegionEnterState = false;
        this.forceUpdate();
    }

    onMouseDownScalingGlassRegion(e:any){
        this.mMouseMode = "scaling";
        this.mScalingGlassMouseDownYAxis = e.clientY;
        this.mScalingGlassOriginalScale = (this.mCurrentScale + 0);
        this.forceUpdate();
    }

    onMouseUpScalingGlassRegion(){
        this.mMouseMode = "initial";
        this.mScalingGlassMouseDownYAxis = -1;
        this.forceUpdate();
    }

    onLeaveSelect(){
        this.displaySelect(false);
    }

    // (event: MouseEvent<SVGSVGElement, MouseEvent>) => void
    onScalingGlassMouseMove(e:any){
        let centerAdjust = 1.15;

        if((this.mMouseMode === "scaling") && (this.mScalingGlassMouseDownYAxis != -1)){
            let scaleTranslate = this.mScalingGlassOriginalScale / (this.mScalingGlassMouseDownYAxis);
            let difference = e.clientY - this.mScalingGlassMouseDownYAxis;

            this.mCurrentScale = this.mScalingGlassOriginalScale + (difference * scaleTranslate * centerAdjust); // (Math.round(((e.clientY*centerAdjust) * scaleTranslate)*1000))/1000;

            this.setStyle({height: (this.mCurrentScale * JGHeader.HEIGHT_BASE) + "px"});
            this.forceUpdate();
        }
    }

    onChoise(optionID : string){
        this.displaySelect(false);
        this.mSelectedTabID = optionID;
        this.props.onChoise(optionID);
    }
}
