import React, {Component} from 'react';
import {JGHeader} from './components/JGHeader';
import {JGClient} from './tools/JGClient';
import {JGBackground} from './components/JGBackground';
import './App.css';
import {Theme, ThemeSection, ThemeManager} from './components/theme';
import {JGStretchPanel} from './components/JGStretchPanel';
import {Redux} from './tools/Redux';
import {Palette} from './components/Palette';
import {JGPage, JGPageBundle} from './components/JGPage';
import {MainPageBundle} from './pages/main_page_bundle';
import {PageCV} from './pages/page_cv';
import {PagePortfolio} from './pages/page_portfolio';
import {Page3D} from './pages/page_3d';
import {PageContact} from './pages/page_contact';
import {PageLanguages} from './pages/page_languages';
import {ThemeStylesManager} from './data/themeStyles';
import {WordPipe} from './tools/WordPipe';
import {initiateLanguages} from './data/LanguageData';
import {ImageClaim} from './components/ImageClaim';

export enum DevMode{
  initial,
  showWebGLTab,
  selectionStage,
  selectionStageServerSupported,
  paletteMac,
  palettePC,
  paletteMacServerSupported,
  palettePCServerSupported
}

export class App extends Component{

  private mDevelopmentMode : DevMode;
  private mSelectedHeaderTab : string;
  private mHasLoadedThemes : boolean;

  private static sVersion = "v0.008";
  private static latestAppInstance : null | App = null;

  constructor(props:{}){
    super(props);
    App.latestAppInstance = this;
    this.mHasLoadedThemes = false;
    this.mDevelopmentMode = DevMode.initial;
    this.mSelectedHeaderTab = "cv";
    initiateLanguages();
    App.initiateThemes();
  }

  public static initiateThemes(){
    let mainTheme = Theme.getThemeByID("mainTheme");
    if(!mainTheme){
      mainTheme = new Theme("mainTheme");
    }

    let customTheme = Theme.getThemeByID("[SAMPLE]Custom");
    if(!customTheme){
      customTheme = new Theme("[SAMPLE]Custom");
    }

    if(mainTheme && customTheme){
      ThemeManager.loadThemeLocally("[SAMPLE]Custom");
      mainTheme.readFromLoadedTheme(customTheme)
    }
    else{
      console.log("App:initiateThemes - mainTheme:" + (mainTheme?"on":"off") + " customTheme:" + (customTheme?"on":"off"))
    }
  }

  public getDevMode(){
    return this.mDevelopmentMode
  }

  public setDevMode(dm : DevMode){
    this.mDevelopmentMode = dm;
    this.forceUpdate()
  }

  public static getLatestAppInstance(){
    return App.latestAppInstance;
  }
  
  loadThemes(){
    if(this.mHasLoadedThemes === true){
      return;
    }

    this.mHasLoadedThemes = true;
    let listOfThemes = Theme.getArrayOfThemesForSelect();

    for(let i=0; i<listOfThemes.length; i++){
      JGClient.callPublicService("loadtheme:" + listOfThemes[i].optionID, Theme.getThemeByID(listOfThemes[i].optionID), this.onLoadTheme.bind(this));
    }
  }


  loadThemesLocal(){

  }

  onLoadTheme(data:string){
		let loadedTheme = JSON.parse(data);
		let currentTheme = Theme.getThemeByID(loadedTheme.mId);

		if(currentTheme){

			currentTheme.readFromLoadedTheme(loadedTheme);
			this.forceUpdate();
		}
  }

  runInitialRefreshes(){
    setTimeout(this.forceUpdate.bind(this), 250)
    setTimeout(this.forceUpdate.bind(this), 500)
    setTimeout(this.forceUpdate.bind(this), 1000)
    setTimeout(this.forceUpdate.bind(this), 1500)
    setTimeout(this.forceUpdate.bind(this), 2000)
    setTimeout(this.forceUpdate.bind(this), 2500)
  }

  componentDidMount(){
    ThemeStylesManager.prepareStyles()

    window.addEventListener("keydown", this.toggleDev.bind(this))

    if((this.mDevelopmentMode === DevMode.paletteMacServerSupported) || (this.mDevelopmentMode === DevMode.palettePCServerSupported)){
      this.loadThemes();
    }

    if((this.mDevelopmentMode === DevMode.paletteMac) || (this.mDevelopmentMode === DevMode.palettePC)){
      this.loadThemesLocal();
    }

    this.runInitialRefreshes();
  }

  onMenuChoice(choise : string){
    window.scrollTo(0,0);
    
    if(choise === "cv"){
      this.mSelectedHeaderTab = "cv";
      JGPage.setPage("mainPageBundle","CVPage");
      this.forceUpdate();
      return;
    }

    if(choise === "portfolio"){
      this.mSelectedHeaderTab = "portfolio";
      JGPage.setPage("mainPageBundle","portfolioPage");
      this.forceUpdate();
      return;
    }

    if(choise === "3D"){
      this.mSelectedHeaderTab = "3D";
      JGPage.setPage("mainPageBundle","3DPage");
      this.forceUpdate();
      return;
    }

    if(choise === "contact"){
      this.mSelectedHeaderTab = "contact";
      JGPage.setPage("mainPageBundle","contactPage");
      this.forceUpdate();
      return;
    }

    if(choise === "logo"){
      this.mSelectedHeaderTab = "logo";
      JGPage.setPage("mainPageBundle","LanguagePage");
      this.forceUpdate();
      return;
    }

    console.log("App.onMenuChoice - got an unfamiliar choise: " + choise);
  }

  toggleDev(ev: KeyboardEvent){
    console.log("App.toggleDev - key: " + ev.key)

    if(ev.key === 'i'){
      this.mDevelopmentMode = DevMode.initial;
      Palette.showPalette(false);
      this.forceUpdate();
      return;
    }

    if(ev.key === 'k'){
      if(this.mDevelopmentMode === DevMode.initial){
        this.mDevelopmentMode = DevMode.palettePC;
        Palette.showPalette(true);
      }
      else{
        this.mDevelopmentMode = DevMode.initial;
        Palette.showPalette(false);
      }
      
      this.forceUpdate();
      return;
    }

    if(ev.key === 'w'){
      if(this.mDevelopmentMode === DevMode.showWebGLTab){
        this.mDevelopmentMode = DevMode.initial
      }
      else{
        this.mDevelopmentMode = DevMode.showWebGLTab
        setTimeout(this.forceUpdate.bind(this), 500)
      }

      this.forceUpdate();
      return;
    }
    
    if(ev.key === 'd'){
      this.mDevelopmentMode = DevMode.selectionStage;
      Palette.showPalette(false);
      this.forceUpdate();
      return;
    }

    if(ev.key === 's'){
      this.mDevelopmentMode = DevMode.selectionStageServerSupported;
      Palette.showPalette(false);
      this.forceUpdate();
      return;
    }

    if(this.mDevelopmentMode === DevMode.selectionStage){
      if(ev.key === 'm'){
        this.mDevelopmentMode = DevMode.paletteMac;
        this.loadThemesLocal();
        Palette.showPalette(true);
        this.forceUpdate();
        return;
      }

      if(ev.key === 'p'){
        this.mDevelopmentMode = DevMode.palettePC;
        this.loadThemesLocal();
        Palette.showPalette(true);
        this.forceUpdate();
        return;
      }
    }

    if(this.mDevelopmentMode === DevMode.selectionStageServerSupported){
      if(ev.key === 'm'){
        this.mDevelopmentMode = DevMode.paletteMacServerSupported;
        this.loadThemes();
        Palette.showPalette(true);
        this.forceUpdate();
      }

      if(ev.key === 'p'){
        this.mDevelopmentMode = DevMode.palettePCServerSupported;
        this.loadThemes();
        Palette.showPalette(true);
        this.forceUpdate();
      }   
    }

  }

  render(){
    // NO
    let headerOptions = [
      {optionID:"cv", optionText: WordPipe.read("Header_CV")},
      {optionID:"portfolio", optionText: WordPipe.read("Header_Portfolio")},
      {optionID:"contact", optionText: WordPipe.read("Header_Contact")}
    ]

    let versionShown = ""; //App.mVersion;
    let versionDisplay = null;

    if(this.mDevelopmentMode === DevMode.showWebGLTab){

      headerOptions = [
        {optionID:"cv", optionText: WordPipe.read("Header_CV")},
        {optionID:"portfolio", optionText: WordPipe.read("Header_Portfolio")},
        {optionID:"3D", optionText: WordPipe.read("Header_WebGL")},
        {optionID:"contact", optionText: WordPipe.read("Header_Contact")}
      ]
    }

    if(this.mDevelopmentMode !== DevMode.initial){
      versionShown = App.sVersion;

      versionDisplay = (
        <div style={{
          position:"absolute",
          top:"70px",
          left:"150px",
          width:"70px",
          height:"25px",
          color:"white",
          textAlign:"left"
        }}>
          {versionShown}
        </div>
      )
    }

    let headerDisplayMode = "regular";

    if((this.mDevelopmentMode === DevMode.palettePC) || (this.mDevelopmentMode === DevMode.palettePCServerSupported)){
      headerDisplayMode = "swiftUpdate"
    }

    let serverStoredThemes : boolean = ((this.mDevelopmentMode === DevMode.palettePCServerSupported)||this.mDevelopmentMode === DevMode.paletteMacServerSupported) ? true : false

    let page = (
      <div className="App">
        <ThemeSection themeID={"mainTheme"}>
          <JGHeader 
            options={headerOptions} 
            selectedTabID={this.mSelectedHeaderTab} 
            scale={1.0} 
            onChoise={this.onMenuChoice.bind(this)}
            displayMode={headerDisplayMode}
          />
          <JGBackground/>
          <Palette serverOn={serverStoredThemes}/>
          <MainPageBundle bundleID={"mainPageBundle"} initialPage={"LanguagePage"}>
            <PageLanguages pageID={"LanguagePage"}/>
            <PageCV pageID={"CVPage"}/>
            <PagePortfolio pageID={"portfolioPage"}/>
            <Page3D pageID={"3DPage"}/>
            <PageContact pageID={"contactPage"}/>
          </MainPageBundle>
          {versionDisplay}
        </ThemeSection>
        <ImageClaim/>
      </div>
    )

    return page;
  }
}

export default App;

    // stored themes: bluish cowboy
    // <JGStretchPanel style={{position:"relative", left:"300px", top:"100px", height:"300px", width:"500px"}} frameThickness={30} componentID={"someID"}/>


