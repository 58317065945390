import * as React from 'react';
import {JGPageBundle} from '../components/JGPage';

/*
    ####   ####   
    #   #  #   #  
    ####   #### 
    #      #   # 
    #      ####    
*/

export class MainPageBundle extends JGPageBundle{

    constructor(props:any){
        super(props);
    }

    draw(){
        var margin = 80;
        let width = window.innerWidth;
        if(width < 700){
            margin = 0;
        }

        this.setStyleDefault({
            position:"relative", 
            left: (margin+"px"),
            top: ((margin/2)+"px"),
            width:("calc("+ width +"px - "+ (margin*2) +"px)")
        })

        return(
            <div style={{width:"100%"}}>
                {this.props.children}
            </div>
        )
    }
}
