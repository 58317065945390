import * as React from 'react';

//  ImageClaim places an unviewable element into the dom 
//  that contains some images to be cashed in advance, from about
//  half a second after being introduced in order not to delay 
//  other data requests which are required directly as the page opens.

export class ImageClaim extends React.Component{

    static imagesClaimed : boolean = false;

    private mShowingImages : boolean;
    private mUnmounting : boolean;

    constructor(props:{}){
        super(props)
        this.mShowingImages = false;
        this.mUnmounting = false;
    }

    callForImages(){
        if(ImageClaim.imagesClaimed === false && this.mUnmounting === false){
            this.mShowingImages = true;
            this.forceUpdate();
        }
    }

    componentDidMount(){
        setTimeout(this.callForImages.bind(this), 450)
    }

    componentWillUnmount(){
        this.mUnmounting = true;
    }

    unseenPixel(imgLink:string){
        let result:any = {
            position:"absolute",
            top:"0px",
            left:"0px",
            width:"1px",
            height:"1px",
            maxWidth:"1px",
            maxHeight:"1px",
            backgroundImage: imgLink
        }

        return result
    }

    render(){
        if( this.mShowingImages === false){
            // console.log("ImageClaim rendering without images")
            return <div/>
        }

        ImageClaim.imagesClaimed = true;
        // console.log("ImageClaim rendering with images")

        return(
            <div style={{
                position:"absolute",
                top:"10px",
                left:"-200px",
                opacity:0.0000001,
                width:"1px",
                height:"1px",
                maxWidth:"1px",
                maxHeight:"1px"
            }}>
                <div style={ this.unseenPixel("url(https://i.imgur.com/SdAQScU.jpg)") /* Portfolio - violet screen */ }/>
                <div style={ this.unseenPixel("url(https://i.imgur.com/QH8ZDKJ.jpg)") /* Contact - typing hands */ }/>
                <div style={ this.unseenPixel("url(https://i.imgur.com/KKR5KP9.jpg)") /* Portfolio - background Nova */ }/>
                <div style={ this.unseenPixel("url(https://i.imgur.com/VrJYJWf.jpg)") /* CV - photo */ }/>
                <div style={ this.unseenPixel("url(https://i.imgur.com/JFaoSge.jpg)") /* Portfolio - Prisassistenten 1 */ }/>
                <div style={ this.unseenPixel("url(https://i.imgur.com/xEdtOdr.jpg)") /* Portfolio - Prisassistenten 2 */ }/>
                <div style={ this.unseenPixel("url(https://i.imgur.com/cDKdkqy.jpg)") /* Portfolio - Prisassistenten 3 */ }/>
                <div style={ this.unseenPixel("url(https://i.imgur.com/HFP2jI9.jpg)") /* Portfolio - Prisassistenten 4 */ }/>
                <div style={ this.unseenPixel("") /*  */ }/>
            </div>
        )
    }
}