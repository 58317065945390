import * as React from 'react';
import { Theme } from '../components/theme';
import { CVJob } from '../data/loadJobs';

export interface JobDisplayProps{
    theme: Theme,
    job: CVJob
}

type fontweight = number | "bold" | "-moz-initial" | "inherit" | "initial" | "revert" | "unset" | "normal" | "bolder" | "lighter" | undefined;
type flexDir = "-moz-initial" | "inherit" | "initial" | "revert" | "unset" | "row" | "column" | "column-reverse" | "row-reverse" | undefined;
type textAlignment = "-moz-initial" | "inherit" | "initial" | "revert" | "unset" | "left" | "center" | "end" | "justify" | "match-parent" | "right" | "start" | undefined;
type flexWrap = "-moz-initial" | "inherit" | "initial" | "revert" | "unset" | "wrap" | "nowrap" | "wrap-reverse" | undefined;

export class JobDisplay extends React.Component<JobDisplayProps, {}>{

    constructor(props:JobDisplayProps){
        super(props)
    }

    render(){
        let contents = new Array<JSX.Element>();

        let keyProgress = 0;
        let fontBold : fontweight = "bold";
        let flexRow : flexDir = "row";
        let leftAligned : textAlignment = "left";
        let flexWrapping : flexWrap = "wrap";

        
        let surroundingStyle = {
            width:"calc(100% - 30px)",
            display:"flex",
            flexDirection: flexRow,
            flexWrap: flexWrapping
        }

        let lineTitleStyle = {
            fontWeight: fontBold,
            color:  this.props.theme.getDetail(null, 1, 0).GetRGB(),
            marginLeft: "15px",
            marginRight: "10px",
            marginTop: "5px"
        }

        let lineContentStyle = {
            marginLeft: "15px",
            marginTop: "5px",
            textAlign: leftAligned
        }

        contents.push(
            <div key={"key" + (keyProgress++)} style={{
                height:"11px"
            }}>
            </div>
        )

       

        // Employer
        if(this.props.job.employer){
            contents.push(
                <div key={"key" + (keyProgress++)} style={surroundingStyle}>
                    <div style={lineTitleStyle}>
                        {this.props.job.contentTitles.employer}
                    </div>
                    <div style={lineContentStyle}>
                        {this.props.job.employer}
                    </div>
                </div>
            )
        }

        // Timespan
        contents.push(
            <div key={"key" + (keyProgress++)} style={surroundingStyle}>
                <div style={lineTitleStyle}>
                    {this.props.job.contentTitles.start}
                </div>
                <div style={lineContentStyle}>
                    {this.props.job.start + " - " + this.props.job.end}
                </div>
            </div>
        )

        // Role
        contents.push(
            <div key={"key" + (keyProgress++)} style={surroundingStyle}>
                <div style={lineTitleStyle}>
                    {this.props.job.contentTitles.title}
                </div>
                <div style={lineContentStyle}>
                    {this.props.job.title}
                </div>
            </div>
        )

        // Description
        contents.push(
            <div key={"key" + (keyProgress++)} style={surroundingStyle}>
                <div style={lineTitleStyle}>
                    {this.props.job.contentTitles.description}
                </div>
                <div style={lineContentStyle}>
                    {this.props.job.description}
                </div>
            </div>
        )

        // Technologies
        contents.push(
            <div key={"key" + (keyProgress++)} style={surroundingStyle}>
                <div style={lineTitleStyle}>
                    {this.props.job.contentTitles.tech}
                </div>
                <div style={lineContentStyle}>
                    {this.props.job.tech}
                </div>
            </div>
        )

        contents.push(
            <div key={"key" + (keyProgress++)} style={{
                height:"16px"
            }}>
            </div>
        )

        return(
            <div style={{
                width: "100%",
                backgroundColor: this.props.theme.getBG(null, 0.1, 0.25).GetRGB(),
                border: "2px solid " + this.props.theme.getBG(null, 1, 0).GetRGB(),
                borderRadius: "15px",
                minHeight: "150px"
            }}>
                {contents}

                <div>

                </div>
            </div>
        )
    }
}