

export function LoadFromBase(url: string, onloaded: (s: string) => void, token: string) {
    var Httpreq = new XMLHttpRequest(); // a new request
    Httpreq.open("GET", url, true);
    Httpreq.setRequestHeader("Authorization", "Bearer " + token);
    Httpreq.setRequestHeader("Content-Type", "application/json");
    Httpreq.addEventListener("load", () => onloaded(Httpreq.responseText));
    Httpreq.send(null);
}

// Posting json files
export function SaveToUrl(url: string, onSaved: (response: string) => void, token: string, content: any) {
    var Httpreq = new XMLHttpRequest(); // a new request
    Httpreq.open("POST", url, true);
    Httpreq.setRequestHeader("Authorization", "Bearer " + token);
    Httpreq.setRequestHeader("Content-Type", "application/json");
    Httpreq.addEventListener("load", () => onSaved(Httpreq.responseText));
    Httpreq.send(JSON.stringify( content ));
}

export function Upload(content: any, onSaved: (response: string) => void) {
    var Httpreq = new XMLHttpRequest(); // a new request
    Httpreq.open("POST", 'http://localhost:8080', true);
    Httpreq.setRequestHeader("Content-Type", "application/json");
    Httpreq.addEventListener("load", () => onSaved(Httpreq.responseText));
    Httpreq.send(content );
}

export enum SessionStage{
    INITIAL,
    SESSION_REQUESTED,
    SESSION_INITIATED,
    SESSION_SECURITY_STEP_1,
    SESSION_SECURITY_STEP_2,
    SESSION_SECURITY_COMPLETED,
    USER_LOGIN_REQUESTED,
    USER_LOGIN_INITIATED,
    USER_LOGIN_SECURE
}

class SessionToken{
    
    private mTokenReadingPosition : number;
    private mRootToken : string;
    private mTokenBase : string;

    constructor(rootToken:string, receivedToken:string){
        this.mTokenReadingPosition = 0;
        this.mRootToken = rootToken;

        this.mTokenBase = this.generateTokenBase(rootToken, receivedToken);
    }

    generateTokenBase(rootToken:string, receivedToken:string){
        return rootToken + receivedToken;
    }

    readToken(){
        let result = "";
        let readingPosition = this.mTokenReadingPosition;
        let increment = 0;
        let charSpot = 0;
        let baseLength = this.mTokenBase.length;

        for(let i=0; i<20; i++){
            increment = ((readingPosition + 133) * (readingPosition + 111)) % 51;
            charSpot = (charSpot + increment) % baseLength;
            result += this.mTokenBase.charAt(charSpot);
            readingPosition++;
        }

        this.mTokenReadingPosition = (readingPosition % 42157) + 7243;

        return result;
    }
}

export class JGClient{

    private mSessionID : string | null;
    private mToken : SessionToken | null;
    private mUserName : string | null;
    private mPassword : string | null;
    private mOnLogin : (success:boolean)=>void;
    private mSessionStage : SessionStage;

    constructor(){
        this.mToken = null;
        this.mUserName = null;
        this.mPassword = null;
        this.mOnLogin = (success:boolean)=>{};
        this.mSessionID = null;
        this.mSessionStage = SessionStage.INITIAL;
    }

    public static callGet(url: string, onloaded: (s: string) => void, token?: string){
        let tk : string = token === undefined ? "" : token;
        LoadFromBase(url, onloaded, tk);
    }

    public static callPublicService(serviceID:string, parameter:any, callback:(response:any)=>void){
        console.log("JGClient.callPublicService called. serviceID:" + serviceID + " param:" + parameter );

        SaveToUrl("http://localhost:8080/jgmedia/" + serviceID, callback, "", parameter);
    }

    public callService(serviceID:string, parameter:any, callback:(response:any)=>void){
        console.log("JGClient.callService called. serviceID:" + serviceID + " param:" + parameter );

        SaveToUrl("http://localhost:8080/jgmedia/" + serviceID, callback, this.readToken(), parameter);
    }

    private readToken(){
        if(this.mToken !== null){
            return this.mToken.readToken();
        }
        else{
            return "";
        }
    }

    public requestSession(){

        if(this.mSessionStage === SessionStage.INITIAL){
            this.mSessionStage = SessionStage.SESSION_REQUESTED;

            let rootID = localStorage.getItem("JGMedia_localSessionRootID");

            if(rootID === null){
                this.callService("requestPublicUserSession", "", this.onSessionReceived.bind(this));
            }
            else{
                this.callService("requestPublicUserSession", rootID, this.onSessionReceived.bind(this));
            }     
        }
    }

    private onSessionReceived(response:{sessionID:string, passedToken:string}){
        this.mSessionID = response.sessionID;

        let rootToken = localStorage.getItem("JGMedia_localSessionRootToken");

        if(rootToken !== null){
            this.mToken = new SessionToken(rootToken, response.passedToken);
        }
        else{
            this.mToken = new SessionToken("", response.passedToken);
        }

        if((this.mUserName !== null)&&(this.mPassword !== null)){
            this.login(this.mUserName, this.mPassword, this.mOnLogin);
        }
    }

    public login(username:string, password:string, onLoggedIn:(success:boolean)=>void){

        if(this.mSessionStage === SessionStage.USER_LOGIN_REQUESTED){

        }

        if(this.isLoggedIn() === true){
            if((username === this.mUserName) && (password === this.mPassword)){
                onLoggedIn(true);
            }

            onLoggedIn(false);
        }
        else{
            if(this.mSessionID === null){
                this.mUserName = username;
                this.mPassword = password;
                this.mOnLogin = onLoggedIn;
                this.requestSession();
            }
            else{
                this.callService("loginUser", "session:" + this.mSessionID, this.onSessionReceived.bind(this));
            }
        }
    }

    public isLoggedIn(){
        if(this.mToken === null){
            return false;
        }

        return true;
    }

    public getUserName(){
        return this.mUserName;
    }
}