import React from 'react';
import {JGClient} from '../tools/JGClient';

/*

    ####   ####   #       ####   ####        ####    ###   ## ##  ####   #      #####
   #      #    #  #      #    #  #   #      #       #   #  # # #  #   #  #      #
   #      #    #  #      #    #  ####        ####   #####  #   #  ####   #      ###
   #      #    #  #      #    #  #   #           #  #   #  #   #  #      #      #
    ####   ####   #####   ####   #    #      ####   #   #  #   #  #      #####  #####

*/

// ColorSample handles a color. In data into the color is checked for validity.
export class ColorSample{

    constructor(onChangeUpdateMethod:(()=>void)|null, red:number, green:number, blue:number, alpha?:number){
        if(alpha !== undefined){
            if(alpha < 0){
                console.warn('Warning: A negative alpha value was found when constructing a ColorSample(!)')
                this.mAlpha = Math.round(-alpha) % 256;
            }
            else{
                if(alpha > 255){
                    console.warn('Warning: An alpha value exceeding 255 was found when constructing a ColorSample(!) - value:' + alpha)
                }
    
                this.mAlpha = Math.round(alpha) % 256;
            }
        }
        else{
            this.mAlpha = 255;
        }

        if(red < 0){
            console.warn('Warning: A negative red value was found when constructing a ColorSample(!) - value:' + red)
            this.mRed = Math.round(-red) % 256;
        }
        else{
            if(red > 255){
                console.warn('Warning: A red value exceeding 255 was found when constructing a ColorSample(!)')
            }

            this.mRed = Math.round(red) % 256;
        }

        if(green < 0){
            console.warn('Warning: A negative green value was found when constructing a ColorSample(!)')
            this.mGreen = Math.round(-green) % 256;
        }
        else{
            if(green > 255){
                console.warn('Warning: A green exceeding 255 was found when constructing a ColorSample(!)')
            }

            this.mGreen = Math.round(green) % 256;
        }

        if(blue < 0){
            console.warn('Warning: A negative blue value was found when constructing a ColorSample(!)')
            this.mBlue = Math.round(-blue) % 256;
        }
        else{
            if(blue > 255){
                console.warn('Warning: A blue value exceeding 255 was found when constructing a ColorSample(!)')
            }

            this.mBlue = Math.round(blue) % 256;
        }

        this.mOnChangeUpdateMethod = onChangeUpdateMethod;
    }

    public setUpdateMethod(onChangeUpdateMethod:(()=>void)|null){
        this.mOnChangeUpdateMethod = onChangeUpdateMethod;
    }

    public updateTheme(){
        if(this.mOnChangeUpdateMethod){
            this.mOnChangeUpdateMethod();
        }
    }

    public equals(comparedColor: ColorSample){
        if(comparedColor.GetRed() != this.GetRed()){
            return false;
        }

        if(comparedColor.GetGreen() != this.GetGreen()){
            return false;
        }

        if(comparedColor.GetBlue() != this.GetBlue()){
            return false;
        }

        if(comparedColor.GetAlpha() != this.GetAlpha()){
            return false;
        }

        return true;
    }

    public copy(onChangeUpdateMethodForTheCopy:(()=>void)|null){
        return new ColorSample(onChangeUpdateMethodForTheCopy, this.GetRed(), this.GetGreen(), this.GetBlue(), this.GetAlpha())
    }

    public setEqualTo(originalColor: ColorSample){
        this.mRed = originalColor.GetRed();
        this.mGreen = originalColor.GetGreen();
        this.mBlue = originalColor.GetBlue();
        this.mAlpha = originalColor.GetAlpha();
        this.updateTheme();
    }

    public SetColor(red:number, green:number, blue:number, alpha?:number ){
        let newRed = Math.round(red);
        let newGreen = Math.round(green);
        let newBlue = Math.round(blue);

        if(newRed > 255){
            console.warn('Warning: A red value above 255 was found in ColorSample.SetColor(!)');
            newRed = 255;
        }

        if(newRed < 0){
            console.warn('Warning: A negative red value was found in ColorSample.SetColor (it should be between 0 and 255)');
            newRed = 0;
        }

        if(newGreen > 255){
            console.warn('Warning: A green value above 255 was found in ColorSample.SetColor(!)');
            newGreen = 255;
        }

        if(newGreen < 0){
            console.warn('Warning: A negative green value was found in ColorSample.SetColor (it should be between 0 and 255)');
            newGreen = 0;
        }

        if(newBlue > 255){
            console.warn('Warning: A blue value above 255 was found in ColorSample.SetColor(!)');
            newBlue = 255;
        }

        if(newBlue < 0){
            console.warn('Warning: A negative blue value was found in ColorSample.SetColor (it should be between 0 and 255)');
            newBlue = 0;
        }

        this.mRed = newRed;
        this.mGreen = newGreen;
        this.mBlue = newBlue;

        if(alpha){
            let newAlpha = Math.round(alpha);

            if(newAlpha > 255){
                console.warn('Warning: An alpha value above 255 was found in ColorSample.SetColor(!)');
                newAlpha = 255;
            }
    
            if(newAlpha < 0){
                console.warn('Warning: A negative alpha value was found in ColorSample.SetColor (it should be between 0 and 255)');
                newAlpha = 0;
            }

            this.mBlue = newAlpha;
        }

        this.updateTheme();
    }

    public GetRed(){
        return this.mRed;
    }

    public SetRed(newRed:number){
        let red = Math.round(newRed);

        if(red > 255){
            console.warn('Warning: A value above 255 was found in ColorSample.SetRed(!)');
            red = 255;
        }

        if(red < 0){
            console.warn('Warning: A negative value was found in ColorSample.SetRed (it should be between 0 and 255)');
            red = 0;
        }

        this.mRed = red;
        this.updateTheme();
    }

    public GetGreen(){
        return this.mGreen;
    }

    public SetGreen(newGreen:number){
        let green = Math.round(newGreen);

        if(green > 255){
            console.warn('Warning: A value above 255 was found in ColorSample.SetGreen(!)');
            green = 255;
        }

        if(green < 0){
            console.warn('Warning: A negative value was found in ColorSample.SetGreen (it should be between 0 and 255)');
            green = 0;
        }

        this.mGreen = green;
        this.updateTheme();
    }

    public GetBlue(){
        return this.mBlue;
    }

    public SetBlue(newBlue:number){
        let blue = Math.round(newBlue);

        if(blue > 255){
            console.warn('Warning: A value above 255 was found in ColorSample.SetBlue(!)');
            blue = 255;
        }

        if(blue < 0){
            console.warn('Warning: A negative value was found in ColorSample.SetBlue (it should be between 0 and 255)');
            blue = 0;
        }

        this.mBlue = blue;
        this.updateTheme();
    }

    public GetAlpha(){
        return this.mAlpha;
    }

    public SetAlpha(newAlpha:number){
        let alpha = Math.round(newAlpha);

        if(alpha > 255){
            console.warn('Warning: A value above 255 was found in ColorSample.SetAlpha(!)');
            alpha = 255;
        }

        if(alpha < 0){
            console.warn('Warning: A negative value was found in ColorSample.SetAlpha (it should be between 0 and 255)');
            alpha = 0;
        }

        this.mAlpha = alpha;
        this.updateTheme();
    }

    public GetRGB(){
        return 'rgb(' + this.mRed + ',' + this.mGreen + ',' + this.mBlue + ')';
    }

    public GetRGBA(){
        return 'rgba(' + this.mRed + ',' + this.mGreen + ',' + this.mBlue + ',' + this.mAlpha + ')';
    }

    public Mix( onChangeUpdateMethodForTheResultingColor:(()=>void)|null, mixedColor: ColorSample, mixedWeightZeroToOne: number ){
        let mix = mixedWeightZeroToOne;

        if(mix > 1.0){
            console.warn('Warning: ColorSample was called with a mix weight parameter value exceeding the maximum of 1.0');
            mix = 1.0; 
        }

        if(mix < 0.0){
            console.warn('Warning: ColorSample was called with a mix weight parameter value below the minimum of 0.0');
            mix = 0.0; 
        }

        let alpha = Math.round((this.mAlpha * (1-mix) ) + (mixedColor.GetAlpha() * mix));
        let red = Math.round( (this.mRed * (1-mix) ) + (mixedColor.GetRed() * mix));
        let green = Math.round((this.mGreen * (1-mix) ) + (mixedColor.GetGreen() * mix));
        let blue = Math.round((this.mBlue * (1-mix) ) + (mixedColor.GetBlue() * mix));

        return new ColorSample(onChangeUpdateMethodForTheResultingColor,red,green,blue,alpha);
    }

    public getHue(preference?:number){

        if((this.mRed === this.mBlue)&&(this.mRed === this.mGreen)){
            if(preference !== undefined){
                if((preference > 0)&&(preference <= 1)){
                    return preference;
                }
            }

            return 0.0;
        }

        if(this.mRed === this.mBlue){
            if(this.mRed > this.mGreen){
                return 5/6;
            }
            else{
                return 2/6;
            }
        }

        if(this.mRed === this.mGreen){
            if(this.mRed > this.mBlue){
                return 1/6;
            }
            else{
                return 4/6;
            }
        }

        if(this.mBlue === this.mGreen){
            if(this.mRed > this.mBlue){
                return 0;
            }
            else{
                return 0.5;
            }
        }

        if(this.mRed > this.mBlue){
            if(this.mBlue > this.mGreen){
                // 5/6 - 6/6
                let diff = this.mRed - this.mGreen;
                let progress = (this.mBlue - this.mGreen) / diff;
                return 1 - (progress/6);
            }
            else{
                if(this.mRed > this.mGreen){
                    // 0/6 - 1/6
                    let diff = this.mRed - this.mBlue;
                    let progress = (this.mGreen - this.mBlue) / diff;
                    return progress/6;
                }
                else{
                    // 1/6 - 2/6
                    let diff = this.mGreen - this.mBlue;
                    let progress = (this.mRed - this.mBlue) / diff;
                    return 2/6 - (progress/6);
                }
            }
        }
        else{
            if(this.mRed > this.mGreen){
                // 4/6 - 5/6
                let diff = this.mBlue - this.mGreen;
                let progress = (this.mRed - this.mGreen) / diff;
                return 4/6 + (progress/6);
            }
            else{
                if(this.mBlue > this.mGreen){
                    // 3/6 - 4/6
                    let diff = this.mBlue - this.mRed;
                    let progress = (this.mGreen - this.mRed) / diff;
                    return 4/6 - (progress/6);
                }
                else{
                    // 2/6 - 3/6
                    let diff = this.mGreen - this.mRed;
                    let progress = (this.mBlue - this.mRed) / diff;
                    return 2/6 + (progress/6);
                }
            }
        }
    }

    public setHue(newHue:number){
        let brightness = this.getBrightness();
        let intensity = this.getColorIntensity();
        let combined = (1-intensity) * 255;
        let base = combined * brightness;

        this.mRed = base + ((255*intensity) * ColorSample.get0to1RedFromHue(newHue));
        this.mGreen = base + ((255*intensity) * ColorSample.get0to1GreenFromHue(newHue));
        this.mBlue = base + ((255*intensity) * ColorSample.get0to1BlueFromHue(newHue));
        this.updateTheme();
    }

    public getColorIntensity(){

        if((this.mRed === this.mBlue)&&(this.mRed === this.mGreen)){
            return 0.0;
        }

        let gloom = this.getGloomLevel();
        let shine = this.getShineLevel();
        let combined = gloom + shine;
        
        return (255-combined)/255;
    }

    public setColorIntensity(intensity:number, huePreference:number){
        let combined = 1-intensity;
        let brightness = this.getBrightness();
        let hue = this.getHue(huePreference);
        let base = (combined*255) * brightness;
        this.mRed = base + ((255*intensity) * ColorSample.get0to1RedFromHue(hue));
        this.mGreen = base + ((255*intensity) * ColorSample.get0to1GreenFromHue(hue));
        this.mBlue = base + ((255*intensity) * ColorSample.get0to1BlueFromHue(hue));
        this.updateTheme();
    }

    public getBrightness(preference?:number){
        let gloom = this.getGloomLevel();
        let shine = this.getShineLevel();
        let combined = gloom + shine;
        if(combined > 0){
            return shine / combined;
        }
        else{
            // This color is a pure hue (not mixed with any white, gray or black) 
            // so any value from 0 to 1 could be the correct brightness.
            // preference is an optional suggestion which will be used if given
            if(preference !== undefined){
                if((preference > 0)&&(preference <= 1)){
                    return preference;
                }
            }
            
            return 0.0;
        }
    }

    public setBrightness(brightness:number){
        let highest = this.mRed;

        if(this.mGreen > highest){
            highest = this.mGreen;
        }

        if(this.mBlue > highest){
            highest = this.mBlue;
        }

        let gloom = 255-highest;

        let lowest = this.mRed;

        if(this.mGreen < lowest){
            lowest = this.mGreen;
        }

        if(this.mBlue < lowest){
            lowest = this.mBlue;
        }

        let shine = lowest;
        let combined = gloom + shine;

        let newShine = brightness * combined;

        let newRed = (this.mRed - lowest) + newShine;
        let newGreen = (this.mGreen - lowest) + newShine;
        let newBlue = (this.mBlue - lowest) + newShine;

        this.mRed = newRed;
        this.mGreen = newGreen;
        this.mBlue = newBlue;
        this.updateTheme();
    }

    public getGloomLevel(){
        let highest = this.mRed;

        if(this.mGreen > highest){
            highest = this.mGreen;
        }

        if(this.mBlue > highest){
            highest = this.mBlue;
        }

        return 255-highest;
    }

    public getShineLevel(){
        let lowest = this.mRed;

        if(this.mGreen < lowest){
            lowest = this.mGreen;
        }

        if(this.mBlue < lowest){
            lowest = this.mBlue;
        }

        return lowest;
    }

    public static get0to1RedFromHue(hue : number){
        if(hue <= 1/6){
            return 1;
        }

        if(hue < 2/6){
            return 1-((hue-(1/6)) * 6);
        }

        if(hue <= 4/6){
            return 0;
        }

        if(hue < 5/6){
            return (hue-(4/6)) * 6;
        }

        return 1;
    }

    public static get0to1GreenFromHue(hue : number){
        if(hue == 0){
            return 0;
        }

        if(hue < 1/6){
            return hue * 6;
        }

        if(hue <= 3/6){
            return 1;
        }

        if(hue < 4/6){
            return 1-((hue-(3/6)) * 6);
        }

        return 0;
    }

    public static get0to1BlueFromHue(hue : number){
        if(hue <= 2/6){
            return 0;
        }

        if(hue < 3/6){
            return (hue-(2/6)) * 6;
        }

        if(hue <= 5/6){
            return 1;
        }

        return 1-((hue-(5/6)) * 6);
    }

    private mRed: number;
    private mGreen: number;
    private mBlue: number;
    private mAlpha: number;
    private mOnChangeUpdateMethod: (()=>void) | null;
}









/*

    #####  #   #  #####  ## ##  #####     ####        ####  ####    ###   ####   ###  ####  ##  # #####
      #    #   #  #      # # #  #         #   #      #      #   #  #   #  #   #   #   #     # # #   #
      #    #####  ###    #   #  ###       ####       #      ####   #####  #   #   #   ##    # # #   #
      #    #   #  #      #   #  #         #   #      #   #  #  #   #   #  #   #   #   #     # # #   #
      #    #   #  #####  #   #  #####     #    # #    ####  #   #  #   #  ####   ###  ####  #  ##   #

*/



// ThemeRegionGradient is a one dimensional color gradient based on a dynamic number of color samples
export class ThemeRegionGradient{

    constructor(onChangeUpdateMethod:(()=>void)|null, signature: string, colorStops: {pos: number, r:number, g:number, b:number}[] ){

        this.mGradientStops = new Array<GradientStop>();

        for(let i=0; i<colorStops.length; i++){
            this.mGradientStops.push(new GradientStop(onChangeUpdateMethod, signature, new ColorSample(onChangeUpdateMethod, colorStops[i].r, colorStops[i].g, colorStops[i].b), colorStops[i].pos, this.unsort.bind(this)));
        }

        this.mSignature = signature;
        this.mIsSorted = false;
        this.mOnChangeUpdateMethod = onChangeUpdateMethod;
    }

    public updateTheme(){
        if(this.mOnChangeUpdateMethod){
            this.mOnChangeUpdateMethod();
        }
    }
    
    /*
    Randomize(){
        this.red = Math.random() * 255;
        this.redMax = (Math.random() * (255-this.red))+this.red;
        this.redMin = Math.random() * this.red;
        this.green = Math.random() * 255;
        this.greenMax = (Math.random() * (255-this.green))+this.green;
        this.greenMin = Math.random() * this.green;
        this.blue = Math.random() * 255;
        this.blueMax = (Math.random() * (255-this.blue))+this.blue;
        this.blueMin = Math.random() * this.blue;
    }*/

    public static getRandom(onChangeUpdateMethod:(()=>void)|null, signature: string){

        let red = Math.random() * 255;
        let redMax = (Math.random() * (255-red))+red;
        let redMin = Math.random() * red;
        let green = Math.random() * 255;
        let greenMax = (Math.random() * (255-green))+green;
        let greenMin = Math.random() * green;
        let blue = Math.random() * 255;
        let blueMax = (Math.random() * (255-blue))+blue;
        let blueMin = Math.random() * blue;

        if(signature === "Sp"){
            red = (Math.random() * 65) + 190;
            redMin = (Math.random() * (255-red))+red;
            redMax = (Math.random() * 35) + (red-35);
            green = (Math.random() * 65) + 190;
            greenMin = (Math.random() * (255-green))+green;
            greenMax = (Math.random() * 35) + (green-35);
            blue = (Math.random() * 65) + 190;
            blueMin = (Math.random() * (255-blue))+blue;
            blueMax = (Math.random() * 35) + (blue-35);
        }

        if(signature === "Sh"){
            red = Math.random() * 64;
            redMax = (Math.random() * (90-red))+red;
            redMin = Math.random() * red;
            green = Math.random() * 64;
            greenMax = (Math.random() * (90-green))+green;
            greenMin = Math.random() * green;
            blue = Math.random() * 64;
            blueMax = (Math.random() * (90-blue))+blue;
            blueMin = Math.random() * blue;
        }

        return new ThemeRegionGradient(
            onChangeUpdateMethod, 
            signature, 
            [
                { pos:0.0, r:redMin, g:greenMin, b:blueMin},
                { pos:0.5, r:red, g:green, b:blue},
                { pos:1.0, r:redMax, g:greenMax, b:blueMax}
            ]
        )
    }

    removeColorStop(stopID:string){
        if(this.mGradientStops.length < 2){
            return;
        }

        let reduced : Array<GradientStop> = new Array<GradientStop>();

        for(let i=0; i<this.mGradientStops.length; i++){
            if(this.mGradientStops[i].getID() !== stopID){
                reduced.push(this.mGradientStops[i]);
            }
        }

        if(reduced.length > 0){
            this.mGradientStops = reduced;
        }

        this.updateTheme();
    }

    repositionColorStop(stopID:string, newPosition:number){
        for(let i=0; i<this.mGradientStops.length; i++){
            if(this.mGradientStops[i].getID() === stopID){
                this.mGradientStops[i].setStopPosition(newPosition);
                this.updateTheme();
                return;
            }
        }
    }

    setColorStopColor(stopID:string,  colorSample : ColorSample){
        for(let i=0; i<this.mGradientStops.length; i++){
            if(this.mGradientStops[i].getID() === stopID){
                this.mGradientStops[i].setColor(colorSample);
                this.updateTheme();
                return;
            }
        }
    }

    getSignature(){
        return this.mSignature;
    }

    getColor( onChangeUpdateMethodForTheResultingColor:(()=>void)|null){
        this.ensureSorted();
        return this.getColorAtPosition(onChangeUpdateMethodForTheResultingColor, 0.5);
    }

    getMax(onChangeUpdateMethodForTheResultingColor:(()=>void)|null){
        this.ensureSorted();
        if(this.mGradientStops.length > 0){
            return this.mGradientStops[this.mGradientStops.length-1].getColor();
        }
        else{
            return new ColorSample(onChangeUpdateMethodForTheResultingColor, 0,0,0,0);
        }
    }

    getMin(onChangeUpdateMethodForTheResultingColor:(()=>void)|null){
        this.ensureSorted();
        if(this.mGradientStops.length > 0){
            return this.mGradientStops[0].getColor();
        }
        else{
            return new ColorSample(onChangeUpdateMethodForTheResultingColor, 0,0,0,0);
        }
    }

    getTranslatedColor(onChangeUpdateMethodForTheResultingColor:(()=>void)|null, color: ColorSample, originalWeightZeroToOne?:number){
        this.ensureSorted();

        // Translates a given color into a relative color within the theme color's boundaries.

        if(originalWeightZeroToOne !== undefined){
            let translatedColor = new ColorSample(
                onChangeUpdateMethodForTheResultingColor,
                this.getColorAtPosition(onChangeUpdateMethodForTheResultingColor, color.GetRed() / 256).GetRed(),
                this.getColorAtPosition(onChangeUpdateMethodForTheResultingColor, color.GetGreen() / 256).GetGreen(),
                this.getColorAtPosition(onChangeUpdateMethodForTheResultingColor, color.GetBlue() / 256).GetBlue(),
                this.getColorAtPosition(onChangeUpdateMethodForTheResultingColor, color.GetAlpha() / 256).GetAlpha()
            );

            return translatedColor.Mix(onChangeUpdateMethodForTheResultingColor, color, originalWeightZeroToOne);
        }
        else{
            return new ColorSample(
                onChangeUpdateMethodForTheResultingColor,
                this.getColorAtPosition(onChangeUpdateMethodForTheResultingColor, color.GetRed() / 256).GetRed(),
                this.getColorAtPosition(onChangeUpdateMethodForTheResultingColor, color.GetGreen() / 256).GetGreen(),
                this.getColorAtPosition(onChangeUpdateMethodForTheResultingColor, color.GetBlue() / 256).GetBlue(),
                this.getColorAtPosition(onChangeUpdateMethodForTheResultingColor, color.GetAlpha() / 256).GetAlpha()
            );
        }
        
    }

    getRed(){
        this.ensureSorted();
        return this.getColor(null).GetRed();
    }

    getGreen(){
        this.ensureSorted();
        return this.getColor(null).GetGreen();
    }

    getBlue(){
        this.ensureSorted();
        return this.getColor(null).GetBlue();
    }

    getAlpha(){
        this.ensureSorted();
        return this.getColor(null).GetAlpha();
    }

    getRedMax(){
        this.ensureSorted();
        return this.getMax(null).GetRed();
    }

    getGreenMax(){
        this.ensureSorted();
        return this.getMax(null).GetGreen();
    }

    getBlueMax(){
        this.ensureSorted();
        return this.getMax(null).GetBlue();
    }

    getAlphaMax(){
        this.ensureSorted();
        return this.getMax(null).GetAlpha();
    }

    getRedMin(){
        this.ensureSorted();
        return this.getMin(null).GetRed();
    }

    getGreenMin(){
        this.ensureSorted();
        return this.getMin(null).GetGreen();
    }

    getBlueMin(){
        this.ensureSorted();
        return this.getMin(null).GetBlue();
    }

    getAlphaMin(){
        this.ensureSorted();
        return this.getMin(null).GetAlpha();
    }

    ensureSorted(){
        if(this.mIsSorted !== true){
            this.sortGradientStops();
        }
    }

    unsort(){
        this.mIsSorted = false;
    }

    sortGradientStops(){
        this.mIsSorted = true;
        this.mGradientStops.sort((a:GradientStop, b:GradientStop)=>{
            if(a.getStopPosition() > b.getStopPosition()){
                return 1;
            }

            if(b.getStopPosition() > a.getStopPosition()){
                return -1;
            }

            return 0
        });
    }

    getColorAtPosition(onChangeUpdateMethodForTheResultingColor:(()=>void)|null, position : number ){
        this.ensureSorted();

        if(this.mGradientStops.length == 0){
            return new ColorSample(onChangeUpdateMethodForTheResultingColor,0,0,0,0);
        }

        if(this.mGradientStops.length == 1){
            return this.mGradientStops[0].getColor();
        }

        if(this.mGradientStops[0].getStopPosition() >= position){
            return this.mGradientStops[0].getColor();
        }

        let previous : GradientStop = this.mGradientStops[0];
        let present : GradientStop = this.mGradientStops[0];

        for(let i=1; i<this.mGradientStops.length; i++){
            previous = present;
            present = this.mGradientStops[i];

            if(present.getStopPosition() === position){
                return present.getColor();
            }

            if(present.getStopPosition() > position){
                let pos = position - previous.getStopPosition();
                let nextStopPos = present.getStopPosition() - previous.getStopPosition();
                let mixAmount = pos / nextStopPos;
                return previous.getColor().Mix(onChangeUpdateMethodForTheResultingColor, present.getColor(), mixAmount);
            }
        }

        return this.mGradientStops[this.mGradientStops.length-1].getColor();
    }

    public getStops(){
        return this.mGradientStops;
    }

    public addStopAtPosition(from_0_to_1 : number, colorSample?:ColorSample){
        let addedColor = colorSample ? colorSample : this.getColorAtPosition(this.mOnChangeUpdateMethod, from_0_to_1);
        let newStop : GradientStop = new GradientStop(this.mOnChangeUpdateMethod, this.mSignature, addedColor, from_0_to_1, this.unsort.bind(this));
        this.mGradientStops.push(newStop);
        this.sortGradientStops();
        this.updateTheme();
        return newStop.getID();
    }

    public readFromLoadedRegion(loadedRegion:any){
        this.mGradientStops = new Array<GradientStop>();
        let stop;
        let newStop;

        for(let i=0; i<loadedRegion.mGradientStops.length; i++){
            stop = loadedRegion.mGradientStops[i];
            newStop = new GradientStop(
                this.mOnChangeUpdateMethod,
                this.mSignature,
                new ColorSample(
                    this.mOnChangeUpdateMethod,
                    stop.mColor.mRed,
                    stop.mColor.mGreen,
                    stop.mColor.mBlue,
                    stop.mColor.mAlpha
                ),
                stop.mStopPosition,
                this.unsort.bind(this)
            )

            this.mGradientStops.push(newStop);
        }

        this.mIsSorted = false;
    }

    private mGradientStops : Array<GradientStop>;
    private mIsSorted : boolean;
    private mSignature : string;
    private mOnChangeUpdateMethod: (()=>void) | null;
}






/*

    ####   ####     ####   ####   ###  #####  ##  #  #####       ####  #####   ####    #### 
   #       #   #   #    #  #   #   #   #      # # #    #        #        #    #    #   #   # 
   #       ####    ######  #   #   #   ###    # # #    #         ####    #    #    #   ####  
   #   ##  #   #   #    #  #   #   #   #      # # #    #             #   #    #    #   #  
    ### #  #    #  #    #  ####   ###  #####  #  ##    #         ####    #     ####    #

*/

export class GradientStop{
    constructor(onChangeUpdateMethod:(()=>void)|null, signature: string, color: ColorSample, stopPosition:number, onChangeStopOrder: () => void){ //, owner:Theme, duty:colorDuties){
        this.mColor = color.Mix(onChangeUpdateMethod,color,0); // Preventing shared instances of the color
        this.mStopPosition = stopPosition;
        this.mStopID = "ID:" + (GradientStop.sIncrement++)+ "&" + Math.random();
        this.mOrderChangeHandler = onChangeStopOrder;
        this.mSignature = signature;

        this.mOnChangeUpdateMethod = onChangeUpdateMethod;
    }

    public updateTheme(){
        if(this.mOnChangeUpdateMethod){
            this.mOnChangeUpdateMethod();
        }
    }

    public getStopPosition(){
        return this.mStopPosition;
    }

    public getID(){
        return this.mStopID;
    }

    public getSignature(){
        return this.mSignature;
    }

    public getColor(){
        return this.mColor;
    }

    public setStopPosition(position : number){
        this.mStopPosition = position;
        this.mOrderChangeHandler();
        this.updateTheme();
    }

    public setColor(color : ColorSample){
        this.mColor.setEqualTo(color);
        this.updateTheme();
    }

    private mColor: ColorSample
    private mSignature : string;
    private mStopPosition : number;
    private mStopID : string;
    private mOrderChangeHandler : ()=> void;
    private static sIncrement : number = 1;
    private mOnChangeUpdateMethod: (()=>void) | null;
}







/*

    #####  #   #  #####  ## ##  #####       ####  #####   ####  #####   ###    ####    ##  # 
      #    #   #  #      # # #  #          #      #      #        #      #    #    #   # # # 
      #    #####  ###    #   #  ###         ###   ###    #        #      #    #    #   # # #  
      #    #   #  #      #   #  #              #  #      #        #      #    #    #   # # #   
      #    #   #  #####  #   #  #####      ####   #####   ####    #     ###    ####    #  ##  

*/

export interface ThemeSectionProps{
    BGColor?: ThemeRegionGradient,
    BGColorAlt?: ThemeRegionGradient,
    FGColor?: ThemeRegionGradient,
    FGColorAlt?: ThemeRegionGradient,
    ComponentColor?: ThemeRegionGradient,
    ComponentColorAlt?: ThemeRegionGradient,
    themeID?: string
}

export interface ThemeSectionStates{
    themeColorBGMain: ThemeRegionGradient,    // Main background color
    themeColorBGAlt: ThemeRegionGradient,     // Secondary background color
    themeColorFGMain: ThemeRegionGradient,    // Main foreground color
    themeColorFGAlt: ThemeRegionGradient,     // Secondary foreground color
    themeColorCompMain: ThemeRegionGradient,  // Main component color
    themeColorCompAlt: ThemeRegionGradient,   // Secondary component color
    themeTest: string
}

export class ThemeSection extends React.Component<ThemeSectionProps, ThemeSectionStates>{

    private mThemeID : string;

    constructor(props:ThemeSectionProps){
        super(props);

        if(props.themeID){
            let thref = Theme.getThemeByID(props.themeID);

            if(!thref){
                let th : Theme = new Theme(props.themeID)
            }

            this.mThemeID = props.themeID;
        }
        else{
            this.mThemeID = "default";
        }
    }

    render(){
        return <div id={"ThemeID_" + this.mThemeID} style={{
            width:'100%'
        }}>
            {this.props.children}
        </div>
    }

    public GetID(){
        return this.mThemeID;
    }
}






/*

    #####  #   #  #####  ## ##  ##### 
      #    #   #  #      # # #  #   
      #    #####  ###    #   #  ### 
      #    #   #  #      #   #  #    
      #    #   #  #####  #   #  ##### 

*/

export class Theme{
    // ---- STATIC USAGE: ----
    private static themes:Array<Theme> = new Array<Theme>();
    private static sStyleGivers:Array<{name:string, styleMethod:(theme:Theme)=>any}> = new Array<{name:string, styleMethod:(theme:Theme)=>any}>();
    private static default:Theme = new Theme("default");
    private static sShowPalette : boolean = false;
    private static paletteUpdateMethod : () => void;

    private mId : string;
    private mColorBGMain : ThemeRegionGradient;
    private mColorBGAlt : ThemeRegionGradient;
    private mColorFGMain : ThemeRegionGradient;
    private mColorFGAlt : ThemeRegionGradient;
    private mColorDetailMain : ThemeRegionGradient;
    private mColorDetailAlt : ThemeRegionGradient;
    private mColorSpecular : ThemeRegionGradient;
    private mColorShadow : ThemeRegionGradient;
    private mUpdateQueue : Array<{componentID:string, refreshMethod:()=>void}>;
    private mWillUpdateComponents : boolean;

    // ---- INSTANCED USAGE: ----
    constructor(themeID?:string){

        if(themeID){
            this.mId = themeID;

            if( themeID === "default"){
                window.addEventListener("resize", Theme.refreshAllComponents);
            }
        }
        else{
            // Generating a unique ID for this theme
            this.mId = 'ThemeID_';
            
            for(let i=0; i<5; i++){
                this.mId += '' + (Math.round(Math.random() * 90000) + 10000);
            }
        }

        this.mUpdateQueue = new Array<{componentID:string, refreshMethod:()=>void}>();

        // Default colors
        this.mColorBGMain = new ThemeRegionGradient(
            this.refreshThemeComponents.bind(this),
            "BG",
            [
                { pos:0.0, r:23,  g:57,  b:43},
                { pos:0.5, r:29,  g:113, b:205},
                { pos:1.0, r:150, g:183, b:220}
            ]
        )

        this.mColorBGAlt = new ThemeRegionGradient(
            this.refreshThemeComponents.bind(this),
            "bg",
            [
                { pos:0.0, r:33,  g:76,  b:185},
                { pos:0.5, r:126, g:130, b:254},
                { pos:1.0, r:113, g:254, b:255}
            ]
        )

        this.mColorFGMain = new ThemeRegionGradient(
            this.refreshThemeComponents.bind(this),
            "FG",
            [
                { pos:0.0, r:3,  g:50,  b:119},
                { pos:0.5, r:4,  g:127, b:174},
                { pos:1.0, r:144, g:193, b:208}
            ]
        )

        this.mColorFGAlt = new ThemeRegionGradient(
            this.refreshThemeComponents.bind(this),
            "fg",
            [
                { pos:0.0, r:10,  g:43,  b:3},
                { pos:0.5, r:66,  g:126, b:193},
                { pos:1.0, r:156, g:190, b:206}
            ]
        )

        this.mColorDetailMain = new ThemeRegionGradient(
            this.refreshThemeComponents.bind(this),
            "DE",
            [
                { pos:0.0, r:110,  g:178,  b:210},
                { pos:0.5, r:110,  g:178,  b:210},
                { pos:1.0, r:207,  g:207,  b:207}
            ]
        )

        this.mColorDetailAlt = new ThemeRegionGradient(
            this.refreshThemeComponents.bind(this),
            "de",
            [
                { pos:0.0, r:210,  g:232,  b:255},
                { pos:0.5, r:210,  g:232,  b:255},
                { pos:1.0, r:168,  g:186,  b:212}
            ]
        )
        
        this.mColorSpecular = new ThemeRegionGradient(
            this.refreshThemeComponents.bind(this),
            "Sp",
            [
                { pos:0.0, r:254,  g:250,  b:241},
                { pos:0.5, r:201,  g:247,  b:237},
                { pos:1.0, r:194,  g:217,  b:228}
            ]
        )

        this.mColorShadow = new ThemeRegionGradient(
            this.refreshThemeComponents.bind(this),
            "Sh",
            [
                { pos:0.0, r:20,  g:15,  b:6},
                { pos:0.5, r:49,  g:34,  b:26},
                { pos:1.0, r:53,  g:82,  b:28}
            ]
        )
        
        this.mWillUpdateComponents = false;

        Theme.themes.push(this);
    }

    public setColors(
        BG:{pos:number, r:number, g:number, b:number}[],
        bg:{pos:number, r:number, g:number, b:number}[],
        FG:{pos:number, r:number, g:number, b:number}[],
        fg:{pos:number, r:number, g:number, b:number}[],
        DE:{pos:number, r:number, g:number, b:number}[],
        de:{pos:number, r:number, g:number, b:number}[],
        Sp:{pos:number, r:number, g:number, b:number}[],
        Sh:{pos:number, r:number, g:number, b:number}[]
    ){
        this.mColorBGMain = new ThemeRegionGradient(
            this.refreshThemeComponents.bind(this),
            "BG",
            BG
        )

        this.mColorBGAlt = new ThemeRegionGradient(
            this.refreshThemeComponents.bind(this),
            "bg",
            bg
        )

        this.mColorFGMain = new ThemeRegionGradient(
            this.refreshThemeComponents.bind(this),
            "FG",
            FG
        )

        this.mColorFGAlt = new ThemeRegionGradient(
            this.refreshThemeComponents.bind(this),
            "fg",
            fg
        )

        this.mColorDetailMain = new ThemeRegionGradient(
            this.refreshThemeComponents.bind(this),
            "DE",
            DE
        )

        this.mColorDetailAlt = new ThemeRegionGradient(
            this.refreshThemeComponents.bind(this),
            "de",
            de
        )
        
        this.mColorSpecular = new ThemeRegionGradient(
            this.refreshThemeComponents.bind(this),
            "Sp",
            Sp
        )

        this.mColorShadow = new ThemeRegionGradient(
            this.refreshThemeComponents.bind(this),
            "Sh",
            Sh
        )
    }

    public static setThemeStyle(styleName:string, styleMethod:(theme:Theme)=>any){
        for(let i=0; i<Theme.sStyleGivers.length; i++){
            if(Theme.sStyleGivers[i].name === styleName){
                Theme.sStyleGivers[i] = {name:styleName, styleMethod:styleMethod};
                return;
            }
        }

        Theme.sStyleGivers.push({name:styleName, styleMethod:styleMethod});
    }

    public setThemeStyle(styleName:string, styleMethod:(theme:Theme)=>any){
        Theme.setThemeStyle(styleName,styleMethod);
    }

    public themeStyle(styleName:string){
        for(let i=0; i<Theme.sStyleGivers.length; i++){
            if(Theme.sStyleGivers[i].name === styleName){
                return Theme.sStyleGivers[i].styleMethod(this);
            }
        }

        return {};
    }

    public static refreshAllComponents(){
        for(let i=0; i<Theme.themes.length; i++){
            Theme.themes[i].refreshThemeComponents();
        }
    }

    public static showPalette(willShow?:boolean){
        if(willShow !== undefined){
            Theme.sShowPalette = willShow;
            if(Theme.paletteUpdateMethod){
                Theme.paletteUpdateMethod();
            }

            Theme.refreshAllComponents();
        }

        return Theme.sShowPalette;
    }

    public static setPaletteUpdateMethod(method:()=>void){
        Theme.paletteUpdateMethod = method;
    }

    public static getDefaultTheme(){
        return Theme.default;
    }

    public static getThemeByID(themeId: string){
        for(let i=0; i< Theme.themes.length; i++){
            if(Theme.themes[i].GetID() === themeId){
                return Theme.themes[i];
            }
        }

        return null;
    }

    public static getArrayOfThemesForSelect(){
        let response = new Array<{optionID:string, optionText:string}>();

        for(let i=0; i<Theme.themes.length; i++){
            let id = Theme.themes[i].GetID();

            // Skipping [Sample] type themes
            if(id.length >= 8){
                if(id.charAt(0) === "["){
                    continue
                }
            }

            response.push({optionID:Theme.themes[i].GetID(), optionText: "" + Theme.themes[i].GetID()});
        }

        return response;
    }

    public removeColorStop(signature:string, stopID:string){
        this.refreshThemeComponents();
        if(signature === "BG"){
            this.mColorBGMain.removeColorStop(stopID);
            return;
        }

        if(signature === "bg"){
            this.mColorBGAlt.removeColorStop(stopID);
            return;
        }

        if(signature === "FG"){
            this.mColorFGMain.removeColorStop(stopID);
            return;
        }

        if(signature === "fg"){
            this.mColorFGAlt.removeColorStop(stopID);
            return;
        }

        if(signature === "DE"){
            this.mColorDetailMain.removeColorStop(stopID);
            return;
        }

        if(signature === "de"){
            this.mColorDetailAlt.removeColorStop(stopID);
            return;
        }

        if(signature === "Sp"){
            this.mColorSpecular.removeColorStop(stopID);
            return;
        }

        if(signature === "Sh"){
            this.mColorShadow.removeColorStop(stopID);
            return;
        }
    }

    public repositionColorStop(signature:string, stopID:string, newPosition:number){
        this.refreshThemeComponents();
        if(signature === "BG"){
            this.mColorBGMain.repositionColorStop(stopID, newPosition);
            return;
        }

        if(signature === "bg"){
            this.mColorBGAlt.repositionColorStop(stopID, newPosition);
            return;
        }

        if(signature === "FG"){
            this.mColorFGMain.repositionColorStop(stopID, newPosition);
            return;
        }

        if(signature === "fg"){
            this.mColorFGAlt.repositionColorStop(stopID, newPosition);
            return;
        }

        if(signature === "DE"){
            this.mColorDetailMain.repositionColorStop(stopID, newPosition);
            return;
        }

        if(signature === "de"){
            this.mColorDetailAlt.repositionColorStop(stopID, newPosition);
            return;
        }

        if(signature === "Sp"){
            this.mColorSpecular.repositionColorStop(stopID, newPosition);
            return;
        }

        if(signature === "Sh"){
            this.mColorShadow.repositionColorStop(stopID, newPosition);
            return;
        }
    }

    public setColorStopColor(signature:string, stopID:string,  colorSample : ColorSample){
        this.refreshThemeComponents();
        if(signature === "BG"){
            this.mColorBGMain.setColorStopColor(stopID, colorSample);
            return;
        }

        if(signature === "bg"){
            this.mColorBGAlt.setColorStopColor(stopID, colorSample);
            return;
        }

        if(signature === "FG"){
            this.mColorFGMain.setColorStopColor(stopID, colorSample);
            return;
        }

        if(signature === "fg"){
            this.mColorFGAlt.setColorStopColor(stopID, colorSample);
            return;
        }

        if(signature === "DE"){
            this.mColorDetailMain.setColorStopColor(stopID, colorSample);
            return;
        }

        if(signature === "de"){
            this.mColorDetailAlt.setColorStopColor(stopID, colorSample);
            return;
        }

        if(signature === "Sp"){
            this.mColorSpecular.setColorStopColor(stopID, colorSample);
            return;
        }

        if(signature === "Sh"){
            this.mColorShadow.setColorStopColor(stopID, colorSample);
            return;
        }
    }

    public randomize(){
        this.mColorBGMain = ThemeRegionGradient.getRandom(this.refreshThemeComponents.bind(this),"BG");
        this.mColorBGAlt = ThemeRegionGradient.getRandom(this.refreshThemeComponents.bind(this),"bg");
        this.mColorFGMain = ThemeRegionGradient.getRandom(this.refreshThemeComponents.bind(this),"FG");
        this.mColorFGAlt = ThemeRegionGradient.getRandom(this.refreshThemeComponents.bind(this),"fg");
        this.mColorDetailMain = ThemeRegionGradient.getRandom(this.refreshThemeComponents.bind(this),"DE");
        this.mColorDetailAlt = ThemeRegionGradient.getRandom(this.refreshThemeComponents.bind(this),"de");
        this.mColorSpecular = ThemeRegionGradient.getRandom(this.refreshThemeComponents.bind(this),"Sp");
        this.mColorShadow = ThemeRegionGradient.getRandom(this.refreshThemeComponents.bind(this),"Sh");
    }

    getBG(onChangeUpdateMethodForTheResultingColor:(()=>void)|null, colorPos? : number, altBGBlendZeroToOne?:number){
        let pos = 0.5

        if( colorPos !== undefined){
            pos = colorPos;

            if(pos > 1.0){
                pos = 1.0;
            }

            if(pos < 0.0){
                pos = 0.0;
            }
        }

        if(altBGBlendZeroToOne !== undefined){
            let mainColor = this.mColorBGMain.getColorAtPosition(null,pos);
            let altColor = this.mColorBGAlt.getColorAtPosition(null,pos);
            return mainColor.Mix(onChangeUpdateMethodForTheResultingColor, altColor, altBGBlendZeroToOne);
        }
        else{
            return this.mColorBGMain.getColorAtPosition(onChangeUpdateMethodForTheResultingColor, pos);
        }
    }

    getBGMix(onChangeUpdateMethodForTheResultingColor:(()=>void)|null, inputColor:ColorSample, altBGBlendZeroToOne?:number){

        let bg = this.mColorBGMain.getTranslatedColor(onChangeUpdateMethodForTheResultingColor, inputColor);

        if(altBGBlendZeroToOne !== undefined){
            let bgAlt = this.mColorBGAlt.getTranslatedColor(null, inputColor);
            return bg.Mix(onChangeUpdateMethodForTheResultingColor, bgAlt, altBGBlendZeroToOne);
        }
        else{
            return bg;
        }
    }

    getFG(onChangeUpdateMethodForTheResultingColor:(()=>void)|null, colorPos? : number, altFGBlendZeroToOne?:number){
        let pos = 0.5
        
        if( colorPos !== undefined){
            pos = colorPos;

            if(pos > 1.0){
                pos = 1.0;
            }

            if(pos < 0.0){
                pos = 0.0;
            }
        }

        if(altFGBlendZeroToOne !== undefined){
            let mainColor = this.mColorFGMain.getColorAtPosition(onChangeUpdateMethodForTheResultingColor, pos);
            let altColor = this.mColorFGAlt.getColorAtPosition(onChangeUpdateMethodForTheResultingColor, pos);
            return mainColor.Mix(onChangeUpdateMethodForTheResultingColor, altColor, altFGBlendZeroToOne);
        }
        else{
            return this.mColorFGMain.getColorAtPosition(onChangeUpdateMethodForTheResultingColor, pos);
        }
    }

    getFGMix(onChangeUpdateMethodForTheResultingColor:(()=>void)|null, inputColor:ColorSample, altFGBlendZeroToOne?:number){

        let fg = this.mColorFGMain.getTranslatedColor(onChangeUpdateMethodForTheResultingColor, inputColor);

        if(altFGBlendZeroToOne !== undefined){
            let fgAlt = this.mColorFGAlt.getTranslatedColor(null, inputColor);
            return fg.Mix(onChangeUpdateMethodForTheResultingColor, fgAlt, altFGBlendZeroToOne);
        }
        else{
            return fg;
        }
    }

    getDetail(onChangeUpdateMethodForTheResultingColor:(()=>void)|null, colorPos? : number, altDetailBlendZeroToOne?:number){
        let pos = 0.5
        
        if( colorPos !== undefined){
            pos = colorPos;

            if(pos > 1.0){
                pos = 1.0;
            }

            if(pos < 0.0){
                pos = 0.0;
            }
        }

        if(altDetailBlendZeroToOne !== undefined){
            let mainColor = this.mColorDetailMain.getColorAtPosition(onChangeUpdateMethodForTheResultingColor, pos);
            let altColor = this.mColorDetailAlt.getColorAtPosition(onChangeUpdateMethodForTheResultingColor, pos);
            return mainColor.Mix(onChangeUpdateMethodForTheResultingColor, altColor, altDetailBlendZeroToOne);
        }
        else{
            return this.mColorDetailMain.getColorAtPosition(onChangeUpdateMethodForTheResultingColor, pos);
        }
    }

    getShadow(onChangeUpdateMethodForTheResultingColor:(()=>void)|null, colorPos? : number){
        let pos = 0.5
        
        if( colorPos !== undefined){
            pos = colorPos;

            if(pos > 1.0){
                pos = 1.0;
            }

            if(pos < 0.0){
                pos = 0.0;
            }
        }

        return this.mColorShadow.getColorAtPosition(onChangeUpdateMethodForTheResultingColor, pos);
    }

    getSpecular(onChangeUpdateMethodForTheResultingColor:(()=>void)|null, colorPos? : number){
        let pos = 0.5
        
        if( colorPos !== undefined){
            pos = colorPos;

            if(pos > 1.0){
                pos = 1.0;
            }

            if(pos < 0.0){
                pos = 0.0;
            }
        }

        return this.mColorSpecular.getColorAtPosition(onChangeUpdateMethodForTheResultingColor, pos);
    }

    public GetID(){
        return this.mId;
    }

    public getStops(region:string){
        
        if(region === "FG"){
            this.mColorFGMain.ensureSorted();
            return this.mColorFGMain.getStops();
        }

        if(region === "FG-Alt"){
            this.mColorFGAlt.ensureSorted();
            return this.mColorFGAlt.getStops();
        }

        if(region === "BG"){
            this.mColorBGMain.ensureSorted();
            return this.mColorBGMain.getStops();
        }

        if(region === "BG-Alt"){
            this.mColorBGAlt.ensureSorted();
            return this.mColorBGAlt.getStops();
        }

        if(region === "Detail"){
            this.mColorDetailMain.ensureSorted();
            return this.mColorDetailMain.getStops();
        }

        if(region === "Detail-Alt"){
            this.mColorDetailAlt.ensureSorted();
            return this.mColorDetailAlt.getStops();
        }

        if(region === "Specular"){
            this.mColorSpecular.ensureSorted();
            return this.mColorSpecular.getStops();
        }

        if(region === "Shadow"){
            this.mColorShadow.ensureSorted();
            return this.mColorShadow.getStops();
        }

        // If misspelled input plus to ensure the compiler that there is a return value..
        console.warn("Warning - Theme.getStops got a missing or misspelled region code. (Valid ones are: FG, FG-Alt, BG, BG-Alt, Detail, Detail-Alt, Specular and Shadow)")
        return this.mColorFGAlt.getStops();
    }

    public addGradientStop(region : string, position_0_to_1 : number, colorSample? : ColorSample){
        this.refreshThemeComponents();

        if(region === "FG"){
            return this.mColorFGMain.addStopAtPosition(position_0_to_1, colorSample);
        }

        if(region === "FG-Alt"){
            return this.mColorFGAlt.addStopAtPosition(position_0_to_1, colorSample);
        }

        if(region === "BG"){
            return this.mColorBGMain.addStopAtPosition(position_0_to_1, colorSample);
        }

        if(region === "BG-Alt"){
            return this.mColorBGAlt.addStopAtPosition(position_0_to_1, colorSample);
        }

        if(region === "Detail"){
            return this.mColorDetailMain.addStopAtPosition(position_0_to_1, colorSample);
        }

        if(region === "Detail-Alt"){
            return this.mColorDetailAlt.addStopAtPosition(position_0_to_1, colorSample);
        }

        if(region === "Specular"){
            return this.mColorSpecular.addStopAtPosition(position_0_to_1, colorSample);
        }

        if(region === "Shadow"){
            return this.mColorShadow.addStopAtPosition(position_0_to_1, colorSample);
        }

        console.warn("Warning - Theme.addGradientStop got a missing or misspelled region code. (Valid ones are: FG, FG-Alt, BG, BG-Alt, Detail, Detail-Alt, Specular and Shadow)");
        return "noStopID";
    }

    public addUpdateListener(listenerID:string,updateMethod:()=>void){
        let foundListener = false;
        for(let i=0; i<this.mUpdateQueue.length; i++){
            if(this.mUpdateQueue[i].componentID === listenerID){
                this.mUpdateQueue[i].refreshMethod = updateMethod;
                foundListener = true;
            }
        }

        if(foundListener === false){
            this.mUpdateQueue.push({componentID:listenerID, refreshMethod:updateMethod});
        }
    }

    public clearUpdateListener(listenerID:string){
        let remainingListeners = new Array<{componentID:string, refreshMethod:()=>void}>();
        for(let i=0; i<this.mUpdateQueue.length; i++){
            if(this.mUpdateQueue[i].componentID !== listenerID){
                remainingListeners.push(this.mUpdateQueue[i]);
            }
        }

        this.mUpdateQueue = remainingListeners;
    }

    public refreshThemeComponents(){
        if(this.mWillUpdateComponents === false){
            this.mWillUpdateComponents = true;
            setTimeout(this.carryOutComponentRefreshCalls.bind(this),1);
        }
    }

    private carryOutComponentRefreshCalls(){
        this.mWillUpdateComponents = false;

        for(let i=0; i<this.mUpdateQueue.length; i++){
            try{
                this.mUpdateQueue[i].refreshMethod();
            }
            catch(e){
                console.log("Error: Theme caught an error while carrying out themed component updates. exception:" + e);
            }
        }
    }

    public readFromLoadedTheme(loadedTheme : any){
        this.mColorBGMain.readFromLoadedRegion(loadedTheme.mColorBGMain);
        this.mColorBGAlt.readFromLoadedRegion(loadedTheme.mColorBGAlt);
        this.mColorFGMain.readFromLoadedRegion(loadedTheme.mColorFGMain);
        this.mColorFGAlt.readFromLoadedRegion(loadedTheme.mColorFGAlt);
        this.mColorDetailMain.readFromLoadedRegion(loadedTheme.mColorDetailMain);
        this.mColorDetailAlt.readFromLoadedRegion(loadedTheme.mColorDetailAlt);
        this.mColorSpecular.readFromLoadedRegion(loadedTheme.mColorSpecular);
        this.mColorShadow.readFromLoadedRegion(loadedTheme.mColorShadow);

        this.refreshThemeComponents();
    }
}


/*

    #####  #   #  #####  ## ##  #####       ####   ####   ## ##  #####   ####   ##  #  #####  ##  #  #####
      #    #   #  #      # # #  #          #      #    #  # # #  #   #  #    #  # # #  #      # # #    #
      #    #####  ###    #   #  ###        #      #    #  # # #  ####   #    #  # # #  ###    # # #    #
      #    #   #  #      #   #  #          #      #    #  #   #  #      #    #  # # #  #      # # #    #
      #    #   #  #####  #   #  #####       ####   ####   #   #  #       ####   #  ##  #####  #  ##    #

*/

interface TCProps{
    themeID?: String,
    style?: any,
    className? : string;
}

interface TCStates{
}

export class ThemeComponent<TCProps,TCStates> extends React.Component<TCProps,TCStates>{

    private mIsEnlistedForThemeRefresh : string | null; // if enlisted for refreshes upon theme changes, this value is the listener id, otherwise null.
    private mThemeID : string|null; // The ID of the theme set to be used by this component
    private mRef : React.RefObject<any>; // 
    private mThemeRef : Theme|null; // A direct reference to the theme in use by this component
    private mHasTriedCapturingTheme : boolean; // True if a search for an owning theme section has already been done

    private mStyleDefaults : any;
    private mStyleProperties : any;
    private mStyleOverrides : any;
    private mStyleFinal : any;
    protected mComponentType : string;

    private classNameProperty : string | undefined;

    constructor(props:TCProps){
        super(props);

        this.mThemeRef = null;

        this.mComponentType = "ThemeComponent";

        this.mStyleDefaults = {width:'100%'};
        let pr:any = props;
        this.mStyleProperties = (pr.style !== undefined) ? pr.style : {};
        this.mStyleOverrides = {};
        this.mStyleFinal = {};

        this.classNameProperty = pr.className;

        for (var prop in this.mStyleProperties) {
            if (Object.prototype.hasOwnProperty.call(this.mStyleProperties, prop)) {
                this.mStyleFinal[prop] = this.mStyleProperties[prop];
            }
        }

        for (var prop in this.mStyleDefaults) {
            if (Object.prototype.hasOwnProperty.call(this.mStyleDefaults, prop)) {
                if(this.mStyleProperties[prop] === undefined){
                    this.mStyleFinal[prop] = this.mStyleDefaults[prop];
                }
            }
        }

        if(pr.themeID){
            this.mThemeID = pr.themeID;
        }
        else{
            this.mThemeID = null;
        }

        this.mIsEnlistedForThemeRefresh = null;
        this.mRef = React.createRef();

        this.mHasTriedCapturingTheme = false;
    }

    public setThemeStyle(styleName:string, styleMethod:(theme:Theme)=>any){
        this.getTheme().setThemeStyle(styleName, styleMethod);
    }

    public themeStyle(styleName:string){
        return this.getTheme().themeStyle(styleName);
    }

    protected setStyleDefault(sd:any){
        let needUpdate = false;

        if(sd){
            for (var prop in sd) {
                
                if (Object.prototype.hasOwnProperty.call(sd, prop)) {
                    if (Object.prototype.hasOwnProperty.call(this.mStyleProperties, prop) == false) {

                        let defChanged = false;
                        
                        if (Object.prototype.hasOwnProperty.call(this.mStyleDefaults, prop)) {
                            if(this.mStyleDefaults[prop] !== sd[prop]){
                                this.mStyleDefaults[prop] = sd[prop];
                                defChanged = true;
                            }
                        }
                        else{
                            this.mStyleDefaults[prop] = sd[prop];
                            defChanged = true;
                        }

                        if(defChanged && (Object.prototype.hasOwnProperty.call(this.mStyleOverrides, prop) == false)){
                            this.mStyleFinal[prop] = sd[prop];
                            needUpdate = true;
                        }
                    }
                }
            }
        }

        return needUpdate;
    }

    public setStyle(style:any){
        let needUpdate = false;

        if(style){
            for (var prop in style) {
                if (Object.prototype.hasOwnProperty.call(style, prop)) {

                    if (Object.prototype.hasOwnProperty.call(this.mStyleOverrides, prop)) {
                        if(style[prop] !== this.mStyleOverrides[prop]){
                            this.mStyleOverrides[prop] = style[prop];
                            this.mStyleFinal[prop] = style[prop];
                            needUpdate = true;
                        }
                    }
                    else{
                        this.mStyleOverrides[prop] = style[prop];
                        this.mStyleFinal[prop] = style[prop];
                        needUpdate = true;
                    }
                }
            }
        }

        return needUpdate;
    }

    private captureTheme(){

        if(this.mThemeRef){
            return this.mThemeRef;
        }

        if(this.mHasTriedCapturingTheme === true){
            return null;
        }

        if(this.mThemeID){
            let th = Theme.getThemeByID(this.mThemeID);
            if(th){
                this.mThemeRef = th;
                return this.mThemeRef;
            }
            else{
                this.mThemeRef = new Theme(this.mThemeID);
                return this.mThemeRef;
            }
        }

            // temp:
        let conf:any = this;
        let pageID = conf.props.pageID
        let componentType = conf.mComponentType

        let domNode : any = this.mRef.current;
        while(domNode !== null){
            const readId : string = domNode.id;

            if(readId){
                let compareStr = 'ThemeID_';
                let looksGood = true;

                for(let i=0; i<compareStr.length; i++){
                    if(i<readId.length){
                        if(readId.charAt(i) !== compareStr.charAt(i)){
                            looksGood = false;
                            break;
                        }
                    }
                    else{
                        looksGood = false;
                        break;
                    }
                }

                if(looksGood === true){

                    let foundId = "";
                    for(let i=compareStr.length; i<readId.length; i++){
                        foundId += readId.charAt(i);
                    }

                    this.mThemeRef = Theme.getThemeByID(foundId);

                    if(this.mThemeRef){
                        this.mThemeID = foundId;
                        return this.mThemeRef;
                    }
                }
            }

            domNode = domNode.parentNode 
        }

        return null;
    }

    public getTheme(){
        if(this.mThemeRef){
            return this.mThemeRef;
        }

        let th = this.captureTheme();

        if(th){
            return th;
        }

        this.mThemeID = "default";
        this.mThemeRef = Theme.getDefaultTheme();
        return this.mThemeRef;
    }

    getBG(colorPosition?:number, altBGBlendZeroToOne?:number){
        return this.getTheme().getBG(this.getTheme().refreshThemeComponents.bind(this.getTheme()),colorPosition, altBGBlendZeroToOne);
    }

    getFG(colorPosition?:number, altFGBlendZeroToOne?:number){
        return this.getTheme().getFG(this.getTheme().refreshThemeComponents.bind(this.getTheme()),colorPosition, altFGBlendZeroToOne);
    }

    getBGMix(inputColor:ColorSample, altFGBlendZeroToOne?:number){
        return this.getTheme().getBGMix(this.getTheme().refreshThemeComponents.bind(this.getTheme()),inputColor, altFGBlendZeroToOne);
    }

    getFGMix(inputColor:ColorSample, altFGBlendZeroToOne?:number){
        return this.getTheme().getFGMix(this.getTheme().refreshThemeComponents.bind(this.getTheme()),inputColor, altFGBlendZeroToOne);
    }

    isReady(){
        if(this.mRef && this.mThemeRef){
            return true;
        }
        else{
            return false;
        }
    }

    getStyle(){
        let finalStyle : any = {};

        for(var prop in this.mStyleFinal){
            if (Object.prototype.hasOwnProperty.call(this.mStyleFinal, prop)) {
                finalStyle[prop] = this.mStyleFinal[prop];
            }
        }

        return finalStyle;
    }

    getClassName(){
        return this.classNameProperty;
    }

    render(){
        if(this.mRef){
            let theme = this.mThemeRef;
            let className : any = this.getClassName();
            let finalStyle = this.getStyle();

            if(theme){
                this.ensureEnlistedForThemeRefreshCalls();

                return (
                    <div ref={this.mRef} style={finalStyle} className={className}>
                        {this.draw()}
                    </div>
                )
            }
            else{
                return (
                    <div ref={this.mRef}/>
                )
            }
        }

        return <div></div>
    }

    draw(){
        return (
            <div style={{width:"100%"}}>
                {this.props.children}
            </div>
        )
    }

    isReadyToDraw(){
        if(this.mRef && this.mThemeRef){
            return true;
        }
        else{
            return false;
        }
    }

    componentDidMount(){
        if(!this.mThemeRef){
            this.mThemeRef = this.getTheme();

            if(this.mThemeRef){
                this.forceUpdate();
            }
        }
        else{
            this.ensureEnlistedForThemeRefreshCalls();
        }
    }

    componentWillUnmount(){
        if(this.mThemeRef){
            let refreshId = this.mIsEnlistedForThemeRefresh;
            if(refreshId){
                this.mThemeRef.clearUpdateListener(refreshId);
                this.mIsEnlistedForThemeRefresh = null;
            }
        }
    }

    protected getRef(){
        return this.mRef;
    }

    public ensureEnlistedForThemeRefreshCalls(){
        if(this.mIsEnlistedForThemeRefresh === null){
            if(this.mThemeRef){
                let listenerID = "Listener:" + Math.random();
                this.mThemeRef.addUpdateListener(listenerID,this.onThemeUpdate.bind(this))
                this.mIsEnlistedForThemeRefresh = listenerID;
            }
        }
    }

    onThemeUpdate(){
        this.forceUpdate();
    }
}


/*
    #####  #   #  #####  ## ##  #####      ## ##   ###   #   #   ###    ####  ####   ####
      #    #   #  #      # # #  #          # # #  #   #  ##  #  #   #  #      #      #   #
      #    #####  ###    #   #  ###        #   #  #####  # # #  #####  #      ###    ####  
      #    #   #  #      #   #  #          #   #  #   #  #  ##  #   #  #   #  #      #   #  
      #    #   #  #####  #   #  #####      #   #  #   #  #   #  #   #   ####  #####  #    #
*/

/*
    localStorage.setItem('myCat', 'Tom');

    The syntax for reading the localStorage item is as follows:

    var cat = localStorage.getItem('myCat');

    The syntax for removing the localStorage item is as follows:

    localStorage.removeItem('myCat');

    The syntax for removing all the localStorage items is as follows:

    // Clear all items
    localStorage.clear();
*/

export interface sampleThemeContent{
    sampleID : string, 
    sampleTitle: string, 
    sampleTheme: Theme
}

export class ThemeManager{

    private static mSampleThemes : Array<sampleThemeContent> = new Array<sampleThemeContent>();

    static loadThemeLocally( themeID: string ){
        let dispT = Theme.getThemeByID(themeID)
        if(dispT){
            let stored : string | null = localStorage.getItem("userTheme:" + themeID )

            if(stored !== null){
                let preSave = JSON.parse(stored);
                dispT.readFromLoadedTheme(preSave);
                return true;
            }
            else{
                return false;
            }
        }

        return false;
    }

    static saveThemeLocally( themeID: string ){
        let userTheme = Theme.getThemeByID(themeID)

        if(userTheme){
            localStorage.setItem("userTheme:" + themeID, JSON.stringify(userTheme) )
            return true;
        }
        
        return false;
    }

    static loadThemeFromServer( themeID: string, onLoadTheme: ()=>void ){
        let dispT = Theme.getThemeByID(themeID)
        if(dispT){
            JGClient.callPublicService("loadtheme:"+ dispT.GetID(), dispT, onLoadTheme);
        }
    }

    static saveThemeToServer( themeID: string, onSavedTheme: ()=>void ){
        let dispT = Theme.getThemeByID(themeID)
        JGClient.callPublicService("savetheme:"+themeID, dispT, onSavedTheme);
    }

    static addSampleTheme( sampleID : string, sampleTitle: string, sampleTheme: Theme ){
        for(let i=0; i<ThemeManager.mSampleThemes.length; i++){
            if(ThemeManager.mSampleThemes[i].sampleID === sampleID){
                ThemeManager.mSampleThemes[i].sampleTitle = sampleTitle
                ThemeManager.mSampleThemes[i].sampleTheme = sampleTheme;
                return;
            }
        }

        ThemeManager.mSampleThemes.push({
            sampleID : sampleID, 
            sampleTitle: sampleTitle, 
            sampleTheme: sampleTheme
        })
    }

    public static getListOfSampleThemes(){
        let list = new Array<{optionID: string, optionText: string}>();

        for(let i=0; i<ThemeManager.mSampleThemes.length; i++){
            list.push({optionID: ThemeManager.mSampleThemes[i].sampleID, optionText: ThemeManager.mSampleThemes[i].sampleTitle})
        }

		return list;
    }

    public static getArrayOfThemesForSelect(){
        return Theme.getArrayOfThemesForSelect();
    }

    static enterDefaultSamples(){

        if(ThemeManager.mSampleThemes.length > 0){
            return
        }

        // Aqua Blue
        let aqua = new Theme("[SAMPLE]Aqua")
        ThemeManager.addSampleTheme( "[SAMPLE]Aqua", "Aqua Blue", aqua );

        // Saloon
        let saloon = new Theme("[SAMPLE]Saloon")
        saloon.setColors(
            [// BG
                {pos: 0.0, r:70, g:53, b:5},
                {pos: 0.19, r:85, g:66, b:13},
                {pos: 0.5, r:132, g:91, b:38},
                {pos: 1.0, r:212, g:180, b:87}
            ],
            [// bg
                {pos: 0.0, r:119, g:94, b:17},
                {pos: 0.331, r:207, g:171, b:57},
                {pos: 0.679, r:233, g:220, b:181},
                {pos: 1.0, r:255, g:243, b:209}
            ],
            [// FG
                {pos: 0.0, r:62, g:54, b:11},
                {pos: 0.473, r:153, g:106, b:34},
                {pos: 1.0, r:219, g:200, b:141}
            ],
            [// fg
                {pos: 0.0, r:109, g:75, b:26},
                {pos: 0.5, r:173, g:144, b:35},
                {pos: 1.0, r:226, g:168, b:68}
            ],
            [// DE
                {pos: 0.5, r:206, g:188, b:123},
                {pos: 1.0, r:237, g:225, b:137}
            ],
            [// de
                {pos: 0.5, r:255, g:243, b:226}
            ],
            [// Sp
                {pos: 0.0, r:235, g:207, b:154},
                {pos: 0.446, r:240, g:240, b:203},
                {pos: 0.922, r:255, g:255, b:255}
            ],
            [// Sh
                {pos: 0.5, r:0, g:0, b:0}
            ]
        )
        ThemeManager.addSampleTheme( "[SAMPLE]Saloon", "Saloon", saloon );

        // Dracula
        let dracula = new Theme("[SAMPLE]Dracula")
        dracula.setColors(
            [// BG
                {pos: 0.0, r:10, g:19, b:5},
                {pos: 0.5, r:58, g:41, b:60},
                {pos: 1.0, r:82, g:61, b:102}
            ],
            [// bg
                {pos: 0.0, r:97, g:63, b:82},
                {pos: 0.5, r:124, g:103, b:155},
                {pos: 1.0, r:171, g:167, b:255}
            ],
            [// FG
                {pos: 0.0, r:3, g:52, b:10},
                {pos: 0.5, r:88.6, g:43.6, b:107},
                {pos: 1.0, r:105, g:95, b:131}
            ],
            [// fg
                {pos: 0.0, r:68, g:45, b:49},
                {pos: 0.5, r:191, g:52, b:167},
                {pos: 1.0, r:206, g:152, b:217}
            ],
            [// DE
                {pos: 0.5, r:136.0, g:113.0, b:175.0},
                {pos: 1.0, r:192, g:146, b:211}
            ],
            [// de
                {pos: 0.5, r:253, g:106, b:73},
                {pos: 1.0, r:253, g:129, b:155}
            ],
            [// Sp
                {pos: 0.0, r:223, g:250, b:253},
                {pos: 0.5, r:205, g:225, b:210},
                {pos: 1.0, r:193, g:193, b:197}
            ],
            [// Sh
                {pos: 0.0, r:2, g:6, b:5},
                {pos: 0.5, r:31, g:14, b:38},
                {pos: 1.0, r:69, g:59, b:55}
            ]
        )
        ThemeManager.addSampleTheme( "[SAMPLE]Dracula", "Dracula", dracula );

        // Custom
        let custom = Theme.getThemeByID("[SAMPLE]Custom")

        if(!custom){
            custom = new Theme("[SAMPLE]Custom");
        }

        ThemeManager.addSampleTheme( "[SAMPLE]Custom", "Custom Theme", custom );
    }
}

ThemeManager.enterDefaultSamples()
