import * as React from 'react';
import {Theme, ColorSample} from '../components/theme';
import {JGPage} from '../components/JGPage';
import {ContactSymbols} from '../components/ContactSymbols';
import {AssistSlider} from '../components/AssistSlider';
import {PhotoSlider} from '../components/PhotoSlider';
import {RollingBackground} from '../components/RollingBackground';
import {WordPipe} from '../tools/WordPipe';
import {ThemeStylesManager} from '../data/themeStyles';

/*
    ####    ###    ###   ##### 
    #   #  #   #  #   #  #     
    ####   #####  #      ###  
    #      #   #  #  ##  #     
    #      #   #   ####  ##### 
*/

export interface PagePortfolioStates{
    showExtenstionJGMediaKit : boolean
}

export class PagePortfolio extends JGPage{
    private static sShowExtenstionJGMediaKit : boolean = false;
    private static sShowExtenstionPrisassistenten : boolean = false;

    constructor(props:any){
        super(props);
        this.setStyleDefault({
            width:"100%"
        })

        this.mComponentType = "PagePortfolio";
    }

    onClickMoreInfoJGMKit(){
        PagePortfolio.sShowExtenstionJGMediaKit = true;
        this.forceUpdate()
    }

    onClickLessInfoJGMKit(){
        PagePortfolio.sShowExtenstionJGMediaKit = false;
        this.forceUpdate()
    }

    onClickMoreInfoPrisassistenten(){
        PagePortfolio.sShowExtenstionPrisassistenten = true;
        this.forceUpdate()
    }

    onClickLessInfoPrisassistenten(){
        PagePortfolio.sShowExtenstionPrisassistenten = false;
        this.forceUpdate()
    }

    draw(){
        // -------------- Setting up a common basis: -------------------

        enum display{
            mobile,
            ipad,
            desktop
        }

        let theme = this.getTheme();
        let width = window.innerWidth;

        let detailColor = theme.getDetail(null, 0.5, 0).GetRGB();
        let highlightColor = theme.getDetail(null, 0.6, 0.6).GetRGB();

        let displayMode : display = display.mobile;

        let marginBoost = 0;
        let contentLeftRightMargin = ThemeStylesManager.getLRMargin();
        let bgStretchRightWidth = 0;
        let bgStretchLeftWidth = 0;

        if(ThemeStylesManager.isIpadWide()){
            displayMode = display.ipad
            marginBoost = 160
            bgStretchRightWidth = 300;
            bgStretchLeftWidth = -100;
        }

        if(ThemeStylesManager.isDesktopWide()){
            displayMode = display.desktop
        }

        // -------------- Generating the sections: -------------------

        let expandLinkStyle : any = {
            color: "rgb(80, 150, 130)",
            cursor: "pointer",
            position: "relative",
            top:"5px"
        }

        // Prisassistenten
        let optionalSectionPrisassistenten = null;
        let moreInfoPALink : JSX.Element | null = null;
        let lessInfoPALink : JSX.Element | null = null;

        if(PagePortfolio.sShowExtenstionPrisassistenten === true){
            lessInfoPALink = <div style={expandLinkStyle} onClick={this.onClickLessInfoPrisassistenten.bind(this)}>- {WordPipe.read("Portfolio_hideInfo")} -</div>
            optionalSectionPrisassistenten = (
                <div style={{
                    position:"relative",
                    left: contentLeftRightMargin + "px",
                    width: window.innerWidth - ((contentLeftRightMargin * 2)+marginBoost) + "px",
                    fontSize: 20 + "px",
                    textAlign:"left",
                    color: detailColor,
                    marginTop:"18px"
                }}>
                    {WordPipe.read("Portfolio_PAContent")}
                </div>
            )
        }
        else{
            moreInfoPALink = <div style={expandLinkStyle} onClick={this.onClickMoreInfoPrisassistenten.bind(this)}>- {WordPipe.read("Portfolio_moreInfo")} -</div>
        }

        let sectionPrisassistentenTitle = (
            <div>
                <div style={{
                    height:"20px"
                }}/>

                <div style={this.themeStyle("CVTitle")}>
                    {WordPipe.read("Portfolio_PATitle")}
                </div>

                <div style={this.themeStyle("CVUnderline")}/>

                <div style={{
                    height:"16px"
                }}/>

                <div style={{
                    position:"relative",
                    left: contentLeftRightMargin + "px",
                    width: window.innerWidth - ((contentLeftRightMargin * 2)+marginBoost) + "px",
                    fontSize: 20 + "px",
                    textAlign:"left",
                    color: detailColor
                }}>
                    {WordPipe.read("Portfolio_PAShort")}
                </div>

                {optionalSectionPrisassistenten}
                {moreInfoPALink}
                {lessInfoPALink}

                <div style={{
                    height:"5px"
                }}/>

            </div>
        )

        // JGMedia Kit
        let optionalSectionJGMediaKit = null;
        let moreInfoKitLink : JSX.Element | null = null;
        let lessInfoKitLink : JSX.Element | null = null;

        if(PagePortfolio.sShowExtenstionJGMediaKit === true){
            lessInfoKitLink = <div style={expandLinkStyle} onClick={this.onClickLessInfoJGMKit.bind(this)}>- {WordPipe.read("Portfolio_hideInfo")} -</div>
            optionalSectionJGMediaKit = (
                <div style={{
                    position:"relative",
                    left: contentLeftRightMargin + "px",
                    width: window.innerWidth - ((contentLeftRightMargin * 2)+marginBoost) + "px",
                    fontSize: 20 + "px",
                    textAlign:"left",
                    color: detailColor
                }}>
                    {WordPipe.read("Portfolio_MKContent")}
                    {/*
                        <div style={{marginTop:"25px"}}>
                            {WordPipe.read("Portfolio_MKTryout")}
                        </div>
                        <div style={{marginTop:"25px"}}>
                            {WordPipe.read("Portfolio_MKInstructions")}
                        </div>
                    */}
                </div>
            )
        }
        else{
            moreInfoKitLink = <div style={expandLinkStyle} onClick={this.onClickMoreInfoJGMKit.bind(this)}>- {WordPipe.read("Portfolio_moreInfo")} -</div>
        }

        let sectionJJGMediaKitTitle = (
            <div>
                <div style={{
                    height:"60px"
                }}/>

                <div style={this.themeStyle("CVTitle")}>
                    JGMedia Kit
                </div>

                <div style={this.themeStyle("CVUnderline")}/>

                <div style={{
                    height:"16px"
                }}/>

                <div style={{
                    position:"relative",
                    left: contentLeftRightMargin + "px",
                    width: window.innerWidth - ((contentLeftRightMargin * 2)+marginBoost) + "px",
                    fontSize: 20 + "px",
                    textAlign:"left",
                    color: detailColor
                }}>
                    {WordPipe.read("Portfolio_MKShort")}
                </div>

                {optionalSectionJGMediaKit}
                {moreInfoKitLink}
                {lessInfoKitLink}

                <div style={{
                    height:"5px"
                }}/>

            </div>
        )

        let jgmediaImgWidth = ThemeStylesManager.getCentralWidth();
        let jgmediaImgHeight = jgmediaImgWidth * (808/1208);

        let sectionJJGMediaKitImage = (
            
            <div style={{
                position:'relative', 
                left: (contentLeftRightMargin + 'px'), 
                width: jgmediaImgWidth + 'px',
                height: jgmediaImgHeight + "px",
                marginTop:'10px',
                backgroundImage:'url(https://i.imgur.com/SdAQScU.jpg)',
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat"
            }}/>
        )

        // TV commercials title
        let sectionCommercialsTitle = (
            <div>
                <div style={{
                    height:"80px"
                }}/>

                <div style={this.themeStyle("CVTitle")}>
                    {WordPipe.read("Portfolio_CommercialTitle")}
                </div>

                <div style={this.themeStyle("CVUnderline")}/>

                <div style={{
                    height:"16px"
                }}/>

                <div style={{
                    position:"relative",
                    left: ThemeStylesManager.getLRMargin() + 'px', 
                    width: ThemeStylesManager.getCentralWidth() + 'px', 
                    fontSize: 20 + "px",
                    textAlign:"left",
                    color: detailColor
                }}>
                    {WordPipe.read("Portfolio_CommercialContent")}
                </div>

                <div style={{
                    height:"25px"
                }}/>

            </div>
        )

        let videoWidth = window.innerWidth - ((2*contentLeftRightMargin)+10 + (marginBoost));
        let videoHeightCommercial = videoWidth * (658/1170);
        let videoHeightMOL = videoWidth * (480/640);

        let sectionFilmsCommercials = (
            <div>
                {/* Jämtlandsgården - Main */}
                <div style={{
                        position:'relative', 
                        left: ThemeStylesManager.getLRMargin() + 'px', 
                        width: ThemeStylesManager.getCentralWidth() + 'px',
                        marginTop:'50px'
                    }}>
                    <iframe 
                        src="https://player.vimeo.com/video/7216875?title=0&amp;byline=0&amp;portrait=0&amp;color=ffffff" 
                        width={videoWidth+''} 
                        height={videoHeightCommercial+''} 
                        frameBorder="0" 
                        allowFullScreen
                    >
                    </iframe>
                </div>

                {/* Jämtlandsgården - Rökt Rulle */}
                <div style={{
                        position:'relative', 
                        left: ThemeStylesManager.getLRMargin() + 'px', 
                        width: ThemeStylesManager.getCentralWidth() + 'px',
                        marginTop:'50px'
                    }}>
                    <iframe 
                            src="https://player.vimeo.com/video/27828018?title=0&amp;byline=0&amp;portrait=0&amp;color=ffffff" 
                            width={videoWidth+''} 
                            height={videoHeightCommercial+''} 
                            frameBorder="0" 
                            allowFullScreen
                        >
                    </iframe>
                </div>
            </div>
        )

        // 3D films title
        let section3DFilmsTitle = (
            <div>
                <div style={{
                    height:"80px"
                }}/>

                <div style={this.themeStyle("CVTitle")}>
                    {WordPipe.read("Portfolio_FilmsTitle")}
                </div>

                <div style={this.themeStyle("CVUnderline")}/>

                <div style={{
                    height:"16px"
                }}/>

                <div style={{
                    position:"relative",
                    left: ThemeStylesManager.getLRMargin() + 'px', 
                    width: ThemeStylesManager.getCentralWidth() + 'px', 
                    fontSize: 20 + "px",
                    textAlign:"left",
                    color: detailColor
                }}>
                    {WordPipe.read("Portfolio_FilmsText")}
                </div>

                <div style={{
                    height:"25px"
                }}/>

            </div>
        )

        let sectionInvGotTitle = (
            <div>
                <div style={{
                    height:"80px"
                }}/>

                <div style={this.themeStyle("CVTitle")}>
                    {WordPipe.read("Portfolio_InvGotTitle")}
                </div>

                <div style={this.themeStyle("CVUnderline")}/>

                <div style={{
                    height:"16px"
                }}/>

                <div style={{
                    position:"relative",
                    left: ThemeStylesManager.getLRMargin() + 'px', 
                    width: ThemeStylesManager.getCentralWidth() + 'px', 
                    fontSize: 20 + "px",
                    textAlign:"left",
                    color: detailColor
                }}>
                    {WordPipe.read("Portfolio_InvGotText")}
                </div>

                <div style={{
                    height:"25px"
                }}/>

            </div>
        )

        let sectionFilms3D = (
            <div>

                {/* 3D Films - M.O.L. */}
                <div style={{
                        position:'relative',
                        left: ThemeStylesManager.getLRMargin() + 'px', 
                        width: ThemeStylesManager.getCentralWidth() + 'px', 
                        maxWidth:'1170px', 
                        marginTop:'20px'
                    }}>
                    <iframe 
                        src="https://player.vimeo.com/video/333148368?title=0&amp;byline=0&amp;portrait=0&amp;color=ffffff" 
                        width={videoWidth+''} 
                        height={videoHeightMOL+''} 
                        frameBorder="0" 
                        allowFullScreen
                    >
                    </iframe>
                </div>


                {/* 3D Films - Prisoner */}
                <div style={{
                        position:'relative',
                        left: ThemeStylesManager.getLRMargin() + 'px', 
                        width: ThemeStylesManager.getCentralWidth() + 'px', 
                        maxWidth:'1170px', 
                        marginTop:'20px'
                    }}>
                    <iframe 
                        src="https://player.vimeo.com/video/382213688?title=0&amp;byline=0&amp;portrait=0&amp;color=ffffff" 
                        width={videoWidth+''} 
                        height={videoHeightMOL+''} 
                        frameBorder="0" 
                        allowFullScreen
                    >
                    </iframe>
                </div>
            </div>
        )

        // Footer
        let sectionFooter = (
            <div style={{
                position:"relative",
                height:"255px",
                marginTop:"60px"
            }}>
                <div style={{
                    backgroundColor: theme.getBG(null,0.1,0).GetRGB(),
                    height:"100%",
                    width:"calc(100% + " + bgStretchRightWidth + "px)",
                    position:"absolute",
                    left: bgStretchLeftWidth + "px"
                }}/>

                <div style={{
                    height:"20px"
                }}/>

                <div style={this.themeStyle("CVTitle")}>
                    {WordPipe.read("Contact_Title")}
                </div>

                <div style={this.themeStyle("CVUnderline")}/>

                <div style={{
                    position:"absolute",
                    top:"100px",
                    left: contentLeftRightMargin + "px",
                    width:"40px",
                    height:"120px"
                }}>
                    <ContactSymbols/>
                </div>
                
                <div style={{
                    position:"absolute",
                    top:"102px",
                    left: (contentLeftRightMargin + 60) + "px",
                    color: theme.getDetail(null, 0.6, 0).GetRGB(),
                    fontSize:"24px"
                }}>
                    +47 94089399
                </div>

                <div style={{
                    position:"absolute",
                    top:"175px",
                    left: (contentLeftRightMargin + 60) + "px",
                    color: theme.getDetail(null, 0.6, 0).GetRGB(),
                    fontSize:"21px"
                }}>
                    johan_granqvist@yahoo.se
                </div>

            </div>
        )
    
        let photoSliderImagesPrisassistenten = [
            {   // Full
                imageUrl: "url(https://i.imgur.com/JFaoSge.jpg)",
                imageWidth: 1512, 
                imageHeight: 934
            },
            {   // Seach
                imageUrl:"url(https://i.imgur.com/xEdtOdr.jpg)", 
                imageWidth: 1262, 
                imageHeight: 802
            },
            {   // Browse
                imageUrl:"url(https://i.imgur.com/cDKdkqy.jpg)", 
                imageWidth: 1416, 
                imageHeight: 942
            },
            {   // Load
                imageUrl:"url(https://i.imgur.com/HFP2jI9.jpg)", 
                imageWidth: 1419, 
                imageHeight: 942
            }
        ]

        let showingTimePrisassistenten = 4500
        let shiftingTimePrisassistenten = 1200
        let refreshRatePrisassistenten = 35

        let photoSliderImagesInvGot = [
            {   // Volvo i mörker
                imageUrl: "url(https://i.imgur.com/cLoUbU0.jpg)",
                imageWidth: 1022, 
                imageHeight: 766
            },
            {   // Soptipp
                imageUrl: "url(https://i.imgur.com/80CA0UB.jpg)",
                imageWidth: 1022, 
                imageHeight: 766
            },
            {   // Draken (001)
                imageUrl: "url(https://i.imgur.com/kWVrVqb.jpg)",
                imageWidth: 1022, 
                imageHeight: 766
            },
            {   // Bensinstation
                imageUrl: "url(https://i.imgur.com/cdERogz.jpg)",
                imageWidth: 1022, 
                imageHeight: 766
            },
            {   // Liggskytt i sikte
                imageUrl: "url(https://i.imgur.com/KDbT0WJ.jpg)",
                imageWidth: 1022, 
                imageHeight: 766
            },
            {   // KSP 000
                imageUrl: "url(https://i.imgur.com/Yjf4YCA.jpg)",
                imageWidth: 1022, 
                imageHeight: 766
            },
            {   // Pansarskott vid bro
                imageUrl: "url(https://i.imgur.com/hxbnyDP.jpg)",
                imageWidth: 1022, 
                imageHeight: 766
            },
            {   // Ryssar i bunker
                imageUrl: "url(https://i.imgur.com/XSgfMGB.jpg)",
                imageWidth: 1022, 
                imageHeight: 766
            },
            {   // Temabild (00A)
                imageUrl: "url(https://i.imgur.com/NapVxPx.jpg)",
                imageWidth: 1022, 
                imageHeight: 766
            },
            {   // Ockupperat fritidshus
                imageUrl: "url(https://i.imgur.com/Tdcr6Ha.jpg)",
                imageWidth: 1022, 
                imageHeight: 766
            },
            {   // Herrgård
                imageUrl: "url(https://i.imgur.com/Ipx8LUp.jpg)",
                imageWidth: 1022, 
                imageHeight: 766
            },
            {   // Preview Party (00C)
                imageUrl: "url(https://i.imgur.com/PEzRq4r.jpg)",
                imageWidth: 1022, 
                imageHeight: 766
            },
            {   // Helikopter och raukar
                imageUrl: "url(https://i.imgur.com/ykiICr1.jpg)",
                imageWidth: 1022, 
                imageHeight: 766
            },
            {   // Pansar & KLAS
                imageUrl: "url(https://i.imgur.com/sIV3A9J.jpg)",
                imageWidth: 1022, 
                imageHeight: 766
            },
            {   // 2 playing the game (002)
                imageUrl: "url(https://i.imgur.com/vgmaiyr.jpg)",
                imageWidth: 1022, 
                imageHeight: 766
            },
            {   // Ryss och raukar (003)
                imageUrl: "url(https://i.imgur.com/IDDkupM.jpg)",
                imageWidth: 1022, 
                imageHeight: 766
            },
            {   // Terrängfordon (004)
                imageUrl: "url(https://i.imgur.com/g9zJzYq.jpg)",
                imageWidth: 1022, 
                imageHeight: 766
            },
            {   // Helikopter över kust (00)
                imageUrl: "url(https://i.imgur.com/4dMfm3I.jpg)",
                imageWidth: 1022, 
                imageHeight: 766
            },
            /*
            {   // MC
                imageUrl: "url(https://i.imgur.com/7cwh0Lk.jpg)",
                imageWidth: 1022, 
                imageHeight: 766
            }, */

            {   // Skymningsstrid
                imageUrl: "url(https://i.imgur.com/lN48AHg.jpg)",
                imageWidth: 1022, 
                imageHeight: 766
            },
            {   // Handvapensduell
                imageUrl: "url(https://i.imgur.com/SzLR5dP.jpg)",
                imageWidth: 1022, 
                imageHeight: 766
            },
            {   // Vapenval vid fabrik
                imageUrl: "url(https://i.imgur.com/Z6n5Z3p.jpg)",
                imageWidth: 1022, 
                imageHeight: 766
            }, 
            {   // Terräng i skymning
                imageUrl: "url(https://i.imgur.com/NE3nRi9.jpg)",
                imageWidth: 1022, 
                imageHeight: 766
            },
            /*
            {   // Sikte med kulspruta
                imageUrl: "url(https://i.imgur.com/38S3YJG.jpg)",
                imageWidth: 1022, 
                imageHeight: 766
            }, */
            {   // Explosion från stridsvagn
                imageUrl: "url(https://i.imgur.com/lCtKzNY.jpg)",
                imageWidth: 1022, 
                imageHeight: 766
            }
        ]

        let showingTimeInvGot = 5500
        let shiftingTimeInvGot = 1500
        let refreshRateInvGot = 35

        // Mobile mode
        if(displayMode === display.mobile){
            return(
                <div style={{
                    width: window.innerWidth + "px",
                    height:"1000px",
                    marginTop:"50px",
                    marginLeft:"0px",
                    color: theme.getDetail(null,0.5,0).GetRGB()
                }}>
                    <RollingBackground/>
                    <div style={{
                        height:"100px"
                    }}/>
                    {sectionPrisassistentenTitle}
                    <PhotoSlider 
                        images={photoSliderImagesPrisassistenten} 
                        showingTimeMS={showingTimePrisassistenten} 
                        shiftingTimeMS={shiftingTimePrisassistenten} 
                        refreshRateMS={refreshRatePrisassistenten}
                    />
                    {sectionJJGMediaKitTitle}
                    {sectionJJGMediaKitImage}
                    {section3DFilmsTitle}
                    {sectionFilms3D}
                    {sectionCommercialsTitle}
                    {sectionFilmsCommercials}
                    {sectionInvGotTitle}
                    <PhotoSlider
                        images={photoSliderImagesInvGot}
                        showingTimeMS={showingTimeInvGot} 
                        shiftingTimeMS={shiftingTimeInvGot} 
                        refreshRateMS={refreshRateInvGot}
                    />
                    {sectionFooter}
              </div>
            )
        }

        // Ipad mode
        if(displayMode === display.ipad){
            return(
                <div style={{
                    width: (window.innerWidth - marginBoost) + "px",
                    height:"1000px",
                    marginTop:"50px",
                    marginLeft:"0px",
                    color: theme.getDetail(null,0.5,0).GetRGB()
                }}>
                    <RollingBackground/>
                    {sectionPrisassistentenTitle}
                    <PhotoSlider 
                        images={photoSliderImagesPrisassistenten} 
                        showingTimeMS={showingTimePrisassistenten} 
                        shiftingTimeMS={shiftingTimePrisassistenten} 
                        refreshRateMS={refreshRatePrisassistenten}
                    />
                    {sectionJJGMediaKitTitle}
                    {sectionJJGMediaKitImage}
                    {section3DFilmsTitle}
                    {sectionFilms3D}
                    {sectionCommercialsTitle}
                    {sectionFilmsCommercials}
                    {sectionInvGotTitle}
                    <PhotoSlider
                        images={photoSliderImagesInvGot}
                        showingTimeMS={showingTimeInvGot} 
                        shiftingTimeMS={shiftingTimeInvGot} 
                        refreshRateMS={refreshRateInvGot}
                    />
                    {sectionFooter}
                </div>
            )
        }

        // Desktop mode
        return(
            <div style={{
                width: (window.innerWidth - marginBoost) + "px",
                height:"1000px",
                marginTop:"50px",
                marginLeft:"0px",
                color: theme.getDetail(null,0.5,0).GetRGB()
            }}>
                <RollingBackground/>
                {sectionPrisassistentenTitle}
                <PhotoSlider 
                    images={photoSliderImagesPrisassistenten} 
                    showingTimeMS={showingTimePrisassistenten} 
                    shiftingTimeMS={shiftingTimePrisassistenten} 
                    refreshRateMS={refreshRatePrisassistenten}
                />
                {sectionJJGMediaKitTitle}
                {sectionJJGMediaKitImage}
                {section3DFilmsTitle}
                {sectionFilms3D}
                {sectionCommercialsTitle}
                {sectionFilmsCommercials}
                {sectionInvGotTitle}
                <PhotoSlider
                    images={photoSliderImagesInvGot}
                    showingTimeMS={showingTimeInvGot} 
                    shiftingTimeMS={shiftingTimeInvGot} 
                    refreshRateMS={refreshRateInvGot}
                />
                {sectionFooter}
            </div>
        )
    }
}
