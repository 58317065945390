import * as React from 'react';
import {Theme, ThemeComponent, ColorSample, ThemeManager} from './theme';
import {MouseMap} from '../tools/MouseMap';
import {JGClient} from '../tools/JGClient';

interface PaletteSelectOptionProps{
    optionID:any,
    optionText:string,
    ownerRef:PaletteSelect,
    isFirst:boolean
}

// PaletteSelectOption is a help class to JGSelect, for displaying a single option.
class PaletteSelectOption extends React.Component<PaletteSelectOptionProps,{}>{

    constructor(props:PaletteSelectOptionProps){
        super(props);
        this.mText = props.optionText;
        this.mOptionID = props.optionID;
        this.mIsHovering = false;
    }
 
    public getOptionID(){
        return this.mOptionID;
    }

    public getText(){
        return this.mText;
    }

    public mMouseIn(){
        this.mIsHovering = true;
        this.forceUpdate();
    }

    public mMouseOut(){
        this.mIsHovering = false;
        this.forceUpdate();
    }

    public onClickItem(){
        this.props.ownerRef.onItemClick(this.props.optionID);
    }

    render(){
        let itemWidth = this.props.ownerRef.props.itemWidth

        if(itemWidth === undefined){
            itemWidth = "249px";
        }

        let itemHeight = this.props.ownerRef.props.itemHeight

        if(itemHeight === undefined){
            itemHeight = "55px";
        }

        let itemFontSize = this.props.ownerRef.props.itemFontSize

        if(itemFontSize === undefined){
            itemFontSize = "35px";
        }

        let backgroundColor = "rgb(142,110,77)";
        if(this.mIsHovering === true){
            backgroundColor = "rgb(171,138,104)";
        }

        let borderSpec = "1px solid rgb(119,90,57)";

        if(this.props.isFirst === true){
            return (
                <div 
                onMouseEnter={this.mMouseIn.bind(this)}
                onMouseLeave={this.mMouseOut.bind(this)}
                onClick={this.onClickItem.bind(this)}
                style={{
                    width:itemWidth,
                    height:itemHeight,
                    fontSize:itemFontSize,
                    textAlign:"center",
                    backgroundColor:backgroundColor,
                    color:"white",
                    borderLeft : borderSpec,
                    borderRight : borderSpec,
                    borderTop : borderSpec,
                    borderBottom : borderSpec,
                    cursor:"pointer"
                }}>
                    {this.props.optionText}
                </div>
            )
        }

        return (
            <div 
            onMouseEnter={this.mMouseIn.bind(this)}
            onMouseLeave={this.mMouseOut.bind(this)}
            onClick={this.onClickItem.bind(this)}
            style={{
                width:itemWidth,
                height:itemHeight,
                fontSize:itemFontSize,
                textAlign:"center",
                backgroundColor:backgroundColor,
                color:"white",
                borderLeft : borderSpec,
                borderRight : borderSpec,
                borderBottom : borderSpec,
                cursor:"pointer"
            }}>
                {this.props.optionText}
            </div>
        )
    }

    private mOptionID : any;
    private mText : string;
    private mIsHovering : boolean;
}

export interface PaletteSelectProps{
    options : Array< {optionID:any, optionText:string} >;
    setReference : (ref:PaletteSelect|null)=>void;
    onSelectItem : (itemID : any)=>void;
    left? : string;
    top? : string;
    width? : string;
    height? : string;
    itemWidth : string;
    itemHeight? : string;
    itemFontSize? : string;
    themeID? : string;
    visible? : boolean;
}

export interface PaletteSelectStates{
}

export class PaletteSelect extends ThemeComponent<PaletteSelectProps,PaletteSelectStates>{
    constructor(props:PaletteSelectProps){
        super(props)

        if(props.visible){
            this.mVisible = true;
        }
        else{
            this.mVisible = false;
        }

        props.setReference(this);
    }

    public setVisible(visible : boolean){
        this.mVisible = visible;
    }

    public onItemClick(itemID : string){
        this.mVisible = false;
        this.props.onSelectItem(itemID);
        this.forceUpdate();
    }

    componentWillUnmount(){
        this.props.setReference(null);
    }

    draw(){
        if(this.mVisible === false){
            return (
                <div></div>
            );
        }

        // Preparing options
        let items = new Array<JSX.Element>();

        for(let i=0; i<this.props.options.length; i++){
            items.push(<PaletteSelectOption 
                key={"key:"+i} 
                optionID={this.props.options[i].optionID} 
                optionText={this.props.options[i].optionText} 
                ownerRef={this}
                isFirst={(i==0)?true:false}
            />)
        }
        
        // itemHeight: this.props.itemHeight?this.props.itemHeight:"55px",
        return (
            <div style={{
                position:"absolute",
                top: this.props.top?this.props.top:"0px",
                left: this.props.left?this.props.left:"0px",
                width: this.props.width?this.props.width:"250px",
                height: this.props.height?this.props.height:"250px",
                overflowY:"auto",
                overflowX:"hidden"
            }}>
                {items}
            </div>
        );
    }

    private mVisible : boolean;
}

export interface PaletteProps{
	updateMethod?: ()=>void,
	themeID?: string,
	style?:any,
	className?:string,
	serverOn?:boolean
}

export interface PaletteStates{
}

enum mouseModes{
	MOUSEMODE_free,
	MOUSEMODE_dragWindow,    // the window's position is following mouse moves (until releasing the left mouse button)
	MOUSEMODE_pickingColor,  // the color picker marker is following the mouse movements
	MOUSEMODE_slidingRed,
	MOUSEMODE_slidingGreen,
	MOUSEMODE_slidingBlue,
	MOUSEMODE_scalingGlass,
	MOUSEMODE_dragGradientStop,
	MOUSEMODE_rainbowPicker
}

enum mouseRegions{
	MOUSEREGION_out,
	MOUSEREGION_unknown,
	MOUSEREGION_frame,
	MOUSEREGION_colorPicker,
	MOUSEREGION_rainbowPicker,
	MOUSEREGION_recentColor1,
	MOUSEREGION_recentColor2,
	MOUSEREGION_recentColor3,
	MOUSEREGION_recentColor4,
	MOUSEREGION_recentColor5,
	MOUSEREGION_gradientTrashBin,
	MOUSEREGION_redSlider,
	MOUSEREGION_greenSlider,
	MOUSEREGION_blueSlider,
	MOUSEREGION_gradientStopArea,
	MOUSEREGION_gradientDisplayArea,
	MOUSEREGION_closingCross,
	MOUSEREGION_scalingGlass,
	MOUSEREGION_randomColor,
	MOUSEREGION_restore,
	MOUSEREGION_saveChanges,
	MOUSEREGION_themeSelect,
	MOUSEREGION_themeRegion_backgroundPrimary,
	MOUSEREGION_themeRegion_backgroundSecondary,
	MOUSEREGION_themeRegion_foregroundPrimary,
	MOUSEREGION_themeRegion_foregroundSecondary,
	MOUSEREGION_themeRegion_detailPrimary,
	MOUSEREGION_themeRegion_detailSecondary,
	MOUSEREGION_themeRegion_specular,
	MOUSEREGION_themeRegion_shadow,
	MOUSEREGION_selectedColor
}

enum hoveredArea{
	HOVEREDAREA_none,
	HOVEREDAREA_closingCross,
	HOVEREDAREA_themeRegion_backgroundPrimary,
	HOVEREDAREA_themeRegion_backgroundSecondary,
	HOVEREDAREA_themeRegion_foregroundPrimary,
	HOVEREDAREA_themeRegion_foregroundSecondary,
	HOVEREDAREA_themeRegion_detailPrimary,
	HOVEREDAREA_themeRegion_detailSecondary,
	HOVEREDAREA_themeRegion_specular,
	HOVEREDAREA_themeRegion_shadow,
	HOVEREDAREA_themeSelect,
	HOVEREDAREA_saveChanges,
	HOVEREDAREA_restore,
	HOVEREDAREA_randomColor,
	HOVEREDAREA_scalingGlass,
	HOVEREDAREA_gradientDisplayArea,
	HOVEREDAREA_gradientTrashBin,
	HOVEREDAREA_recentColor1,
	HOVEREDAREA_recentColor2,
	HOVEREDAREA_recentColor3,
	HOVEREDAREA_recentColor4,
	HOVEREDAREA_recentColor5,
	HOVEREDAREA_rainbowPicker,
	HOVEREDAREA_colorSliderRed,
	HOVEREDAREA_colorSliderGreen,
	HOVEREDAREA_colorSliderBlue,
	HOVEREDAREA_colorPicker
}

enum themeRegion{
	bgMain,
	bgAlt,
	fgMain,
	fgAlt,
	detailMain,
	detailAlt,
	specular,
	shadow
}

interface GradientArrowProps{
	stopColor:string,
	points:string,
	stopID:string,
	stopSignature:string,
	eventHandler:(stopID:string, event:any)=>void
	cursor:string
}

class GradientStopArrow extends React.Component<GradientArrowProps,{}>{
	constructor(props:GradientArrowProps){
		super(props);
	}

	render(){
		return(
			<polygon style={{
				fill: this.props.stopColor, 
				stroke:"#1d1d1b", 
				cursor:this.props.cursor === "initial" ? "pointer" : this.props.cursor,
				strokeMiterlimit:10, 
				strokeWidth:"0.5px" 
			}} points={this.props.points} onMouseDown={this.onmousedown.bind(this)}/>
		)
	}

	onmousedown(e:any){
		this.props.eventHandler(this.props.stopID, {type:"mouseDown", clientY:e.clientY});
		return false;
	}
}

class RecentColorSlot{
	private mColor : ColorSample;
	private mShadedColor : ColorSample;
	private mAge : number;

	constructor(color:ColorSample){
		this.mAge = 0;
		this.mColor = color.copy(null);
		this.mShadedColor = new ColorSample(null,color.GetRed()/2, color.GetGreen()/2, color.GetBlue()/2, color.GetAlpha());
	}

	setColor(color:ColorSample){
		this.mAge = 0;
		this.mColor = color.copy(null,);
		this.mShadedColor = new ColorSample(null,color.GetRed()/2, color.GetGreen()/2, color.GetBlue()/2, color.GetAlpha());
	}

	getColor(){
		return this.mColor;
	}

	getShadowedColor(){
		return this.mShadedColor;
	}

	getAge(){
		return this.mAge;
	}

	addAge(addedAge:number){
		this.mAge += addedAge;
	}

}

export class Palette extends ThemeComponent< PaletteProps, PaletteStates >{

	private mJGSelectRef : PaletteSelect | null;
	private mRestoreThemeSelectRef : PaletteSelect | null;  
    private mSubWindowPosX : number; // top-left corner X
	private mSubWindowPosY : number; // top-left corner Y
	private mSubWindowWidth : number; // width of the palette
	private mHeightVSWidthRatio : number; // 0.5 means half as high as the palette's width.

	private mMouseMode : mouseModes; 
	
    private mMouseGraspX : number; // the absolute X position where the left mouse button was first pressed down
    private mMouseGraspY : number; // the absolute Y position where the left mouse button was first pressed down
    private mPreGraspX : number;
    private mPreGraspY : number;

	private mDisplayedTheme : Theme | null;
	
	private mPaletteID : string;

	private mDebugOut : string; // temporary

	private mMouseMap : MouseMap;
	private mLatestValidHue : number;
	private mPreferedBrightness : number;

	private mLatestMouseCode : String;
	private mLatestMouseRelX : number;
	private mLatestMouseRelY : number;

	private mGradientStopCreationPhase : number;

	private hasBegunModifyingColor : boolean;

	private mRecentColors : Array<RecentColorSlot>;

	private mSelectedGradientStopID : string | null;

	private mHoveredArea : hoveredArea;
	private mSelectedThemeRegion : themeRegion;

	private static mSingleton : Palette|null = null;

	private static sUniqueKeyBase : number = 0;

	private mIsDisplayingThemeSelect : boolean;
	private mIsDisplayingThemeRestoreSelect : boolean;

	constructor(props:PaletteProps){
		super(props);

		Palette.mSingleton = this;

		this.mSubWindowWidth = window.innerWidth * 0.6; // width of the palette
		this.mHeightVSWidthRatio = 354.98 / 443.94; // 0.5 means half as high as the palette's width. // 354.98 / 443.94;

		if((this.mSubWindowWidth * this.mHeightVSWidthRatio) > (window.innerHeight * 0.8)){
			this.mSubWindowWidth = (window.innerHeight * 0.9) / this.mHeightVSWidthRatio;
		}

		this.mSubWindowPosX = (window.innerWidth / 2) - this.mSubWindowWidth / 2;
		this.mSubWindowPosY = ((window.innerHeight / 2) - ((this.mSubWindowWidth*this.mHeightVSWidthRatio)) / 2) ;
		
		this.mMouseMode = mouseModes.MOUSEMODE_free;
		
        this.mMouseGraspX = -1;
        this.mMouseGraspY = -1;
        this.mPreGraspX = -1;
        this.mPreGraspY = -1;

		this.mDisplayedTheme = null;
		this.mJGSelectRef = null;
		this.mRestoreThemeSelectRef = null;

		this.mLatestMouseCode = "";
		this.mLatestMouseRelX = 0;
		this.mLatestMouseRelY = 0;

		this.mHoveredArea = hoveredArea.HOVEREDAREA_none;
		this.mSelectedThemeRegion = themeRegion.fgMain;

		this.mGradientStopCreationPhase = 0;

		this.mRecentColors = new Array<RecentColorSlot>();
		this.mRecentColors.push(new RecentColorSlot(new ColorSample(null,255,255,255)));
		this.mRecentColors.push(new RecentColorSlot(new ColorSample(null,192,192,192)));
		this.mRecentColors.push(new RecentColorSlot(new ColorSample(null,127,127,127)));
		this.mRecentColors.push(new RecentColorSlot(new ColorSample(null,65,65,65)));
		this.mRecentColors.push(new RecentColorSlot(new ColorSample(null,0,0,0)));

		this.mSelectedGradientStopID = null;

		this.hasBegunModifyingColor = true;
		this.mIsDisplayingThemeSelect = false;
		this.mIsDisplayingThemeRestoreSelect = false;

		this.mPaletteID = "palette:" + Math.random();
		
		this.mMouseMap = new MouseMap("/jgmedia/paletteMouseMap.json");

		this.mLatestValidHue = 0;
		this.mPreferedBrightness = 1;
		this.mDebugOut = "";

		Theme.setPaletteUpdateMethod(this.forceUpdate.bind(this));
	}

	public pickRecentColor(index:number){
		let newColor = this.mRecentColors[index].getColor().copy(null);
		this.mRecentColors[index].addAge(1000);
		this.preserveSwitchedColor(this.mRecentColors[index].getColor());

		this.getSelectedGradientStop().getColor().setEqualTo(newColor);

		this.updateTheme();
	}

	public preserveSwitchedColor(forNewColor?:ColorSample){
		this.hasBegunModifyingColor = false;
		let current = this.getSelectedGradientStop().getColor().copy(null);

		for(let i=0; i<5; i++){
			if(this.mRecentColors[i].getColor().equals(current)){
				return;
			}
		}

		if(forNewColor){
			for(let i=0; i<5; i++){
				if(this.mRecentColors[i].getColor().equals(forNewColor)){
					this.mRecentColors[i].addAge(1000);
				}
			}
		}

		this.ageRecents();
		let oldestColor = this.mRecentColors[0];
		for(let i=1; i<5; i++){
			if(this.mRecentColors[i].getAge() > oldestColor.getAge()){
				oldestColor = this.mRecentColors[i];
			}
		}

		oldestColor.setColor(current);
		return;
	}

	public preserveModifiedColor(){
		if(this.hasBegunModifyingColor === false){
			this.hasBegunModifyingColor = true;
			
			let current = this.getSelectedGradientStop().getColor().copy(null);

			for(let i=0; i<5; i++){
				if(this.mRecentColors[i].getColor().equals(current)){
					return;
				}
			}
	
			this.ageRecents();
			let oldestColor = this.mRecentColors[0];

			for(let i=1; i<5; i++){
				if(this.mRecentColors[i].getAge() > oldestColor.getAge()){
					oldestColor = this.mRecentColors[i];
				}
			}
	
			oldestColor.setColor(current);
			return;
		}
	}

	private ageRecents(){
		for(let i=0; i<5; i++){
			this.mRecentColors[i].addAge(1);
		}
	}

	private onClickRecentColor1(){
		this.pickRecentColor(0);
	}

	private onClickRecentColor2(){
		this.pickRecentColor(1);
	}

	private onClickRecentColor3(){
		this.pickRecentColor(2);
	}

	private onClickRecentColor4(){
		this.pickRecentColor(3);
	}

	private onClickRecentColor5(){
		this.pickRecentColor(4);
	}

	public static showPalette(show:boolean|null){
		if(Palette.mSingleton){
			if(show === null){
				Theme.showPalette(!Theme.showPalette());
			}
			else{
				Theme.showPalette(show);
			}
		}
	}

	public static update(){
		if(Palette.mSingleton){
			Palette.mSingleton.forceUpdate();
		}
	}

    private onMouseDown(event : React.MouseEvent<HTMLDivElement, MouseEvent>){
		if(this.isDisplayingThemeSelect() === true){
			return;
		}

		if(this.isDisplayingThemeRestore() === true){
			return;
		}

		let relX = ((event.clientX - this.getXDiffCompensation()) - this.mSubWindowPosX) / (this.mSubWindowWidth - this.getXDiffCompensation());
		let relY = (event.clientY - this.mSubWindowPosY) / (this.mSubWindowWidth * this.mHeightVSWidthRatio);
		let code = this.mMouseMap.getRelativePositionPixelCode(relX, relY);
		this.mHoveredArea = hoveredArea.HOVEREDAREA_none;

		if(this.mMouseMode === mouseModes.MOUSEMODE_free){
			// Drag window
			if((code == "A")||(code == "D")){
				this.mMouseGraspX = event.clientX;
				this.mMouseGraspY = event.clientY;
				this.mPreGraspX = this.mSubWindowPosX;
				this.mPreGraspY = this.mSubWindowPosY;

				this.mMouseMode = mouseModes.MOUSEMODE_dragWindow;
				this.forceUpdate();
			}

			// Gradient stop creation
			if(code === "F"){
				this.mGradientStopCreationPhase = 1;
			}
			else{
				this.mGradientStopCreationPhase = 0;
			}

			// Scaling glass
			if(code == "N"){
				this.mMouseGraspX = event.clientX;
				this.mPreGraspX = this.mSubWindowWidth;
				this.mPreGraspY = this.mSubWindowPosY;

				this.mMouseMode = mouseModes.MOUSEMODE_scalingGlass;
				this.forceUpdate();
			}

			// Rainbow picker
			if(code === "K"){
				this.mMouseMode = mouseModes.MOUSEMODE_rainbowPicker;
				let newHue = (((Math.round(relY*100000))/100000) - 0.629) / (0.305); // min: 0.629 max: 0.934

				if(newHue < 0){
					newHue = 0;
				}

				if(newHue > 1){
					newHue = 1;
				}

				this.mLatestValidHue = newHue;
				this.preserveModifiedColor();
				this.getSelectedGradientStop().getColor().setHue(newHue);
				this.updateTheme();
			}

			// Color picker
			if(code === "C"){
				this.mMouseMode = mouseModes.MOUSEMODE_pickingColor;
				let newBrightness = 1-((((Math.round(relY*100000))/100000) - 0.62725) / 0.30493); // min: 0.62725 max: 0.93218
				let newIntensity = 1-((((Math.round(relX*100000))/100000) - 0.41149) / 0.24972); // min: 0.41149 max: 0.66121

				if(newBrightness < 0){
					newBrightness = 0;
				}

				if(newBrightness > 1){
					newBrightness = 1;
				}

				if(newIntensity < 0){
					newIntensity = 0;
				}

				if(newIntensity > 1){
					newIntensity = 1;
				}

				this.mPreferedBrightness = newBrightness;
				this.preserveModifiedColor();
				this.getSelectedGradientStop().getColor().setBrightness(newBrightness);
				this.getSelectedGradientStop().getColor().setColorIntensity(newIntensity, this.mLatestValidHue);
				this.updateTheme();
			}

			// Color sliders
			if((code == "O")||(code == "P")||(code == "Q")){
				this.mLatestMouseRelY = ((Math.round(relY*100000))/100000);
				let sliderMin = 0.46566;
				let sliderMax = 0.74327;
				let sliderDiff = sliderMax - sliderMin;
				let currentYLevel = (sliderDiff - (this.mLatestMouseRelY - sliderMin)) / sliderDiff;

				if(currentYLevel < 0){
					currentYLevel = 0;
				}

				if(currentYLevel > 1){
					currentYLevel = 1;
				}

				this.preserveModifiedColor();

				// Red color slider
				if(code == "O"){
					this.mMouseMode = mouseModes.MOUSEMODE_slidingRed;
					this.getSelectedGradientStop().getColor().SetRed(Math.round(currentYLevel * 255));
					this.updateTheme();
				}

				// Green color slider
				if(code == "P"){
					this.mMouseMode = mouseModes.MOUSEMODE_slidingGreen;
					this.getSelectedGradientStop().getColor().SetGreen(Math.round(currentYLevel * 255));
					this.updateTheme();
				}

				// Blue color slider
				if(code == "Q"){
					this.mMouseMode = mouseModes.MOUSEMODE_slidingBlue;
					this.getSelectedGradientStop().getColor().SetBlue(Math.round(currentYLevel * 255));
					this.updateTheme();
				}
			}

			return;
		}
		
		this.mHoveredArea = hoveredArea.HOVEREDAREA_none;
        this.forceUpdate();
	}
	
	private onClick(event : React.MouseEvent<HTMLDivElement, MouseEvent>){
		if(this.isDisplayingThemeSelect() === true){
			return;
		}

		if(this.isDisplayingThemeRestore() === true){
			return;
		}

		let relX = ((event.clientX - this.getXDiffCompensation()) - this.mSubWindowPosX) / (this.mSubWindowWidth - this.getXDiffCompensation());
		let relY = (event.clientY - this.mSubWindowPosY) / (this.mSubWindowWidth * this.mHeightVSWidthRatio);
		let code = this.mMouseMap.getRelativePositionPixelCode(relX, relY);
		
		// Closing cross
		if(code == "Y"){
			Theme.showPalette(false);
		}

		// Random colors
		if(code == "H"){
			this.getDisplayedTheme().randomize();
			this.updateTheme();
		}

		// THEME REGIONS:
		if(code === "L"){
			if(this.mSelectedThemeRegion != themeRegion.bgMain){
				this.preserveSwitchedColor();
			}
			this.mSelectedThemeRegion = themeRegion.bgMain;
			this.forceUpdate();
		}

		if(code === "M"){
			if(this.mSelectedThemeRegion != themeRegion.bgAlt){
				this.preserveSwitchedColor();
			}
			this.mSelectedThemeRegion = themeRegion.bgAlt;
			this.forceUpdate();
		}

		if(code === "U"){
			if(this.mSelectedThemeRegion != themeRegion.fgMain){
				this.preserveSwitchedColor();
			}
			this.mSelectedThemeRegion = themeRegion.fgMain;
			this.forceUpdate();
		}

		if(code === "T"){
			if(this.mSelectedThemeRegion != themeRegion.fgAlt){
				this.preserveSwitchedColor();
			}
			this.mSelectedThemeRegion = themeRegion.fgAlt;
			this.forceUpdate();
		}

		if(code === "c"){
			if(this.mSelectedThemeRegion != themeRegion.detailMain){
				this.preserveSwitchedColor();
			}
			this.mSelectedThemeRegion = themeRegion.detailMain;
			this.forceUpdate();
		}

		if(code === "b"){
			if(this.mSelectedThemeRegion != themeRegion.detailAlt){
				this.preserveSwitchedColor();
			}
			this.mSelectedThemeRegion = themeRegion.detailAlt;
			this.forceUpdate();
		}

		if(code === "g"){
			if(this.mSelectedThemeRegion != themeRegion.specular){
				this.preserveSwitchedColor();
			}
			this.mSelectedThemeRegion = themeRegion.specular;
			this.forceUpdate();
		}

		if(code === "V"){
			if(this.mSelectedThemeRegion != themeRegion.shadow){
				this.preserveSwitchedColor();
			}
			this.mSelectedThemeRegion = themeRegion.shadow;
			this.forceUpdate();
		}

		// Trashbin for gradient stops
		if(code === "X"){
			if(this.canTrashSelectedGradientStop()){
				this.preserveSwitchedColor();
			}

			let stop = this.getSelectedGradientStop();
			this.getDisplayedTheme().removeColorStop(stop.getSignature(), stop.getID());
			this.updateTheme();
		}

		// current gradient main display - adding new stop click
		if(code === "F"){
			if(this.mGradientStopCreationPhase === 2){
				this.mGradientStopCreationPhase = 0;

				let positioning = (this.mLatestMouseRelY - 0.16772) / 0.39435; // min: 0.16772 max: 0.56207
				if(positioning < 0){
					positioning = 0;
				}
	
				if(positioning > 1){
					positioning = 1;
				}
	
				this.preserveSwitchedColor();
				this.mSelectedGradientStopID = this.getDisplayedTheme().addGradientStop(this.getSelectedRegionGradientCode(),positioning);
				this.forceUpdate();
			}
		}

		// Rainbow picker
		if(code === "K"){
			let newHue = (((Math.round(relY*100000))/100000) - 0.629) / (0.305); // min: 0.629 max: 0.934
			
			if(newHue < 0){
				newHue = 0;
			}

			if(newHue > 1){
				newHue = 1;
			}

			this.preserveModifiedColor();
			this.getSelectedGradientStop().getColor().setHue(newHue);
			this.updateTheme();
		}

		// Recent colors
		if(code === "R"){
			this.onClickRecentColor1();
		}

		if(code === "S"){
			this.onClickRecentColor2();
		}
		
		if(code === "W"){
			this.onClickRecentColor3();
		}
		
		if(code === "Z"){
			this.onClickRecentColor4();
		}
		
		if(code === "a"){
			this.onClickRecentColor5();
		}

		// Save Theme
		if(code === "J"){
			this.onClickThemeSave();
		}

		// Restore Theme
		if(code === "I"){
			this.onClickThemeRestore();
		}

		// Theme Select
		if(code === "G"){
			this.onClickThemeSelect();
		}
	}

	public onSavedThemeConfirm(){
		console.log("Saved theme successfully.");
	}

	public onLoadTheme(data:string){
		let loadedTheme = JSON.parse(data);
		let currentTheme = Theme.getThemeByID(loadedTheme.mId);

		if(currentTheme){
			currentTheme.readFromLoadedTheme(loadedTheme);
			this.forceUpdate();
		}
	}

	public updateTheme(){
		this.forceUpdate();

		if(this.props.updateMethod){
			this.props.updateMethod();
		}

		if(this.mDisplayedTheme){
			this.mDisplayedTheme.refreshThemeComponents();
		}
	}
	
	private onMouseUp(event : React.MouseEvent<HTMLDivElement, MouseEvent>){
		if(this.isDisplayingThemeSelect() === true){
			return;
		}

		let relX = ((event.clientX - this.getXDiffCompensation()) - this.mSubWindowPosX) / (this.mSubWindowWidth - this.getXDiffCompensation());
		let relY = (event.clientY - this.mSubWindowPosY) / (this.mSubWindowWidth * this.mHeightVSWidthRatio);
		let code = this.mMouseMap.getRelativePositionPixelCode(relX, relY);

		this.mLatestMouseRelX = ((Math.round(relX*100000))/100000);
		this.mLatestMouseRelY = ((Math.round(relY*100000))/100000);
		this.mLatestMouseCode = code;

		this.mMouseGraspX = -1;
		this.mMouseGraspY = -1;
		this.mPreGraspX = -1;
		this.mPreGraspY = -1;

		if(this.mGradientStopCreationPhase === 1 && code === "F"){
			this.mGradientStopCreationPhase = 2
		}

		this.mMouseMode = mouseModes.MOUSEMODE_free;
		this.refreshHoverState(code);
        this.forceUpdate();
	}

	private onMouseLeave(event : React.MouseEvent<HTMLDivElement, MouseEvent>){
		this.mMouseMode = mouseModes.MOUSEMODE_free;
        this.forceUpdate();
	}

	private getXDiffCompensation(){
		return Math.round(this.mSubWindowWidth*0.021);
	}
	
	private onMouseMove(event : React.MouseEvent<HTMLDivElement, MouseEvent>){
		if(this.isDisplayingThemeSelect() === true){
			return;
		}

		if(this.isDisplayingThemeRestore() === true){
			return;
		}
		
		let relX = ((event.clientX - this.getXDiffCompensation()) - this.mSubWindowPosX) / (this.mSubWindowWidth - this.getXDiffCompensation());
		let relY = (event.clientY - this.mSubWindowPosY) / (this.mSubWindowWidth * this.mHeightVSWidthRatio);
		let code = this.mMouseMap.getRelativePositionPixelCode(relX, relY);
		
		// Free mode
		if(this.mMouseMode === mouseModes.MOUSEMODE_free){
			this.refreshHoverState(code);

			this.mLatestMouseRelX = ((Math.round(relX*100000))/100000);
			this.mLatestMouseRelY = ((Math.round(relY*100000))/100000);

			this.mLatestMouseCode = code;
			this.forceUpdate();
		}

		// Drag gradient stop mode
		if(this.mMouseMode === mouseModes.MOUSEMODE_dragGradientStop){
			let difference = event.clientY - this.mMouseGraspY;
			let newGradientPos = this.mPreGraspY + (difference / (this.mSubWindowWidth * this.mHeightVSWidthRatio) * 2.52);

			if(newGradientPos > 1){
				newGradientPos = 1;
			}

			if(newGradientPos < 0){
				newGradientPos = 0;
			}

			this.getSelectedGradientStop().setStopPosition(newGradientPos);
			this.updateTheme();
		}

		// Drag window mode
		if(this.mMouseMode === mouseModes.MOUSEMODE_dragWindow){
			this.mSubWindowPosX = this.mPreGraspX + (event.clientX - this.mMouseGraspX);
			this.mSubWindowPosY = this.mPreGraspY + (event.clientY - this.mMouseGraspY);
			this.forceUpdate();
		}

		// Scaling glass mode
		if(this.mMouseMode === mouseModes.MOUSEMODE_scalingGlass){
			let diff = 1.3;
			let change = (event.clientX*diff) - (this.mMouseGraspX*diff);
			let newWidth = this.mPreGraspX + change;
			let newY = this.mPreGraspY - ((change/2)*this.mHeightVSWidthRatio);

			if(newWidth < 50){
				newWidth = 50;
			}
			this.mSubWindowWidth = newWidth;
			this.mSubWindowPosY = newY;

			this.forceUpdate();
		}

		// Rainbow dragging
		if(this.mMouseMode === mouseModes.MOUSEMODE_rainbowPicker){
			let newHue = (((Math.round(relY*100000))/100000) - 0.629) / (0.305); // min: 0.629 max: 0.934
			
			if(newHue < 0){
				newHue = 0;
			}

			if(newHue > 1){
				newHue = 1;
			}

			this.preserveModifiedColor();
			this.mLatestValidHue = newHue;
			this.getSelectedGradientStop().getColor().setHue(newHue);
			this.updateTheme();
		}

		// Color picking mode
		if(this.mMouseMode === mouseModes.MOUSEMODE_pickingColor){
			let newBrightness = 1-((((Math.round(relY*100000))/100000) - 0.62725) / 0.30493); // min: 0.62725 max: 0.93218
			let newIntensity = 1-((((Math.round(relX*100000))/100000) - 0.41149) / 0.24972); // min: 0.41149 max: 0.66121

			if(newBrightness < 0){
				newBrightness = 0;
			}

			if(newBrightness > 1){
				newBrightness = 1;
			}

			if(newIntensity < 0){
				newIntensity = 0;
			}

			if(newIntensity > 1){
				newIntensity = 1;
			}

			this.preserveModifiedColor();
			this.mPreferedBrightness = newBrightness;
			this.getSelectedGradientStop().getColor().setBrightness(newBrightness);
			this.getSelectedGradientStop().getColor().setColorIntensity(newIntensity, this.mLatestValidHue);
			this.updateTheme();
		}

		// Color sliding mode
		if((this.mMouseMode === mouseModes.MOUSEMODE_slidingRed)
			||(this.mMouseMode === mouseModes.MOUSEMODE_slidingGreen)
			||(this.mMouseMode === mouseModes.MOUSEMODE_slidingBlue)){

			this.mLatestMouseRelY = ((Math.round(relY*100000))/100000);
			let sliderMin = 0.46566;
			let sliderMax = 0.74327;
			let sliderDiff = sliderMax - sliderMin;
			let currentYLevel = (sliderDiff - (this.mLatestMouseRelY - sliderMin)) / sliderDiff;

			if(currentYLevel < 0){
				currentYLevel = 0;
			}

			if(currentYLevel > 1){
				currentYLevel = 1;
			}

			this.preserveModifiedColor();

			// Red color slider
			if(this.mMouseMode === mouseModes.MOUSEMODE_slidingRed){
				this.getSelectedGradientStop().getColor().SetRed(Math.round(currentYLevel * 255));
				this.updateTheme();
			}

			// Green color slider
			if(this.mMouseMode === mouseModes.MOUSEMODE_slidingGreen){
				this.getSelectedGradientStop().getColor().SetGreen(Math.round(currentYLevel * 255));
				this.updateTheme();
			}

			// Blue color slider
			if(this.mMouseMode === mouseModes.MOUSEMODE_slidingBlue){
				this.getSelectedGradientStop().getColor().SetBlue(Math.round(currentYLevel * 255));
				this.updateTheme();
			}
		}
	}

	public refreshHoverState(code : string){
		if(code === "Y"){
			this.mHoveredArea = hoveredArea.HOVEREDAREA_closingCross;
			return;
		}

		if(code === "X"){
			this.mHoveredArea = hoveredArea.HOVEREDAREA_gradientTrashBin;
			return;
		}

		if(code === "R"){
			this.mHoveredArea = hoveredArea.HOVEREDAREA_recentColor1;
			return;
		}

		if(code === "S"){
			this.mHoveredArea = hoveredArea.HOVEREDAREA_recentColor2;
			return;
		}

		if(code === "W"){
			this.mHoveredArea = hoveredArea.HOVEREDAREA_recentColor3;
			return;
		}

		if(code === "Z"){
			this.mHoveredArea = hoveredArea.HOVEREDAREA_recentColor4;
			return;
		}

		if(code === "a"){
			this.mHoveredArea = hoveredArea.HOVEREDAREA_recentColor5;
			return;
		}

		if(code === "K"){
			this.mHoveredArea = hoveredArea.HOVEREDAREA_rainbowPicker;
			return;
		}

		if(code === "G"){
			this.mHoveredArea = hoveredArea.HOVEREDAREA_themeSelect;
			return;
		}

		if(code === "N"){
			this.mHoveredArea = hoveredArea.HOVEREDAREA_scalingGlass;
			return;
		}

		if(code === "H"){
			this.mHoveredArea = hoveredArea.HOVEREDAREA_randomColor;
			return;
		}

		if(code === "I"){
			this.mHoveredArea = hoveredArea.HOVEREDAREA_restore;
			return;
		}

		if(code === "J"){
			this.mHoveredArea = hoveredArea.HOVEREDAREA_saveChanges;
			return;
		}

		if(code === "L"){
			this.mHoveredArea = hoveredArea.HOVEREDAREA_themeRegion_backgroundPrimary;
			return;
		}

		if(code === "M"){
			this.mHoveredArea = hoveredArea.HOVEREDAREA_themeRegion_backgroundSecondary;
			return;
		}

		if(code === "U"){
			this.mHoveredArea = hoveredArea.HOVEREDAREA_themeRegion_foregroundPrimary;
			return;
		}

		if(code === "T"){
			this.mHoveredArea = hoveredArea.HOVEREDAREA_themeRegion_foregroundSecondary;
			return;
		}

		if(code === "c"){
			this.mHoveredArea = hoveredArea.HOVEREDAREA_themeRegion_detailPrimary;
			return;
		}

		if(code === "b"){
			this.mHoveredArea = hoveredArea.HOVEREDAREA_themeRegion_detailSecondary;
			return;
		}

		if(code === "g"){
			this.mHoveredArea = hoveredArea.HOVEREDAREA_themeRegion_specular;
			return;
		}

		if(code === "V"){
			this.mHoveredArea = hoveredArea.HOVEREDAREA_themeRegion_shadow;
			return;
		}

		if(code === "F"){
			this.mHoveredArea = hoveredArea.HOVEREDAREA_gradientDisplayArea;
			return;
		}

		if(code === "O"){
			this.mHoveredArea = hoveredArea.HOVEREDAREA_colorSliderRed;
			return;
		}

		if(code === "P"){
			this.mHoveredArea = hoveredArea.HOVEREDAREA_colorSliderGreen;
			return;
		}

		if(code === "Q"){
			this.mHoveredArea = hoveredArea.HOVEREDAREA_colorSliderBlue;
			return;
		}

		if(code === "C"){
			this.mHoveredArea = hoveredArea.HOVEREDAREA_colorPicker;
			return;
		}

		this.mHoveredArea = hoveredArea.HOVEREDAREA_none;
		return;
	}

	getArrayOfThemeRestoreOptionsForSelect(){
		return ThemeManager.getListOfSampleThemes()
	}

	// scaling offsetX: 332
	// scaling offsetY: 176

    draw(){

		// Determining the cursor to be shown:

		if(Theme.showPalette() === false){
			return <div></div>;
		}

		let usedCursor = "initial";
		let usedCursorGradientStop = "pointer";

		if(
			this.mHoveredArea == hoveredArea.HOVEREDAREA_recentColor1
			|| this.mHoveredArea == hoveredArea.HOVEREDAREA_recentColor2
			|| this.mHoveredArea == hoveredArea.HOVEREDAREA_recentColor3
			|| this.mHoveredArea == hoveredArea.HOVEREDAREA_recentColor4
			|| this.mHoveredArea == hoveredArea.HOVEREDAREA_recentColor5
			|| this.mHoveredArea == hoveredArea.HOVEREDAREA_saveChanges
			|| this.mHoveredArea == hoveredArea.HOVEREDAREA_restore
			|| this.mHoveredArea == hoveredArea.HOVEREDAREA_closingCross
			|| this.mHoveredArea == hoveredArea.HOVEREDAREA_themeSelect
			|| this.mHoveredArea == hoveredArea.HOVEREDAREA_randomColor){
			usedCursor = "pointer";
		}

		if(this.mHoveredArea == hoveredArea.HOVEREDAREA_gradientTrashBin){
			if(this.canTrashSelectedGradientStop()){
				usedCursor = "pointer";
			}
			else{
				usedCursor = "not-allowed";
			}
		}

		if(this.mHoveredArea == hoveredArea.HOVEREDAREA_themeRegion_backgroundPrimary 
				&& this.mSelectedThemeRegion != themeRegion.bgMain){
			usedCursor = "pointer";
		}

		if(this.mHoveredArea == hoveredArea.HOVEREDAREA_themeRegion_backgroundSecondary 
			&& this.mSelectedThemeRegion != themeRegion.bgAlt){
			usedCursor = "pointer";
		}

		if(this.mHoveredArea == hoveredArea.HOVEREDAREA_themeRegion_foregroundPrimary
			&& this.mSelectedThemeRegion != themeRegion.fgMain){
			usedCursor = "pointer";
		}

		if(this.mHoveredArea == hoveredArea.HOVEREDAREA_themeRegion_foregroundSecondary 
			&& this.mSelectedThemeRegion != themeRegion.fgAlt){
			usedCursor = "pointer";
		}

		if(this.mHoveredArea == hoveredArea.HOVEREDAREA_themeRegion_detailPrimary 
			&& this.mSelectedThemeRegion != themeRegion.detailMain){
			usedCursor = "pointer";
		}

		if(this.mHoveredArea == hoveredArea.HOVEREDAREA_themeRegion_detailSecondary 
			&& this.mSelectedThemeRegion != themeRegion.detailAlt){
			usedCursor = "pointer";
		}

		if(this.mHoveredArea == hoveredArea.HOVEREDAREA_themeRegion_shadow 
			&& this.mSelectedThemeRegion != themeRegion.shadow){
			usedCursor = "pointer";
		}

		if(this.mHoveredArea == hoveredArea.HOVEREDAREA_themeRegion_specular 
			&& this.mSelectedThemeRegion != themeRegion.specular){
			usedCursor = "pointer";
		}

		if(this.mHoveredArea == hoveredArea.HOVEREDAREA_gradientDisplayArea){
			usedCursor = "pointer";
		}

		if( this.mMouseMode === mouseModes.MOUSEMODE_dragGradientStop){
			usedCursor = "ns-resize";
			usedCursorGradientStop = "ns-resize";
		}

		if((this.mMouseMode === mouseModes.MOUSEMODE_slidingRed)
			||(this.mMouseMode === mouseModes.MOUSEMODE_slidingGreen)
			||(this.mMouseMode === mouseModes.MOUSEMODE_slidingBlue)
			||(this.mHoveredArea === hoveredArea.HOVEREDAREA_colorSliderRed)
			||(this.mHoveredArea === hoveredArea.HOVEREDAREA_colorSliderGreen)
			||(this.mHoveredArea === hoveredArea.HOVEREDAREA_colorSliderBlue)){
			usedCursor = "ns-resize";
		}

		if((this.mHoveredArea === hoveredArea.HOVEREDAREA_rainbowPicker)
		||(this.mMouseMode === mouseModes.MOUSEMODE_rainbowPicker)){
			usedCursor = "pointer";
		}

		if((this.mHoveredArea === hoveredArea.HOVEREDAREA_colorPicker)
		||(this.mMouseMode === mouseModes.MOUSEMODE_pickingColor)){
			usedCursor = "crosshair";
		}

		if((this.mHoveredArea === hoveredArea.HOVEREDAREA_scalingGlass)||(this.mMouseMode === mouseModes.MOUSEMODE_scalingGlass)){
			usedCursor = "w-resize";
		}

		// Calculating gradient stops

        let foregroundStops : Array<JSX.Element> = new Array<JSX.Element>();
        let stops = this.getDisplayedTheme().getStops("FG");

        for(let i=0; i<stops.length; i++){
            foregroundStops.push(<stop key={"key:"+i} offset={stops[i].getStopPosition()+""} stop-color={stops[i].getColor().GetRGB()}/>);
		}
        
        let foregroundAltStops : Array<JSX.Element> = new Array<JSX.Element>();
        stops = this.getDisplayedTheme().getStops("FG-Alt");

        for(let i=0; i<stops.length; i++){
            foregroundAltStops.push(<stop key={"key:"+i} offset={stops[i].getStopPosition()+""} stop-color={stops[i].getColor().GetRGB()}/>);
        }

        let backgroundStops : Array<JSX.Element> = new Array<JSX.Element>();
        stops = this.getDisplayedTheme().getStops("BG");

        for(let i=0; i<stops.length; i++){
            backgroundStops.push(<stop key={"key:"+i} offset={stops[i].getStopPosition()+""} stop-color={stops[i].getColor().GetRGB()}/>);
        }

        let backgroundAltStops : Array<JSX.Element> = new Array<JSX.Element>();
        stops = this.getDisplayedTheme().getStops("BG-Alt");

        for(let i=0; i<stops.length; i++){
            backgroundAltStops.push(<stop key={"key:"+i} offset={stops[i].getStopPosition()+""} stop-color={stops[i].getColor().GetRGB()}/>);
        }

        let detailStops : Array<JSX.Element> = new Array<JSX.Element>();
        stops = this.getDisplayedTheme().getStops("Detail");

        for(let i=0; i<stops.length; i++){
            detailStops.push(<stop key={"key:"+i} offset={stops[i].getStopPosition()+""} stop-color={stops[i].getColor().GetRGB()}/>);
        }

        let detailAltStops : Array<JSX.Element> = new Array<JSX.Element>();
        stops = this.getDisplayedTheme().getStops("Detail-Alt");

        for(let i=0; i<stops.length; i++){
            detailAltStops.push(<stop key={"key:"+i} offset={stops[i].getStopPosition()+""} stop-color={stops[i].getColor().GetRGB()}/>);
        }

        let specularStops : Array<JSX.Element> = new Array<JSX.Element>();
        stops = this.getDisplayedTheme().getStops("Specular");

        for(let i=0; i<stops.length; i++){
            specularStops.push(<stop key={"key:"+i} offset={stops[i].getStopPosition()+""} stop-color={stops[i].getColor().GetRGB()}/>);
        }

        let shadowStops : Array<JSX.Element> = new Array<JSX.Element>();
        stops = this.getDisplayedTheme().getStops("Shadow");

        for(let i=0; i<stops.length; i++){
            shadowStops.push(<stop key={"key:"+i} offset={stops[i].getStopPosition()+""} stop-color={stops[i].getColor().GetRGB()}/>);
		}

		let selectedRegionGradientStops : Array<JSX.Element> = new Array<JSX.Element>();
		stops = this.getSelectedRegionGradientStops();
		
		for(let i=0; i<stops.length; i++){
            selectedRegionGradientStops.push(<stop key={"key:"+i} offset={stops[i].getStopPosition()+""} stop-color={stops[i].getColor().GetRGB()}/>);
		}

		// Creating gradient stop color arrows:

		let gradientColorArrows : Array<JSX.Element> = new Array<JSX.Element>();
		stops = this.getSelectedRegionGradientStops();

		for(let i=0; i<stops.length; i++){
			let diff = 139.72 * stops[i].getStopPosition();

			if(this.getSelectedGradientStopID() === stops[i].getID()){
				gradientColorArrows.push(
					// Gradients color arrow selection indicator
					<polygon style={{fill:"#fff", cursor:"initial"}} points={"168.61 "+(52.34+diff)+" 174.69 "+(60.21+diff)+" 168.61 "+(67.27+diff)+" 154.56 "+(67.27+diff)+" 154.56 "+(52.34+diff)+" 168.61 "+(52.34+diff)}/>
				);

				usedCursorGradientStop = "ns-resize"
			}
			else{
				usedCursorGradientStop = "pointer"
			}
            
			gradientColorArrows.push(
				// Gradients arrow colored part
				<GradientStopArrow 
					stopColor={stops[i].getColor().GetRGB()}
					points={"167.43 "+(54.73+diff)+" 174.69 "+(60.16+diff)+" 167.43 "+(64.88+diff)+" 156.54 "+(64.88+diff)+" 156.54 "+(54.73+diff)+" 167.43 "+(54.73+diff)}
					stopID={stops[i].getID()}
					stopSignature={stops[i].getSignature()}
					eventHandler={this.onGradientStopEvent.bind(this)}
					cursor={usedCursorGradientStop}
				/>
			);
		}

		// calculating figure path of gradient stop adding symbol
		let positioning = (this.mLatestMouseRelY - 0.16772) / 0.39435; // min: 0.16772 max: 0.56207
		if(positioning < 0){
			positioning = 0;
		}

		if(positioning > 1){
			positioning = 1;
		}

		let diff = 139.7 * positioning;
		let diffLittle = 0.05 * positioning;
		let stopAddingSignPath = "M200.36," + (56.81+diff) + "c-2.73,0-5.29," + (3+diffLittle) + "-5.29," + (3+diffLittle) + "l-16.27.07V" + (58.37+diff) + "l-4.07,1.84L178.8,"+(62+diff)+"V"+(60.57+diff)+"l16.27-.07s2.1,3.25,5.29,3.25,3.94-2.28,3.94-3.47S203.69,"+(56.81+diff)+",200.36,"+(56.81+diff)+"Z";

		// Preparing a contrasting text color over the current color display
		let colorValueDetailColorRed = "rgb(128,0,0)";
		let colorValueDetailColorGreen = "rgb(0,64,0)";
		let colorValueDetailColorBlue = "rgb(0,0,128)";
		let colorValueDetailColorGeneral = "rgb(20,20,20)";
		let lightLevel = this.getSelectedGradientStop().getColor().GetRed() + (this.getSelectedGradientStop().getColor().GetGreen()*2) + this.getSelectedGradientStop().getColor().GetBlue();
		if(lightLevel < 512){
			colorValueDetailColorRed = "rgb(255,200,200)";
			colorValueDetailColorGreen = "rgb(200,255,200)";
			colorValueDetailColorBlue = "rgb(200,200,255)";
			colorValueDetailColorGeneral = "rgb(235,235,235)";
		}

		// Preparing color slider Y-positions
		let sliderMin = 166.42;
		let sliderMax = 263.42;
		let sliderDiff = sliderMax - sliderMin;
		let sliderRedY = sliderMax - ((this.getSelectedGradientStop().getColor().GetRed()/255) * sliderDiff);
		let sliderGreenY = sliderMax - ((this.getSelectedGradientStop().getColor().GetGreen()/255) * sliderDiff);
		let sliderBlueY = sliderMax - ((this.getSelectedGradientStop().getColor().GetBlue()/255) * sliderDiff);
		// ..And 'R', 'G' and 'B' letter Y positions...
		let letterMin = 169.37;
		let letterMax = letterMin + sliderDiff;
		let sliderRedLetterY = letterMax - ((this.getSelectedGradientStop().getColor().GetRed()/255) * sliderDiff);
		let sliderGreenLetterY = letterMax - ((this.getSelectedGradientStop().getColor().GetGreen()/255) * sliderDiff);
		let sliderBlueLetterY = letterMax - ((this.getSelectedGradientStop().getColor().GetBlue()/255) * sliderDiff);
		let sliderBallRadius = "7.6";

		// Preparing the rainbow marker's Y-position
		this.mLatestValidHue = this.getSelectedGradientStop().getColor().getHue(this.mLatestValidHue);
		let rbmAdd = 108.44 * this.mLatestValidHue;						                                                                                                                            // "+(221.07+rbmAdd)+"
		let rainbowMarkerPoints = ""
			+ "149.04 "  + ( 221.07 + rbmAdd ) 
			+ " 149.23 " + ( 226.03 + rbmAdd ) 
			+ " 152.96 " + ( 223.84 + rbmAdd ) 
			+ " 174.39 " + ( 223.84 + rbmAdd )
			+ " 178 "    + ( 226.03 + rbmAdd )  
			+ " 177.89 " + ( 220.7  + rbmAdd ) 
			+ " 174.06 " + ( 222.99 + rbmAdd )
			+ " 152.96 " + ( 222.99 + rbmAdd ) 
			+ " 149.04 " + ( 221.07 + rbmAdd );

		// Preparing the rainbow mouse hover indication line:
		let rainbowHoverWhitelineA = <div></div>;
		let rainbowHoverWhitelineB = <div></div>;
		if(this.mHoveredArea === hoveredArea.HOVEREDAREA_rainbowPicker){
			let hoverYpositioning = (this.mLatestMouseRelY - 0.629) / (0.305); // min: 0.629 max: 0.934

			// this.mLatestMouseRelY
			let rainbowMarkerHoverYpos = (222.94 + (108.48 * hoverYpositioning)) + "";
			let tickWidth = 4.0;
			let tickStartA = (153.39 - tickWidth) + "";
			let tickStartB = 173.45 + "";
			rainbowHoverWhitelineA = (
				<rect style={{fill:"#fff"}} x={tickStartA} y={rainbowMarkerHoverYpos} width={tickWidth+""} height="0.85"/>
			)
			rainbowHoverWhitelineB = (
				<rect style={{fill:"#fff"}} x={tickStartB} y={rainbowMarkerHoverYpos} width={tickWidth+""} height="0.85"/>
			)
		}

		// Preparing the color picker's gradient lines
		let colorPickerLines = new Array<JSX.Element>();
		let totalHeightSpan = 330.93 - 222.37;
		let incrementY = totalHeightSpan / 255;
		let current = this.getSelectedGradientStop().getColor();
		let fullHue = new ColorSample(null, current.GetRed(), current.GetGreen(), current.GetBlue());
		fullHue.setColorIntensity(1, this.mLatestValidHue);
		let HueRed = fullHue.GetRed();
		let HueGreen = fullHue.GetGreen();
		let HueBlue = fullHue.GetBlue();

		for(let i=0; i<256; i++){
			const posYLine = (222.37 + (i*incrementY)) + "";
			const brightness = 255-i;
			const LeftColor = "rgb(" + brightness + "," + brightness + "," + brightness + ")";
			const RightColor = "rgb(" + HueRed + "," + HueGreen + "," + HueBlue + ")";

			colorPickerLines.push(
				<defs key={"key:A"+i}>
					<style>{".cls_palette_61_Line" + i + "{fill:url(#palette_linear-gradient_line"+i+");}"}
					</style>
					<linearGradient id={"palette_linear-gradient_line" + i} x1="187.75" y1={posYLine} x2="297.18" y2={posYLine} gradientUnits="userSpaceOnUse">
						<stop offset="0" stop-color={RightColor}/>
						<stop offset="1" stop-color={LeftColor}/>
					</linearGradient>
				</defs>
			);

			colorPickerLines.push(
				<rect key={"key:B"+i} className={"cls_palette_61_Line" + i } x="187.75" y={posYLine} width="109.43" height="0.87"/>
			);
		}

		// Preparing the color picker's outer and inner mark circles
		let pickerMarkXMin = 188.5;
		let pickerMarkXDiff = 296.48 - 188.5;
		let pickerMarkYMin = 223.24;
		let pickerMarkYDiff = 331.37 - 223.24;
		let intensity = 1-this.getSelectedGradientStop().getColor().getColorIntensity();
		let brightness = 1-this.getSelectedGradientStop().getColor().getBrightness(this.mPreferedBrightness);
		let markerPosX = (pickerMarkXMin + (pickerMarkXDiff*intensity)) + "";
		let markerPosY = (pickerMarkYMin + (pickerMarkYDiff*brightness)) + "";

		let colorPickerMarkOuter = <circle style={{fill:"none", strokeMiterlimit:10, stroke:"#575756" }} cx={markerPosX} cy={markerPosY} r="5.05"/>
		let colorPickerMarkInner = <circle style={{fill:"none", strokeMiterlimit:10, stroke:"#dadada" }} cx={markerPosX} cy={markerPosY} r="4.18"/>
		
		// Preparing themeSelectOptions   - [{optionID:"id:one", optionText:"Option one"}]
		let themeSelectOptions = ThemeManager.getArrayOfThemesForSelect();
		let themeRestoreSelectOptions = this.getArrayOfThemeRestoreOptionsForSelect();

		// Preparing hover color for the theme selector graphics
		let jgselectHoverColor = "#575756";
		let jgselectHoverTitleColor = "#1d1d1b";
		if(this.mHoveredArea == hoveredArea.HOVEREDAREA_themeSelect){
			jgselectHoverColor = "#A77756";
			jgselectHoverTitleColor = "#875736";
		}

        return(
			<div style={{
					position:"fixed", 
					left: (this.mSubWindowPosX+"px"), 
					top: (this.mSubWindowPosY+"px"), 
					width: (this.mSubWindowWidth + "px"), 
					height:(Math.round(this.mSubWindowWidth*this.mHeightVSWidthRatio) + "px"),
					zIndex:2
				}}
				onMouseMove={this.onMouseMove.bind(this)}
				onClick={this.onClick.bind(this)}
				onMouseDown={this.onMouseDown.bind(this)}
				onMouseUp={this.onMouseUp.bind(this)}
				onMouseLeave={this.onMouseLeave.bind(this)}>
				
				<svg viewBox="0 0 443.94 354.98">

					{/*  Woodplank background darkened   (earlier: /jgmedia/plankwooddark.png)*/}
					<defs>
						<style>{".cls_palette_2{fill:url(#woodplankdark);}"}
						</style>
						<pattern id="woodplankdark" patternUnits="userSpaceOnUse" width="500" height="500">
							<image href="https://i.imgur.com/3DN7K8P.png" x="0" y="0" width="500" height="500" />
						</pattern>
					</defs>
					<path className="cls_palette_2" d="M363.68,48.36c26.83,14.41,38.07,50.06,9.38,61.88-25,10.28-39.94,9-43.31,25.87s19.43,23.83,53.44,34.88c43.31,14.06,60.75,23.62,60.75,53.82s-16.32,48-42.66,68.92C369.46,319,292.06,356.61,221.19,354.92S84.5,325.67,44,282.36C.8,236.16,9.73,165.67,23.75,137.24,33.72,117,62.09,85.5,98.56,63,136.18,39.76,179,29,217.78,26.9,269.56,24.05,336.65,33.84,363.68,48.36ZM337.8,199.51c-24.88,5.09-40.41,28.83-29.28,39.79s35.11,1.19,47.1-8.2C368.1,221.33,368.83,193.17,337.8,199.51Z"/>

					{/*  Woodplank background  (earlier: /jgmedia/plankwood.png) */}
					<defs>
						<style>{".cls_palette_3{fill:url(#woodplank);}"}
						</style>
						<pattern id="woodplank" patternUnits="userSpaceOnUse" width="500" height="500">
							<image href="https://i.imgur.com/7S1rJNH.png" x="0" y="0" width="500" height="500" />
						</pattern>
					</defs>
					<path fill="url(#woodplank)" d="M361,47.07c26.83,14.41,38.07,50.06,9.39,61.87-25,10.28-39.94,9-43.32,25.88s19.43,23.83,53.44,34.87c43.31,14.06,60.75,23.63,60.75,53.82s-16.31,48-42.65,68.93c-31.82,25.28-109.22,62.88-180.1,61.19S81.79,324.38,41.29,281.07C-1.91,234.87,7,164.38,21,135.94,31,115.73,59.39,84.2,95.86,61.69c37.61-23.22,80.43-33.95,119.21-36.09C266.86,22.75,333.94,32.55,361,47.07ZM335.09,198.22c-24.87,5.08-40.4,28.83-29.28,39.78s35.11,1.2,47.11-8.19C365.39,220,366.12,191.88,335.09,198.22Z"/>

					{/*  Recent colors backlight - slot 1  */}
					<defs>
						<style>{(".cls_palette_7{fill:url(#palette_radial-gradient-4B);}")}
						</style>
						<radialGradient id="palette_radial-gradient-4B" cx="45.67" cy="110.0" r="35.63" gradientTransform="translate(0 55.45) scale(1 1)" gradientUnits="userSpaceOnUse">
							<stop offset="0.32" stop-color="#fff" stop-opacity={this.mHoveredArea == hoveredArea.HOVEREDAREA_recentColor1?"0.7":"0.0"}/>
							<stop offset="1" stop-color="#fff" stop-opacity="0"/>
						</radialGradient>
					</defs>
					<circle className="cls_palette_7" cx="43.67" cy="166.42" r="41.63"/>

					{/*  Recent colors backlight - slot 2  */}
					<defs>
						<style>{".cls_palette_9{fill:url(#palette_radial-gradient-6);"+(this.mHoveredArea == hoveredArea.HOVEREDAREA_recentColor2?"cursor:pointer;":"")+"}"}
						</style>
						<radialGradient id="palette_radial-gradient-6" cx="-33.6" cy="235.41" r="42.41" gradientTransform="translate(69.13 23.42) scale(0.82 0.82)" gradientUnits="userSpaceOnUse">
							<stop offset="0.34" stop-color="#fff" stop-opacity={this.mHoveredArea == hoveredArea.HOVEREDAREA_recentColor2?"0.7":"0.0"}/>
							<stop offset="1" stop-color="#fff" stop-opacity="0"/>
						</radialGradient>
					</defs>
					<ellipse className="cls_palette_9" cx="41.7" cy="215.54" rx="41.7" ry="38.45"/>

					{/*  Recent colors backlight - slot 3  */}
					<defs>
						<style>{".cls_palette_4{fill:url(#palette_radial-gradient);"+(this.mHoveredArea == hoveredArea.HOVEREDAREA_recentColor3?"cursor:pointer;":"")+"}"}
						</style>					
						<radialGradient id="palette_radial-gradient" cx="53.0" cy="254.46" r="33" gradientTransform="translate(0 84.79) scale(1 0.67)" gradientUnits="userSpaceOnUse">
							<stop offset="0.32" stop-color="#fff" stop-opacity={this.mHoveredArea == hoveredArea.HOVEREDAREA_recentColor3?"0.7":"0.0"}/>
							<stop offset="1" stop-color="#fff" stop-opacity="0"/>
						</radialGradient>
					</defs>
					<ellipse className="cls_palette_4" cx="53.0" cy="254.46" rx="34.49" ry="31.45"/>

					{/*  Recent colors backlight - slot 4  */}
					<defs>
						<style>{".cls_palette_6{fill:url(#palette_radial-gradient-3);"+(this.mHoveredArea == hoveredArea.HOVEREDAREA_recentColor4?"cursor:pointer;":"")+"}"}
						</style>
						<radialGradient id="palette_radial-gradient-3" cx="76.61" cy="283.49" r="31.12" gradientTransform="translate(0 94.47) scale(1 0.67)" gradientUnits="userSpaceOnUse">
							<stop offset="0.32" stop-color="#fff" stop-opacity={this.mHoveredArea == hoveredArea.HOVEREDAREA_recentColor4?"0.7":"0.0"}/>
							<stop offset="1" stop-color="#fff" stop-opacity="0"/>
						</radialGradient>
					</defs>
					<circle className="cls_palette_6" cx="76.61" cy="283.49" r="31.12"/>

					{/*  Recent colors backlight - slot 5  */}
					<defs>
						<style>{".cls_palette_5{fill:url(#palette_radial-gradient-2);"+(this.mHoveredArea == hoveredArea.HOVEREDAREA_recentColor5?"cursor:pointer;":"")+"}"}
						</style>
						<radialGradient id="palette_radial-gradient-2" cx="105.91" cy="302.27" r="31.14" gradientTransform="translate(0 100.73) scale(1 0.67)" gradientUnits="userSpaceOnUse">
							<stop offset="0.32" stop-color="#fff" stop-opacity={this.mHoveredArea == hoveredArea.HOVEREDAREA_recentColor5?"0.7":"0.0"}/>
							<stop offset="1" stop-color="#fff" stop-opacity="0"/>
						</radialGradient>
					</defs>
					<circle className="cls_palette_5" cx="105.91" cy="302.27" r="31.14"/>

					{/*  Random colors backlight  */}
					<defs>
						<style>{".cls_palette_8{fill:url(#palette_radial-gradient-5);"+(this.mHoveredArea == hoveredArea.HOVEREDAREA_randomColor?"cursor:pointer;":"")+"}"}
						</style>
						<radialGradient id="palette_radial-gradient-5" cx="394.31" cy="206.99" r="31.5" gradientTransform="translate(0 55.75) scale(1 0.73)" gradientUnits="userSpaceOnUse">
							<stop offset="0.34" stop-color="#fff" stop-opacity={this.mHoveredArea == hoveredArea.HOVEREDAREA_randomColor?"0.7":"0.0"}/>
							<stop offset="1" stop-color="#fff" stop-opacity="0"/>
						</radialGradient>
					</defs>
					<circle className="cls_palette_8" cx="394.31" cy="206.99" r="31.5"/>

					{/*  Save changes backlight  */}
					<defs>
						<style>{".cls_palette_10{fill:url(#palette_radial-gradient-7);"+(this.mHoveredArea == hoveredArea.HOVEREDAREA_saveChanges?"cursor:pointer;":"")+"}"}
						</style>
						<radialGradient id="palette_radial-gradient-7" cx="293.1" cy="293.21" r="28.24" gradientTransform="translate(65.38 12.38) scale(0.93 0.93)" gradientUnits="userSpaceOnUse">
							<stop offset="0.32" stop-color="#fff" stop-opacity={this.mHoveredArea == hoveredArea.HOVEREDAREA_saveChanges?"0.7":"0.0"}/>
							<stop offset="1" stop-color="#fff" stop-opacity="0"/>
						</radialGradient>
					</defs>
					<ellipse className="cls_palette_10" cx="336.77" cy="283.87" rx="31.5" ry="26.5"/>

					{/*  restore backlight  */}
					<defs>
						<style>{".cls_palette_11{fill:url(#palette_radial-gradient-8);"+(this.mHoveredArea == hoveredArea.HOVEREDAREA_restore?"cursor:pointer;":"")+"}"}
						</style>
						<radialGradient id="palette_radial-gradient-8" cx="345.2" cy="270" r="28.24" gradientTransform="translate(65.38 4.46) scale(0.93 0.93)" gradientUnits="userSpaceOnUse">
							<stop offset="0.34" stop-color="#fff" stop-opacity={this.mHoveredArea == hoveredArea.HOVEREDAREA_restore?"0.7":"0.0"}/>
							<stop offset="1" stop-color="#fff" stop-opacity="0"/>
						</radialGradient>
					</defs>
					<ellipse className="cls_palette_11" cx="385.01" cy="254.46" rx="31.5" ry="26.5"/>

					{/*  Quit cross backlight  */}
					<defs>
						<style>{".cls_palette_12{fill:url(#palette_radial-gradient-9);"+(this.mHoveredArea == hoveredArea.HOVEREDAREA_closingCross?"cursor:pointer;":"")+"}"}
						</style>
						<radialGradient id="palette_radial-gradient-9" cx="312.26" cy="137.43" r="28.24" gradientTransform="translate(65.38 -40.79) scale(0.93 0.93)" gradientUnits="userSpaceOnUse">
							<stop offset="0.27" stop-color="#fff" stop-opacity={this.mHoveredArea == hoveredArea.HOVEREDAREA_closingCross?"0.7":"0.0"}/>
							<stop offset="1" stop-color="#fff" stop-opacity="0"/>
						</radialGradient>
					</defs>
					<ellipse className="cls_palette_12" cx="354.51" cy="86.46" rx="31.5" ry="26.5"/>

					{/*  Scaling glass backlight  */}
					<defs>
						<style>{".cls_palette_67{fill:url(#palette_radial-gradient-14);}"}
						</style>
						<radialGradient id="palette_radial-gradient-14" cx="298.08" cy="208.94" r="25.86" gradientTransform="translate(95.81 -16.38) scale(0.82 0.93)" gradientUnits="userSpaceOnUse">
							<stop offset="0.34" stop-color="#fff" stop-opacity={this.mHoveredArea == hoveredArea.HOVEREDAREA_scalingGlass?"0.7":"0.0"}/>
							<stop offset="1" stop-color="#fff" stop-opacity="0"/>
						</radialGradient>
					</defs>
					<ellipse className="cls_palette_67" cx="341.01" cy="177.08" rx="25.63" ry="24.28"/>

					{/*  current color shadowlayer  */}
					<defs>
						<style>{".cls_palette_15{fill:" + this.getSelectedGradientStop().getColor().Mix(null,new ColorSample(null,0,0,0),0.36).GetRGB() + ";}"}
						</style>
					</defs>
					<path className="cls_palette_15" d="M121.77,88.47c-10.32-2-5.13-13.29-12.06-17-7.95-4.27-16.92,4.92-15.12,9.44s2.49,11.32-.76,12-6.06-7-11.63-6.54-16.61,5.66-12,12,13.18,10.33,7.58,18.6c-3.66,5.42-7.42,9.65-4,12.44s9.05,1.37,14.08,0c12.66-3.47,7.11,11.09,15.12,16.49,7.42,5,19.42,7.16,24.28,1.38,6.12-7.25,2.9-15.23,17.2-14.23,6.91.49,7.16-16.12.27-20S147.72,93.71,144,89C136.77,80,127.24,89.54,121.77,88.47Z"/>

					{/*  current color main  */}
					<defs>
						<style>{".cls_palette_16{fill:" + this.getSelectedGradientStop().getColor().GetRGB() + ";}"}
						</style>
					</defs>
					<path className="cls_palette_16" d="M120,87.47c-10.32-2-5.13-13.29-12.06-17C100,66.19,91,75.38,92.8,79.9s2.48,11.32-.77,12-6.06-7-11.62-6.54-16.61,5.66-12,12,13.18,10.33,7.59,18.6c-3.67,5.42-7.42,9.65-4,12.44s9,1.37,14.08,0c12.66-3.47,7.11,11.09,15.11,16.49,7.42,5,19.42,7.16,24.29,1.38C131.65,139,128.43,131,142.73,132c6.91.49,7.16-16.12.27-20s2.92-19.24-.82-23.94C135,79,125.44,88.54,120,87.47Z"/>

					{/*  recent color 1 shadowLayer  */}
					<defs>
						<style>{".cls_palette_Recent1Shadow{fill:"+this.mRecentColors[0].getShadowedColor().GetRGB()+";"+(this.mHoveredArea == hoveredArea.HOVEREDAREA_recentColor1?"cursor:pointer;":"")+"}"}
						</style>
					</defs>
					<path className="cls_palette_Recent1Shadow" d="M54.22,148c-12.39-3.74-12,5.09-15.43,8.49s-15.16-.47-18.51,6.47c-2.64,5.48,7.53,4.36,8.09,10.3.5,5.38-8.64,10.52,2.59,12.43s16.5-4.88,20.51.93,14.27.12,15.66-4.75c2.48-8.71-8.32-8.84-5.72-17.52C63.26,158.18,62.9,150.6,54.22,148Z"/>

					{/*  recent color 1 main  */}
					<defs>
						<style>{".cls_palette_Recent1{fill:"+this.mRecentColors[0].getColor().GetRGB()+";"+(this.mHoveredArea == hoveredArea.HOVEREDAREA_recentColor1?"cursor:pointer;":"")+"}"}
						</style>
					</defs>
					<path className="cls_palette_Recent1" d="M52.9,146.9c-12.38-3.74-12,5.09-15.43,8.5s-15.15-.48-18.5,6.46c-2.65,5.48,7.53,4.36,8.09,10.3.5,5.38-8.64,10.52,2.58,12.43s16.51-4.88,20.52.93,14.27.12,15.66-4.75c2.48-8.71-8.32-8.84-5.72-17.52C61.94,157.1,61.59,149.52,52.9,146.9Z"/>

					{/*  recent color 2 shadowlayer  */}
					<defs>
						<style>{".cls_palette_Recent2Shadow{fill:"+this.mRecentColors[1].getShadowedColor().GetRGB()+";"+(this.mHoveredArea == hoveredArea.HOVEREDAREA_recentColor2?"cursor:pointer;":"")+"}"}
						</style>
					</defs>
					<path className="cls_palette_Recent2Shadow" d="M59.33,198.92c-5.57-2.75-6.09,5.36-9.9,4.34s-9.1,5.71-10.8,1.18-5.56-9.67-8.53-7.25-.13,11.33-3.58,13.32-8.71,15.71.24,14.62,10.37-4.88,15,1.61,8.86,11.08,14.1,6.72S53.1,222.18,57.4,217,64.47,201.47,59.33,198.92Z"/>

					{/*  recent color 2 main  */}
					<defs>
						<style>{".cls_palette_Recent2{fill:"+this.mRecentColors[1].getColor().GetRGB()+";"+(this.mHoveredArea == hoveredArea.HOVEREDAREA_recentColor2?"cursor:pointer;":"")+"}"}
						</style>
					</defs>
					<path className="cls_palette_Recent2" d="M58.33,198c-5.57-2.75-6.09,5.36-9.9,4.34s-9.1,5.71-10.8,1.18-5.56-9.67-8.53-7.26-.13,11.34-3.58,13.33-8.71,15.71.24,14.62,10.37-4.88,15,1.61,8.86,11.08,14.1,6.72S52.1,221.22,56.4,216,63.47,200.51,58.33,198Z"/>

					{/*  recent color 3 shadowlayer  */}
					<defs>
						<style>{".cls_palette_Recent3Shadow{fill:"+this.mRecentColors[2].getShadowedColor().GetRGB()+";"+(this.mHoveredArea == hoveredArea.HOVEREDAREA_recentColor3?"cursor:pointer;":"")+"}"}
						</style>
					</defs>
					<path className="cls_palette_Recent3Shadow" d="M57.05,246.13c-8.54,5.27-10.42-8.91-16.86-2.7s-5.53,19.22,5,21.77c4.47,1.08,9.65-1.66,13.32.28,7.14,3.77,9.6-4.22,9.71-9.06C68.38,248,63.56,242.13,57.05,246.13Z"/>

					{/*  recent color 3 main  */}
					<defs>
						<style>{".cls_palette_Recent3{fill:"+this.mRecentColors[2].getColor().GetRGB()+";"+(this.mHoveredArea == hoveredArea.HOVEREDAREA_recentColor3?"cursor:pointer;":"")+"}"}
						</style>
					</defs>
					<path className="cls_palette_Recent3" d="M56.21,245.45c-8.55,5.26-10.43-8.92-16.86-2.7s-5.53,19.22,5,21.76c4.47,1.09,9.65-1.65,13.32.29,7.14,3.77,9.6-4.22,9.71-9.06C67.53,247.33,62.72,241.45,56.21,245.45Z"/>

					{/*  recent color 4 shadowlayer  */}
					<defs>
						<style>{".cls_palette_Recent4Shadow{fill:"+this.mRecentColors[3].getShadowedColor().GetRGB()+";"+(this.mHoveredArea == hoveredArea.HOVEREDAREA_recentColor4?"cursor:pointer;":"")+"}"}
						</style>
					</defs>
					<path className="cls_palette_Recent4Shadow" d="M73.66,274.48c-8.15-4-15.95,9.6-8.45,14.2s17.71,9.19,21.41,5.56c2.24-2.2,10.28-13.56-3.2-11.89C79.5,282.84,77.51,276.37,73.66,274.48Z"/>

					{/*  recent color 4 main  */}
					<defs>
						<style>{".cls_palette_Recent4{fill:"+this.mRecentColors[3].getColor().GetRGB()+";"+(this.mHoveredArea == hoveredArea.HOVEREDAREA_recentColor4?"cursor:pointer;":"")+"}"}
						</style>
					</defs>
					<path className="cls_palette_Recent4" d="M72.75,273.7c-8.16-4-16,9.6-8.45,14.21s17.71,9.18,21.41,5.55C88,291.27,96,279.91,82.5,281.58,78.59,282.07,76.6,275.6,72.75,273.7Z"/>

					{/*  recent color 5 shadowlayer  */}
					<defs>
						<style>{".cls_palette_Recent5Shadow{fill:"+this.mRecentColors[4].getShadowedColor().GetRGB()+";"+(this.mHoveredArea == hoveredArea.HOVEREDAREA_recentColor5?"cursor:pointer;":"")+"}"}
						</style>
					</defs>
					<path className="cls_palette_Recent5Shadow" d="M107,292.72c-9.95,1.35-6.44,2-10.65,9.95s4.42,10.27,8.18,6.81c3.38-3.11,11.27,1.61,15.36,2.33,8,1.39,4.26-9.6-.84-11.36C111.08,297.72,114.33,291.73,107,292.72Z"/>

					{/*  recent color 5 main  */}
					<defs>
						<style>{".cls_palette_Recent5{fill:"+this.mRecentColors[4].getColor().GetRGB()+";"+(this.mHoveredArea == hoveredArea.HOVEREDAREA_recentColor5?"cursor:pointer;":"")+"}"}
						</style>
					</defs>
					<path className="cls_palette_Recent5" d="M106.08,291.88c-10,1.34-6.44,2.05-10.65,9.94s4.42,10.27,8.18,6.82c3.38-3.11,11.27,1.61,15.36,2.33,8,1.39,4.25-9.61-.85-11.36C110.17,296.88,113.41,290.89,106.08,291.88Z"/>

					{/*  recent colors title text  */}
					<defs>
						<style>{".cls_palette_23{fill:#1d1d1b;font-family:SegoePrint-Bold, Segoe Print;font-weight:700;font-size:8px;cursor:initial;}"
							+".cls_palette_24{letter-spacing:0.02em;cursor:initial;}"}
						</style>
					</defs>
					<text className="cls_palette_23" transform="translate(30.86 135.19) rotate(13.48)">Recent colo
						<tspan className="cls_palette_24" x="45.7" y="0">r
						</tspan>
						<tspan x="49.91" y="0">s:
						</tspan>
					</text>

					{/*  Dropdownlist text  */}
					<defs>
						<style>{".cls_palette_SelectHoverText{font-size:12px;fill:"+ jgselectHoverTitleColor +";font-family:SegoePrint-Bold, Segoe Print;font-weight:700;}"}
						</style>
					</defs>
					<text className="cls_palette_SelectHoverText" transform="translate(209.0 78.34)">{this.getDisplayedTheme().GetID()}
					</text>

					{/*  Dropdownlist underline  */}
					<defs>
						<style>{".cls_palette_SelectHover{fill:"+ jgselectHoverColor +";}"}
						</style>
					</defs>
					<rect className="cls_palette_SelectHover" x="208.08" y="81.31" width="112.31" height="0.99"/>

					{/*  Dropdownlist downarrow  */}
					<polygon className="cls_palette_SelectHover" points="308.15 72.63 320.4 72.63 314.27 77.92 308.15 72.63"/>

					{/*  Dropdownlist title text  */}
					<defs>
						<style>{".cls_palette_SelectHoverTitle{fill:"+ jgselectHoverTitleColor +";font-family:SegoePrint-Bold, Segoe Print;font-weight:700;font-size:7px;}"}
						</style>
					</defs>
					<text className="cls_palette_SelectHoverTitle" transform="translate(208.08 64.13)">Editing theme: 
					</text>

					{/*  Editing region text  */}
					<defs>
						<style>{".cls_palette_22{font-size:12px;fill:#1d1d1b;font-family:SegoePrint-Bold, Segoe Print;font-weight:700;}"}
						</style>
					</defs>
					<text className="cls_palette_22" transform="translate(208.08 114.05)">{this.getRegionTitle()}
					</text>

					{/*  Editing region title  */}
					<defs>
						<style>{".cls_palette_26{fill:#1d1d1b;font-family:SegoePrint-Bold, Segoe Print;font-weight:700;font-size:7px;}"
							+".cls_palette_27{letter-spacing:0em;}"}
						</style>
					</defs>
					<text className="cls_palette_26" transform="translate(208.08 99.84)">Editing 
						<tspan className="cls_palette_27" x="28.11" y="0">r
						</tspan>
						<tspan x="31.69" y="0">egion:
						</tspan>
					</text>

					{/*  Gradients main display body  */}
					<defs>
						<style>{".cls_palette_28{fill:url(#palette_linear-gradient);}"}
						</style>
						<linearGradient id="palette_linear-gradient" x1="185.75" y1="59.42" x2="185.75" y2="200.59" gradientUnits="userSpaceOnUse">
							{selectedRegionGradientStops}
						</linearGradient>
					</defs>
					<rect className="cls_palette_28" x="175.37" y="59.42" width="20.75" height="141.17"/>

					{/*  current color RGB values text  */}
					<defs>
						<style>{".cls_palette_CCTRed{fill:"+ colorValueDetailColorRed +";font-family:SegoePrint-Bold, Segoe Print;font-weight:700;font-size:8.5px;}"}
						</style>
						<style>{".cls_palette_CCTGreen{fill:"+ colorValueDetailColorGreen +";font-family:SegoePrint-Bold, Segoe Print;font-weight:700;font-size:8.5px;}"}
						</style>
						<style>{".cls_palette_CCTBlue{fill:"+ colorValueDetailColorBlue +";font-family:SegoePrint-Bold, Segoe Print;font-weight:700;font-size:8.5px;}"}
						</style>
					</defs>
					{/*  current color red text */}
					<text className="cls_palette_CCTRed" transform="translate(95 101.3)">R : {Math.round(this.getSelectedGradientStop().getColor().GetRed())}
					</text>
					{/*  current color green text  */}
					<text className="cls_palette_CCTGreen" transform="translate(95 111.05)">G : {Math.round(this.getSelectedGradientStop().getColor().GetGreen())}
					</text>
					{/*  current color blue text  */}
					<text className="cls_palette_CCTBlue" transform="translate(95 121.48)">B : {Math.round(this.getSelectedGradientStop().getColor().GetBlue())}
					</text>

					{/*  color slider body red  */}
					<defs>
						<style>{".cls_palette_35{fill:url(#palette_linear-gradient-3);}"}
						</style>
						<linearGradient id="palette_linear-gradient-3" x1="88.59" y1="165.37" x2="88.59" y2="264.37" gradientUnits="userSpaceOnUse">
							<stop offset="0" stop-color={"rgb(" + "255" +"," + this.getSelectedGradientStop().getColor().GetGreen() + "," + this.getSelectedGradientStop().getColor().GetBlue() + ")" }/>
							<stop offset="1" stop-color={"rgb(" + "0" +"," + this.getSelectedGradientStop().getColor().GetGreen() + "," + this.getSelectedGradientStop().getColor().GetBlue() + ")" }/>
						</linearGradient>
					</defs>
					<rect className="cls_palette_35" x="81.75" y="165.37" width="13.69" height="99"/>

					{/*  color slider body green  */}
					<defs>
						<style>{".cls_palette_36{fill:url(#palette_linear-gradient-4);}"}
						</style>
						<linearGradient id="palette_linear-gradient-4" x1="108.66" y1="165.37" x2="108.66" y2="264.37" gradientUnits="userSpaceOnUse">
							<stop offset="0" stop-color={"rgb(" + this.getSelectedGradientStop().getColor().GetRed() + "," + "255" + "," + this.getSelectedGradientStop().getColor().GetBlue() + ")" }/>
							<stop offset="1" stop-color={"rgb(" + this.getSelectedGradientStop().getColor().GetRed() + "," + "0" + "," + this.getSelectedGradientStop().getColor().GetBlue() + ")" }/>
						</linearGradient>
					</defs>
					<rect className="cls_palette_36" x="101.34" y="165.37" width="14.63" height="99"/>

					{/*  color slider body blue  */}
					<defs>
						<style>{".cls_palette_37{fill:url(#palette_linear-gradient-5);}"}
						</style>
						<linearGradient id="palette_linear-gradient-5" x1="129.05" y1="165.37" x2="129.05" y2="264.37" gradientUnits="userSpaceOnUse">
						<stop offset="0" stop-color={"rgb(" + this.getSelectedGradientStop().getColor().GetRed() + "," + this.getSelectedGradientStop().getColor().GetGreen() + "," + "255" + ")" }/>
							<stop offset="1" stop-color={"rgb(" + this.getSelectedGradientStop().getColor().GetRed() + "," + this.getSelectedGradientStop().getColor().GetGreen() + "," + "0" + ")" }/>
						</linearGradient>
					</defs>
					<rect className="cls_palette_37" x="121.59" y="165.37" width="14.91" height="99"/>

					{/*  color slider lines: */}
					<defs>
						<style>{".cls_palette_SliderLine{fill:#575756;fill-opacity:0.4;}"}
						</style>
					</defs>
					{/*  color slider line red  */}
					<rect className="cls_palette_SliderLine" x="87.85" y="165.37" width="1.5" height="99"/>
					{/*  color slider line green  */}
					<rect className="cls_palette_SliderLine" x="108.19" y="165.37" width="1.5" height="99"/>
					{/*  color slider line blue  */}
					<rect className="cls_palette_SliderLine" x="128.22" y="165.37" width="1.5" height="99"/>

					{/*  Color slider ball red  */}
					<defs>
						<style>{".cls_palette_38{stroke-miterlimit:10;stroke:#be1622;fill:url(#palette_radial-gradient-10);}"}
						</style>
						<radialGradient id="palette_radial-gradient-10" cx="88.12" cy={sliderRedY} r={sliderBallRadius} gradientUnits="userSpaceOnUse">
							<stop offset="0" stop-color="#f6b3ab"/>
							<stop offset="1" stop-color="#e52613"/>
						</radialGradient>
					</defs>
					<circle className="cls_palette_38" cx="88.12" cy={sliderRedY} r={sliderBallRadius}/>

					{/*  color slider letter 'R'  */}
					<defs>
						<style>{".cls_palette_23{fill:#1d1d1b;font-family:SegoePrint-Bold, Segoe Print;font-weight:700;font-size:8px;}"}
						</style>
					</defs>
					<text className="cls_palette_23" transform={"translate(85.22 "+sliderRedLetterY+")"}>R
					</text>

					{/*  Color slider ball green  */}
					<defs>
						<style>{".cls_palette_39{stroke-miterlimit:10;stroke:#008d36;fill:url(#palette_radial-gradient-11);}"}
						</style>
						<radialGradient id="palette_radial-gradient-11" cx="108.94" cy={sliderGreenY} r={sliderBallRadius} gradientUnits="userSpaceOnUse">
							<stop offset="0" stop-color="#ede97e"/>
							<stop offset="1" stop-color="#58b031"/>
						</radialGradient>
					</defs>
					<circle className="cls_palette_39" cx="108.94" cy={sliderGreenY} r={sliderBallRadius}/>

					{/*  color slider letter 'G'  */}
					<defs>
						<style>{".cls_palette_23{fill:#1d1d1b;font-family:SegoePrint-Bold, Segoe Print;font-weight:700;font-size:8px;}"}
						</style>
					</defs>
					<text className="cls_palette_23" transform={"translate(105.91 "+sliderGreenLetterY+")"}>G
					</text>

					{/*  Color slider ball blue  */}
					<defs>
						<style>{".cls_palette_40{stroke-miterlimit:10;stroke:#2d2e83;fill:url(#palette_radial-gradient-12);}"}
						</style>
						<radialGradient id="palette_radial-gradient-12" cx="128.97" cy={sliderBlueY} r={sliderBallRadius} gradientUnits="userSpaceOnUse">
							<stop offset="0" stop-color="#86d1f5"/>
							<stop offset="1" stop-color="#2c57a4"/>
						</radialGradient>
					</defs>
					<circle className="cls_palette_40" cx="128.97" cy={sliderBlueY} r={sliderBallRadius}/>

					{/*  color slider letter 'B'  */}
					<defs>
						<style>{".cls_palette_23{fill:#1d1d1b;font-family:SegoePrint-Bold, Segoe Print;font-weight:700;font-size:8px;}"}
						</style>
					</defs>
					<text className="cls_palette_23" transform={"translate(125.94 "+sliderBlueLetterY+")"}>B
					</text>

					{/*  Save changes text  */}
					<defs>
						<style>{".cls_palette_41{fill:#1d1d1b;font-family:SegoePrint-Bold, Segoe Print;font-weight:700;font-size:10px;}"
							+".cls_palette_42{letter-spacing:-0.01em;}"}
						</style>
					</defs>
					<text className="cls_palette_41" transform="translate(316.7 280.33)">
						<tspan >  
						</tspan>
						<tspan className="cls_palette_42" x="8" y="0">S
						</tspan>
						<tspan x="14.27" y="0">ave
						</tspan>
						<tspan x="4" y="14">Theme
						</tspan>
					</text>

					{/*  Restore text  */}
					<defs>
						<style>{".cls_palette_41{fill:#1d1d1b;font-family:SegoePrint-Bold, Segoe Print;font-weight:700;font-size:10px;}"
							+".cls_palette_43{letter-spacing:-0.02em;}"
							+".cls_palette_43B{letter-spacing:-0.02em;}"}
						</style>
					</defs>
					<text className="cls_palette_41" transform="translate(368.27 253.37)">
						<tspan className="cls_palette_43">Restore
						</tspan>
					</text>
					<text className="cls_palette_41" transform="translate(368.27 266.37)">
						<tspan className="cls_palette_43">Theme
						</tspan>
					</text>
					

					{/*  Random text  */}
					<defs>
						<style>{".cls_palette_34{fill:#1d1d1b;font-family:SegoePrint-Bold, Segoe Print;font-weight:700;font-size:9px;}"
							+".cls_palette_24{letter-spacing:0.02em;}"
							+".cls_palette_44{letter-spacing:0em;}"
							+".cls_palette_45{letter-spacing:0.02em;}"}
						</style>
					</defs>
					<text className="cls_palette_34" transform="translate(376.27 205.15)">
						<tspan className="cls_palette_24">R
							<tspan className="cls_palette_44" x="6.72" y="0">andom
							</tspan>
						</tspan>
						<tspan x="0" y="10.8" > colo
						</tspan>
						<tspan className="cls_palette_45" x="20.84" y="10.8">r
						</tspan>
						<tspan className="cls_palette_44" x="25.58" y="10.8">s
						</tspan>
					</text>

					{/*  recent color 1 specular upper  */}
					<defs>
						<style>{".cls_palette_46{fill:#fff;opacity:0.67;}"}
						</style>
					</defs>
					<path className="cls_palette_46" d="M44.54,147.4a7.3,7.3,0,0,1,3.79-.72c1.91,0,3.1,1.21,2.62,1.2a18.38,18.38,0,0,0-5.86.91c-2.55.91-5,5-5,5s1.12-4.53,4.44-6.38"/>

					{/*  recent color 1 specular left  */}
					<defs>
						<style>{".cls_palette_46{fill:#fff;opacity:0.67;}"}
						</style>
					</defs>
					<path className="cls_palette_46" d="M22.19,159.52a9.55,9.55,0,0,1,3.64-.82c1.49,0,2.13.31,1.76.3-.88,0-2.28,1-4,1.53-2,.65-4.08,3.52-4.08,3.52s.08-3.22,2.67-4.53"/>

					{/*  recent color 2 specular top-right  */}
					<defs>
						<style>{".cls_palette_46{fill:#fff;opacity:0.67;}"}
						</style>
					</defs>
					<path className="cls_palette_46" d="M53.82,199.86a3.26,3.26,0,0,1,1.45-.64c.62-.09.91.05.76.06-.37,0-.88.66-1.55,1.06A5.44,5.44,0,0,0,53,202.48a3.42,3.42,0,0,1,.78-2.62"/>

					{/*  recent color 2 specular top-left  */}
					<defs>
						<style>{".cls_palette_46{fill:#fff;opacity:0.67;}"}
						</style>
					</defs>
					<path className="cls_palette_46" d="M29.42,197.59a1.57,1.57,0,0,1,1.46-.81c.76,0,1.31,1.13,1.12,1.14A3.48,3.48,0,0,0,29.72,199c-1,1-1.69,5.1-1.69,5.1s.18-4.55,1.39-6.48"/>

					{/*  recent color 2 specular mid-left  */}
					<defs>
						<style>{".cls_palette_46{fill:#fff;opacity:0.67;}"}
						</style>
					</defs>
					<path className="cls_palette_46" d="M21.75,217.8a9,9,0,0,1,1.45-2.7c.78-1,1.29-1.24,1.09-1-.47.55-.65,1.9-1.24,3.25a9,9,0,0,0-.21,4.21,3.84,3.84,0,0,1-1.09-3.76"/>

					{/*  recent color 3 specular  */}
					<defs>
						<style>{".cls_palette_46{fill:#fff;opacity:0.67;}"}
						</style>
					</defs>
					<path className="cls_palette_46" d="M37.91,246a8.81,8.81,0,0,1,2.14-2.54c1.47-1.5,2.46-1.26,2.09-.89a21.53,21.53,0,0,0-3.43,3.92c-1.57,2.37-2.41,7.47-2.41,7.47a9.14,9.14,0,0,1,.76-6.48"/>

					{/*  recent color 5 specular  */}
					<defs>
						<style>{".cls_palette_46{fill:#fff;opacity:0.67;}"}
						</style>
					</defs>
					<path className="cls_palette_46" d="M100.25,294.17c.79-.64.89-.49,1.72-.68,1.13-.28,1.62.5,1.34.56a6.82,6.82,0,0,0-2.74,1c-1.38.9-3,4.34-3,4.34s.14-3,1.85-4.56"/>

					{/*  recent color 4 specular  */}
					<defs>
						<style>{".cls_palette_46{fill:#fff;opacity:0.67;}"}
						</style>
					</defs>
					<path className="cls_palette_46" d="M65.13,276a6,6,0,0,1,2-1.49c1.34-.83,2-.44,1.71-.23a14.59,14.59,0,0,0-3.21,2.26c-1.57,1.48-3,5.2-3,5.2a7,7,0,0,1,1.61-4.79"/>

					{/*  current color specular top center  */}
					<defs>
						<style>{".cls_palette_46{fill:#fff;opacity:0.67;}"}
						</style>
					</defs>
					<path className="cls_palette_46" d="M98.73,71.44a7.34,7.34,0,0,1,3.79-.72c1.91,0,3.1,1.21,2.62,1.2a18.37,18.37,0,0,0-5.85.91c-2.55.91-5,5-5,5s1.12-4.53,4.43-6.38"/>

					{/*  current color specular top left  */}
					<defs>
						<style>{".cls_palette_46{fill:#fff;opacity:0.67;}"}
						</style>
					</defs>
					<path className="cls_palette_46" d="M70.36,90.92a9.57,9.57,0,0,1,3.28-1.76c1.44-.4,2.14-.28,1.78-.19-.85.22-1.93,1.56-3.43,2.55C70.24,92.67,69,96,69,96s-.78-3.12,1.36-5.08"/>

					{/*  current color specular lower left  */}
					<defs>
						<style>{".cls_palette_46{fill:#fff;opacity:0.67;}"}
						</style>
					</defs>
					<path className="cls_palette_46" d="M72.87,122.5a3.41,3.41,0,0,1,1.68-1.28c.77-.21,1.18,0,1,0-.45.11-.92,1.22-1.66,2s-1.22,3.82-1.22,3.82-.78-3,.22-4.55"/>

					{/*  current color specular top right  */}
					<defs>
						<style>{".cls_palette_46{fill:#fff;opacity:0.67;}"}
						</style>
					</defs>
					<path className="cls_palette_46" d="M133.09,84.8a4.2,4.2,0,0,1,2,1.12c.61.68.65,1.16.51,1-.34-.4-1.61-.41-2.68-.83-1.26-.49-4.11.38-4.11.38s2.28-2,4.25-1.65"/>

					{/*  paint brush shadow  */}
					<defs>
						<style>{".cls_palette_47{opacity:0.26;}"
							+".cls_palette_48{clip-path:url(#palette_clip-path);}"
							+".cls_palette_49{fill:#1d1d1b;}"}
						</style>
						<clipPath id="palette_clip-path">
							<path className="cls_palette_1" d="M237.48,34c62.43,8.78,203.6,36.45,203.6,36.45l-.38,2.79S290.28,61.48,231.81,53.39C173.62,45.33,84.4,26.88,84.4,26.88l1.22-8.77S177.32,25.51,237.48,34Z"/>
						</clipPath>
					</defs>
					<g className="cls_palette_47">
						<g className="cls_palette_48">
							<path className="cls_palette_49" d="M96.91,61.39c37.62-23.22,80.43-34,119.22-36.09C267.91,22.45,335,32.24,362,46.76c26.83,14.42,38.08,50.06,9.39,61.88Z"/>
						</g>
					</g>

					{/*  colorpicker rainbow body  */}
					<defs>
						<style>{".cls_palette_33{fill:url(#palette_linear-gradient-2);}"}
						</style>
						<linearGradient id="palette_linear-gradient-2" x1="163.46" y1="223.37" x2="163.46" y2="331.73" gradientUnits="userSpaceOnUse">
							<stop offset="0" stop-color="rgb(255,100,100)"/>
							<stop offset="0.16667" stop-color="rgb(227,227,0)"/>
							<stop offset="0.33333" stop-color="rgb(100,255,100)"/>
							<stop offset="0.5" stop-color="rgb(0,227,227)"/>
							<stop offset="0.66667" stop-color="rgb(100,100,255)"/>
							<stop offset="0.83333" stop-color="rgb(227,0,227)"/>
							<stop offset="1" stop-color="rgb(255,100,100)"/>
						</linearGradient>
					</defs>
					<rect className="cls_palette_33" x="153.43" y="223.37" width="20.06" height="108.36"/>

					{/*  Rainbow position marker */}
					<defs>
						<style>{".cls_palette_25{fill:#575756;}"}
						</style>
					</defs>
					<polygon className="cls_palette_25" points={rainbowMarkerPoints}/>

					{/*  rainbow hover whiteline */}
					{rainbowHoverWhitelineA}
					{rainbowHoverWhitelineB}

					{/*  Trashbin circular background  */}
					<defs>
						<style>{".cls_palette_50{stroke:"+colorValueDetailColorGeneral+";stroke-miterlimit:10;"+(((this.mHoveredArea === hoveredArea.HOVEREDAREA_gradientTrashBin)&&(this.canTrashSelectedGradientStop()))?"fill:#d2d2d2;opacity:0.40;}":"fill:#b2b2b2;opacity:0.25;}")}
						</style>
					</defs>
					<circle className="cls_palette_50" cx="113.71" cy="137.87" r="9.09"/>

					{/*  trashbin figure  */}
					<defs>
						<style>{".cls_palette_51{opacity:0.75;}"
							+".cls_palette_52{fill:"+colorValueDetailColorGeneral+";}"}
						</style>
					</defs>
					<g className="cls_palette_51">
						<polygon className="cls_palette_52" points="110.41 143.63 109.74 143.63 108.95 135 109.62 135 110.41 143.63"/>
						<polygon className="cls_palette_52" points="112.18 142.27 111.51 142.27 111.12 136.13 111.78 136.13 112.18 142.27"/>
						<rect className="cls_palette_52" x="113.28" y="136.13" width="0.66" height="6.13"/>
						<polygon className="cls_palette_52" points="117.89 134.25 117.22 134.25 115.25 132.45 115.92 132.45 117.89 134.25"/>
						<polygon className="cls_palette_52" points="110.01 134.25 109.35 134.25 111.32 132.45 111.98 132.45 110.01 134.25"/>
						<polygon className="cls_palette_52" points="115.72 142.27 115.06 142.27 115.45 136.13 116.11 136.13 115.72 142.27"/>
						<polygon className="cls_palette_52" points="117.49 143.63 116.83 143.63 117.61 135 118.28 135 117.49 143.63"/>
						<rect className="cls_palette_52" x="113.23" y="139.61" width="0.66" height="7.45" transform="translate(-29.77 256.9) rotate(-90)"/>
						<rect className="cls_palette_52" x="113.23" y="129.38" width="0.66" height="10.6" transform="translate(-21.11 248.24) rotate(-90)"/>
						<rect className="cls_palette_52" x="113.2" y="129.89" width="0.72" height="5.1" transform="translate(-18.87 246) rotate(-90)"/>
					</g>

					{/*  region picker background-secondary gradient */}
					<defs>
						<style>{".cls_palette_53{fill:url(#palette_linear-gradient-6);}"}
						</style>
						<linearGradient id="palette_linear-gradient-6" x1="285.21" y1="122.4" x2="285.21" y2="200.35" gradientUnits="userSpaceOnUse">
							{backgroundAltStops}
						</linearGradient>
					</defs>
					<rect className="cls_palette_53" x="259.78" y="122.4" width="50.87" height="77.94"/>

					{/*  region picker background-primary gradient */}
					<defs>
						<style>{".cls_palette_54{fill:url(#palette_linear-gradient-7);}"}
						</style>
						<linearGradient id="palette_linear-gradient-7" x1="234.6" y1="122.4" x2="234.6" y2="200.35" gradientUnits="userSpaceOnUse">
							{backgroundStops}
						</linearGradient>
					</defs>
					<rect className="cls_palette_54" x="209.17" y="122.4" width="50.87" height="77.94"/>

					{/*  region picker background-secondary outline  */}
					<defs>
						<style>{".cls_palette_55H{fill:none;"+ ((this.mSelectedThemeRegion === themeRegion.bgAlt)?"stroke-miterlimit:10;stroke:#fcea10;stroke-width:2px;}" : (this.mHoveredArea === hoveredArea.HOVEREDAREA_themeRegion_backgroundSecondary)?"stroke-miterlimit:10;stroke:#fcea10;stroke-width:2px;stroke-opacity:0.6;fill:white;fill-opacity:0.4;cursor:pointer;}":"stroke:none;fill:none;}")}
						</style>
					</defs>
					<rect className="cls_palette_55H" x="259.78" y="122.4" width="50.87" height="77.94"/>

					{/*  region picker background-primary outline  */}
					<defs>
						<style>{".cls_palette_55G{fill:none;"+ ((this.mSelectedThemeRegion === themeRegion.bgMain)?"stroke-miterlimit:10;stroke:#fcea10;stroke-width:2px;}" : (this.mHoveredArea === hoveredArea.HOVEREDAREA_themeRegion_backgroundPrimary)?"stroke-miterlimit:10;stroke:#fcea10;stroke-width:2px;stroke-opacity:0.6;fill:white;fill-opacity:0.4;cursor:pointer;}":"stroke:none;fill:none;}")}
						</style>
					</defs>
					<rect className="cls_palette_55G" x="209.17" y="122.4" width="50.87" height="77.94"/>

					{/*  region picker shadow gradient  */}
					<defs>
						<style>{".cls_palette_shadowGradient{fill:url(#palette_linear-gradient-SH);}"}
						</style>
						<linearGradient id="palette_linear-gradient-SH" x1="220.0" y1="178.83" x2="300.0" y2="178.83" gradientUnits="userSpaceOnUse">
							{shadowStops}
						</linearGradient>
					</defs>
					<ellipse className="cls_palette_shadowGradient" cx="265.97" cy="178.83" rx="40.0" ry="11.11"/>

					{/*  region picker shadow outline  */}
					<defs>
						<style>{".cls_palette_55F{fill:none;"+ ((this.mSelectedThemeRegion === themeRegion.shadow)?"stroke-miterlimit:10;stroke:#fcea10;stroke-width:2px;}" : (this.mHoveredArea === hoveredArea.HOVEREDAREA_themeRegion_shadow)?"stroke-miterlimit:10;stroke:#fcea10;stroke-width:2px;stroke-opacity:0.6;fill:white;fill-opacity:0.4;cursor:pointer;}":"stroke:none;fill:none;}")}
						</style>
					</defs>
					<ellipse className="cls_palette_55F" cx="265.97" cy="178.83" rx="39.3" ry="11.11"/>

					{/*  region picker foreground-primary gradient */}
					<defs>
						<style>{".cls_palette_56{fill:url(#palette_linear-gradient-8);}"}
						</style>
						<linearGradient id="palette_linear-gradient-8" x1="240.39" y1="131.11" x2="240.39" y2="181.83" gradientUnits="userSpaceOnUse">
							{foregroundStops}
						</linearGradient>
					</defs>
					<path className="cls_palette_56" d="M260.57,131.11c-22.3,0-40.37,11.35-40.37,25.36s18.07,25.36,40.37,25.36"/>

					{/*  region picker foreground-secondary gradient */}
					<defs>
						<style>{".cls_palette_57{fill:url(#palette_linear-gradient-9);}"}
						</style>
						<linearGradient id="palette_linear-gradient-9" x1="280.34" y1="131.11" x2="280.34" y2="181.83" gradientUnits="userSpaceOnUse">
							{foregroundAltStops}
						</linearGradient>
					</defs>
					<path className="cls_palette_57" d="M260.15,181.83c22.3,0,40.38-11.36,40.38-25.36s-18.08-25.36-40.38-25.36"/>

					{/*  region picker detail-primary gradient  */}
					<defs>
						<style>{".cls_palette_58{fill:url(#palette_linear-gradient-10);}"}
						</style>
						<linearGradient id="palette_linear-gradient-10" x1="248.8" y1="142.05" x2="248.8" y2="170.88" gradientUnits="userSpaceOnUse">
							{detailStops}
						</linearGradient>
					</defs>
					<path className="cls_palette_58" d="M260.27,142.05c-12.67,0-22.94,6.46-22.94,14.42s10.27,14.41,22.94,14.41"/>

					{/*  region picker detail-secondary gradient  */}
					<defs>
						<style>{".cls_palette_59{fill:url(#palette_linear-gradient-11);}"}
						</style>
						<linearGradient id="palette_linear-gradient-11" x1="271.51" y1="142.05" x2="271.51" y2="170.88" gradientUnits="userSpaceOnUse">
							{detailAltStops}
						</linearGradient>
					</defs>
					<path className="cls_palette_59" d="M260,170.88c12.67,0,22.94-6.45,22.94-14.41S272.71,142.05,260,142.05"/>

					{/*  region picker foreground-primary outline  */}
					<defs>
						<style>{".cls_palette_55E{fill:none;"+ ((this.mSelectedThemeRegion === themeRegion.fgMain)?"stroke-miterlimit:10;stroke:#fcea10;stroke-width:2px;}" : (this.mHoveredArea === hoveredArea.HOVEREDAREA_themeRegion_foregroundPrimary)?"stroke-miterlimit:10;stroke:#fcea10;stroke-width:2px;stroke-opacity:0.6;fill:white;fill-opacity:0.4;cursor:pointer;}":"stroke:none;fill:none;}")}
						</style>
					</defs>
					<path className="cls_palette_55E" d="M260.57,181.83c-22.3,0-40.37-11.36-40.37-25.36s18.07-25.36,40.37-25.36l-.3,10.94c-12.67,0-22.94,6.46-22.94,14.42s10.27,14.41,22.94,14.41Z"/>

					{/*  region picker foreground-secondary outline  */}
					<defs>
						<style>{".cls_palette_55D{fill:none;"+ ((this.mSelectedThemeRegion === themeRegion.fgAlt)?"stroke-miterlimit:10;stroke:#fcea10;stroke-width:2px;}" : (this.mHoveredArea === hoveredArea.HOVEREDAREA_themeRegion_foregroundSecondary)?"stroke-miterlimit:10;stroke:#fcea10;stroke-width:2px;stroke-opacity:0.6;fill:white;fill-opacity:0.4;cursor:pointer;}":"stroke:none;fill:none;}")}
						</style>
					</defs>
					<path className="cls_palette_55D" d="M260.15,181.83c22.3,0,40.38-11.36,40.38-25.36s-18.08-25.36-40.38-25.36L260,142.05c12.67,0,22.94,6.46,22.94,14.42S272.71,170.88,260,170.88Z"/>

					{/*  region picker specular gradient  */}
					<defs>
						<style>{".cls_palette_60{fill:url(#palette_radial-gradient-13);}"}
						</style>
						<radialGradient id="palette_radial-gradient-13" cx="-30.63" cy="176.58" r="9.74" gradientTransform="translate(417.21 120.53) rotate(74.88)" gradientUnits="userSpaceOnUse">
							{specularStops}
						</radialGradient>
					</defs>
					<polygon className="cls_palette_60" points="236.82 147.59 235.34 140.86 228.95 138.27 234.89 134.78 235.38 127.9 240.54 132.48 247.23 130.82 244.47 137.14 248.12 142.99 241.26 142.32 236.82 147.59"/>

					{/*  region picker specular outline  */}
					<defs>
						<style>{".cls_palette_55A{fill:none;"+ ((this.mSelectedThemeRegion === themeRegion.specular)?"stroke-miterlimit:10;stroke:#fcea10;stroke-width:2px;}" : (this.mHoveredArea === hoveredArea.HOVEREDAREA_themeRegion_specular)?"stroke-miterlimit:10;stroke:#fcea10;stroke-width:2px;stroke-opacity:0.6;fill:white;fill-opacity:0.4;cursor:pointer;}":"stroke:none;fill:none;}")}
						</style>
					</defs>
					<polygon className="cls_palette_55A" points="236.82 147.59 235.34 140.86 228.95 138.27 234.89 134.78 235.38 127.9 240.54 132.48 247.23 130.82 244.47 137.14 248.12 142.99 241.26 142.32 236.82 147.59"/>

					{/*  region picker detail-secondary outline  */}
					<defs>
						<style>{".cls_palette_55B{fill:none;"+ ((this.mSelectedThemeRegion === themeRegion.detailAlt)?"stroke-miterlimit:10;stroke:#fcea10;stroke-width:2px;}" : (this.mHoveredArea === hoveredArea.HOVEREDAREA_themeRegion_detailSecondary)?"stroke-miterlimit:10;stroke:#fcea10;stroke-width:2px;stroke-opacity:0.6;fill:white;fill-opacity:0.4;cursor:pointer;}":"stroke:none;fill:none;}")}
						</style>
					</defs>
					<path className="cls_palette_55B" d="M260,170.88c12.67,0,22.94-6.45,22.94-14.41S272.71,142.05,260,142.05Z"/>

					{/*  region picker detail-primary outline  */}
					<defs>
						<style>{".cls_palette_55C{"+ ((this.mSelectedThemeRegion === themeRegion.detailMain)?"stroke-miterlimit:10;stroke:#fcea10;stroke-width:2px;fill:none;}" : (this.mHoveredArea === hoveredArea.HOVEREDAREA_themeRegion_detailPrimary)?"stroke-miterlimit:10;stroke:#fcea10;stroke-width:2px;stroke-opacity:0.6;fill:white;fill-opacity:0.4;cursor:pointer;}":"stroke:none;fill:none;}")}
						</style>
					</defs>
					<path className="cls_palette_55C" d="M260.27,142.05c-12.67,0-22.94,6.46-22.94,14.42s10.27,14.41,22.94,14.41Z"/>

					{/*  color picker grey background full  */}
					<defs>
						<style>{".cls_palette_25{fill:#575756;}"}
						</style>
					</defs>
					<rect className="cls_palette_25" x="187.75" y="222.37" width="109.43" height="109.43"/>

					{/*  quiting cross rectangle  */}
					<defs>
						<style>{".cls_palette_64{stroke-miterlimit:10;fill:#cbbba0;stroke:#706f6f;stroke-width:1.5px;"+(this.mHoveredArea == hoveredArea.HOVEREDAREA_closingCross?"cursor:pointer;":"")+"}"}
						</style>
					</defs>
					<rect className="cls_palette_64" x="343.32" y="75.27" width="22.38" height="22.38"/>

					{colorPickerLines}

					{colorPickerMarkOuter}
					{colorPickerMarkInner}
					

					{/*  quiting cross diagonal bottom-left to top-right  */}
					<defs>
						<style>{".cls_palette_25{fill:#575756;"+(this.mHoveredArea == hoveredArea.HOVEREDAREA_closingCross?"cursor:pointer;":"")+"}"}
						</style>
					</defs>
					<rect className="cls_palette_25" x="342.41" y="85.63" width="24.19" height="1.66" transform="translate(42.72 276.04) rotate(-45.01)"/>

					{/*  quiting cross diagonal top-left to bottom-right  */}
					<defs>
						<style>{".cls_palette_25{fill:#575756;"+(this.mHoveredArea == hoveredArea.HOVEREDAREA_closingCross?"cursor:pointer;":"")+"}"}
						</style>
					</defs>
					<rect className="cls_palette_25" x="342.41" y="85.63" width="24.19" height="1.66" transform="translate(544.09 398.25) rotate(-135.01)"/>

					{/*  Scaling glass sideways arrows  */}
					<defs>
						<style>{".cls_palette_68{fill:url(#palette_linear-gradient-15);}"}
						</style>
						<linearGradient id="palette_linear-gradient-15" x1="320.91" y1="178.58" x2="362.81" y2="178.58" gradientUnits="userSpaceOnUse">
							<stop offset="0" stop-color="#575756"/>
							<stop offset="0.49" stop-color="#b3b3b3"/>
							<stop offset="1" stop-color="#575756"/>
						</linearGradient>
					</defs>
					<polygon className="cls_palette_68" points="320.91 179.24 324.75 173.89 324.75 176.56 359.53 176.56 359.53 173.89 362.81 178.83 359.81 183.27 359.72 181.02 324.75 181.02 324.75 183.27 320.91 179.24"/>

					{/*  Scaling glass gradiented circle area  */}
					<defs>
						<style>{".cls_palette_69{stroke-miterlimit:10;stroke:#575756;fill:url(#palette_linear-gradient-16);}"}
						</style>
						<linearGradient id="palette_linear-gradient-16" x1="332.31" y1="168.89" x2="342.12" y2="178.7" gradientUnits="userSpaceOnUse">
							<stop offset="0" stop-color="#2581c4"/>
							<stop offset="1" stop-color="#2581c4" stop-opacity="0"/>
						</linearGradient>
					</defs>
					<circle className="cls_palette_69" cx="337.22" cy="173.8" r="6.94"/>

					{/*  Scaling glass handle  */}
					<defs>
						<style>{".cls_palette_25{fill:#575756;}"}
						</style>
					</defs>
					<rect className="cls_palette_25" x="340" y="182.28" width="13.67" height="2.41" transform="translate(231.33 -191.51) rotate(45)"/>

					{/*  Scaling glass specular  */}
					<defs>
						<style>{".cls_palette_70{fill:url(#palette_radial-gradient-15);}"}
						</style>
						<radialGradient id="palette_radial-gradient-15" cx="333.92" cy="170.53" r="1.35" gradientUnits="userSpaceOnUse">
							<stop offset="0.48" stop-color="#fff"/>
							<stop offset="1" stop-color="#2581c4" stop-opacity="0"/>
						</radialGradient>
					</defs>
					<circle className="cls_palette_70" cx="333.92" cy="170.53" r="1.35"/>

					{/*  Paintbrush tip shape  */}
					<defs>
						<style>{".cls_palette_71{stroke-miterlimit:10;stroke:#2d2e83;fill:url(#palette_linear-gradient-17);}"}
						</style>
						<linearGradient id="palette_linear-gradient-17" x1="25.89" y1="34.4" x2="68.22" y2="34.4" gradientTransform="translate(6.04 -31.17) rotate(7.88)" gradientUnits="userSpaceOnUse">
							<stop offset="0.51" stop-color="#253689"/>
							<stop offset="0.98" stop-color="#fff5ab"/>
						</linearGradient>
					</defs>
					<path className="cls_palette_71" d="M27.55,2.29C33.27,7.08,41.8-.26,51.84.56S69.35,9,69.35,9l-1.22,8.78s-14.76,5.48-20.66-1.58c-2.27-2.72-10.78-5-14.19-7.23C27.82,5.33,27.55,2.29,27.55,2.29Z"/>

					{/*  paintbrush handle  */}
					<defs>
						<style>{".cls_palette_72{fill:url(#palette_linear-gradient-18);}"}
						</style>
						<linearGradient id="palette_linear-gradient-18" x1="263.22" y1="26" x2="263.22" y2="46.01" gradientTransform="translate(6.04 -31.17) rotate(7.88)" gradientUnits="userSpaceOnUse">
							<stop offset="0" stop-color="#af6b2f"/>
							<stop offset="0.23" stop-color="#b67130"/>
							<stop offset="0.34" stop-color="#d28b35"/>
							<stop offset="0.44" stop-color="#bc651e"/>
							<stop offset="0.65" stop-color="#726150"/>
							<stop offset="1" stop-color="#1d1d1b"/>
						</linearGradient>
					</defs>
					<path className="cls_palette_72" d="M236.42,27C298.85,35.75,440,63.41,440,63.41l-.39,2.79S289.23,54.47,230.76,46.38c-58.19-8.05-147.41-26.5-147.41-26.5l1.21-8.78S176.27,18.5,236.42,27Z"/>

					{/*  paintbrush mid part  */}
					<defs>
						<style>{".cls_palette_73{fill:url(#palette_linear-gradient-19);}"}
						</style>
						<linearGradient id="palette_linear-gradient-19" x1="75.99" y1="31.1" x2="75.99" y2="39.96" gradientTransform="translate(0.45 -21.09)" gradientUnits="userSpaceOnUse">
							<stop offset="0.01" stop-color="#6d6c6c"/>
							<stop offset="0.15" stop-color="#fff"/>
							<stop offset="0.26" stop-color="#868685"/>
							<stop offset="0.49" stop-color="#4e4e4d"/>
							<stop offset="0.7" stop-color="#d3d3d2"/>
							<stop offset="0.79" stop-color="#555453"/>
							<stop offset="1" stop-color="#1d1d1b"/>
						</linearGradient>
					</defs>
					<rect className="cls_palette_73" x="68.84" y="10.02" width="15.19" height="8.86" transform="translate(2.7 -10.34) rotate(7.88)"/>

					{/*  Paintbrush title text  */}
					<defs>
						<style>{".cls_palette_74{font-family:SegoePrint-Bold, Segoe Print;font-weight:700;fill:#fff;font-size:14.39px;}"
							+".cls_palette_75{letter-spacing:-0.02em;}"
							+".cls_palette_76{letter-spacing:0.02em;}"}
						</style>
					</defs>
					<text className="cls_palette_74" transform="translate(174.28 32.43) rotate(7.88) scale(1.18 1)">
						<tspan className="cls_palette_75">Customize colors
						</tspan>
					</text>

					{/*  paintbrush tip paint  */}
					<defs>
						<style>{".cls_palette_77{fill:url(#palette_linear-gradient-20);}"}
						</style>
						<linearGradient id="palette_linear-gradient-20" x1="39.38" y1="9.18" x2="64.18" y2="9.18" gradientUnits="userSpaceOnUse">
							<stop offset="0" stop-color="#253689"/>
							<stop offset="1" stop-color="#253689"/>
						</linearGradient>
					</defs>
					<path className="cls_palette_77" d="M39.38,5.42,48,2.05A44.39,44.39,0,0,1,55.78,4.2a71.78,71.78,0,0,1,8.35,4.13c.93.47-10-2.67-12.94-1.88-3.21.87,12.75,5.44,12.75,5.44s-9.27-.75-9.19.75c.09,1.69,9.19,2.72,9.19,2.72s-12.55,1.31-14.63.84c-1.66-.37-6.37-4.31-6.37-4.31Z"/>

					{/*  gradient stop add : figure & plus sign  */}
					<defs>
						<style>{".cls_palette_AddStopFigure{fill:" + (this.mHoveredArea == hoveredArea.HOVEREDAREA_gradientDisplayArea?"#575756":"none") + ";}"}
						</style>
						<style>{".cls_palette_AddStopPlus{fill:" + (this.mHoveredArea == hoveredArea.HOVEREDAREA_gradientDisplayArea?"#dadada":"none") + ";}"}
						</style>
					</defs>
					<path className="cls_palette_AddStopFigure" d={stopAddingSignPath}/>
					<rect className="cls_palette_AddStopPlus" x="199.82" y={57.86+diff} width="1.11" height="4.66"/>
					<rect className="cls_palette_AddStopPlus" x="198.0" y={59.7+diff} width="4.66" height="1.11"/>

					{/*  Covering layer vs cursor change over text - theme selection & edited region */}
					<defs>
						<style>{".cls_palette_textCover1{fill:white;stroke:none;fill-opacity:0;cursor:"+usedCursor+"}"}
						</style>
					</defs>
					<rect className="cls_palette_textCover1" x="0.0" y="0.0" width="430.0" height="490.0"/>

					{gradientColorArrows}
					
				</svg>

				{/* Select theme to edit dropdown */}
				<PaletteSelect 
					options={themeSelectOptions} 
					setReference={this.onSetReference.bind(this)}
					onSelectItem={this.onSelectThemeItem.bind(this)}
					width={Math.round((this.mSubWindowWidth*0.00215)*360) + "px"}
					height={Math.round((this.mSubWindowWidth*0.00215)*320) + "px"}
					top={Math.round((this.mSubWindowWidth*0.00215)*50) + "px"}
					left={Math.round((this.mSubWindowWidth*0.00215)*60) + "px"}
					itemWidth={(Math.round((this.mSubWindowWidth*0.00215)*360)-2) + "px"}
					itemHeight={Math.round((this.mSubWindowWidth*0.00215)*40) + "px"}
					itemFontSize={Math.round((this.mSubWindowWidth*0.00215)*25) + "px"}
				/>

				{/* Restore theme dropdown */}
				<PaletteSelect 
					options={themeRestoreSelectOptions} 
					setReference={this.onSetRestoreThemeReference.bind(this)}
					onSelectItem={this.onSelectRestoreThemeOption.bind(this)}
					width={Math.round((this.mSubWindowWidth*0.00215)*360) + "px"}
					height={Math.round((this.mSubWindowWidth*0.00215)*320) + "px"}
					top={Math.round((this.mSubWindowWidth*0.00215)*50) + "px"}
					left={Math.round((this.mSubWindowWidth*0.00215)*60) + "px"}
					itemWidth={(Math.round((this.mSubWindowWidth*0.00215)*360)-2) + "px"}
					itemHeight={Math.round((this.mSubWindowWidth*0.00215)*40) + "px"}
					itemFontSize={Math.round((this.mSubWindowWidth*0.00215)*25) + "px"}
				/>
			</div>
        );
	}

	public onSelectRestoreThemeOption(id:string){
		setTimeout(this.postponedActivation.bind(this), 20);

		let equivalent = Theme.getThemeByID(id)

		if(this.mDisplayedTheme && equivalent){
			this.mDisplayedTheme.readFromLoadedTheme(equivalent)
			this.forceUpdate()
		}
		else{
			console.log("onSelectRestoreThemeOption - this.mDisplayedTheme: " + (this.mDisplayedTheme?"inst":null) + "  equivalent: " + (equivalent?"inst":"null") + "  id:" + id)
		}

		/*
		if(this.props.serverOn){  // Restoring theme by server call
			console.log("Palette - option clicked for restoring theme by server call, id: " + id)

			let dispT = this.mDisplayedTheme;
			if(dispT){
				let id = dispT.GetID();
				ThemeManager.loadThemeFromServer(id, ()=>{console.log("Palette: Theme restored successfully from server: " + id)})
			}
		}
		else{   // Restoring theme from local storage
			console.log("Palette - option clicked for restoring theme from local storage, id: " + id)

			let dispT = this.mDisplayedTheme;
			if(dispT){
				let id = dispT.GetID();
				ThemeManager.loadThemeLocally(id, ()=>{console.log("Palette: Theme restored successfully from local storage: " + id)})
			}
		}*/
	}

	public onSetReference(ref:PaletteSelect|null){
		this.mJGSelectRef = ref;

		if(ref === null){
			this.mIsDisplayingThemeSelect = false;
		}
	}

	public onSetRestoreThemeReference(ref:PaletteSelect|null){
		this.mRestoreThemeSelectRef = ref;

		if(ref === null){
			this.mIsDisplayingThemeRestoreSelect = false;
		}
	}

	public onSelectThemeItem(itemID : string){
		let newTheme = Theme.getThemeByID(itemID);
		if(newTheme){
			this.mDisplayedTheme = newTheme;
		}
		
		setTimeout(this.postponedActivation.bind(this), 20);
	}

	private postponedActivation(){
		this.mIsDisplayingThemeSelect = false;
		this.mIsDisplayingThemeRestoreSelect = false;
		this.forceUpdate();
	}

	public isDisplayingThemeSelect(){
		if(this.mJGSelectRef === null){
			this.mIsDisplayingThemeSelect = false;
			return false;
		}
		else{
			if(this.mIsDisplayingThemeSelect === true){
				return true;
			}
			else{
				return false;
			}
		}
	}

	public isDisplayingThemeRestore(){
		if(this.mRestoreThemeSelectRef === null){
			this.mIsDisplayingThemeRestoreSelect = false;
			return false;
		}
		else{
			if(this.mIsDisplayingThemeRestoreSelect === true){
				return true;
			}
			else{
				return false;
			}
		}
	}

	private onClickThemeSelect(){
		if(this.mJGSelectRef){
			this.mJGSelectRef.setVisible(true);
			this.mIsDisplayingThemeSelect = true;
			this.forceUpdate();
		}
	}

	private onClickThemeRestore(){
		if(this.mRestoreThemeSelectRef){
			this.mRestoreThemeSelectRef.setVisible(true);
			this.mIsDisplayingThemeRestoreSelect = true;
			this.forceUpdate();
		}
	}

	private onClickThemeSave(){
		setTimeout(this.postponedActivation.bind(this), 20);

		let equivalent = Theme.getThemeByID("[SAMPLE]Custom")

		if(this.mDisplayedTheme && equivalent){
			equivalent.readFromLoadedTheme(this.mDisplayedTheme)
		}

		if(this.props.serverOn){
			let dispT = this.mDisplayedTheme;
			if(dispT){
				let id = dispT.GetID()
				ThemeManager.saveThemeToServer(id, ()=>{console.log("Palette:onClickTHemeSave - theme was saved to server:" + id)})
			}
		}
		else{
			let dispT = this.mDisplayedTheme;
			if(dispT){
				let id = dispT.GetID()
				ThemeManager.saveThemeLocally("[SAMPLE]Custom")
			}
		}
	}

	public getRegionTitle(){
		switch(this.mSelectedThemeRegion){
			case themeRegion.bgMain: return "Background Main";
			case themeRegion.bgAlt: return "Background Alt";
			case themeRegion.fgMain: return "Foreground Main";
			case themeRegion.fgAlt: return "Foreground Alt";
			case themeRegion.detailMain: return "Detail Main";
			case themeRegion.detailAlt: return "Detail Alt";
			case themeRegion.specular: return "Specular";
			case themeRegion.shadow: return "Shadows";
			default:;
		}
		
		return "unselected";
	}

    getDisplayedTheme(){
        if(this.mDisplayedTheme !== null){
            return this.mDisplayedTheme;
        }
        
        if(this.props.themeID){
			let th = Theme.getThemeByID(this.props.themeID);
			this.mDisplayedTheme = th;

			if(th){
				return th;
			}
        }

        this.mDisplayedTheme = this.getTheme();
        return this.mDisplayedTheme;
    }

    setDisplayedTheme(theme : Theme){
        this.mDisplayedTheme = theme;
        this.forceUpdate();
	}

	public getSelectedRegionGradientCode(){
		if(this.mSelectedThemeRegion === themeRegion.bgMain){
			return "BG";
		}

		if(this.mSelectedThemeRegion === themeRegion.bgAlt){
			return "BG-Alt"; 
		}

		if(this.mSelectedThemeRegion === themeRegion.fgMain){
			return "FG";
		}

		if(this.mSelectedThemeRegion === themeRegion.fgAlt){
			return "FG-Alt";
		}

		if(this.mSelectedThemeRegion === themeRegion.detailMain){
			return "Detail";
		}

		if(this.mSelectedThemeRegion === themeRegion.detailAlt){
			return "Detail-Alt";
		}

		if(this.mSelectedThemeRegion === themeRegion.specular){
			return "Specular";
		}

		if(this.mSelectedThemeRegion === themeRegion.shadow){
			return "Shadow";
		}

		// (just for ensuring the compiler that there's a return value..)
		return "Shadow";		
	}

	private getSelectedRegionGradientStops(){
		return this.getDisplayedTheme().getStops(this.getSelectedRegionGradientCode());
	}
	
	public canTrashSelectedGradientStop(){
		return this.getSelectedRegionGradientStops().length > 1;
	}

	public getUniqueKey(){
		return "key:" + Palette.sUniqueKeyBase++;
	}

	private onGradientStopEvent(stopID:string, event:any){
		if(event.type === "mouseDown"){
			if(this.mSelectedGradientStopID != stopID){
				let stops = this.getTheme().getStops(this.getSelectedRegionGradientCode());
				for(let i=0; i<stops.length; i++){
					if(stops[i].getID() === stopID){
						if(stops[i].getColor().equals(this.getSelectedGradientStop().getColor()) === false){
							this.preserveSwitchedColor(stops[i].getColor());
						}
					}
				}
			}

			this.mSelectedGradientStopID = stopID;
			this.mMouseMode = mouseModes.MOUSEMODE_dragGradientStop;

			this.mMouseGraspY = event.clientY;
			this.mPreGraspY = this.getSelectedGradientStop().getStopPosition();
			this.updateTheme();
		}
	}

	public getSelectedGradientStopID(){
		let stops = this.getSelectedRegionGradientStops();
		let mostCorrectID = stops[0].getID();

		if(this.mSelectedGradientStopID){
			for(let i=0; i<stops.length; i++){
				if(stops[i].getID() === this.mSelectedGradientStopID){
					mostCorrectID = this.mSelectedGradientStopID;
					break;
				}
			}
		}

		this.mSelectedGradientStopID = mostCorrectID;
		return mostCorrectID;
	}

	public getSelectedGradientStop(){
		let stops = this.getSelectedRegionGradientStops();

		if(this.mSelectedGradientStopID){
			for(let i=0; i<stops.length; i++){
				if(stops[i].getID() === this.mSelectedGradientStopID){
					return stops[i];
				}
			}
		}

		return stops[0];
	}

	public debug(sign:string){
		this.mDebugOut = sign;
		this.forceUpdate();
	}
}



/*
	<< paletteMap >>
	width: 434
	height: 354
	scaling offsetX: 332
	scaling offsetY: 176

	background 255, 255, 255
	wood foreground: 29, 29, 27
	color picker area: 134, 124, 185
	rainbow picker area: 198, 105, 40
	trashbin circle: 129, 131, 106

	recent color slot1: 148, 39, 20
	recent color slot2: 138, 74, 107
	recent color slot3: 91, 112, 37
	recent color slot4: 127, 137, 105
	recent color slot5: 40, 88, 164

	red color slider: 98, 13, 5
	green color slider: 53, 72, 40
	blue color slider: 27, 45, 134

	gradient control area: 147, 96, 55
	gradient control items area: 64, 44, 35

	theme selection lister: 112, 194, 177
	closing cross: 176, 163, 151

	scaling device area: 103, 36, 131
	random area: 231, 52, 43
	cansel area: 243, 145, 0
	Save changes area: 250, 178, 51

	(region picker:)
	background primary: 112, 112, 111
	background secondary: 164, 138, 122
	foreground primary: 0, 102, 51
	foreground secondary: 59, 170, 53
	detail primary: 48, 172, 103
	detail secondary: 149, 193, 31
	specular: 252, 235, 19
	shadow: 59, 59, 53


	<div style={{position:"absolute", left:"10px", top:"30px", width:"400px", height:"20px", backgroundColor:"gray", color:"white"}}>
		{"Mouse code:" + this.mLatestMouseCode + " relX:" + this.mLatestMouseRelX + " relY:" + this.mLatestMouseRelY }
	</div>



				<svg viewBox="0 0 435.9 360.01">
				<defs>
					<style>{""
					}</style>

				</defs>



					</defs>                                                   +                ?         ?               +               -          +                                                               +             +
					<path className="cls_palette_AddStopFigure" d="M200.36, 56.81c-2.73,0-5.29,3-5.29,   3    l-16.27.07V58.37l-4.07,1.84L178.8,62V60.57  l16.27-.07s2.1,3.25,5.29,3.25,3.94-2.28,3.94-3.47S203.69,56.81,200.36,56.81Z"/>
					<path className="cls_palette_AddStopFigure" d="M200.36,196.51c-2.73,0-5.29,3.05-5.29,3.05 l-16.27.07v-1.56l-4.07,1.84,4.07,1.82v-1.46 l16.27-.07s2.1,3.25,5.29,3.25,3.94-2.28,3.94-3.47S203.69,196.51,200.36,196.51Z"/>
					stopAddingSignPath


*/