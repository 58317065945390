import * as React from 'react';
import {JGPage} from '../components/JGPage';
import {WordPipe} from '../tools/WordPipe';
import {App} from '../App';

class LanguageChoice extends React.Component<{languageCode:string, displayedText:string, selectionHandler: (languageCode:string)=>void, bgColor:string, fgColor:string}>{
    onClick(){
        this.props.selectionHandler(this.props.languageCode);
    }

    render(){
        let bgImg = "url(https://i.imgur.com/lFSl1VK.jpg)";

        if(this.props.languageCode === "SE"){
            bgImg = "url(https://i.imgur.com/CTgUp8I.jpg)";
        }

        if(this.props.languageCode === "NO"){
            bgImg = "url(https://i.imgur.com/NZUBjER.jpg)";
        }

        let fontSize = 45;
        let textTop = 16;

        if(window.innerWidth < 500){
            fontSize = 31;
            textTop = 25;
        }

        return (
            <div onClick={this.onClick.bind(this)} style={{
                height: "96px",
                width: "76%",
                marginLeft: "12%",
                borderRadius: "9px",
                backgroundColor: this.props.bgColor,
                marginTop: "20px",
                display:"flex",
                flexDirection:"row",
                position:"relative"
            }}>
                {/* Flag area */}
                <div style={{
                    height: "80px",
                    width: "110px",
                    position:"relative",
                    borderRadius: "4px",
                    left:"8px",
                    top:"8px",
                    backgroundImage: bgImg
                }}>
                </div>

                {/* Text area */}
                <div style={{
                    height: "90px",
                    width: "calc(100% - 115px)",
                    position:"absolute",
                    left:"115px",
                    top: textTop + "px",
                    fontSize: fontSize + "px",
                    textAlign:"center",
                    color: this.props.fgColor
                }}>
                    {this.props.displayedText}
                </div>

                {/* click layer */}
                <div style={{
                    width:"100%",
                    height:"100%",
                    position:"absolute",
                    cursor:"pointer"
                }}/>
            </div>
        )
    }
}

export class PageLanguages extends JGPage{

    constructor(props:any){
        super(props)
        this.mComponentType = "PageLanguages";
    }

    setLanguage(languageCode : string){
        WordPipe.setLanguage(languageCode)

        let inst = App.getLatestAppInstance();
        if(inst){
            inst.onMenuChoice("cv");
        }
    }

    componentDidMount(){
        this.getTheme()
        window.addEventListener("resize", this.onResize.bind(this));
    }

    componentWillUnMount(){
        window.removeEventListener("resize", this.onResize.bind(this))
    }

    onResize(){
        this.forceUpdate()
    }

    draw(){
        let marginUpper = (window.innerHeight / 2) - 250
        
        if(marginUpper < 0){
            marginUpper = 0;
        }

        let bg = this.getTheme().getBG(null, 0.7, 0).GetRGB();
        let fg = "white" // this.getTheme().getBG(null, 0.0, 0).GetRGB();
        return (
            <div >
                <div style={{height: marginUpper + "px"}}/>
                <LanguageChoice languageCode="NO" displayedText="NORSK" selectionHandler={this.setLanguage.bind(this)} bgColor={bg} fgColor={fg}/>
                <LanguageChoice languageCode="EN" displayedText="ENGLISH" selectionHandler={this.setLanguage.bind(this)} bgColor={bg} fgColor={fg}/>
                <LanguageChoice languageCode="SE" displayedText="SVENSKA" selectionHandler={this.setLanguage.bind(this)} bgColor={bg} fgColor={fg}/>
            </div>
        )
    }
}