///////  Reading json files /////////

/* Original from bdkalk

// GetFromUrl - loads a string from an url
export function GetFromUrl(url: string) {
    alert("GetFromUrl called!!")
    var Httpreq = new XMLHttpRequest(); // a new request
    Httpreq.open("GET", url, false);

    try {
        Httpreq.send(null);
    }

    catch (e) {
        alert("GetFromUrl() - error: Httprequest failed!  Requested url was: \"" + url + "\"")
    }

    return Httpreq.responseText;
}

// LoadFromUrl - also loads a string, though it won't wait for the result but takes an event handler instead
export function LoadFromUrl(url: string, onloaded: (s: string) => void, token: ClientOAuth2.Token) {
    var Httpreq = new XMLHttpRequest(); // a new request
    Httpreq.open("GET", url, true);
    Httpreq.setRequestHeader("Authorization", "Bearer " + token.accessToken);
    Httpreq.setRequestHeader("Content-Type", "application/json");
    Httpreq.addEventListener("load", () => onloaded(Httpreq.responseText));
    Httpreq.send(null);
}

// Posting json files
export function SaveToUrl(url: string, selectionId:string | null, onSaved: (response: string) => void, token: ClientOAuth2.Token, content:SelectionSetGW) {
    var Httpreq = new XMLHttpRequest(); // a new request
    Httpreq.open("POST", url, true);
    Httpreq.setRequestHeader("Authorization", "Bearer " + token.accessToken);
    Httpreq.setRequestHeader("Content-Type", "application/json");
    Httpreq.addEventListener("load", () => onSaved(Httpreq.responseText));
    var contentSet: { id: string, name: string, meta: string } = { id: "", meta: content.GetMeta(), name: content.GetName() }
    Httpreq.send(JSON.stringify(content.GetSet()));
}
*/

// LoadFromUrl - also loads a string, though it won't wait for the result but takes an event handler instead
export function LoadFromBase(url: string, onloaded: (s: string) => void, token: string) {
    var Httpreq = new XMLHttpRequest(); // a new request
    Httpreq.open("GET", url, true);
    Httpreq.setRequestHeader("Authorization", "Bearer " + token);
    Httpreq.setRequestHeader("Content-Type", "application/json");
    Httpreq.addEventListener("load", () => onloaded(Httpreq.responseText));
    Httpreq.send(null);
}

// Posting json files
export function SaveToUrl(url: string, onSaved: (response: string) => void, token: string, content: any) {
    var Httpreq = new XMLHttpRequest(); // a new request
    Httpreq.open("POST", url, true);
    Httpreq.setRequestHeader("Authorization", "Bearer " + token);
    Httpreq.setRequestHeader("Content-Type", "application/json");
    Httpreq.addEventListener("load", () => onSaved(Httpreq.responseText));
    Httpreq.send(JSON.stringify( content ));
}

export function Upload(content: any, onSaved: (response: string) => void) {
    var Httpreq = new XMLHttpRequest(); // a new request
    Httpreq.open("POST", 'http://localhost:8080', true);
    Httpreq.setRequestHeader("Content-Type", "application/json");
    Httpreq.addEventListener("load", () => onSaved(Httpreq.responseText));
    Httpreq.send(content );
}


/*
function onLoadET(s: string){
    console.log('response from 8080: ' + s)
}

export function ET_call_home(){
    console.log('Calling E.T. phone home...')
    var Httpreq = new XMLHttpRequest(); // a new request
    Httpreq.open("GET", 'http://localhost:8080', true);
    // Httpreq.setRequestHeader("Authorization", "Bearer " + token);
    Httpreq.setRequestHeader("Content-Type", "application/json");
    Httpreq.addEventListener("load", () => onLoadET(Httpreq.responseText));
    Httpreq.send(null);
}

function onLocal(response : string){
    console.log("LocalShop got:" +  response);
}

export function LocalShopForLocalPeople(){
    console.log('... Are you local? ...');
    var Httpreq = new XMLHttpRequest();
    Httpreq.open("GET", 'http://localhost:8080/localshop', true);
    Httpreq.setRequestHeader("Content-Type", "application/json");
    Httpreq.addEventListener("load", () => onLocal(Httpreq.responseText));
    Httpreq.send("we_Don't_Want_No_Trouble_Over_Here");
}*/
