import {LoadFromBase} from './DataTransfer';

export class MouseMap{
    constructor(mapUrl: string){
        this.mLines = new Array<String>();
        this.mReady = false;
        this.mLineCount = 0;
        this.mLineLength = -1;
        LoadFromBase(mapUrl, this.onLoadMap.bind(this), "tokenDummy");
    }

    private onLoadMap(data:string){
        let lines = JSON.parse(data).lines;

        for(let i=0; i<lines.length; i++){
            this.mLines.push(lines[i]);

            this.mLineLength = lines[i].length;
            this.mLineCount++;
        }

        this.mReady = true;
    }

    public getLineCount(){
        return this.mLineCount;
    }

    public getLineLength(){
        return this.mLineLength;
    }

    public isReady(){
        return this.mReady;
    }

    public getPixelCode(x:number,y:number){
        if((x<0)||(x>=this.mLineLength)||(y<0)||(y>=this.mLineCount)||(this.mReady === false)){
            return "?";
        }

        return this.mLines[y].charAt(x) + "";
    }

    public getRelativePositionPixelCode(X_0to1:number, Y_0to1:number){
        if((X_0to1<0)||(X_0to1>1)||(Y_0to1<0)||(Y_0to1>1)||(this.mReady === false)){
            return "?";
        }

        let xPos = Math.round(X_0to1*this.mLineLength);
        let yPos = Math.round(Y_0to1*this.mLineCount);

        return this.getPixelCode(xPos, yPos);
    }

    private mLines : String[];
    private mReady : boolean;
    private mLineCount : number;
    private mLineLength : number;
}