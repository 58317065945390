import {Theme} from "../components/theme";

export class ThemeStylesManager{
    private static hasPrepared:boolean = false;
    private static contentLeftRightMargin = 20;
    private static CVSubTitleSize = 20;
    private static maximumCentralSectionWidth = 1200;
    private static centralSectionWidthFactor = 0.7;
    private static displayModeBreakpointIpad = 699;
    private static displayModeBreakpointDesktop = 949;

    public static getCentralWidth(){
        let boost = 0;
        if(ThemeStylesManager.isIpadWide() === true){
            boost = 160
        }

        return window.innerWidth - ((ThemeStylesManager.getLRMargin()*2)+boost);
    }
    
    public static getLRMargin(){
        let response = ThemeStylesManager.contentLeftRightMargin;

        if(ThemeStylesManager.isIpadWide() === true){
            response = 0;
        }

        if(ThemeStylesManager.isDesktopWide() === true){
            let excessWidth = (window.innerWidth - ThemeStylesManager.maximumCentralSectionWidth) * ThemeStylesManager.centralSectionWidthFactor;

            if(excessWidth > 0){
                response = excessWidth / 2;
            }
        }

        return response;
    }

    public static isIpadWide(){
        if(window.innerWidth > ThemeStylesManager.displayModeBreakpointIpad){
            return true;
        }

        return false;
    }

    public static isDesktopWide(){
        if(window.innerWidth > ThemeStylesManager.displayModeBreakpointDesktop){
            return true;
        }

        return false;
    }

    public static getContentLRMarginBase(){
        return ThemeStylesManager.contentLeftRightMargin;
    }

    public static getMaximumCentralSectionWidth(){
        return ThemeStylesManager.maximumCentralSectionWidth;
    }

    public static getCentralSectionWidthFactor(){
        return ThemeStylesManager.centralSectionWidthFactor;
    }

    public static prepareStyles(){
        if(ThemeStylesManager.hasPrepared === true){
            return;
        }

        ThemeStylesManager.hasPrepared = true;

        // CV Title
        Theme.setThemeStyle("CVTitle", (theme:Theme)=>{
            let margin = ThemeStylesManager.getLRMargin();

            let maxFontSize = 40;
            let size = ((50 + ((window.innerWidth - 500) / 15))*0.65);
            if(size > maxFontSize){
                size = maxFontSize;
            }

            return {
                position:"relative",
                left: margin + "px",
                width: ThemeStylesManager.getCentralWidth() + "px",
                fontSize: size + "px",
                textAlign:"left",
                color: theme.getDetail(null, 1, 0).GetRGB()
            }
        })

        // CV Underline
        Theme.setThemeStyle("CVUnderline", (theme:Theme)=>{
            let margin = ThemeStylesManager.getLRMargin();
            let centralWidth = ThemeStylesManager.getCentralWidth();

            return {
                backgroundColor: theme.getDetail(null, 1, 0).GetRGB(),
                width: ThemeStylesManager.getCentralWidth() + "px",
                height: "2px",
                position:"relative",
                marginTop: "2px",
                left: margin + "px"
            }
        })

        // CV Language title
        Theme.setThemeStyle("CVLanguageTitle", (theme:Theme)=>{
            let margin = ThemeStylesManager.getLRMargin();

            return {
                position:"relative",
                left: margin + "px",
                width: ThemeStylesManager.getCentralWidth() + "px",
                fontSize: 20 + "px",
                textAlign:"left",
                color: theme.getDetail(null, 1, 0).GetRGB()
            }
        })

        // CV Language level
        Theme.setThemeStyle("CVLanguageLevel", (theme:Theme)=>{
            return {
                position:"absolute",
                left: "100px",
                top:"1px",
                fontSize: 20 + "px",
                textAlign:"left",
                color: theme.getDetail(null, 0.3, 0.5).GetRGB()
            }
        })
    }
}