import React from 'react';
import { ThemeComponent } from "./theme";

export interface JGBackgroundProps{
    themeID? : string,
	style?:any,
	className?:string
}

export class JGBackground extends ThemeComponent<JGBackgroundProps,{}>{
    constructor(props:JGBackgroundProps){
        super(props);
    }

    draw(){
        return (
            <div style={{
                zIndex: -99999999, 
                position:"fixed", 
                width:"100%",
                height:"100%",
                top:"0px", 
                left:"0px", 
                backgroundColor: this.getBG(0.3, 0.0).GetRGB()
                }}>
            </div>
        )
    }
}
