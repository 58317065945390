
import * as React from 'react';
import {ThemeComponent, Theme, ColorSample} from './theme';

export interface RelativeSelectMeasures{
    scale:number,
    totalHeight:number,
    totalWidth:number,
    optionHeight:number,
    optionWidtht:number,
    fontSize:number
}

/*
    #####   ####    ####       ###   ####   #####   ###    ###   ##  #
        #  #    #  #          #   #  #   #    #      #    #   #  # # #
        #  #        ###       #   #  ####     #      #    #   #  # # #   
    #   #  #   ##      #  ##  #   #  #        #      #    #   #  # # #   
     ###    ### #  ####   ##   ###   #        #     ###    ###   #  ##  

*/

export interface JGSelectOptionProps{
    scalingRatio? : number,
    relativeItemMeasures? : {width:number, height:number, fontSize:number},
    optionID:any,
    optionText:string,
    ownerRef:JGSelect,
    isFirst:boolean,
    isLast:boolean,
    hoverIndex:number,
    isHoveredVersion : boolean,
    borderColor : string,
    style?:any,
    styleHovered?:any,
    className? : string,
    themeID? : string
}

// JGSelectOption is a help class to JGSelect, for displaying a single option.
class JGSelectOption extends ThemeComponent<JGSelectOptionProps,{}>{
    
    private mOptionID : any;
    private mText : string;
    private mHoverLevel : number;

    private mOptionStyle : any;
    private mOptionStyleHover : any;
    private mStylesUpdated : boolean;

    private mAnimationActive : boolean;
    private mLastAnimationFrameTime : number;

    private mHoverElementRef : React.RefObject<HTMLDivElement>;


    constructor(props:JGSelectOptionProps){
        super(props);

        this.mStylesUpdated = false;
        this.mText = props.optionText;
        this.mOptionID = props.optionID;
        this.mHoverLevel = 0.0;
        this.mAnimationActive = false;
        this.mLastAnimationFrameTime = 0;
        this.mHoverElementRef = React.createRef();
    }

    public isHovering(){
        let hi = this.props.ownerRef.getHoverIndex();
        if(hi === this.props.hoverIndex){
            return true;
        }
        else{
            return false;
        }
    }

    public updateStyles(){
        if(this.mStylesUpdated === false){
            this.mStylesUpdated = true;

            let theme = this.getTheme();

            let styleBasic = this.props.style;
            let styleHovered = this.props.styleHovered;
    
            if((styleBasic === undefined) || (styleBasic === null)){
                styleBasic = {};
            }
    
            if((styleHovered === undefined) || (styleHovered === null)){
                styleHovered = {};
            }
    
            // opacity
            styleHovered.opacity = this.mHoverLevel;
    
            // positioning
            styleBasic.position = styleBasic.position ? styleBasic.position : "absolute";
            styleHovered.position = styleBasic.position ? styleBasic.position : "absolute";
            styleBasic.top = styleBasic.top ? styleBasic.top : "0px";
            styleHovered.top = styleHovered.top ? styleHovered.top : "0px";
            styleBasic.left = styleBasic.left ? styleBasic.left : "0px";
            styleHovered.left = styleHovered.left ? styleHovered.left : "0px";
    
            // cursor
            styleBasic.cursor = styleBasic.cursor ? styleBasic.cursor : "pointer";
            styleHovered.cursor = styleHovered.cursor ? styleHovered.cursor : "pointer";
    
            // text align
            styleBasic.textAlign = styleBasic.textAlign ? styleBasic.textAlign : "center";
            styleHovered.textAlign = styleHovered.textAlign ? styleHovered.textAlign : "center";
    
            // Item height
            styleBasic.height = styleBasic.height ? styleBasic.height : "55px";
            styleHovered.height = styleHovered.height ? styleHovered.height : "55px";

            // Item width
            styleBasic.width = styleBasic.width ? styleBasic.width : "calc(100% - 2px)";
            styleHovered.width = styleHovered.width ? styleHovered.width : "calc(100% - 2px)";

            // font size
            styleBasic.fontSize = styleBasic.fontSize ? styleBasic.fontSize : "45px";
            styleHovered.fontSize = styleHovered.fontSize ? styleHovered.fontSize : "45px";

            // background color
            let baseBG = theme.getFG(null, 0.5, 0);
            let BGGlow = theme.getFG(null, 0.5, 1);
            let glowRed = (BGGlow.GetRed() * 0.24) + baseBG.GetRed() + 5;
            let glowGreen = (BGGlow.GetGreen() * 0.24) + baseBG.GetGreen() + 5;
            let glowBlue = (BGGlow.GetBlue() * 0.24) + baseBG.GetBlue() + 5;

            if(glowRed > 255){
                glowRed = 255;
            }

            if(glowGreen > 255){
                glowGreen = 255;
            }

            if(glowBlue > 255){
                glowBlue = 255;
            }

            let glowColor = new ColorSample(null, glowRed, glowGreen, glowBlue);

            styleBasic.backgroundColor = styleBasic.backgroundColor ? styleBasic.backgroundColor : baseBG.GetRGB();
            styleHovered.backgroundColor = styleHovered.backgroundColor ? styleHovered.backgroundColor : glowColor.GetRGB();

            // text color
            styleBasic.color = styleBasic.color ? styleBasic.color : theme.getDetail(null, 0.5, 0).GetRGB();
            styleHovered.color = styleHovered.color ? styleHovered.color : theme.getDetail(null, 0.5, 1).GetRGB();

            // borders
            let borderSpec = "1px solid " + (this.props.borderColor ? this.props.borderColor : theme.getFG(null, 0, 0).GetRGB());

            if(this.props.isLast === true){
                styleBasic.borderBottom = styleBasic.borderBottom ? styleBasic.borderBottom : borderSpec;
                styleHovered.borderBottom = styleHovered.borderBottom ? styleHovered.borderBottom : borderSpec;
            }

            styleBasic.borderTop = styleBasic.borderTop ? styleBasic.borderTop : borderSpec;
            styleHovered.borderTop = styleHovered.borderTop ? styleHovered.borderTop : borderSpec;
            styleBasic.borderRight = styleBasic.borderRight ? styleBasic.borderRight : borderSpec;
            styleHovered.borderRight = styleHovered.borderRight ? styleHovered.borderRight : borderSpec;
            styleBasic.borderLeft = styleBasic.borderLeft ? styleBasic.borderLeft : borderSpec;
            styleHovered.borderLeft = styleHovered.borderLeft ? styleHovered.borderLeft : borderSpec;

            this.mOptionStyle = styleBasic;
            this.mOptionStyleHover = styleHovered;
        }
        else{
            this.mOptionStyleHover.opacity = this.mHoverLevel;
        }
    }
 
    public getOptionID(){
        return this.mOptionID;
    }

    public getText(){
        return this.mText;
    }

    public mMouseIn(){
        this.props.ownerRef.setHoverIndex(this.props.hoverIndex);
    }

    public mMouseOut(){
        if(this.props.ownerRef.getHoverIndex() === this.props.hoverIndex){
            this.props.ownerRef.setHoverIndex(-1);
        }
    }

    componentWillUnmount(){
        super.componentWillUnmount();
        this.mAnimationActive = false;
    }

    componentDidMount(){
        super.componentDidMount();
        this.startHoverAnimation();
    }

    public startHoverAnimation(){
        this.mAnimationActive = true;
        requestAnimationFrame(this.animateHover.bind(this));
    }

    public stopHoverAnimation(){
        this.mAnimationActive = false;
    }

    public animateHover(time : number){
        
        if(this.mAnimationActive === true){
            requestAnimationFrame(this.animateHover.bind(this))
        }
        else{
            return;
        }

        let lastFrame = this.mLastAnimationFrameTime;
        this.mLastAnimationFrameTime = time;
        let timeElapsed = time - lastFrame;

        let animationSpeed = 0.01;
        let advancementFactor = 1.0;
        let reversionFactor = 0.15;

        const previousFactor = this.mHoverLevel;

        if(this.isHovering() === true){
            this.mHoverLevel += (timeElapsed * animationSpeed * advancementFactor);

            if(this.mHoverLevel >= 1.0){
                this.mHoverLevel = 1.0;
            }
        }
        else{
            this.mHoverLevel -= (timeElapsed * animationSpeed * reversionFactor);

            if(this.mHoverLevel <= 0.0){
                this.mHoverLevel = 0.0;
            }
        }

        if(this.mHoverLevel !== previousFactor){
            this.updateHoverOpacity();
        }
    }

    updateHoverOpacity(){
        let ref = this.mHoverElementRef.current;
        if(ref){
            ref.style.opacity = this.mHoverLevel + "";

            // console.log("Updating Select option's opacity. mHoverLevel:" + this.mHoverLevel );
        }
        else{
            // console.log("Updating Select option's opacity but having no ref!" );
        }
    }

    public onClickItem(){
        this.props.ownerRef.onItemClick(this.props.optionID);
    }

    draw(){
        this.updateStyles();

        let itemHeight = this.mOptionStyle.height;
        let textAdjustment = itemHeight / -13

        return (
            <div
            onMouseEnter={this.mMouseIn.bind(this)}
            onMouseLeave={this.mMouseOut.bind(this)}
            onClick={this.onClickItem.bind(this)}
            style={{
                position:"relative",
                top:"0px",
                left:"0px",
                width:"100%",
                height: itemHeight
            }}>
                {/* Basic */}
                <div style={
                    this.mOptionStyle
                }>
                    <div style={{position:"relative", top: textAdjustment + "px", width:"100%"}}>
                        {this.props.optionText}
                    </div>
                </div>

                {/* Hovered */}
                <div 
                style={this.mOptionStyleHover}
                ref={this.mHoverElementRef}>
                    <div style={{position:"relative", top: textAdjustment + "px", width:"100%"}}>
                        {this.props.optionText}
                    </div>
                </div>
            </div>
        )
    }

}


/*
    #####   ####    ####  #####  #      #####   ####  #####   
        #  #    #  #      #      #      #      #        #    
        #  #        ###   ###    #      ###    #        #    
    #   #  #   ##      #  #      #      #      #        #   
     ###    ### #  ####   #####  #####  #####   ####    #  

*/


export interface JGSelectProps{
    options : Array< {optionID:any, optionText:string} >;
    setReference : (ref:JGSelect|null)=>void;
    onSelectItem : (itemID : any)=>void;
    onMouseLeave? : ()=>void;
    style ? : any;
    borderColor? : string,
    itemStyle ? : any;
    itemHoverStyle ? : any;
    className? : string;
    itemClassName? : string;
    themeID? : string;
    visible? : boolean;
    scalingRatio? : number;
    relativeMeasures? : {top:number, left:number, width:number, height:number};
    relativeItemMeasures? : {width:number,height:number, fontSize:number};
}

export interface JGSelectStates{
}

export class JGSelect extends ThemeComponent<JGSelectProps,JGSelectStates>{
    private mVisible : boolean;
    private mHoverIndex : number;

    constructor(props:JGSelectProps){
        super(props)

        this.mHoverIndex = -1;

        if(props.visible){
            this.mVisible = true;
        }
        else{
            this.mVisible = false;
        }

        props.setReference(this);

        this.setStyleDefault({
            position: "absolute",
            top: "0px",
            left: "0px",
            width: "250px",
            height: "250px",
            overflowY: "auto",
            overflowX: "hidden"
        });
    }

    public getHoverIndex(){
        return this.mHoverIndex;
    }

    public setHoverIndex(index : number){
        this.mHoverIndex = index;
    }

    public setVisible(visible : boolean){
        this.mVisible = visible;
    }

    public onItemClick(itemID : string){
        this.mVisible = false;
        this.props.onSelectItem(itemID);
        this.forceUpdate();
    }

    componentWillUnmount(){
        this.props.setReference(null);
    }

    draw(){

        if(this.mVisible === false){
            return (
                <div></div>
            );
        }

        let theme = this.getTheme();

        // Preparing options
        let items = new Array<JSX.Element>();
        for(let i=0; i<this.props.options.length; i++){
            const index = i;
            items.push(<JGSelectOption 
                borderColor={this.props.borderColor ? this.props.borderColor : theme.getFG(null,0,0).GetRGB()}
                scalingRatio={this.props.scalingRatio}
                relativeItemMeasures={this.props.relativeItemMeasures}
                key={"key:"+i} 
                optionID={this.props.options[i].optionID} 
                optionText={this.props.options[i].optionText} 
                ownerRef={this}
                isFirst={(i==0)?true:false}
                isLast={(i==(this.props.options.length-1))?true:false}
                style={this.props.itemStyle}
                styleHovered={this.props.itemHoverStyle}
                className={this.props.itemClassName}
                themeID={theme.GetID()}
                isHoveredVersion={false}
                hoverIndex={index}
            >
            </JGSelectOption>
            )
        }

        if(this.props.scalingRatio && this.props.relativeMeasures){
            let scale = this.props.scalingRatio;
            let selectWidth = Math.round(this.props.relativeMeasures.width * scale) + "px";
            let selectHeight = Math.round(this.props.relativeMeasures.height * scale) + "px";
            let selectTop = Math.round(this.props.relativeMeasures.top * scale) + "px";
            let selectLeft = Math.round(this.props.relativeMeasures.left * scale) + "px";

            this.setStyle({
                width: selectWidth,
                height: selectHeight,
                top: selectTop,
                left: selectLeft
            });
        }
        
        return (
            <div style={this.getStyle()} onMouseLeave={this.props.onMouseLeave}>
                {items}
            </div>
        );
    }
}