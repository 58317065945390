import * as React from 'react';
import {Theme, ThemeComponent, ColorSample} from './theme';

// JGPage only displays its contents if being selected by its containing JGPageBundle

/*
    #####   ####   ####    ###    ###   ##### 
        #  #    #  #   #  #   #  #   #  #     
        #  #       ####   #####  #      ###  
    #   #  #   ##  #      #   #  #  ##  #     
     ###    ### #  #      #   #   ####  ##### 
*/

export interface JGPageProps{
    pageID: string;
    backRef?: (ref:JGPage)=>void;
    themeID?:string;
    style?:any;
    className?:string;
}

export interface JGPageStates{

}

export class JGPage extends ThemeComponent<JGPageProps,JGPageStates>{
    private mBundleRef : null | JGPageBundle;
    private mBundleID : null | string;
    private mHasTriedFindingBundle : boolean;

    private static sBundleHandles : Array<{id:string, bundle:JGPageBundle|null}> = new Array<{id:string, bundle:JGPageBundle|null}>();

    constructor(props:JGPageProps){
        super(props);

        this.mBundleRef = null;
        this.mBundleID = null;
        this.mHasTriedFindingBundle = false;
        this.mComponentType = "JGPage";

        if(this.props.backRef){
            this.props.backRef(this);
        }
    }

    private getBundle(){
        if(this.mBundleRef){
            return this.mBundleRef;
        }
        
        let domNode : any = this.getRef().current;
        
        while(domNode !== null){
            const readId : string = domNode.id;

            if(readId){
                let compareStr = 'PageBundleID_'; // PageBundleID_mainPageArea
                let looksGood = true;

                for(let i=0; i<compareStr.length; i++){
                    if(i<readId.length){
                        if(readId.charAt(i) !== compareStr.charAt(i)){
                            looksGood = false;
                            break;
                        }
                    }
                    else{
                        looksGood = false;
                        break;
                    }
                }

                if(looksGood === true){

                    let foundId = "";
                    for(let i=compareStr.length; i<readId.length; i++){
                        foundId += readId.charAt(i);
                    }

                    this.mBundleRef = JGPage.getBundleByID(foundId);

                    if(this.mBundleRef){
                        this.mBundleID = foundId;
                        this.mBundleRef.addPageRef(this);
                        return this.mBundleRef;
                    }
                }
            }

            domNode = domNode.parentNode 
        }

        return null;
    }

    componentWillUnmount(){
        if(this.mBundleRef){
            this.mBundleRef.clearPageRef(this);
        }
    }

    public static setPage(bundleID:string, pageID:string){
        let bundle = JGPage.getBundleByID(bundleID);

        if(bundle){
            bundle.setPage(pageID);
        }
    }

    public static updateBundleRef(bundleID:string, bundleRef:JGPageBundle){
        for(let h=0; h<JGPage.sBundleHandles.length; h++){
            if(JGPage.sBundleHandles[h].id === bundleID){
                JGPage.sBundleHandles[h].bundle = bundleRef;
                return;
            }
        }

        JGPage.sBundleHandles.push({id:bundleID, bundle:bundleRef});
    }

    public static getBundleByID(bundleID:string){

        for(let h=0; h<JGPage.sBundleHandles.length; h++){
            if(JGPage.sBundleHandles[h].id === bundleID){
                return JGPage.sBundleHandles[h].bundle;
            }
        }

        return null;
    }

    public static clearBundleInstance(bundleID:string, instanceID:string){
        for(let h=0; h<JGPage.sBundleHandles.length; h++){
            if(JGPage.sBundleHandles[h].id === bundleID){
                let b = JGPage.sBundleHandles[h].bundle;
                if(b){
                    if(b.getInstanceID() === instanceID){
                        JGPage.sBundleHandles[h].bundle = null;
                    }
                }

                return;
            }
        }
    }

    isReadyToDraw(){
        if( super.isReadyToDraw() ){
            if(this.getBundle()){
                return true;
            }
        }

        return false;
    }

    render(){
        if(this.isReadyToDraw() === true){
            let bundle = this.getBundle();

            let className : any = this.getClassName();
            let finalStyle = this.getStyle();

            this.ensureEnlistedForThemeRefreshCalls();

            if(bundle){
                if(this.props.pageID === bundle.getSelectedPageID()){
                    return (
                        <div ref={this.getRef()} style={finalStyle} className={className}>
                            {this.draw()}
                        </div>
                    )
                }
            }
        }

        return <div ref={this.getRef()}/>
    }
}

/*
    #####   ####   ####    ###    ###   #####  ####   #   #  #   #  ####   #      #####
        #  #    #  #   #  #   #  #   #  #      #   #  #   #  ##  #  #   #  #      #
        #  #       ####   #####  #      ###    ####   #   #  # # #  #   #  #      ###
    #   #  #   ##  #      #   #  #  ##  #      #   #  #   #  #  ##  #   #  #      #
     ###    ### #  #      #   #   ####  #####  ####    ###   #   #  ####   #####  #####

*/

export interface JGPageBundleProps{
    bundleID:string;
    initialPage?: string;
    style?: any;
    className?: string;
    themeID?:string;
    onPageChange?: (newPage:string)=>void;
    backRef?: (ref:JGPageBundle)=>void;
}

export interface JGPageBundleStates{
    displayedPage:string
}

export class JGPageBundle extends ThemeComponent<JGPageBundleProps,JGPageBundleStates>{
    private mBundleInstanceID: string;
    private mPages : Array<{pageID:string, ref:JGPage|null}>;

    constructor(props:JGPageBundleProps){
        super(props);

        this.mBundleInstanceID = "instance:" + Math.round(Math.random()*100000000);
        this.mPages = new Array<{pageID:string, ref:JGPage|null}>();

        if(this.props.backRef){
            this.props.backRef(this);
        }

        JGPage.updateBundleRef(this.props.bundleID,this);

        if(this.props.initialPage){
            this.state.displayedPage = this.props.initialPage;
        }

        window.addEventListener("resize", this.onResize.bind(this));
    }

    state={
        displayedPage:"no_page_selected_yet"
    }

    public getBundleID(){
        return this.props.bundleID;
    }

    public getInstanceID(){
        return this.mBundleInstanceID;
    }

    public addPageRef(ref:JGPage){
        for(let i=0; i<this.mPages.length; i++){
            if(this.mPages[i].pageID === ref.props.pageID){
                this.mPages[i].ref = ref;
                return;
            }
        }

        this.mPages.push({pageID:ref.props.pageID, ref:ref});
    }

    public clearPageRef(ref:JGPage){
        for(let i=0; i<this.mPages.length; i++){
            if(this.mPages[i].pageID === ref.props.pageID){
                this.mPages[i].ref = null;
                return;
            }
        }
    }

    public setPage(pageID:string){
        this.setState({displayedPage:pageID});

        for(let i=0; i<this.mPages.length; i++){
            let page = this.mPages[i].ref;
            if(page){
                page.forceUpdate();
            }
        }

        if(this.props.onPageChange){
            this.props.onPageChange(pageID);
        }
    }

    public getSelectedPageID(){
        return this.state.displayedPage;
    }

    componentWillUnmount(){
        JGPage.clearBundleInstance(this.props.bundleID, this.mBundleInstanceID);
        window.removeEventListener("resize", this.onResize.bind(this));
    }

    onResize(){
        this.forceUpdate();
    }

    render(){
        if(this.isReadyToDraw() === true){
            let className : any = this.getClassName();
            let finalStyle = this.getStyle();

            this.ensureEnlistedForThemeRefreshCalls();

            return (
                <div style={finalStyle} className={className} id={"PageBundleID_" + this.props.bundleID}>
                    {this.draw()}
                </div>
            );
        }
        
        return <div ref={this.getRef()}/>
    }

    
}