import * as React from 'react';
import { ThemeStylesManager } from '../data/themeStyles';

export interface PhotoSliderProps{
    images:{
        imageUrl:string, 
        imageWidth:number, 
        imageHeight:number
    }[],
    showingTimeMS?:number,
    shiftingTimeMS?:number,
    refreshRateMS?:number
}

export class PhotoSlider extends React.Component<PhotoSliderProps>{
    private mInitialTime: number;
    private mStarted: boolean;

    constructor(props:PhotoSliderProps){
        super(props)
        this.mInitialTime = new Date().valueOf();
        this.mStarted = false;
    }

    componentDidMount(){
        this.mStarted = true;
        setTimeout(this.updateBeat.bind(this), 0);
    }

    componentWillUnmount(){
        this.mStarted = false;
    }

    updateBeat(){
        if(this.mStarted === true){
            setTimeout(this.updateBeat.bind(this), this.props.refreshRateMS ? this.props.refreshRateMS : 35);
            this.forceUpdate();
        }
    }

    render(){
        let imageWidth = ThemeStylesManager.getCentralWidth();

        let slides = new Array<{
            imageHeight:number,
            imageUrl:string
        }>();

        let highestImageHeight = 0;

        for(let i=0; i<this.props.images.length; i++){
            let imgHeight = imageWidth * this.props.images[i].imageHeight / this.props.images[i].imageWidth;

            if(imgHeight > highestImageHeight){
                highestImageHeight = imgHeight;
            }

            slides.push(
                {
                    imageHeight: imgHeight, 
                    imageUrl: this.props.images[i].imageUrl
                }
            )
        }

        let now = new Date();
        let exact = now.valueOf() - this.mInitialTime;

        let showTime = this.props.showingTimeMS ? this.props.showingTimeMS : 5500
        if(showTime < 0){
            showTime = 0;
        }

        let shiftTime = this.props.shiftingTimeMS ? this.props.shiftingTimeMS : 1500
        if(shiftTime < 0){
            shiftTime = 0;
        }
        
        let totalShowTime = showTime + shiftTime;

        let phase = ((exact / totalShowTime) - ((exact / totalShowTime)%1))% this.props.images.length;

        let nextOpacity = 0;
        let progress = exact % totalShowTime;
        if(progress > showTime){
            nextOpacity = (totalShowTime - progress) / shiftTime
            nextOpacity = 1.0 - nextOpacity;
        }

        return(
            <div style={{
                position:'relative', 
                left: (ThemeStylesManager.getLRMargin() + 'px'), 
                width: imageWidth + 'px',
                height: highestImageHeight + "px",
                marginTop:'10px'
            }}>
                {/* slide next in line (just for loading the image in advance)*/}
                <div style={{
                    position:'absolute', 
                    left: '0px',
                    top: '0px',
                    opacity: 0.00000001,
                    width: '1px',
                    height: "1px",
                    backgroundImage: slides[(phase+2) % this.props.images.length].imageUrl
                }}/>

                {/* Current slide A */}
                <div style={{
                    position:'absolute', 
                    left: '0px',
                    top: '0px',
                    opacity: 1.0,
                    width: imageWidth + 'px',
                    height: slides[phase].imageHeight + "px",
                    backgroundImage: slides[phase].imageUrl,
                    backgroundSize: "100% 100%",
                    backgroundRepeat: "no-repeat"
                }}/>
            
                {/* Current slide B */}
                <div style={{
                    position:'absolute', 
                    left: '0px',
                    top: '0px',
                    opacity: nextOpacity,
                    width: imageWidth + 'px',
                    height: slides[(phase+1) % this.props.images.length].imageHeight + "px",
                    backgroundImage: slides[(phase+1) % this.props.images.length].imageUrl,
                    backgroundSize: "100% 100%",
                    backgroundRepeat: "no-repeat"
                }}/>
            </div>
        )
    }
}
