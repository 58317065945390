import { WordPipe, LanguageBook } from "../tools/WordPipe";

export function initiateLanguages(){

    let langNO = WordPipe.getLanguageBook("NO");
    let langSE = WordPipe.getLanguageBook("SE");
    let langEN = WordPipe.getLanguageBook("EN");

    // -------------  JGHeader menu  ----------------

    langNO.add("Header_CV","CV");
    langSE.add("Header_CV","CV");
    langEN.add("Header_CV","CV");

    langNO.add("Header_Portfolio","Portfolio");
    langSE.add("Header_Portfolio","Portfolio");
    langEN.add("Header_Portfolio","Portfolio");

    langNO.add("Header_Contact","Kontakt");
    langSE.add("Header_Contact","Kontakt");
    langEN.add("Header_Contact","Contact");

    langNO.add("Header_WebGL","WebGL");
    langSE.add("Header_WebGL","WebGL");
    langEN.add("Header_WebGL","WebGL");

    // -------------  Contact Page  ----------------

    langNO.add("Contact_Title","Kontakt");
    langSE.add("Contact_Title","Kontakt");
    langEN.add("Contact_Title","Contact");

    // -------------  CV Page  ----------------

    langNO.add("CV_summaryTitle","Sammendrag:");
    langSE.add("CV_summaryTitle","Sammanfattning:");
    langEN.add("CV_summaryTitle","Summary:");

    langNO.add("CV_summaryContent","Johan har sammenlagt 5 år IT-relatert høyskole utdannelse, 4 år profesjonell erfaring som front end utvikler og meget goda resultat fra tidligere prosjekter. Han mestrer løsningsorientert programmering og skriver ren, objekt orientert og strukturert kode som er lett til å forvalte og som løser problemer på alle nivåer av kompleksitet.");
    langSE.add("CV_summaryContent","Johan har sammanlagt 5 års IT-relaterad högskole utbildning, 4 års professionell erfarenhet som front end utvecklare och mycket goda resultat från tidigare projekt. Han bemästrar lösningsorienterad programmering och skriver ren, objekt orienterad och strukturerad kod som är lätt att underhålla och som löser problem på alla nivåer av komplexitet.");
    langEN.add("CV_summaryContent","Johan has a sum of 5 years from IT related highschool education, 4 years of professional experience as a front end developer and has got a record of great results from earlier projects. He produces structured, object oriented and well documented code which is easy to maintain and which solves problems at all levels of complexity.");

    langNO.add("CV_professionLine1","Full stack utvikler");
    langSE.add("CV_professionLine1","Full stack utvecklare");
    langEN.add("CV_professionLine1","Full stack developer");

    langNO.add("CV_professionLine2","& Grafisk Designer");
    langSE.add("CV_professionLine2","& Grafisk Designer");
    langEN.add("CV_professionLine2","& Graphics Designer");

    langNO.add("CV_mainSkillsTitle","Nøkkelkvalifikasjoner");
    langSE.add("CV_mainSkillsTitle","Specialiteter");
    langEN.add("CV_mainSkillsTitle","Main Skills");

    langNO.add("CV_relatedSkillsTitle","Relaterte kompetanser");
    langSE.add("CV_relatedSkillsTitle","Relaterade kunskaper");
    langEN.add("CV_relatedSkillsTitle","Related knowledge");

    langNO.add("CV_relatedSkillsContent","C++, Kotlin, Git, MySQL, Accessability, Redux, Testing, Unit testing, Jest, Objekt orientert programmering, VS, VS Code, CI, CD, Scrum, Slack, Jira, Smidige utviklingsmetoder, Responsiv Design, 3D Design, 3D Modellering, 3D Animasjon og rigging, 3D Texturering, Spill design, Film produksjion og film klipping, TV Reklame design, Affærs og markeds strategi.");
    langSE.add("CV_relatedSkillsContent","C++, Kotlin, Git, MySQL, Accessability, Redux, Testning, Modul-testning, Jest, Objekt orienterad programmering, VS, VS Code, CI, CD, Scrum, Slack, Jira, Agila metoder, Responsiv Design, 3D Design, 3D Modellering, 3D Animation och riggning, 3D Texturering, Spel Design, Film produktion and klippning, TV reklam design, Affärs och marknadsförnings strategi.");
    langEN.add("CV_relatedSkillsContent","C++, Kotlin, Git, MySQL, Accessability, Redux, Testing, Unit testing, Jest, Object oriented programming, VS, VS Code, CI, CD, Scrum, Slack, Jira, Agile Methods, Responsive Design, 3D Design, 3D Modeling, 3D Animation and rigging, 3D Texturing, Game Design, Film production and clipping, TV Commercial design, Business and marketing strategy.");

    langNO.add("CV_personalityTitle","Personlige Egenskaper");
    langSE.add("CV_personalityTitle","Personliga Egenskaper");
    langEN.add("CV_personalityTitle","Personality");

    // --

    langNO.add("CV_personality1","Engasjert");
    langNO.add("CV_personality2","Omgjengelig");
    langNO.add("CV_personality3","Nøyaktig");
    langNO.add("CV_personality4","Kreativ");
    langNO.add("CV_personality5","Innovativ");

    langSE.add("CV_personality1","Engagerad");
    langSE.add("CV_personality2","Social");
    langSE.add("CV_personality3","Noggrann");
    langSE.add("CV_personality4","Kreativ");
    langSE.add("CV_personality5","Innovativ");

    langEN.add("CV_personality1","Enthusiastic");
    langEN.add("CV_personality2","Sociable");
    langEN.add("CV_personality3","Focused");
    langEN.add("CV_personality4","Creative");
    langEN.add("CV_personality5","Innovative");

    // --

    // {WordPipe.read("CV_summaryContent")}

    langNO.add("CV_projectsTitle","Prosjekterfaring");
    langSE.add("CV_projectsTitle","Projekterfarenhet");
    langEN.add("CV_projectsTitle","Project Experience");

    langNO.add("CV_jobsTitle","Arbeidserfaring");
    langSE.add("CV_jobsTitle","Arbetserfarenhet");
    langEN.add("CV_jobsTitle","Working Experience");

    langNO.add("CV_exclusionComment","( Flere mindre relevante stillinger er utelatt )");
    langSE.add("CV_exclusionComment","( Endast relevanta jobb har tagits med )");
    langEN.add("CV_exclusionComment","( Only the most relevant experiences are shown )");

    langNO.add("CV_SchoolsTitle","Utdannelser");
    langSE.add("CV_SchoolsTitle","Utbildningar");
    langEN.add("CV_SchoolsTitle","Education");

    // --

    langNO.add("CV_languageTitle","Språk");
    langSE.add("CV_languageTitle","Språk");
    langEN.add("CV_languageTitle","Languages");

    langNO.add("CV_swedishTitle","Svensk:");
    langSE.add("CV_swedishTitle","Svenska:");
    langEN.add("CV_swedishTitle","Swedish:");

    langNO.add("CV_swedishLevel","Morsmål");
    langSE.add("CV_swedishLevel","Modersmål");
    langEN.add("CV_swedishLevel","Primary");

    langNO.add("CV_englishTitle","Engelsk:");
    langSE.add("CV_englishTitle","Engelska:");
    langEN.add("CV_englishTitle","English:");

    langNO.add("CV_englishLevel","Flytende");
    langSE.add("CV_englishLevel","Flytande");
    langEN.add("CV_englishLevel","Fluent");

    langNO.add("CV_spanishTitle","Spansk:");
    langSE.add("CV_spanishTitle","Spanska:");
    langEN.add("CV_spanishTitle","Spanish:");

    langNO.add("CV_spanishLevel","Grunnleggende");
    langSE.add("CV_spanishLevel","Grundläggande");
    langEN.add("CV_spanishLevel","Basic");

    // ---------- Portfolio ---------------

    langNO.add("Portfolio_PAContent","Prisassistenten er et kraftfullt verktøy for å finne bygningsrelaterte varer på nettet, vise utvidet vareinformasjon og utføre bestillinger. Verktøyet har en innbygget søkefunksjon med høyoptimert visning av søkeresultat og kategorisk visning av varer. Brukeren kan lagre og gjennbruke bestillinger, lagre favoritter, organisere så kalt bygningsdeler og mye mer. Prisassistenten krever innlogging for å brukes, ellers demonstrerer Johan gjærne systemets mange funksjoner ved oppmøte.");
    langSE.add("Portfolio_PAContent","Prisassistenten är ett kraftfullt verktyg för att finna byggrelaterade varor på nätet, visa detaljerad information och utföra beställningar. Programmet har en innbyggd sökfunktion med högoptimerad visning av sökresultat och kategorisk visning av varor. Användaren kan spara och återanvända beställningar, spara favoriter, organisera så kallade byggdelar och mycket mer. Prisassistenten kräver inloggning för att användas, annars demonstrerar Johan gärna systemets funktioner vid ett möte.");
    langEN.add("Portfolio_PAContent","Prisassistenten is a powerful tool for finding building related products on the web, showing detailed information and performing orders. The program has a built in search engine with highly optimized display of search results and cathegoric display of products. The user can store and repeat earlier orders, mark favorites, organize so called build parts and much more. Prisassistenten require an account to be used, but Johan can also demonstrate the program and its many functionalities upon a meeting.");

    langNO.add("Portfolio_PATitle","Prisassistenten");
    langSE.add("Portfolio_PATitle","Prisassistenten");
    langEN.add("Portfolio_PATitle","Prisassistenten");

    langNO.add("Portfolio_PAShort","Her ser du Prisassistenten i bruk.");
    langSE.add("Portfolio_PAShort","Här ser du bilder av Prisassistenten.");
    langEN.add("Portfolio_PAShort","Here you can see Prisassistenten in use.");

    langNO.add("Portfolio_MKContent","JGMedia Kit er en samling verktøy for webbside utvikling som innholder en fleksibel og kjapp server arkitektur der sikkerhet har høy prioritet. På klient sidan finnes et komponent bibliotek basert på React.js, der et ferg tema i etterkant og uten forkunskaper kan endres på i runtime av brukeren, ved hjelp av et palettformet skaler- og flyttbart vindue med verktøy nok for å gi omtrent densamme presisjonen som i for eksempel Photoshop, og der alle endringer kan ses direkte i web browsern samtidig som de utføres. Alle komponentene i JGMedia Kit er skaperbare, følger det ferge tema som du velger og kan kjøres på alle plattformer.");
    langSE.add("Portfolio_MKContent","JGMedia Kit är en samling verktyg för webbsideutveckling som innehåller en flexibel och snabb server arkitektur där säkerhet har hög prioritet. På klientsidan finns ett komponent bibliotek baserat på React.js, där ett färgtema kan ändras på under körtid utan att det behövs några förkunskaper, med hjälp av ett palettformat skaler- och flyttbart fönster med verktyg nog för att ge samma precision som i t.ex. Photoshop, och där alla ändringar kan ses direkt i webb browsern samtidigt som de utförs. Alla komponenterna i JGMedia Kit är skalerbara, fölger det färgtema som du välger och kan köras på alla plattformar.");
    langEN.add("Portfolio_MKContent","JGMedia Kit is a collection of tools for web development. It contains a flexible and swift server architecture where security has a high priority. For the front end there's a component library based on React.js, where a color theme can be edited during runtime, without any need of development skills. The tool is a palette shaped and movable window with enough controls to provide about the same precision as when using for example Photoshop, and where all changes can be seen directly in the web browser as they are performed. All the components in JGMedia Kit are scalable, follows color themes and run well on all platforms.");

    langNO.add("Portfolio_MKTryout","Hvis du ønsker å prøve denne fergkontrollen fra JGMedia Kit og har adgang til mus og tastatur - tast da 'd' og deretter 'p' for å åpne den opp. Hvis du bruker safari - tast da istedet 'd' og deretter 'm'.");
    langSE.add("Portfolio_MKTryout","Om du vill testa denna färgkontrollen från JGMedia Kit och har tillgång till mus och tangentbord, tryck då 'd' och därefter 'p' för att öppna kontrollen. Om du kör Safari - tryck då istället 'd' och därefter 'm'.");
    langEN.add("Portfolio_MKTryout","If you wish to try this color control from JGMedia Kit and have access to mouse and keyboard, you can press 'd' and then 'p' to open it up. If you are running on Safari, then instead press 'd' and 'm'.");

    langNO.add("Portfolio_MKInstructions","Instruksjoner: Paletten er et vindue som du kan flytte rundt ved å dra i bakgrunnen med musen. Paletten kan også skaleres til ønsket størrelse ved å bruke forstørrelsesglasset på høyre side. Du velger først en region i rutan under teksten 'Editing region:'. Enhver region består av en fergskala som vises rett til venster, der du kan legge til fergestopp ved å klikke i fergeskalan når du hovrer over den og ser en plus symbol, eller fjerne valgt fergestopp ved å klikke på søpplebøtta til venstre, eller forflytte et fergestopp opp eller ner i skalan ved å dra i fergestoppet med musen. Du kan bruke alle kontrollene nedenfor for å skifte ferg på det fergestopp du har valgt. Når du velger et annet fergestopp plaseres den tidligere fergen i noen av felten under teksten 'Recent colors' og du kan kopiere en tidligere ferg til det nye fergestoppet ved å klikke på noen av fergefelten. På høyre sida finner du 'Random colors' som slumper frem nye ferger til hele temaet. Resultatet ser da vanligvis ikke særlig pent ut men for å få inspirasjon kan du klikke inntil det begynner å likne på noe og deretter bruke finjustere ferger inntil du er fornøyd. Når du er klar kan du klikke på 'Save Theme' for å lagre det nye temaet. Du finner det igjen ved å klikke 'Restore Theme' og velge 'Custom Theme' i listen som dykker opp. Der finner du også flere forinnstillte temaer som du kan teste ut.");
    langSE.add("Portfolio_MKInstructions","Instruktioner: Paletten är ett fönster som du kan flytta runt genom att dra i bakgrunden med musen. Paletten kan också skaleras till önskad storlek genom att använda förstoringsglaset till höger. Du välger först en region i rutan under texten 'Editing region:'. Varje region består av en färgskala som visas direkt till vänster, där du kan lägga till färgstopp genom att klikka i färgskalan när du hovrar över den och ser en pluss symbol, eller ta bort det valda färgstoppet genom att klicka på soptunnan till vänster, eller flytta färgstopp upp eller ner i skalan genom att dra i färgstoppet med musen. Du kan använda alla färgkontrollerna nedan för att ändra färg på det valda färgstoppet. När du välger ett nytt färgstopp placeras den tidigare färgen i något av fälten under texten 'Recent colors' och du kan kopiera en tidigare färg till ett nytt färgstopp genom att klicka på något av dessa färgfält. På höger sida finner du 'Random colors' som slumpar fram nya färger till hela temat. Resultatet ser då vanligtvis inte så vackert ut men för att finna inspiration kan du klicka tills det börjar likna på något och använda de andra kontrollerna för att finslipa tills du är nöjd. När du är nöjd klickar du på 'Save Theme' för att lagra ditt tema. Du finner det igen genom att klicka på 'Restore Theme' och välja 'Custom Theme' i listan som dyker upp. Där finner du också flera förinställda teman som du kan pröva.");
    langEN.add("Portfolio_MKInstructions","Instructions: The palette is a window that you can move around by dragging its background. It can also be scaled by dragging the scaling glass to the right. First choose a region in the box under 'Editing region'. Each region is a gradient which is displayed directly to the left, where you can add color stops by clicking at the gradient when you hover over it and see a plus sign. You can remove color stops by selecting them and clicking the trash bin symbol to the left. You can also drag color stops up or down within the gradent. Use all the color controls below to change the color of your selected color stop. When you select a new color stop, the previous color will be placed into one of the fields below 'Recent colors' and you can copy colors from there into the color stop you have selected by clicking on these color fields. To the right you'll find 'Random Colors' which generates random colors through the entire theme. The result may not always look pretty but you can repeat this function until you find something inspiring and then fine adjust the individual colors using the other controls. When you are satisfied, click 'Save Theme'. You can find the stored theme by cklicking 'Restore Theme' and choose 'Custom Theme' in the list that pops up. There you also find some pre defined themes that you can try out.");

    langNO.add("Portfolio_hideInfo","skjul info");
    langSE.add("Portfolio_hideInfo","dölj info");
    langEN.add("Portfolio_hideInfo","hide info");

    langNO.add("Portfolio_moreInfo","Klikk for mer info");
    langSE.add("Portfolio_moreInfo","Klicka för mer info");
    langEN.add("Portfolio_moreInfo","Click for more info");

    langNO.add("Portfolio_MKShort","Her er JGMedia Kit i bruk for å endre ferge tema på webbsidan som du ser på.");
    langSE.add("Portfolio_MKShort","Här är JGMedia Kit i bruk för att ändra färgtema på webbsidan som du ser på.");
    langEN.add("Portfolio_MKShort","Here you see JGMedia Kit in use, changing the color theme of the site you are reading.");

    langNO.add("Portfolio_CommercialTitle","TV-reklame");
    langSE.add("Portfolio_CommercialTitle","TV-Reklam");
    langEN.add("Portfolio_CommercialTitle","TV Commercials");

    langNO.add("Portfolio_CommercialContent","To reklamefilmer for Jämtlandsgården, et lokalt slakteri i svenske Jämtland. Den første filmen har fokus på fordelene med å vite hvor maten kommer fra, kontra langtransport av dyr. Den andre er en kort julereklame for to jämtlandske tradisjonsprodukter. Filmene ble vist på svensk TV4. Filmene er laget i samarbeid med grafisk designer Anna Granqvist.");
    langSE.add("Portfolio_CommercialContent","Två reklamfilmer för Jämtlandsgården, ett lokalt slakteri i Jämtland. Den första filmen har fokus på fördelarna med att veta vart maten kommer ifrån, kontra långtransporter av djur. Den andra är en kort julreklam för två jämtländska traditionsprodukter. Filmerna har med omfattning visats på svenska TV4. Filmerna gjordes i samarbete med grafisk designer Anna Granqvist.");
    langEN.add("Portfolio_CommercialContent","Here's two commercials for Jämtlandsgården, a local slaughtery in the swedish region Jämtland. The first film focuses on the benefits of knowing where the food comes from, versus distant transports of animals. The second film is a short christmas commercial for two traditional dishes of Jämtland. Both films have been broadcasted in swedish TV4. The films were made in cooperation with graphics designer Anna Granqvist.");

    langNO.add("Portfolio_FilmsTitle","Animerte filmer");
    langSE.add("Portfolio_FilmsTitle","Animerade filmer");
    langEN.add("Portfolio_FilmsTitle","Animated Films");

    langNO.add("Portfolio_FilmsText","I samarbeid med Sabina Sergazin lagde Johan blant annet disse to 3D animerte filmene. Den første kalt 'Meaning of Love' utspiller seg i et tempel og over et uttørket landskap der kun en dråpe vann vekker liv mot alle odds. I den andre filmen 'The Prisoner' behandler forfatteren Sabina et dypere sambånd mellom bevissthet og frihet. Sabina står for samtlige ideer, tekster og scener til filmene, mens Johan har lagd all 3D grafikk, rigging, animasjon, effekter og annet forverkligende arbeid.");
    langSE.add("Portfolio_FilmsText","I samarbete med Sabina Sergazin skapade Johan bland annat dessa två 3D animerade filmerna. Den första kallad 'Meaning of Love' utspelar sig i ett tempel och över ett uttorkat landskap där en enda droppe vatten väcker liv mot alla odds. I den andra filmen 'The Prosoner' behandlar författaren Sabina ett djupare samband mellan medvetenhet och frihet. Sabina står för samtliga ideer, texter och scener till filmerna, medans Johan har skapat all 3D grafik, riggning, animation, effekter och annat förverkligande arbete.");
    langEN.add("Portfolio_FilmsText","Togeather with Sabina Sergazin, Johan created among other things these two 3D animated films. The first one called 'Meaning of Love' takes place in a temple and over a dry landscape, where a single drop of water awakes life against all odds. In the second film 'The Prisoner' Sabina points out a deeper connection between conciousness and freedom. To Sabina's credit goes all ideas, texts and scenes for the films, while Johan created all the models, graphics, animations and effects for bringing the stories into life.");

    langNO.add("Portfolio_InvGotTitle","Invasion:Gotland");
    langSE.add("Portfolio_InvGotTitle","Invasion:Gotland");
    langEN.add("Portfolio_InvGotTitle","Invasion:Gotland");

    langNO.add("Portfolio_InvGotText","INVASION:GOTLAND er et modernt multi-player TV spill basert på spillet Battlefield:Vietnam, men som handler om et fiktivt 1977, der den svenske øya Gotland blir invadert av ryssere og må forsvares mot det ryske angrepet. Spillet er lagt i 70-talls retro stil, med våpen, fordon og utstyr lagd i samstemmighet med vad som bruktes under 70 tallets Sverige og Sovietunionen, liksom velkjente gotlandske miljøer, musikk og fordon som for eksempel Volvo 245. Alle karakterer i spillet snakker svensk eller russisk. Utviklings teamet (bestående av 9 spillutviklere) gjorde beta release for spillet I Visby den 11 desember 2004, med stor oppmerksomhet fra media, politikere og spill entusiaster, even tjenestemen fra Gotlands military regiment P-18 deltog i det store lan konventet og spillet har deretter oppnådd stor popularitet bland battlefield entusiaster over hele verden." );
    langSE.add("Portfolio_InvGotText","INVASION:GOTLAND är ett modernt multi-player TV-spel baserat på spelet Battlefield:Vietnam, men som handlar om ett fiktivt 1977, där den svenska ön Gotland blir invaderat av ryssar och måste försvaras mot det ryska angreppet. Spelet är gjort i 70-tals retro stil, med vapen, fordon och utrustning skapad i samstämmighet med vad som användes under 70 talets Sverige och Sovietunionen, liksom välkända gotländska miljöer, musik och fordon som till exempel Volvo 245. Alla karaktärer i spelet talar svenska eller ryska. Utvecklings teamet (bestående av 9 spelutvecklere) gjorde en beta release för spelet I Visby den 11 december 2004, med stor uppmärksamhet från media, politiker och spel entusiaster, även tjänstemän från Gotlands militärregemente P-18 deltog i det stora lan-konventet och spelet har därefter uppnått stor popularitet bland FPS-entusiaster över hela världen." );
    langEN.add("Portfolio_InvGotText","INVASION:GOTLAND is a modern FPS multi-player computer game based on the game Battlefield:Vietnam, but which takes place in a fictive 1977, where the swedish island Gotland is being invaded by russians and has to be defended from the russian assault. The game is made in a 70:s retro style, with weapons, vehicles and equipment made in similarity with what was used at the time by Sweden and the Soviet Union. The game takes place in well recognized environments of Gotland, with familiar music and vehicles as for example the Volvo 245. All characters speak either swedish or russian. The development team (consisting of 9 game developers) made a beta release in Visby the 11:th december 2004, with great attention from media, politicians and game enthusiasts, even officers from Gotland's military regiment P-18 stopped by to participate in the grand lan convent and the game has since then reached great populaarity among Battlefield enthusiasts all over the world." );

    /* 
    {WordPipe.read("Portfolio_FilmsText")} 

    langNO.add("Portfolio_","");
    langSE.add("Portfolio_","");
    langEN.add("Portfolio_","");
    */

}