import React from 'react';
import { ThemeComponent } from "./theme";

export class JGLogo extends ThemeComponent<{themeID?:string, clickHandler:()=>void},{}>{
    constructor(props:{themeID?:string, clickHandler:()=>void}){
        super(props);
    }

    draw(){

        return(
        <svg style={{width:"100%", height:"100%"}}
            viewBox="0 0 175.61 175.61"
        >
            <defs>
                <style>{
                    ".cls-1{fill:none;}.radialBG{fill:url(#radial-gradient);}.mouseRegion{fill:rgba(0,1,2,0)}.cls-4{fill:url(#linear-gradient);}.cls-5{fill:url(#linear-gradient-2);}.cls-10,.cls-12,.cls-6,.cls-8{font-size:20.4px;font-family:Impact;}.cls-6{fill:#5bc5f2;}.cls-7{clip-path:url(#clip-path);}.cls-8{fill:#fbba00;}.cls-9{clip-path:url(#clip-path-2);}.cls-10{fill:#faf085;}.cls-11{clip-path:url(#clip-path-3);}.cls-12{fill:#5389c8;"
                }</style>
                <radialGradient id="radial-gradient" cx="87.81" cy="87.81" r="87.81" gradientUnits="userSpaceOnUse">
                    <stop offset="0.15" stopColor={this.getBG(0.50, 1.0).GetRGB()}/>
                    <stop offset="0.20" stopColor={this.getBG(0.47, 1.0).GetRGB()}/>
                    <stop offset="0.25" stopColor={this.getBG(0.44, 1.0).GetRGB()}/>
                    <stop offset="0.30" stopColor={this.getBG(0.41, 1.0).GetRGB()}/>
                    <stop offset="0.35" stopColor={this.getBG(0.38, 1.0).GetRGB()}/>
                    <stop offset="0.40" stopColor={this.getBG(0.35, 1.0).GetRGB()}/>
                    <stop offset="0.45" stopColor={this.getBG(0.33, 1.0).GetRGB()}/>
                    <stop offset="0.50" stopColor={this.getBG(0.30, 0.95).GetRGB()}/>
                    <stop offset="0.55" stopColor={this.getBG(0.27, 0.90).GetRGB()}/>
                    <stop offset="0.60" stopColor={this.getBG(0.24, 0.80).GetRGB()}/>
                    <stop offset="0.65" stopColor={this.getBG(0.21, 0.70).GetRGB()}/>
                    <stop offset="0.70" stopColor={this.getBG(0.18, 0.60).GetRGB()}/>
                    <stop offset="0.75" stopColor={this.getBG(0.15, 0.50).GetRGB()}/>
                    <stop offset="0.80" stopColor={this.getBG(0.12, 0.40).GetRGB()}/>
                    <stop offset="0.85" stopColor={this.getBG(0.09, 0.30).GetRGB()}/>
                    <stop offset="0.90" stopColor={this.getBG(0.06, 0.20).GetRGB()}/>
                    <stop offset="0.95" stopColor={this.getBG(0.03, 0.10).GetRGB()}/>
                    <stop offset="1.00" stopColor={this.getBG(0.00, 0.00).GetRGB()}/>
                </radialGradient>
                <linearGradient id="linear-gradient" x1="87" y1="75.65" x2="140.29" y2="75.65" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#f9f3ab"/>
                    <stop offset="0.5" stopColor="#dedc00"/>
                    <stop offset="0.65" stopColor="#ebcc00"/>
                    <stop offset="0.83" stopColor="#f7bf00"/>
                    <stop offset="1" stopColor="#fbba00"/>
                </linearGradient>
                <linearGradient id="linear-gradient-2" x1="33.7" y1="75.63" x2="87" y2="75.63" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#5389c8"/>
                    <stop offset="0.16" stopColor="#5598d3"/>
                    <stop offset="0.46" stopColor="#5abfee"/>
                    <stop offset="0.5" stopColor="#5bc5f2"/>
                    <stop offset="0.53" stopColor="#65c8f3"/>
                    <stop offset="0.66" stopColor="#84d1f5"/>
                    <stop offset="0.78" stopColor="#9bd8f7"/>
                    <stop offset="0.9" stopColor="#a8dcf8"/>
                    <stop offset="1" stopColor="#adddf8"/>
                </linearGradient>
                <clipPath id="clip-path">
                    <polygon className="cls-1" points="29.93 130.67 155.39 123.88 153.27 140.16 29.93 130.67"/>
                </clipPath>
                <clipPath id="clip-path-2">
                    <polygon className="cls-1" points="29.93 130.67 155.03 130.12 155.03 135.56 29.93 130.67"/>
                </clipPath>
                <clipPath id="clip-path-3">
                    <polygon className="cls-1" points="21.38 116.3 113.64 109.87 25.87 130.53 126.75 155.06 21.38 145.23 21.38 116.3"/>
                </clipPath>
            </defs>
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    <circle className="radialBG" cx="87.81" cy="87.81" r="87.81"/>

                    {/* The inserted logo should begin here */}

                    <path className="cls-4" d="M140.29,36H87V99.29a21.75,21.75,0,0,0,4.49,8c3.46,4,9.35,8,19.09,8,8,0,11.47-1,14.3-2.74a17.23,17.23,0,0,0,4.05-3.25l0,6h11.39V90.55H129.62a18.26,18.26,0,0,1-5.24,8.26c-5.72,5.36-10.83,5.9-16,4.32-4.9-1.51-8.78-6.29-8.77-15,0,0-.26-31.66-.28-40.6l19.32.25c6.81,0,10.33,1.94,10.51,8.43l11.12.14Z"/>
                    <path className="cls-5" d="M33.7,36H87V99.29a21.94,21.94,0,0,1-4.49,8c-3.47,4-9,8-20.32,8-16.24-.47-21.9-7.48-25.34-12.88-3-4.63-3.15-11.79-3.15-11.79H44.37a15.84,15.84,0,0,0,4.81,8.76c5.72,5.36,11.88,5.18,17,3.6,4.9-1.5,8.17-6.07,8.15-14.79,0,0,.27-31.66.29-40.6l-19.33.25c-6.8,0-10.33,1.94-10.51,8.43l-11.12.14Z"/>
                    <text className="cls-6" transform="translate(32.67 140.71) scale(2.19 1)">
                        <tspan xmlSpace="preserve">MEDIA</tspan>
                    </text>
                    <g className="cls-7">
                        <text className="cls-8" transform="translate(32.67 140.71) scale(2.19 1)">
                            <tspan xmlSpace="preserve">MEDIA</tspan>
                        </text>
                    </g>
                    <g className="cls-9">
                        <text className="cls-10" transform="translate(32.67 140.71) scale(2.19 1)">
                            <tspan xmlSpace="preserve">MEDIA</tspan>
                        </text>
                    </g>
                    <g className="cls-11">
                        <text className="cls-12" transform="translate(32.67 140.71) scale(2.19 1)">
                            <tspan xmlSpace="preserve">MEDIA</tspan>
                        </text>
                    </g>

                    {/* The inserted logo should end here */}

                    <circle className="mouseRegion" cx="87.81" cy="87.81" r="87.81" style={{cursor:"pointer"}} onClick={this.props.clickHandler}/>
                </g>
            </g>
        </svg>
        )
    }
}