interface infoline{
    title: string,
    content: string
}

export interface CVJobTitles{
    employer: string,
    title: string,
    place: string,
    sector: string,
    start: string,
    end: string,
    description: string,
    tech: string,
    infolines: string[]
}

export interface CVJob{
    contentTitles: CVJobTitles,
    employer?: string,
    title: string,
    place: string,
    start: string,
    end: string,
    sector?: string,
    description: string,
    tech: string,
    infolines?: infoline[]
}

export function loadJobs( languageCode : string ){
    let result = new Array<CVJob>();

    // Norwegian

    if(languageCode === "NO"){
        let titles = {
            employer: "Oppdragsgiver:",
            title: "Rolle:",
            place: "Sted:",
            sector: "Bransje:",
            start: "Tidsperiode:",
            end: " - ",
            description: "Beskrivelse:",
            tech: "Teknologi/Verktøy:",
            infolines: []
        };

        // Dossier Solutions
        result.push({
            contentTitles: titles,
            employer: "Dossier Solutions AS",
            title: "Full stack utvikler",
            place: "Oslo",
            start: "2.2020",
            end: "3.2022",
            sector: "Privat",
            description: "Videreutvikling av Dossier's produkt Dossier Profile, i et stort team med kanban prosess, brukerhistorier, løpende leveranser og automatiserte tester, der oppgavene fremst er front end relaterte og med Dossier's egenutviklede komponentbibliotek Mithra.",
            tech: "IntelliJ, React.js, LESS, JavaScript, npm, Java, Git, Jira, Slack, Jest, Gradle, Selenium",
            infolines: []
        })

        // Bjerk
        result.push({
            contentTitles: titles,
            employer: "Bjerk AS",
            title: "Front end utvikler",
            place: "Oslo",
            start: "3.2019",
            end: "4.2019",
            sector: "Privat",
            description: "Front-end utvikling innen varierende kunde prosjekter, i team og med bruk og videreutvikling av Bjerk sitt eget komponent bibliotek OBF Kit.",
            tech: "Html, CSS, JavaScript, react.js, node.js, npm, Gatsby, Grommet, Java, Git, Jira, Slack, OBF Kit.",
            infolines: []
        })

        // Focus Software
        result.push({
            contentTitles: titles,
            employer: "Focus Software AS",
            title: "Front end utvikler",
            place: "Asker",
            start: "2.2018",
            end: "1.2019",
            sector: "Privat",
            description: "Videreutvikling og ferdigstilling av Prisassistenten (se prosjektet Prisassistenten for mer informasjon).",
            tech: "Visual Studio, React.js, Javascript (ts ,tsx), CSS, HTML, Photoshop, JSON, Git",
            infolines: []
        })

        // Glassworks
        result.push({
            contentTitles: titles,
            employer: "Glassworks AS",
            title: "Front end utvikler",
            place: "Nesodden",
            start: "3.2017",
            end: "1.2018",
            sector: "Privat",
            description: "Arkitektur, design og utvikling av Prisassistenten (se prosjekt for mer informasjon).",
            tech: "Visual Studio, React.js, Javascript (ts ,tsx), CSS, HTML, Photoshop, JSON, Git",
            infolines: []
        })

        // Bo Granqvist AB
        result.push({
            contentTitles: titles,
            employer: "Bo Granqvist AB",
            title: "Grafisk designer",
            place: "Stockholm",
            start: "1998",
            end: "2012",
            sector: "Privat",
            description: "Arbeid med planlegging og organisasjon, markedsanalyse, 3D modellering, teksturering og animasjon for TV-reklame og annen markedsføring, logotype design, kurs-illustrasjoner m.m.",
            tech: "Illustrator, Photoshop, Premiere, After effects, Microsoft Office, 3DStudio Max",
            infolines: []
        })
    }




    // Swedish
    
    if(languageCode === "SE"){
        let titles = {
            employer: "Uppdragsgivare:",
            title: "Roll:",
            place: "Plats:",
            sector: "Sektor:",
            start: "Tidsperiod:",
            end: " - ",
            description: "Beskrivning:",
            tech: "Teknologi/Verktyg:",
            infolines: []
        };

        // Dossier Solutions
        result.push({
            contentTitles: titles,
            employer: "Dossier Solutions AS",
            title: "Full stack utvecklare",
            place: "Oslo",
            start: "2.2020",
            end: "3.2022",
            sector: "Privat",
            description: "Vidareutveckling av Dossier's produkt Dossier Profile, i ett stort team i kanban process med fortlöpande leverering, brukarhistorier och automatiserade tester, där uppgifterna främst är relaterade till front end och med Dossier's egenutvecklade komponentbibliotek Mithra.",
            tech: "IntelliJ, React.js, LESS, JavaScript, npm, Java, Git, Jira, Slack, Jest, Gradle, Selenium",
            infolines: []
        })

        // Bjerk
        result.push({
            contentTitles: titles,
            employer: "Bjerk AS",
            title: "Front end utvecklare",
            place: "Oslo",
            start: "3.2019",
            end: "4.2019",
            sector: "Privat",
            description: "Front-end utveckling i varierande kundprojekt i team, samt bruk och vidareutveckling av Bjerks eget komponentbibliotek OBF Kit.",
            tech: "Html, CSS, JavaScript, react.js, node.js, npm, Gatsby, Grommet, Java, Git, Jira, Slack, Obf Kit.",
            infolines: []
        })

        // Focus Software
        result.push({
            contentTitles: titles,
            employer: "Focus Software AS",
            title: "Front end utvecklare",
            place: "Asker (Oslo)",
            start: "2.2018",
            end: "1.2019",
            sector: "Privat",
            description: "Videreutveckling och färdigställning av Prisassistenten (se projektet Prisassistenten för mer information).",
            tech: "Visual Studio, React.js, Javascript (ts ,tsx), CSS, HTML, Photoshop, JSON, Git",
            infolines: []
        })

        // Glassworks
        result.push({
            contentTitles: titles,
            employer: "Glassworks AS",
            title: "Front end utvecklare",
            place: "Nesodden (Oslo)",
            start: "3.2017",
            end: "1.2018",
            sector: "Privat",
            description: "Arkitektur, design och utveckling av Prisassistenten (se prosjektet Prisassistenten för mer information).",
            tech: "Visual Studio, React.js, Javascript (ts ,tsx), CSS, HTML, Photoshop, JSON, Git",
            infolines: []
        })

        // Bo Granqvist AB
        result.push({
            contentTitles: titles,
            employer: "Bo Granqvist AB",
            title: "Grafisk designer",
            place: "Stockholm",
            start: "1998",
            end: "2012",
            sector: "Privat",
            description: "Arbete med planläggning och organisation, marknadsanalys, 3D modellering, teksturering och animation för TV-reklam och annan marknadsföring, logotype design, kurs-illustrationer m.m.",
            tech: "Illustrator, Photoshop, Premiere, After effects, Microsoft Office, 3DStudio Max",
            infolines: []
        })
    }





    // English
    
    if(languageCode === "EN"){
        let titles = {
            employer: "Employer:",
            title: "Title:",
            place: "Location:",
            sector: "Sector:",
            start: "Time:",
            end: " - ",
            description: "Description:",
            tech: "Technology/Tools:",
            infolines: []
        };

        // Dossier Solutions
        result.push({
            contentTitles: titles,
            employer: "Dossier Solutions AS",
            title: "Full stack developer",
            place: "Oslo",
            start: "2.2020",
            end: "3.2022",
            sector: "Private",
            description: "Development of Dossier's product Dossier Profile, in a large team using a kanban process with continous delivery, user stories and automated testing, where the tasks are mainly front end related and using Dossier's self developed component library Mithra.",
            tech: "IntelliJ, React.js, LESS, JavaScript, npm, Java, Git, Jira, Slack, Jest, Gradle, Selenium",
            infolines: []
        })
        
        // Bjerk
        result.push({
            contentTitles: titles,
            employer: "Bjerk AS",
            title: "Front end developer",
            place: "Oslo",
            start: "3.2019",
            end: "4.2019",
            sector: "Private",
            description: "Front end development for a variety of customers, in team while using and developing the component library of Bjerk, called the OBF Kit.",
            tech: "Html, CSS, JavaScript, react.js, node.js, npm, Gatsby, Grommet, Java, Git, Jira, Slack, OBF Kit.",
            infolines: []
        })

        // Focus Software
        result.push({
            contentTitles: titles,
            employer: "Focus Software AS",
            title: "Front end developer",
            place: "Asker (Oslo)",
            start: "2.2018",
            end: "1.2019",
            sector: "Private",
            description: "Development and completion of Prisassistenten (see the project 'Prisassistenten' for more information).",
            tech: "Visual Studio, React.js, Javascript (ts ,tsx), CSS, HTML, Photoshop, JSON, Git",
            infolines: []
        })

        // Glassworks
        result.push({
            contentTitles: titles,
            employer: "Glassworks AS",
            title: "Front end developer",
            place: "Nesodden",
            start: "3.2017",
            end: "1.2018",
            sector: "Private",
            description: "Arkitekture, design and development of Prisassistenten (see the project 'Prisassistenten' for more information).",
            tech: "Visual Studio, React.js, Javascript (ts ,tsx), CSS, HTML, Photoshop, JSON, Git",
            infolines: []
        })

        // Bo Granqvist AB
        result.push({
            contentTitles: titles,
            employer: "Bo Granqvist AB",
            title: "Graphics designer",
            place: "Stockholm",
            start: "1998",
            end: "2012",
            sector: "Private",
            description: "Planning and organization, market analysis, 3D Modeling, texturing and animation for TV Commercials and other marketing, logotype design, educational illustrations and other various tasks",
            tech: "Adobe Illustrator, Photoshop, Premiere, After effects, Microsoft Office, 3DStudio Max",
            infolines: []
        })
    }

    return result;
}