import * as React from 'react';
import {Theme, ThemeComponent} from './theme';

export class ContactSymbols extends ThemeComponent<{baseColor?:string, detailColor?:string},{}>{

    draw(){
        let baseColor = this.props.baseColor ? this.props.baseColor : this.getTheme().getFG(null, 0.3, 0).GetRGB();
        let detailColor = this.props.detailColor ? this.props.detailColor : this.getTheme().getBG(null, 1, 0.3).GetRGB();

        return (
            <svg viewBox="0 0 19.25 54.47">

                {/*  Phone background circle  */}
                <circle style={{fill: baseColor}} cx="9.63" cy="9.63" r="9.63"/>

                {/*  ?  */}
                <path style={{fill: detailColor}} d="M6.22,3.57c.93,0,2.19,1.74,1.87,2.61-.06.18-.57.31-.66.47-.63,1.1.07,1.83,1.4,3.78s1.72,2.7,2.89,2.45c.21-.05.54-.54.76-.54.93,0,2.17,1.75,1.87,2.63-.06.2-.54.48-.75.54-2.23.65-3.63-.43-6.1-4S4.15,6,5.45,4.12C5.58,3.93,6,3.56,6.22,3.57Z"/>

                {/*  ?  */}
                <circle style={{fill: baseColor}} cx="9.63" cy="44.85" r="9.63"/>

                {/*  ?  */}
                <rect style={{fill: detailColor}} x="5.1" y="41.56" width="9.47" height="6.24"/>

                {/*  ?  */}
                <path style={{fill: baseColor}} d="M4,40.52l6.13,4.77,5.5-4.74.31.37-5.47,4.72a.41.41,0,0,1-.6,0L3.76,41Z"/>

                {/*  ?  */}
                <polyline style={{fill: baseColor}} points="4.78 47.84 8.61 44.54 8.91 44.9 5.09 48.21"/>

                {/*  ?  */}
                <polyline style={{fill: baseColor}} points="14.8 48.26 11.04 44.87 11.33 44.49 15.1 47.87"/>
            
            </svg>
        )
    }
}

