import * as React from 'react';
import {Theme, ColorSample} from '../components/theme';
import {JGPage, JGPageProps} from '../components/JGPage';
import {SkillLevelsView} from '../components/SkillLevelBar';
import {ContactSymbols} from '../components/ContactSymbols';
import {loadProjects, CVProject} from '../data/loadProjects';
import {loadJobs, CVJob} from '../data/loadJobs';
import {loadEducations, CVEducation} from '../data/loadEducations';
import {ProjectDisplay} from '../components/ProjectDisplay';
import {JobDisplay} from '../components/JobDisplay';
import {EducationDisplay} from '../components/EducationDisplay';
import {WordPipe} from '../tools/WordPipe';
import {ThemeStylesManager} from '../data/themeStyles';

/*
    ####    ###    ###   ##### 
    #   #  #   #  #   #  #     
    ####   #####  #      ###  
    #      #   #  #  ##  #     
    #      #   #   ####  ##### 
*/

export class PageCV extends JGPage{

    constructor(props:JGPageProps){
        super(props);
        this.setStyleDefault({
            width:"100%"
        })

        this.mComponentType = "PageCV";
    }

    draw(){

        // -------------- Setting up a common basis: -------------------

        enum display{
            mobile,
            ipad,
            desktop
        }

        let theme = this.getTheme();
        let width = window.innerWidth;

        let detailColor = theme.getDetail(null, 0.5, 0).GetRGB();
        let highlightColor = theme.getDetail(null, 0.6, 0.6).GetRGB();

        let displayMode : display = display.mobile;

        var projects: Array<CVProject> = loadProjects(WordPipe.getLanguageCode());
        var jobs: Array<CVJob> = loadJobs(WordPipe.getLanguageCode());
        var educations: Array<CVEducation> = loadEducations(WordPipe.getLanguageCode());

        let contentLeftRightMargin = ThemeStylesManager.getLRMargin();
        let bgStretchRightWidth = 0;
        let bgStretchLeftWidth = 0;

        let skillSplitCenterMargin = 80 + ((window.innerWidth - 950) / 5);
        
        if(ThemeStylesManager.isIpadWide()){
            displayMode = display.ipad
            bgStretchRightWidth = 300;
            bgStretchLeftWidth = -100;
        }

        if(ThemeStylesManager.isDesktopWide()){
            displayMode = display.desktop
        }

        let leftMarginOfMobileTitle = (window.innerWidth - 200)/2;
        let topMarginOfMobileTitle = 50 - (leftMarginOfMobileTitle/5.5);

        let nameSizeMobile = (50 + ((window.innerWidth - 500) / 15));
        let professionSizeMobile = 25 + ((window.innerWidth - 500) / 30);

        let imageWidthMobile = (window.innerWidth * 0.6) ;

        if(imageWidthMobile > (window.innerHeight * 0.85)){
            imageWidthMobile = window.innerHeight * 0.85;
        }

        let imageLeftMarginMobile = (window.innerWidth*0.2) - contentLeftRightMargin;

        let displayedProjectsMobile : JSX.Element[] = new Array<JSX.Element>();
        let progressiveKey = 0;

        for(let i=0; i<projects.length; i++){
            displayedProjectsMobile.push(
                <div key={"key:" + (progressiveKey++)}
                    style={{
                        width: "calc(100% - " + (contentLeftRightMargin * 2) + "px)",
                        position:"relative",
                        marginTop: "26px",
                        left: contentLeftRightMargin + "px"
                    }}>
                    <ProjectDisplay theme={theme} project={projects[i]}/>
                </div>
            );
        }

        let displayedJobs : JSX.Element[] = new Array<JSX.Element>();

        for(let i=0; i<jobs.length; i++){
            displayedJobs.push(
                <div key={"key:" + (progressiveKey++)}
                    style={{
                        width: "calc(100% - " + (contentLeftRightMargin * 2) + "px)",
                        position:"relative",
                        marginTop: "26px",
                        left: contentLeftRightMargin + "px"
                    }}>
                    <JobDisplay theme={theme} job={jobs[i]}/>
                </div>
            );
        }

        let displayedEducations : JSX.Element[] = new Array<JSX.Element>();

        for(let i=0; i<educations.length; i++){
            displayedEducations.push(
                <div key={"key:" + (progressiveKey++)}
                    style={{
                        width: "calc(100% - " + (contentLeftRightMargin * 2) + "px)",
                        position:"relative",
                        marginTop: "26px",
                        left: contentLeftRightMargin + "px"
                    }}>
                    <EducationDisplay theme={theme} education={educations[i]}/>
                </div>
            );
        }

        let skills : {text:string, value:number}[] = [
            {text:"HTML", value:0.65},
            {text:"CSS", value:0.75},
            {text:"Javascript", value:0.85},
            {text:"React.js", value:0.78},
            {text:"MySQL", value:0.6},
            {text:"Java", value:0.75},
            {text:"Node.js", value:0.4},
            {text:"Data Security", value:0.7},
            {text:"Photoshop", value:0.8},
            {text:"Illustrator", value:0.65},
            {text:"Premiere", value:0.5},
            {text:"3DStudioMax", value:0.85},
            {text:"WebGL", value:0.4},
            {text:"SVG Graphics", value:0.7},
            {text:"UX Design", value:0.65},
            {text:"System Design", value:0.8}
        ]


        // Same skills data but split into 2 separate columns of data
        let skillsA = new Array<{text:string, value:number}>();
        let skillsB = new Array<{text:string, value:number}>();

        for(let i=0; i<skills.length; i++){
            if(i >= (skills.length/2)){
                skillsB.push(skills[i]);
            }
            else{
                skillsA.push(skills[i]);
            }
        }

        // -------------- Generating the sections: -------------------

        let sectionTitleCurriculumVitae = ( <div></div>);
        let sectionTitleName = ( <div></div>);
        let sectionTitleBundle = ( <div></div>);

        let sectionSkills = ( <div></div>);


        // Title: Curriculum Vitae - mobile
        if(displayMode === display.mobile){
            let sizeBase = 60 * (window.innerWidth+650) / 1000;

            sectionTitleCurriculumVitae = (
                <div style={{
                    width: "100%",
                    height: ((sizeBase * 2)-20) + "px",
                    marginTop: topMarginOfMobileTitle + "px",
                    fontSize: sizeBase + "px",
                    color: theme.getDetail(null,0.5,0).GetRGB(),
                    textAlign: "center",
                    position:"relative",
                    top: (50 - sizeBase/2) + "px"
                }}>
                    CV
                </div>
            )
        }

        // Title: Name - mobile
        if(displayMode === display.mobile){
            sectionTitleName = (
                <div style={{
                    position:"relative",
                    fontSize:"39px",
                    textAlign:"left",
                    left: contentLeftRightMargin + "px",
                    width: "calc(100% - " + (2*contentLeftRightMargin) + "px",
                }}>
                    {/* image */}
                    <div style={{
                        width: imageWidthMobile + "px",
                        height: imageWidthMobile + "px",
                        marginTop: "30px",
                        borderRadius: (imageWidthMobile / 2) + "px",
                        backgroundImage: "url(https://i.imgur.com/DRZQT2D.jpg)",
                        backgroundSize: "100% 100%",
                        backgroundRepeat: "no-repeat",
                        backgroundPositionY: "0",
                        opacity: 0.7,
                        position: "relative",
                        left: imageLeftMarginMobile + "px"
                    }}/>

                    <div style={{
                        fontSize: nameSizeMobile + "px",
                        marginTop:"20px",
                        textAlign:"center"}}>
                        Johan Granqvist
                    </div>
                    
                    <div style={{
                        fontSize: professionSizeMobile + "px",
                        textAlign:"center"}}>
                        {WordPipe.read("CV_professionLine1")}
                    </div>

                    <div style={{
                        fontSize: professionSizeMobile + "px",
                        textAlign:"center"}}>
                        {WordPipe.read("CV_professionLine2")}
                    </div>

                    <div style={{
                        height:"50px"
                    }}/>
                </div>
            )
        }
        else{
            let statement = null;
            if(displayMode === display.desktop){
                statement = (
                    <div>
                        <div style={{
                            marginTop:"23px",
                            marginLeft:"80px",
                            textAlign:"left",
                            fontSize:"30px"
                        }}>
                            {WordPipe.read("CV_summaryTitle")}
                        </div>
                        <div style={{
                            marginTop:"4px",
                            marginLeft:"80px",
                            textAlign:"left",
                            fontSize:"21px"
                        }}>
                            {WordPipe.read("CV_summaryContent")}
                        </div>
                    </div>
                )
            } //  Johan har samtidig et godt øye for estetikk og grafisk design og lager illustrasjoner og animasjoner for å gjøre grensesnittet penere, tydeligere og mer kommuniserende.

            sectionTitleBundle = (
                <div style={{
                    
                }}>
                    <div style={{height:"40px"}}>
                    </div>

                    <div style={this.themeStyle("CVTitle")}>
                        CV
                    </div>

                    <div style={this.themeStyle("CVUnderline")}/>

                    <div style={{
                        position: "relative",
                        left: ThemeStylesManager.getLRMargin() + 'px', 
                        width: ThemeStylesManager.getCentralWidth() + 'px',
                        display:"flex",
                        flexDirection:"row"
                    }}>
                        <div>
                            {/* image */}
                            <div style={{
                                width: 240 + "px",
                                height: 240 + "px",
                                marginTop: "30px",
                                borderRadius: 12 + "px",
                                backgroundImage: "url(https://i.imgur.com/DRZQT2D.jpg)",
                                backgroundSize: "100% 100%",
                                backgroundRepeat: "no-repeat",
                                backgroundPositionY: "0",
                                opacity: 0.7,
                                position: "relative",
                                left: 0 + "px"
                            }}/>

                            <div style={{
                                fontSize: 34 + "px",
                                marginTop:"15px",
                                textAlign:"left"}}>
                                Johan Granqvist
                            </div>
                            
                            <div style={{
                                fontSize: 22 + "px",
                                textAlign:"left"}}>
                                {WordPipe.read("CV_professionLine1")}
                            </div>

                            <div style={{
                                fontSize: 22 + "px",
                                textAlign:"left"}}>
                                {WordPipe.read("CV_professionLine2")}
                            </div>

                            <div style={{
                                height:"50px"
                            }}/>
                        </div>
                        {statement}
                    </div>
                </div>
            )
        }

        // Skills  

        if(displayMode === display.desktop){
            sectionSkills = (
                <div style={{
                    position:"relative",
                }}>
                    <div style={{
                        backgroundColor: theme.getBG(null,0.1,0).GetRGB(),
                        height:"100%",
                        width:"calc(100% + " + bgStretchRightWidth + "px)",
                        position:"absolute",
                        left: bgStretchLeftWidth + "px"
                    }}/>
    
                    <div style={{
                        height:"30px"
                    }}/>
    
                    <div style={this.themeStyle("CVTitle")}>
                        {WordPipe.read("CV_mainSkillsTitle")}
                    </div>
    
                    <div style={this.themeStyle("CVUnderline")}/>
    
                    {/* Split sections */}
                    <div style={{
                        position: "relative", 
                        marginTop:"15px",
                        display:"flex",
                        flexDirection:"row",
                        left: ThemeStylesManager.getLRMargin() + "px",
                        width: ThemeStylesManager.getCentralWidth() + "px"
                    }}>
                        {/* Left section */}
                        <div style={{
                            width: ((ThemeStylesManager.getCentralWidth() - skillSplitCenterMargin) / 2) + "px"
                        }}>
                            <SkillLevelsView width={(ThemeStylesManager.getCentralWidth() - skillSplitCenterMargin) / 2.001} theme={theme} skills={skillsA}/>
                        </div>

                        <div style={{
                            width: skillSplitCenterMargin + "px"
                        }}/>

                        {/* Right section */}
                        <div style={{
                            width: ((ThemeStylesManager.getCentralWidth() - skillSplitCenterMargin) / 2) + "px"
                        }}>
                            <SkillLevelsView width={(ThemeStylesManager.getCentralWidth() - skillSplitCenterMargin) / 2.001} theme={theme} skills={skillsB}/>
                        </div>
                    </div>

                    <div style={{
                        height:"20px"
                    }}/>

                    <div style={this.themeStyle("CVTitle")}>
                        {WordPipe.read("CV_relatedSkillsTitle")}
                    </div>
    
                    <div style={this.themeStyle("CVUnderline")}/>
    
                    <div style={{
                        height:"16px"
                    }}/>
    
                    <div style={{
                            position:"relative",
                            left: contentLeftRightMargin + "px",
                            width: ThemeStylesManager.getCentralWidth() + "px",
                            fontSize: 20 + "px",
                            textAlign:"left",
                            color: detailColor
                        }}>
                        {WordPipe.read("CV_relatedSkillsContent")}
                    </div>
    
                    <div style={{
                        height:"40px"
                    }}/>
    
                </div>
            )
        }
        else{
            sectionSkills = (
                <div style={{
                    position:"relative"
                }}>
                    <div style={{
                        backgroundColor: theme.getBG(null,0.1,0).GetRGB(),
                        height:"100%",
                        width:"calc(100% + " + bgStretchRightWidth + "px)",
                        position:"absolute",
                        left: bgStretchLeftWidth + "px"
                    }}/>
    
                    <div style={{
                        height:"30px"
                    }}/>
    
                    <div style={this.themeStyle("CVTitle")}>
                        {WordPipe.read("CV_mainSkillsTitle")}
                    </div>
    
                    <div style={this.themeStyle("CVUnderline")}/>
    
                    <div style={{position: "relative", left: contentLeftRightMargin + "px", marginTop:"15px"}}>
                        <SkillLevelsView width={ThemeStylesManager.getCentralWidth()} theme={theme} skills={skills}/>
                    </div>
    
                    <div style={this.themeStyle("CVTitle")}>
                        {WordPipe.read("CV_relatedSkillsTitle")}
                    </div>
    
                    <div style={this.themeStyle("CVUnderline")}/>
    
                    <div style={{
                        height:"16px"
                    }}/>
    
                    <div style={{
                            position:"relative",
                            left: contentLeftRightMargin + "px",
                            width: ThemeStylesManager.getCentralWidth() + "px",
                            fontSize: 20 + "px",
                            textAlign:"left",
                            color: detailColor
                        }}>
                        {WordPipe.read("CV_relatedSkillsContent")}
                    </div>
    
                    <div style={{
                        height:"40px"
                    }}/>
    
                </div>
            )
        }

        // Statement
        let sectionStatement = null;

        if(displayMode !== display.desktop){
            sectionStatement = (
                <div>
                    <div style={{
                        height:"20px"
                    }}/>
    
                    <div style={this.themeStyle("CVTitle")}>
                        {WordPipe.read("CV_summaryTitle")}
                    </div>
    
                    <div style={this.themeStyle("CVUnderline")}/>
    
                    <div style={{
                        height:"16px"
                    }}/>
    
                    <div style={{
                        position:"relative",
                        left: contentLeftRightMargin + "px",
                        width: ThemeStylesManager.getCentralWidth() + "px",
                        fontSize: 20 + "px",
                        textAlign:"left",
                        color: detailColor
                    }}>
                        {WordPipe.read("CV_summaryContent")}
                    </div>
    
                    <div style={{
                        height:"25px"
                    }}/>
    
                </div>
            )
        }

        // Personality
        let sectionPersonality = (
            <div>
                <div style={{
                    height:"20px"
                }}/>

                <div style={this.themeStyle("CVTitle")}>
                    {WordPipe.read("CV_personalityTitle")}
                </div>

                <div style={this.themeStyle("CVUnderline")}/>

                <div style={{
                    height:"16px"
                }}/>

                <div style={{
                    position:"relative",
                    left: contentLeftRightMargin + "px",
                    width: ThemeStylesManager.getCentralWidth() + "px",
                    fontSize: 20 + "px",
                    textAlign:"left",
                    color: highlightColor
                }}>
                    <div>- {WordPipe.read("CV_personality1")}</div>
                    <div>- {WordPipe.read("CV_personality2")}</div>
                    <div>- {WordPipe.read("CV_personality3")}</div>
                    <div>- {WordPipe.read("CV_personality4")}</div>
                    <div>- {WordPipe.read("CV_personality5")}</div>
                </div>

                <div style={{
                    height:"25px"
                }}/>

            </div>
        )


        // Projects
        let sectionProjects = ( 
            <div>
                <div style={{
                    height:"20px"
                }}/>

                <div style={this.themeStyle("CVTitle")}>
                    {WordPipe.read("CV_projectsTitle")}
                </div>

                <div style={this.themeStyle("CVUnderline")}/>

                {displayedProjectsMobile}

                <div style={{
                    height:"30px"
                }}/>

            </div>
        )

        // Employments
        let sectionEmployments = (
            <div>
                <div style={{
                    height:"20px"
                }}/>

                <div style={this.themeStyle("CVTitle")}>
                    {WordPipe.read("CV_jobsTitle")}
                </div>

                <div style={this.themeStyle("CVUnderline")}/>

                {displayedJobs}

                <div style={{
                    height:"17px"
                }}/>

                <div style={{
                    position:"relative",
                    left: (contentLeftRightMargin+1) + "px",
                    textAlign:"left",
                    color: highlightColor,
                    fontSize: "16px",
                    fontWeight: "bold"
                }}>
                    {WordPipe.read("CV_exclusionComment")}
                </div>

                <div style={{
                    height:"30px"
                }}/>

            </div>
        )

        // Educations
        let sectionEducations = (
            <div>
                <div style={{
                    height:"20px"
                }}/>

                <div style={this.themeStyle("CVTitle")}>
                    {WordPipe.read("CV_SchoolsTitle")}
                </div>

                <div style={this.themeStyle("CVUnderline")}/>

                {displayedEducations}

                <div style={{
                    height:"30px"
                }}/>

            </div>
        )

        // Languages
        let sectionLanguages = (
            <div>
                <div style={{
                    height:"20px"
                }}/>

                <div style={this.themeStyle("CVTitle")}>
                    {WordPipe.read("CV_languageTitle")}
                </div>

                <div style={this.themeStyle("CVUnderline")}/>

                <div style={{
                    height:"16px"
                }}/>

                <div style={this.themeStyle("CVLanguageTitle")}>
                    {WordPipe.read("CV_swedishTitle")}
                    <div style={this.themeStyle("CVLanguageLevel")}>
                        {WordPipe.read("CV_swedishLevel")}
                    </div>
                </div>

                <div style={this.themeStyle("CVLanguageTitle")}>
                    {WordPipe.read("CV_englishTitle")}
                    <div style={this.themeStyle("CVLanguageLevel")}>
                        {WordPipe.read("CV_englishLevel")}
                    </div>
                </div>

                <div style={this.themeStyle("CVLanguageTitle")}>
                    {WordPipe.read("CV_spanishTitle")}
                    <div style={this.themeStyle("CVLanguageLevel")}>
                        {WordPipe.read("CV_spanishLevel")}
                    </div>
                </div>

                <div style={{
                    height:"50px"
                }}/>

            </div>
        )

        // Footer
        let sectionFooter = (
            <div style={{
                position:"relative",
                height:"255px"
            }}>
                <div style={{
                    backgroundColor: theme.getBG(null,0.1,0).GetRGB(),
                    height:"100%",
                    width:"calc(100% + " + bgStretchRightWidth + "px)",
                    position:"absolute",
                    left: bgStretchLeftWidth + "px"
                }}/>

                <div style={{
                    height:"20px"
                }}/>

                <div style={this.themeStyle("CVTitle")}>
                    {WordPipe.read("Contact_Title")}
                </div>

                <div style={this.themeStyle("CVUnderline")}/>

                <div style={{
                    position:"absolute",
                    top:"100px",
                    left: contentLeftRightMargin + "px",
                    width:"40px",
                    height:"120px"
                }}>
                    <ContactSymbols/>
                </div>
                
                <div style={{
                    position:"absolute",
                    top:"102px",
                    left: (contentLeftRightMargin + 60) + "px",
                    color: theme.getDetail(null, 0.6, 0).GetRGB(),
                    fontSize:"24px"
                }}>
                    +47 94089399
                </div>

                <div style={{
                    position:"absolute",
                    top:"175px",
                    left: (contentLeftRightMargin + 60) + "px",
                    color: theme.getDetail(null, 0.6, 0).GetRGB(),
                    fontSize:"21px"
                }}>
                    johan_granqvist@yahoo.se
                </div>

            </div>
        )
    
        // -------------- Returning responsive outputs: -------------------

        // Mobile mode
        if(displayMode === display.mobile){
            return(
                <div style={{
                    height:"3000px",
                    color: theme.getDetail(null,0.5,0).GetRGB()
                }}>
                    {sectionTitleCurriculumVitae}
                    {sectionTitleName}
                    {sectionSkills}
                    {sectionStatement}
                    {sectionPersonality}
                    {sectionProjects}
                    {sectionEmployments}
                    {sectionEducations}
                    {sectionLanguages}
                    {sectionFooter}
              </div>
            )
        }

        // Ipad mode
        if(displayMode === display.ipad){
            return(
                <div style={{
                    height:"3000px",
                    color: theme.getDetail(null,0.5,0).GetRGB()
                }}>
                    {sectionTitleBundle}
                    {sectionSkills}
                    {sectionStatement}
                    {sectionPersonality}
                    {sectionProjects}
                    {sectionEmployments}
                    {sectionEducations}
                    {sectionLanguages}
                    {sectionFooter}
                </div>
            )
        }

        // Desktop mode
        return(
            <div style={{
                height:"3000px",
                color: theme.getDetail(null,0.5,0).GetRGB()
            }}>
                {sectionTitleBundle}
                {sectionSkills}
                {sectionStatement}
                {sectionPersonality}
                {sectionProjects}
                {sectionEmployments}
                {sectionEducations}
                {sectionLanguages}
                {sectionFooter}
            </div>
        )
    }
}
