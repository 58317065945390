import * as React from 'react';
import {Theme, ColorSample} from '../components/theme';
import {JGPage} from '../components/JGPage';

/*
    ####    ###    ###   ##### 
    #   #  #   #  #   #  #     
    ####   #####  #      ###  
    #      #   #  #  ##  #     
    #      #   #   ####  ##### 
*/


export class Page3D extends JGPage{

    constructor(props:any){
        super(props);
        this.setStyleDefault({
            width: "100%", 
            height: "700px"
        })

        this.mComponentType = "Page3D";
    }

    draw(){
        let theme = this.getTheme();

        return(
            <div style={{
                width: "100%",
                height: "1000px",
                marginTop: "200px",
                color: theme.getDetail(null,0.5,0).GetRGB()
            }}>
            </div>
        )
    }
}
