interface infoline{
    title: string,
    content: string
}

export interface CVEducationTitles{
    schoolName: string,
    educationName: string,
    grade: string,
    place: string,
    start: string,
    end: string,
    description: string,
    tech: string,
    infolines: string[]
}

export interface CVEducation{
    contentTitles: CVEducationTitles,
    schoolName: string,
    educationName: string,
    grade?: string,
    place: string,
    start: string,
    end: string,
    description: string,
    tech: string,
    infolines?: infoline[]
}

export function loadEducations( languageCode : string ){
    let result = new Array<CVEducation>();



    // Norwegian

    if(languageCode === "NO"){
        let titles = {
            schoolName: "Skole:",
            educationName: "Kurs:",
            grade: "Examensgrad:",
            place: "Sted:",
            sector: "Bransje:",
            start: "Tidsperiode:",
            end: " - ",
            description: "Beskrivelse:",
            tech: "Teknologi/Verktøy:",
            infolines: []
        };

        // YA - Yrkeshögskolan
        result.push({
            contentTitles: titles,
            schoolName: "YA Yrkeshögskolan",
            educationName: "Java Programmering",
            grade: "Bachelor",
            place: "Östersund, Sverige",
            start: "2015",
            end: "2017",
            description: "Kvalificerad yrkeshögskoleexamen: Yrkesutdannelse innen Java programmering, systemering, webutvikling, datasikkerhet, databaser og objekt orientert programmering. Utdanningen er på www.seqf.se angitt til nivå 6, dvs tilsvarende nivå som bachelor ihht International Standard Classification of Education (ISCED) (skala går fra 1 til 8, der master er på nivå 7)",
            tech: "Java, Netbeans, Eclipse, MySQL, XML, HTML, JSON, Git, Scrum, Smidige metoder.",
            infolines: []
        })

        // Högskolan på Gotland
        result.push({
            contentTitles: titles,
            schoolName: "Högskolan på Gotland",
            educationName: "Spelutveckling och Interaktiva medier",
            place: "Visby, Sverige",
            start: "2002",
            end: "2005",
            description: "Teknisk høyskoleutdanning med rettet mot produksjon av data og TV-spill, 3D programmering med diskret matematikk for 3D grafikk, OpenGL, 3D Modellering, 3D animering, teksturering og modellering, samt spilldesign og produksjon.",
            tech: "C++, Java, OpenGL, 3DStudio Max, Photoshop, Flash.",
            infolines: []
        })

        // Datahögskolan
        result.push({
            contentTitles: titles,
            schoolName: "Datahögskolan",
            educationName: "Kreativ Datavetenskap",
            place: "Stockholm, Sverige",
            start: "2000",
            end: "2001",
            description: "Utdanning i systemering, programmering, datastrukturer, objektorientert programmering, Windows programmering (Med Microsoft Foundation Classes og Visual Studio), assemblering, flere programmeringsspråk med hovedvekt på C++, systemdesign, prosjektering, avanserte algoritmer, optimering og datastrukturer.",
            tech: "C++, Objektorientert programmering, Pascal, Visual Basic, Delphi, SQL, MFC, Visual Studio, assemblering",
            infolines: []
        })

        return result;
    }





    
    // Swedish
    
    if(languageCode === "SE"){
        let titles = {
            schoolName: "Skola:",
            educationName: "Kurs:",
            grade: "Examensgrad:",
            place: "Plats:",
            sector: "Sektor:",
            start: "Tidsperiod:",
            end: " - ",
            description: "Beskrivning:",
            tech: "Teknologier/Verktyg:",
            infolines: []
        };

        // YA - Yrkeshögskolan
        result.push({
            contentTitles: titles,
            schoolName: "YA Yrkeshögskolan",
            educationName: "Java Programmering",
            grade: "Bachelor",
            place: "Östersund, Sverige",
            start: "2015",
            end: "2017",
            description: "Kvalificerad yrkeshögskoleexamen: Yrkesutbildning innom Java programmering, systemering, webbutveckling, datasäkerhet, databaser och objekt orienterad programmering. Utbildningen är på www.seqf.se angiven till nivå 6, dvs motsvarande nivå som bachelor enligt International Standard Classification of Education (ISCED) (skalan går från 1 till 8, där master ligger på nivå 7)",
            tech: "Java, Netbeans, Eclipse, MySQL, XML, HTML, JSON, Git, Scrum, Agila metoder.",
            infolines: []
        })

        // Högskolan på Gotland
        result.push({
            contentTitles: titles,
            schoolName: "Högskolan på Gotland",
            educationName: "Spelutveckling och Interaktiva medier",
            place: "Visby, Sverige",
            start: "2002",
            end: "2005",
            description: "Teknisk högskoleutbildning inriktad mot produktion av data och TV-spel, 3D programmering med diskret matematikk för 3D grafik, OpenGL, 3D Modellering, 3D animering, teksturering samt speldesign och produktion.",
            tech: "C++, Java, OpenGL, 3DStudio Max, Photoshop, Flash.",
            infolines: []
        })

        // Datahögskolan
        result.push({
            contentTitles: titles,
            schoolName: "Datahögskolan",
            educationName: "Kreativ Datavetenskap",
            place: "Stockholm, Sverige",
            start: "2000",
            end: "2001",
            description: "Utbildning i systemering, programmering, datastrukturer, objektorienterad programmering, Windows programmering (Med Microsoft Foundation Classes och Visual Studio), assemblering, flere programmeringsspråk med fokus på C++, systemdesign, projektering, avancerade algoritmer, optimering och datastrukturer.",
            tech: "C++, Objektorienterad programmering, Pascal, Visual Basic, Delphi, SQL, MFC, Visual Studio, assemblering",
            infolines: []
        })

        return result;
    }





    
    // English
    
    if(languageCode === "EN"){
        let titles = {
            schoolName: "School:",
            educationName: "Course:",
            grade: "Examination:",
            place: "Location:",
            sector: "Sector:",
            start: "Time period:",
            end: " - ",
            description: "Description:",
            tech: "Technology/Tools:",
            infolines: []
        };

        // YA - Yrkeshögskolan
        result.push({
            contentTitles: titles,
            schoolName: "YA Yrkeshögskolan",
            educationName: "Java Programming",
            grade: "Bachelor",
            place: "Östersund, Sverige",
            start: "2015",
            end: "2017",
            description: "Certified highschool examination: Specialized education toward Java programming, system design, web development, data security, databases and object oriented programming. The education is set to level 6 at www.seqf.se, which means an equivalent to bachelor according to International Standard Classification of Education (ISCED) (the scale goes from 1 to 8, where master is level 7)",
            tech: "Java, Netbeans, Eclipse, MySQL, XML, HTML, JSON, Git, Scrum, Agile methods.",
            infolines: []
        })

        // Högskolan på Gotland
        result.push({
            contentTitles: titles,
            schoolName: "Högskolan på Gotland",
            educationName: "Game development and interactive media",
            place: "Visby, Sweden",
            start: "2002",
            end: "2005",
            description: "Technical highschool education directed towards production of computer games, 3D programming using discreet mathematics for 3D graphics, OpenGL, 3D Modeling, 3D animation, texturing, game design and game production.",
            tech: "C++, Java, OpenGL, 3DStudio Max, Photoshop, Flash.",
            infolines: []
        })

        // Datahögskolan
        result.push({
            contentTitles: titles,
            schoolName: "Datahögskolan",
            educationName: "Creative Computer Science",
            place: "Stockholm, Sweden",
            start: "2000",
            end: "2001",
            description: "Highschool education in system design, programming, data structures, object oriented programming, Windows programming (Using Microsoft Foundation Classes and Visual Studio), assembling, several programming languages with focus on C++, system design, project planning, advanced algorithms, optimization and data structures.",
            tech: "C++, Object oriented programming, Pascal, Visual Basic, Delphi, SQL, MFC, Visual Studio, assembles",
            infolines: []
        })

        return result;
    }

    return result;
}