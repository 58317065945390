import * as React from 'react';
import {JGPage} from '../components/JGPage';
import {JGSelect} from '../components/JGSelect';
import {ContactSymbols} from '../components/ContactSymbols';
import {WordPipe} from '../tools/WordPipe';
import {ThemeStylesManager} from '../data/themeStyles';

/*
    ####    ###    ###   ##### 
    #   #  #   #  #   #  #     
    ####   #####  #      ###  
    #      #   #  #  ##  #     
    #      #   #   ####  ##### 
*/

export class PageContact extends JGPage{

    private mSelector: JGSelect|null;

    constructor(props:any){
        super(props);
        this.setStyleDefault({
            width:"100%", 
            height:"700px"
        })

        this.mComponentType = "PageContact";
        this.mSelector = null;
    }

    public setSelector(sel:JGSelect|null){
        this.mSelector = sel;
    }

    private onSelectItem(itemID:any){
        console.log("contact page received JGSelect item click with id: " + itemID);
    }

    componentDidMount(){
        this.getTheme()
        window.addEventListener("resize", this.onResize.bind(this));
    }

    componentWillUnMount(){
        window.removeEventListener("resize", this.onResize.bind(this))
    }

    onResize(){
        this.forceUpdate()
    }

    draw(){

        let theme = this.getTheme();

        enum display{
            mobile,
            ipad,
            desktop
        }

        let width = window.innerWidth;

        let detailColor = theme.getDetail(null, 0.5, 0).GetRGB();
        let highlightColor = theme.getDetail(null, 0.6, 0.6).GetRGB();

        let displayMode : display = display.mobile;

        let contentLeftRightMargin = ThemeStylesManager.getLRMargin();

        let topMargin = 40;
        let midspace = 50;
        let fontSizeMod = 0;

        let titleStyle = this.themeStyle("CVTitle")

        if(ThemeStylesManager.isIpadWide()){
            displayMode = display.ipad
        }
        else{
            midspace = 35;
            topMargin = 90;
            titleStyle.fontSize = "32px";
            fontSizeMod = -2;
        }

        if(ThemeStylesManager.isDesktopWide()){
            displayMode = display.desktop
        }

        // Footer
        let sectionFooter = (
            <div style={{
                position:"relative",
                height:"255px",
                marginTop:"10px"
            }}>

                <div style={{
                    height:"20px"
                }}/>

                <div style={titleStyle}>
                    {WordPipe.read("Contact_Title")}
                </div>

                <div style={this.themeStyle("CVUnderline")}/>

                <div style={{
                    position:"absolute",
                    top:(midspace+70) + "px",
                    left: contentLeftRightMargin + "px",
                    width:"40px",
                    height:"120px"
                }}>
                    <ContactSymbols baseColor={this.getTheme().getFG(null, 0.5, 0).GetRGB()}/>
                </div>
                
                <div style={{
                    position:"absolute",
                    top:(midspace+72) + "px",
                    left: (contentLeftRightMargin + 60) + "px",
                    color: theme.getDetail(null, 0.9, 0).GetRGB(),
                    fontSize: (fontSizeMod+24) + "px"
                }}>
                    +47 94089399
                </div>

                <div style={{
                    position:"absolute",
                    top: (midspace+145) + "px",
                    left: (contentLeftRightMargin + 60) + "px",
                    color: theme.getDetail(null, 0.9, 0).GetRGB(),
                    fontSize: (fontSizeMod+21) + "px"
                }}>
                    johan_granqvist@yahoo.se
                </div>

            </div>
        )

        let bgWidth = window.innerWidth;
        let bgRatio = 1267 / 1900;
        let bgHeight = window.innerHeight;
        let offsetX = 0;
        let offsetY = 0;

        let bgScale = 1.0;


        if(window.innerHeight > bgWidth*bgRatio){
            bgScale = window.innerHeight / (bgWidth*bgRatio);
            offsetX = ((window.innerWidth - (bgHeight/bgRatio))/2)*bgScale;
        }
        else{
            bgScale = (bgWidth*bgRatio) / window.innerHeight;
            offsetY = (((bgWidth*bgRatio) - (window.innerHeight))/1.5)*bgScale;
        }

        bgHeight = bgWidth * bgRatio * bgScale;
        bgWidth = bgWidth * bgScale;

        return(
            <div style={{
                width: "100%",
                marginTop: topMargin + "px",
                color: theme.getDetail(null,0.5,0).GetRGB()
            }}>
                <div style={{
                    zIndex: -100, 
                    position:"fixed", 
                    width: bgWidth + "px",
                    height: bgHeight + "px",
                    top: "0px", 
                    left: "0px", 
                    backgroundPositionX: (offsetX/2) + "px",
                    backgroundPositionY: -offsetY + "px",
                    backgroundImage: "url(https://i.imgur.com/QH8ZDKJ.jpg)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize:  bgWidth + "px " + bgHeight + "px",
                    opacity: 0.25
                    }}>
                </div>

                {sectionFooter}
            </div>
        )
    }
}
