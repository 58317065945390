interface infoline{
    title: string,
    content: string
}

export interface CVProjectTitles{
    projectName: string,
    employer: string,
    role: string,
    place: string,
    sector: string,
    start: string,
    end: string,
    description: string,
    tasks: string,
    tech: string,
    infolines: string[]
}

export interface CVProject{
    contentTitles: CVProjectTitles,
    projectName: string,
    employer?: string,
    role: string,
    place: string,
    start: string,
    end: string,
    sector?: string,
    description: string,
    tasks: string[],
    tech: string,
    infolines?: infoline[]
}

export function loadProjects( languageCode : string ){
    let result = new Array<CVProject>();

    // Norwegian

    if(languageCode === "NO"){
        let titles = {
            projectName: "Prosjektnavn:",
            employer: "Oppdragsgiver:",
            role: "Rolle:",
            place: "Sted:",
            sector: "Bransje:",
            start: "Tidsperiode:",
            end: " - ",
            description: "Beskrivelse:",
            tasks: "Oppgaver:",
            tech: "Teknologi/Verktøy:",
            infolines: []
        };

        result.push({
            contentTitles: titles,
            projectName: "JGMedia Kit",
            role: "Full stack utvikler",
            place: "Oslo",
            start: "4.2019",
            end: "...",
            sector: "Privat",
            description: "JGMedia Kit er en arkitektur for helhetlige web baserte systemer og består av en samling nye verktøy, gjennbrukbare komponenter og nye tekniske løsninger for webutvikling. JGMedia Kit består dels av en fleksibel og optimert serverarkitektur som bland annet gjør det lettere å slå sammen flere systemer til en domene. JGMedia Kit består også av en front end arkitektur med verktøy for å kontrollere ferge temaer med høy presisjon og direkte response i runtime, samt et nytt react basert komponent bibliotek for å lage grafiske grensesnitt. Den webbsida som du leser nå er lagd med hjelp av JGMedia Kit.",
            tasks: [
                "Lage en fleksibel og sjenerell front end arkitektur med verktøy for editering i runtime.",
                "Lage en back end arkitektur med vekt på sikkerhet og høy fleksibilitet.",
                "Lage en webb basert CV med inkludert portfolio ved bruk av disse nye verktøy."
            ],
            tech: "JGMedia Kit, Java, Netbeans, VS Code, React.js, JavaScript (tsx), CSS, HTML, JSON, Git, Node.js, Unit testing, CI, CD, Photoshop, Illustrator, SVG, WebGL, Vimeo, Responsive Design.",
            infolines: []
        })

        result.push({
            contentTitles: titles,
            projectName: "Taximum",
            employer: "Bjerk AS",
            role: "Front end utvikler",
            place: "Oslo",
            start: "3.2019",
            end: "4.2019",
            sector: "Privat",
            description: "Taximum er et system som hjelper taxi bedrifter til å rapportere og få oversikt over kostnader og fører statistikk. Johan laget og ferdigstilte en front end til systemet, der brukeren kan logge seg inn, endre og legge til statistikk fra bedriften sin og se utvalgt statistikk fra utvalgt tidsrom med data og grafer.",
            tasks: [
                "Lage en front end arkitektur for Taximum fra bunn.",
                "Lage grafiske komponenter for interaksjon med systemet med React og JavaScript (der komponentbiblioteket Grommet ikke har tilsvarende komponenter som i ønsket design).",
                "Utvikling av logikk for utvalg, editering og visning av bruker statistikk.",
                "Testing, unit testing."
            ],
            tech: "VS Code, React.js, JavaScript (tsx), CSS, HTML, JSON, Git, Redux, Node.js, Unit testing, Grommet.",
            infolines: []
        })

        result.push({
            contentTitles: titles,
            projectName: "OLA – Key manager",
            employer: "Bjerk AS",
            role: "Front end utvikler",
            place: "Oslo",
            start: "3.2019",
            end: "4.2019",
            sector: "Privat",
            description: "Programmet er utformet for å administrere bestillinger av nøkler innen et stort antall borettslag i Norge. Key manager er en del av et bredere prosjekt (OLA) som utvikles i teamarbeid innen Bjerk, der Johan dels ansvarer for å utvikle en front end for key manager og i tillegg for å lage en standardisert hjemme side til alle de borettslag som skal bruke systemet.",
            tasks: [
                "Lage grensesnitt for bestilling av nøkler samt opplisting og status for bestillinger.",
                "Lage de grafiske komponenter som trenges og som ikke finnes i Grommet, med grafikk og interaksjonslogikk.",
                "Utvikling av testmoduler."
            ],
            tech: "VS Code, React.js, JavaScript (tsx), CSS, HTML, WebSQL, Git, Jira, Node.js, Gatsby, Unit testing, Photoshop, Grommet.",
            infolines: []
        })

        result.push({
            contentTitles: titles,
            projectName: "FOR-2019",
            employer: "Bjerk AS",
            role: "Utvikler",
            place: "Oslo",
            start: "3.2019",
            end: "4.2019",
            sector: "Privat",
            description: "Norsk Filmforbund (NFF) trengte en sammenstilling av sendings data fra alle de store TV kanalene i Norge for å kunne kreve og fordele de pengene som film produsentene har rett til. Johan lagde et program som importerte all sendings data fra varierende format til en felles datakilde, og lagde algoritmer for å korrigere og sikre kvalitet på all inn data, sånn at den deretter kan brukes i database og som underlag for beregninger. Programmet som Johan lagde utførte sammenlagt over 90000 nødvendige korrigeringer i datakilden, som deretter ble importert til en database hos NFF.",
            tasks: [
                "Sammenstill data fra TV kanalenes sendings data (ifa Excel ark med varierende formatering og masse avvik) til en gemensom datakilde, levert som en eneste JSON fil med et spesifisert format for import til NFF sin database.",
                "Behandle alle feil og formatforseller fra datakildene ved å lage parsers og algoritmer for feilretting, sånn at den nye datakilden blir brukbar for beregninger."
            ],
            tech: "Java (NetBeans), VS Code, Excel, Git.",
            infolines: []
        })

        result.push({
            contentTitles: titles,
            projectName: "Prisassistenten",
            employer: "Glassworks AS, Focus Software AS",
            role: "Front end arkitekt",
            place: "Oslo",
            start: "3.2017",
            end: "1.2019",
            sector: "Privat",
            description: "Utvikling av front end til Prisassistenten, et nettbasert system som gjør store mengder bygningsrelaterte produktdata tilgjengelige og søkbare for byggevirksomheter og andre brukere av byggematerialer. Prisassistenten hjelper bygningsentreprenøren til å beregne overheadkostnader for byggeprosjekter, få detaljert informasjon om byggevarer og organiserer utvalg av varer til såkalt bygningsdeler, som tilsvarer enkelte deler av eller hele bygningsprosjekt. Prisassistenten erstatter et tidligere system med samme navn som ikke var nettbasert.  Den etterlikner det tidligere systemet i funksjonalitet, men er nettbasert og krever ingen installasjon, har ny og bedre design, mer fleksibilitet og er bedre tilpasset moderne behov og moderne hardware.",
            tasks: [
                "Utvikle en ny arkitektur for Prisassistenten fra bunn",
                "Lage UX design og interaksjonsdesign for front end, basert på designdokument og i samarbeid med fagkyndige innen byggebransjen",
                "Lage grafiske komponenter for interaksjon med systemet",
                "Utvikling av søkealgoritmer med avanserte søkeinnstillinger og høyoptimalisert visning av søkeresultat",
                "Testledelse og utvikling av test og debugmoduler for systemet",
                "Optimalisering, tilpassinger og funksjonstillegg etter forespørsler fra Byggdata AS"
            ],
            tech: "Visual Studio, React.js, Javascript (ts ,tsx), CSS, HTML, Photoshop, JSON, Git",
            infolines: []
        })

        result.push({
            contentTitles: titles,
            projectName: "INVASION:GOTLAND",
            role: "Lead Tech",
            place: "Visby, Sverige",
            start: "6.2004",
            end: "6.2005",
            description: "INVASION:GOTLAND er et modernt multi-player TV spill basert på spillet Battlefield:Vietnam, men som handler om et fiktivt 1977, der den svenske øya Gotland blir invadert av ryssere og må forsvares mot det ryske angrepet. Spillet er lagt i 70-talls retro stil, med våpen, fordon og utstyr lagd i samstemmighet med vad som bruktes under 70 tallets Sverige og Sovietunionen, liksom velkjente gotlandske miljøer, musikk og fordon som for eksempel Volvo 245. Alle karakterer i spillet snakker svensk eller russisk. Utviklings teamet (bestående av 9 spillutviklere) gjorde beta release for spillet I Visby den 11 desember 2004, med stor oppmerksomhet fra media, politikere og spill entusiaster, even tjenestemen fra Gotlands military regiment P-18 deltog i det store lan konventet og spillet har deretter oppnådd stor popularitet bland battlefield entusiaster over hele verden. ",
            tasks: [
                "Hovedansvar for script og koding.",
                "Lage automatiseringer og verktøy for å underlette arbeidsprosessene.",
                "Lage logikk for å sette sammen all grafikk, animasjoner, lagde miljøer, modeller, musikk, lyd og annet materiale fra spillutviklerne til å bli til nye versjoner av spillet.",
                "Testing og problemfiksing"
            ],
            tech: "C++ (Visual Studio), 3D Studio Max, Photoshop, Battle Craft (fra BF:Vietnam).",
            infolines: []
        })
    }





    
    // Swedish

    if(languageCode === "SE"){
        let titles = {
            projectName: "Projekt namn:",
            employer: "Uppdragsgivare:",
            role: "Roll:",
            place: "Plats:",
            sector: "Sektor:",
            start: "Tidsperiod:",
            end: " - ",
            description: "Beskrivning:",
            tasks: "Uppgifter:",
            tech: "Teknologier/Redskap:",
            infolines: []
        };

        result.push({
            contentTitles: titles,
            projectName: "JGMedia Kit",
            role: "Full stack utvecklare",
            place: "Oslo",
            start: "4.2019",
            end: "...",
            sector: "Privat",

            description: "JGMedia Kit är en arkitektur för webb baserade system och består av en samling nya redskap, komponenter och nya tekniska lösningar för webbutveckling. JGMedia kit består dels av en flexibel och snabb serverarkitektur som bland annat gör det lättare att sätta samman flera system till en domän. JGMedia Kit har också en klient arkitektur med verktyg för att kontrollera färgteman med hög precision och direktrespons under körtid, samt ett nytt react baserat komponent bibliotek för det grafiska gränssnittet. Denna webbsida som du läser nu är skapad med hjälp av JGMedia Kit.",
            tasks: [
                "Skapa en flexibel och generell front end arkitektur med verktyg för realtidseditering.",
                "Skapa en back end arkitektur med vikt på säkerhet, snabbhet och flexibilitet.",
                "Skapa en webb baserad CV med portfolio med hjälp av dessa nya verktyg."
            ],
            tech: "JGMedia Kit, Java, Netbeans, VS Code, React.js, JavaScript (tsx), CSS, HTML, JSON, Git, Node.js, Unit testing, CI, CD, Photoshop, Illustrator, SVG, WebGL, Vimeo, Responsive Design.",
            infolines: []
        })

        result.push({
            contentTitles: titles,
            projectName: "Taximum",
            employer: "Bjerk AS",
            role: "Front end utvecklare",
            place: "Oslo",
            start: "3.2019",
            end: "4.2019",
            sector: "Privat",

            description: "Taximum är ett system som hjälper taxi-företag at rapportera in och få översikt över kostnader och statistik. Johan skapade och färdigställde en front end till systemet, där användaren kan logga in, ändra och lägga till statistik från verksamheten och se utvald statistik från utvald tidsperiod och få det uppvisat med grafer.",
            tasks: [
                "Skapa en front end arkitektur till Taximum.",
                "Skapa grafiska komponenter för interaktion med systemet i React og med JavaScript (i de fall där komponentbiblioteket Grommet inte har motsvarende komponenter som i den önskade designen).",
                "Utveckling av logik for urval, editering och visning av användarstatistik.",
                "Testning, automatiserad testning."
            ],
            tech: "VS Code, React.js, JavaScript (tsx), CSS, HTML, JSON, Git, Redux, Node.js, Unit testing, Grommet.",
            infolines: []
        })

        result.push({
            contentTitles: titles,
            projectName: "OLA – Key manager",
            employer: "Bjerk AS",
            role: "Front end utvecklare",
            place: "Oslo",
            start: "3.2019",
            end: "4.2019",
            sector: "Privat",
            description: "Programmet är utformat för att administrera beställningar av nycklar inom ett stort antal bostadsrättsföreningar i Norge. Key Manager är en del av ett bredare projekt (OLA) som utvecklas i teamarbete inom Bjerk, där Johan dels ansvarar för att utveckla en klient till Key Manager och i tillägg skapar en standardiserad hemsida till de föreningar som skall använda systemet.",
            tasks: [
                "Skapa ett gränssnitt för beställningar av nycklar där beställningar visas upp med uppdaterad status för leverans.",
                "Skapa de grafiska komponenter som behövs och som inte finns i komponentbiblioteket Grommet, med grafik och interaktionslogik.",
                "Utveckling av automatiska tester."
            ],
            tech: "VS Code, React.js, JavaScript (tsx), CSS, HTML, WebSQL, Git, Jira, Node.js, Gatsby, Automatiska tester, Photoshop, Grommet.",
            infolines: []
        })

        result.push({
            contentTitles: titles,
            projectName: "FOR-2019",
            employer: "Bjerk AS",
            role: "Utvikler",
            place: "Oslo",
            start: "3.2019",
            end: "4.2019",
            sector: "Privat",
            description: "Norsk Filmforbund (NFF) behövde en sammanställning av sändningsdata från alla de stora TV Kanalerna i Norge för att kunna kräva och fördela de pengar som film producenterna har rätt till. Johan skapade ett program som importerade all sändnings data från varierande format till en gemensam datakälla och skapade algoritmer för att korrigera och säkra kvaliteten på all ingående data, så att den därefter kan användas i en databas och som underlag för beräkningar. Programmet som Johan skapade utförde sammanlagt över 90000 nödvändiga korrigeringar i datakällan, som därefter blev inporterad till en databas hos NFF.",
            tasks: [
                "Sammanställ data från TV Kanalernas sändningar (i form av Excel ark med varierande formatering och många fel) till en gemensam datakälla, levererad som en JSON fil med et specifikt format för import till NFF's databas.",
                "Bearbeta alla fel och formatskillnader i datakällorna genom att skapa parsers och algoritmer för felrättning, så att den nya datakällan blir användbar för beräkningar."
            ],
            tech: "Java (NetBeans), VS Code, Excel, Git.",
            infolines: []
        })

        result.push({
            contentTitles: titles,
            projectName: "Prisassistenten",
            employer: "Glassworks AS, Focus Software AS",
            role: "Front end arkitekt",
            place: "Oslo",
            start: "3.2017",
            end: "1.2019",
            sector: "Privat",
            description: "utveckling av front end til Prisassistenten, ett webb-baserat system som gör stora mängder bygg-relaterad produktdata tillgänglig och sökbar för byggverksamheter och andra användare av byggmaterial. Prisassistenten hjälper byggentrepenörer att beräkna overheadkostnader för byggprojekt, ger detaljerad information om byggvaror och organiserar urval av varor til så kallade byggdelar, som motsvarar delar av eller hela byggprojekt. Prisassistenten ersätter ett tidigare system med samma namn som inte var nätbaserat. Prisassistenten efterliknar det tidigare systemet i funktionalitet, men är nätbaserat og kräver ingen installation, har ny og bättre design, mer flexibilitet och är bättre anpassat till modärna behov och modärna hårdvaror.",
            tasks: [
                "Utveckla en ny arkitektur för Prisassistenten från scratch",
                "Skapa UX design och interaktionsdesign för front end, baserat på designdokument och i samarbete med kunniga inom byggbranshen",
                "Skapa grafiska komponenter för interaktion med systemet",
                "Utveckling av sökalgoritmer med avancerade sökinställingar och högoptimerad visning av sökresultat",
                "Testledning och utveckling av test- och debugmoduler för systemet",
                "Optimering, anpassningar och funktionstillägg enligt önskemål från Byggdata AS"
            ],
            tech: "Visual Studio, React.js, Javascript (ts ,tsx), CSS, HTML, Photoshop, JSON, Git",
            infolines: []
        })

        result.push({
            contentTitles: titles,
            projectName: "INVASION:GOTLAND",
            role: "Lead Tech",
            place: "Visby, Sverige",
            start: "6.2004",
            end: "6.2005",

            description: "INVASION:GOTLAND är ett modernt multi-player TV-spel baserat på spelet Battlefield:Vietnam, men som handlar om ett fiktivt 1977, där den svenska ön Gotland blir invaderat av ryssar och måste försvaras mot det ryska angreppet. Spelet är gjort i 70-tals retro stil, med vapen, fordon och utrustning skapad i samstämmighet med vad som användes under 70 talets Sverige och Sovietunionen, liksom välkända gotländska miljöer, musik och fordon som till exempel Volvo 245. Alla karaktärer i spelet talar svenska eller ryska. Utvecklings teamet (bestående av 9 spelutvecklere) gjorde en beta release för spelet I Visby den 11 december 2004, med stor uppmärksamhet från media, politiker och spel entusiaster, även tjänstemän från Gotlands militärregemente P-18 deltog i det stora lan-konventet och spelet har därefter uppnått stor popularitet bland FPS-entusiaster över hela världen.",
            tasks: [
                "Huvudansvar för skriptning och kodning.",
                "Skapa automatiseringar och verktyg för att underlätta arbetsprocesser.",
                "Skapa logik för att sätta samman all grafik, animation, skapade miljöer, modeller, musik, ljud och annat material från spelutviklarna till nya versioner av spelet.",
                "Testning og problemlösning"
            ],

            tech: "C++ (Visual Studio), 3D Studio Max, Photoshop, Battle Craft (från BF:Vietnam).",
            infolines: []
        })
    }







    // English

    if(languageCode === "EN"){
        let titles = {
            projectName: "Project name:",
            employer: "Employer:",
            role: "Title:",
            place: "Location:",
            sector: "Sector:",
            start: "Time:",
            end: " - ",
            description: "Description:",
            tasks: "Tasks:",
            tech: "Technology/Tools:",
            infolines: []
        };

        result.push({
            contentTitles: titles,
            projectName: "JGMedia Kit",
            role: "Full stack developer",
            place: "Oslo",
            start: "4.2019",
            end: "...",
            sector: "Private",
            description: "JGMedia Kit is an architecture for web based systems and consist of a collection of new tools, reusable components and new technologies for web development. JGMedia Kit has a flexible and optimized server architecture which among other things makes it easy to fuse different websites into a single domain. JGMedia Kit also has a front end arkiteckture with tools for controlling color themes with high precision and direct responce in runtime, aswell as a new React.js based component library. The website that you are reading now was built using JGMedia Kit.",
            tasks: [
                "Create a flexible and general front end architecture with tools for making runtime editions.",
                "Create a back end architecture with high security as priority along with speed and high flexibility.",
                "Make a web based CV with portfolio included using these new tools."
            ],
            tech: "JGMedia Kit, Java, Netbeans, VS Code, React.js, JavaScript (tsx), CSS, HTML, JSON, Git, Node.js, Unit testing, CI, CD, Photoshop, Illustrator, SVG, WebGL, Vimeo, Responsive Design.",
            infolines: []
        })

        result.push({
            contentTitles: titles,
            projectName: "Taximum",
            employer: "Bjerk AS",
            role: "Front end developer",
            place: "Oslo",
            start: "3.2019",
            end: "4.2019",
            sector: "Private",
            description: "Taximum is a system that helps taxi driving businesses to report and get an overview of costs and driver statistics. Johan created and completed a front end for the system, where the user can log in, edit and add driver statistics, view statistics from any chosen time period displayed with graphs and data.",
            tasks: [
                "Create a new front end architecture for Taximum from scratch.",
                "Create graphical components for interaction with the system using React.js and Javascript (wherever the component library Grommet misses any suitable components for the design).",
                "Development of logics for selection, edition and display of driver statistics.",
                "Testing, unit testing."
            ],
            tech: "VS Code, React.js, JavaScript (tsx), CSS, HTML, JSON, Git, Redux, Node.js, Unit testing, Grommet.",
            infolines: []
        })

        result.push({
            contentTitles: titles,
            projectName: "OLA – Key manager",
            employer: "Bjerk AS",
            role: "Front end developer",
            place: "Oslo",
            start: "3.2019",
            end: "4.2019",
            sector: "Private",
            description: "The program is designed for administrating orders of house keys within a large amount of house owner communities within Norway. Key manager is part of a broader project (OLA) which is developed in teamwork within Bjerk, where Johan is responsible for developing the front end for key manager and in addition make a standardized home page for all the house owner communities which are using the system.",
            tasks: [
                "Create a user interface for ordering house keys and have the orders listed up with order statuses being displayed and updated.",
                "Create the required graphical components that can't be found within the component library Grommet, med graphics and interaction logics.",
                "Development of unit test modules."
            ],
            tech: "VS Code, React.js, JavaScript (tsx), CSS, HTML, WebSQL, Git, Jira, Node.js, Gatsby, Unit testing, Photoshop, Grommet.",
            infolines: []
        })

        result.push({
            contentTitles: titles,
            projectName: "FOR-2019",
            employer: "Bjerk AS",
            role: "Programmer",
            place: "Oslo",
            start: "3.2019",
            end: "4.2019",
            sector: "Private",
            description: "Norsk Filmforbund (NFF) was needing a summary of broadcasting data from all of the mayor TV channels in Norway in order to demand and distribute the money that the film producers have right to claim. Johan created a program that imported broadcasting data from a variety of formats into a common data source and made algorithms for correcting and securing the quality of all incoming data, so that it can be used within the database as basis for calculations. The program that Johan made performed more than 90.000 necessary corrections in the data source, which then was imported into a database owned by NFF.",
            tasks: [
                "Gather data from the TV channels broadcasting data (as Excel files with a variety of formats and lots of errors) into a common data source, delivered as a single JSON file with a specified format for importing it into the database of NFF.",
                "Correct all errors and format differences from the data sources by making parsers and algorithms for error corrections, so that the new data source becomes usable for calculations."
            ],
            tech: "Java (NetBeans), VS Code, Excel, Git.",
            infolines: []
        })

        result.push({
            contentTitles: titles,
            projectName: "Prisassistenten",
            employer: "Glassworks AS, Focus Software AS",
            role: "Front end architect",
            place: "Oslo",
            start: "3.2017",
            end: "1.2019",
            sector: "Private",
            description: "Developing the front end for Prisassistenten, a web based system that makes a large amount of building related product data available and searchable for building entrepeneurs and other consumers of building materials. Prisassistenten helps the builder to calculate overhead costs of building projects, get detailed information of building products and organizing selections of wares into so called build parts, which represents parts of or entire building projects. Prisassistenten replaces an earlier system with the same name which was not web based, and mimics the functionalities of the previous system while being web based and without requiring any installation, having a new and better design, more flexibility and better accustomisation to modern needs and hardware.",
            tasks: [
                "Develop a new modernized architecture for Prisassistenten from scratch",
                "Create UX design for the front end, based on a design document and in cooperation with experts within the building industry",
                "Creating graphical components for interaction with the system",
                "Developing search algorithms with advanced search settings and highly optimized display of search results",
                "Test leading and development of test and debug modules for the system",
                "Optimization, adjustments and adding functionalities upon request from Byggdata AS"
            ],
            tech: "Visual Studio, React.js, Javascript (ts ,tsx), CSS, HTML, Photoshop, JSON, Git",
            infolines: []
        })

        result.push({
            contentTitles: titles,
            projectName: "INVASION:GOTLAND",
            role: "Lead Tech",
            place: "Visby, Sverige",
            start: "6.2004",
            end: "6.2005",
            description: "INVASION:GOTLAND is a modern FPS multi-player computer game based on the game Battlefield:Vietnam, but which takes place in a fictive 1977, where the swedish island Gotland is being invaded by russians and has to be defended from the russian assault. The game is made in a 70:s retro style, with weapons, vehicles and equipment made in similarity with what was used at the time by Sweden and the Soviet Union. The game takes place in well recognized environments of Gotland, with familiar music and vehicles as for example the Volvo 245. All characters speak either swedish or russian. The development team (consisting of 9 game developers) made a beta release in Visby the 11:th december 2004, with great attention from media, politicians and game enthusiasts, even officers from Gotland's military regiment P-18 stopped by to participate in the grand lan convent and the game has since then reached great populaarity among Battlefield enthusiasts all over the world.",
            tasks: [
                "Main responsibility for all scripting and coding.",
                "Create automations and tools for making the working processes of team members more efficient.",
                "Create logics for puting together all graphics, animations, game environments, 3D models, music, sounds, and other materials from the game developers into new versions of the game.",
                "Testing and problem solving"
            ],
            tech: "C++ (Visual Studio), 3D Studio Max, Photoshop, Battle Craft (from BF:Vietnam).",
            infolines: []
        })
    }

    return result;
}