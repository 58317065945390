import React from 'react';
import { ThemeComponent } from "./theme";

export interface RollingBackgroundProps{
    themeID? : string,
	style?:any,
	className?:string
}

export class RollingBackground extends ThemeComponent<RollingBackgroundProps,{}>{
    constructor(props:RollingBackgroundProps){
        super(props);
    }

    componentWillMount(){
        window.addEventListener("scroll", this.onScroll.bind(this))
    }

    componentWillUnMount(){
        window.removeEventListener("scroll", this.onScroll.bind(this))
    }

    onScroll(){
        this.forceUpdate()
    }

    draw(){

        // width 540 * height 960

        
        let width = window.innerWidth;
        let height = window.innerHeight * 2;

        let ratio = 960 / 540;

        let scrollMax = 4000 + (width * 6)

        let posY = 0 - (height * (window.scrollY / scrollMax));

        if((height/width) >= 3){
            width *= 1.7;
        }

        return (
            <div style={{
                zIndex: -100, 
                position:"fixed", 
                width:"100%",
                height:"100%",
                top:"0px", 
                left:"0px", 
                backgroundImage: "url(https://i.imgur.com/KKR5KP9.jpg)",
                backgroundRepeat: "no-repeat",
                backgroundSize: width + "px " + height + "px",
                backgroundPosition: "0px " + posY + "px",
                opacity: 0.35
                }}>
            </div>
        )
    }
}