import * as React from 'react';
import { Theme } from '../components/theme';
import { CVProject } from '../data/loadProjects';

export interface ProjectDisplayProps{
    theme: Theme,
    project: CVProject
}

type fontweight = number | "bold" | "-moz-initial" | "inherit" | "initial" | "revert" | "unset" | "normal" | "bolder" | "lighter" | undefined;
type flexDir = "-moz-initial" | "inherit" | "initial" | "revert" | "unset" | "row" | "column" | "column-reverse" | "row-reverse" | undefined;
type textAlignment = "-moz-initial" | "inherit" | "initial" | "revert" | "unset" | "left" | "center" | "end" | "justify" | "match-parent" | "right" | "start" | undefined;
type flexWrap = "-moz-initial" | "inherit" | "initial" | "revert" | "unset" | "wrap" | "nowrap" | "wrap-reverse" | undefined;

export class ProjectDisplay extends React.Component<ProjectDisplayProps, {}>{

    constructor(props:ProjectDisplayProps){
        super(props)
    }

    render(){
        let contents = new Array<JSX.Element>();
        let keyProgress = 0;
        let fontBold : fontweight = "bold";
        let flexRow : flexDir = "row";
        let leftAligned : textAlignment = "left";
        let flexWrapping : flexWrap = "wrap";

        let surroundingStyle = {
            width:"calc(100% - 30px)",
            display:"flex",
            flexDirection: flexRow,
            flexWrap: flexWrapping
        }

        let lineTitleStyle = {
            fontWeight: fontBold,
            color:  this.props.theme.getDetail(null, 1, 0).GetRGB(),
            marginLeft: "15px",
            marginRight: "10px",
            marginTop: "5px"
        }

        let lineContentStyle = {
            marginLeft: "15px",
            marginTop: "5px",
            textAlign: leftAligned
        }

        contents.push(
            <div key={"key" + (keyProgress++)} style={{
                height:"11px"
            }}>
            </div>
        )

        // Employer
        if(this.props.project.employer){
            contents.push(
                <div key={"key" + (keyProgress++)} style={surroundingStyle}>
                    <div style={lineTitleStyle}>
                        {this.props.project.contentTitles.employer}
                    </div>
                    <div style={lineContentStyle}>
                        {this.props.project.employer}
                    </div>
                </div>
            )
        }

        // ProjectName
        contents.push(
            <div key={"key" + (keyProgress++)} style={surroundingStyle}>
                <div style={lineTitleStyle}>
                    {this.props.project.contentTitles.projectName}
                </div>
                <div style={lineContentStyle}>
                    {this.props.project.projectName}
                </div>
            </div>
        )

        // Timespan
        contents.push(
            <div key={"key" + (keyProgress++)} style={surroundingStyle}>
                <div style={lineTitleStyle}>
                    {this.props.project.contentTitles.start}
                </div>
                <div style={lineContentStyle}>
                    {this.props.project.start + " - " + this.props.project.end}
                </div>
            </div>
        )

        // Role
        contents.push(
            <div key={"key" + (keyProgress++)} style={surroundingStyle}>
                <div style={lineTitleStyle}>
                    {this.props.project.contentTitles.role}
                </div>
                <div style={lineContentStyle}>
                    {this.props.project.role}
                </div>
            </div>
        )

        // Description
        contents.push(
            <div key={"key" + (keyProgress++)} style={surroundingStyle}>
                <div style={lineTitleStyle}>
                    {this.props.project.contentTitles.description}
                </div>
                <div style={lineContentStyle}>
                    {this.props.project.description}
                </div>
            </div>
        )

        // Tasks
        contents.push(
            <div key={"key" + (keyProgress++)} style={surroundingStyle}>
                <div style={lineTitleStyle}>
                    {this.props.project.contentTitles.tasks}
                </div>
            </div>
        )

        for(let j=0; j<this.props.project.tasks.length; j++){
            contents.push(
                <div key={"key" + (keyProgress++)} style={{
                    position:"relative",
                    width:"calc(100% - 30px)"
                }}>
                    <div style={{
                        position: "absolute",
                        left:"25px",
                        top:"8px",
                        width:"6px",
                        height:"6px",
                        borderRadius:"3px",
                        backgroundColor: this.props.theme.getDetail(null, 0.5, 0).GetRGB(),
                    }}/>
                    <div style={{
                        marginLeft:"38px",
                        marginTop: "10px",
                        textAlign: leftAligned
                    }}>
                        {this.props.project.tasks[j]}
                    </div>
                </div>
            )
        }

        contents.push(
            <div key={"key" + (keyProgress++)} style={{
                height:"7px"
            }}>
            </div>
        )

        // Technologies
        contents.push(
            <div key={"key" + (keyProgress++)} style={surroundingStyle}>
                <div style={lineTitleStyle}>
                    {this.props.project.contentTitles.tech}
                </div>
                <div style={lineContentStyle}> 
                    {this.props.project.tech}
                </div>
            </div>
        )

        contents.push(
            <div key={"key" + (keyProgress++)} style={{
                height:"16px"
            }}>
            </div>
        )

        return(
            <div style={{
                width: "100%",
                backgroundColor: this.props.theme.getBG(null, 0.1, 0).GetRGB(),
                border: "2px solid " + this.props.theme.getBG(null, 1, 0).GetRGB(),
                borderRadius: "15px",
                minHeight: "150px"
            }}>
                {contents}
            </div>
        )
    }
}