export class LanguageBook{
    private mLanguageCode:string; // For example "EN", "SE" or "NO"
    private mLines: {lineID:string, line:string}[];

    constructor(languageCode:string){
        this.mLines = new Array<{lineID:string, line:string}>();
        this.mLanguageCode = languageCode;
        WordPipe.addLanguage(this);
    }

    public add(id:string, word:string){
        this.mLines.push({lineID:id, line:word})
    }

    public getLanguageCode(){
        return this.mLanguageCode;
    }

    public read(lineID:string){
        for(let word = 0; word<this.mLines.length; word++){
            if(this.mLines[word].lineID === lineID){
                return this.mLines[word].line;
            }
        }

        return null;
    }
}

// WordPipe lets you request words or texts from a chosen language
export class WordPipe{
    static sLanguageBooks: LanguageBook[] = new Array<LanguageBook>();
    static sLanguageCode: string = "EN";

    static setLanguage(languageCode: string){
        WordPipe.sLanguageCode = languageCode;
    }

    static getLanguageCode(){
        return WordPipe.sLanguageCode;
    }

    static getLanguageBook(languageCode: string){
        for(let i=0; i<WordPipe.sLanguageBooks.length; i++){
            if(WordPipe.sLanguageBooks[i].getLanguageCode() === languageCode){
                return WordPipe.sLanguageBooks[i];
            }
        }

        return new LanguageBook(languageCode);
    }

    static addLanguage(languageBook:LanguageBook){
        WordPipe.sLanguageBooks.push(languageBook)
    }

    static read(lineID:string, languageCode?:string){
        let lang = languageCode ? languageCode : WordPipe.sLanguageCode;

        for(let book = 0; book<WordPipe.sLanguageBooks.length; book++){
            if(WordPipe.sLanguageBooks[book].getLanguageCode() === lang){
                let result = WordPipe.sLanguageBooks[book].read(lineID);

                if(result !== null){
                    return result;
                }
                else{
                    console.warn("WordPipe : Came across a word that's missing in LanguageBook:" + lang + " - id:" + lineID);
                    return "[missing line:" + lineID + " in language book:" + lang + "]";
                }
            }
        }

        console.warn("WordPipe - Missing LanguageBook : " + lang);
        return "[missing languageBook:" + lang + "]";
    }
}